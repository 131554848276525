import EngagementConfiguration from "./EngagementConfiguration";
import UserSegments from "./UserSegments";

export interface RecencyGroupInterface {
  id: number;
  period: number;
}

export default class EngagementCoefficientsConfiguration {
  enabled: boolean = false;
  userSegments: UserSegments[] = [];
  recencyGroups: RecencyGroupInterface[] = [];
  engagementConfig: EngagementConfiguration = new EngagementConfiguration();
}

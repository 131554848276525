import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import React from "react";
import { placeholders } from "../../../../../assets/placeholders/placeholders";
import { formatProfileDate } from "../../../../../helpers/helpers";

/**
 * Returns assests corresponding to the providede entity
 * @param showType When true, entity is competition and when its false, entity is team.
 * @param showGender When it's false, entity is player
 */

export const getAssetsForEntity = (
  showType: boolean,
  showGender: boolean,
  entity: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel
): string => {
  if (!showGender) {
    return entity &&
      entity.assets &&
      //@ts-ignore
      entity.assets.headshot
      ? //@ts-ignore
        entity.assets.headshot
      : placeholders.avatar;
  }

  if (showType) {
    return entity &&
      entity.assets &&
      //@ts-ignore
      entity.assets.logo
      ? //@ts-ignore
        entity.assets.logo
      : placeholders.competition;
  } else if (!showType) {
    return entity &&
      entity.assets &&
      //@ts-ignore
      entity.assets.logo
      ? //@ts-ignore
        entity.assets.logo
      : placeholders.team;
  }

  return "";
};

type EntityInformationProps = {
  entity: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel;
  showType: boolean; // We have type only for CompetitionBasicModel, but we can not use it as a check for the entity instance, so we will additional prop for it.
  showInfoThatPlayerDontMatch: boolean; // We don't have gender for PlayerBasicModel and we can not use it as a check for the entity instance, so we will additional prop for it.
};

const EntityInformation: React.FC<EntityInformationProps> = ({
  entity,
  showType,
  showInfoThatPlayerDontMatch,
}) => {
  return (
    <div className="flex items-center">
      <img
        src={getAssetsForEntity(showType, showInfoThatPlayerDontMatch, entity)}
        className={`${showType ? "w-16" : "w-12"} h-12 mr-4`}
      ></img>
      <div className="mb-4 w-full">
        <h3 className="font-bold">{entity?.name}</h3>
        <div className="lg:flex lg:flex-row lg:items-center hidden">
          <p className="text-sm">
            Country:{" "}
            <span className="font-bold text-blueGray-400 text-sm mr-2">
              {entity?.country?.name}
            </span>
          </p>
          {entity &&
          showType &&
          //@ts-ignore
          entity.type ? (
            <p className="text-sm">
              Type:{" "}
              <span className="font-bold text-blueGray-400 text-sm mr-2">
                {
                  //@ts-ignore
                  entity.type
                }
              </span>
            </p>
          ) : null}
          {showInfoThatPlayerDontMatch ? (
            <p className="text-sm">
              Gender:{" "}
              <span className="font-bold text-blueGray-400 text-sm mr-2">
                {
                  //@ts-ignore
                  entity?.gender
                }
              </span>
            </p>
          ) : null}
          {!showInfoThatPlayerDontMatch ? (
            <p className="text-sm">
              Birthdate:{" "}
              <span className="font-bold text-blueGray-400 text-sm mr-2">
                {
                  //@ts-ignore
                  formatProfileDate(entity?.birthDate)
                }
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EntityInformation;

import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../../enums/enums";
import {
  inputNumber,
  remapKeysToSpecificCase,
} from "../../../../helpers/helpers";
import ExternalPointsModel, {
  ExternalPoints,
} from "../../../../models/features/externalPoints/ExternalPointsModel";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import ExternalPointsConfigValidator from "../../../../validators/ExternalPointsConfigValidator";
import AddButton from "../../../Buttons/AddButton";
import RemoveButton from "../../../Buttons/RemoveButton";
import UpdateButton from "../../../Buttons/UpdateButton";
import Enabled from "../Common/Enabled";
import MaximumCountTooltip from "./MaximumCountTooltip";
import { ApiContext } from "../../../../providers/ApiProvider";

type ExternalPointsComponentProps = {
  externalPoints: ExternalPointsModel;
  isExternalPointsFeaturesEdited: any;
};

const ExternalPointsComponent: React.FC<ExternalPointsComponentProps> = ({
  externalPoints,
  isExternalPointsFeaturesEdited,
}) => {
  // Make copy to default external points config. That way we will handle the UI update.
  const copyExternalPointsConfig = JSON.parse(JSON.stringify(externalPoints));

  const [newExternalPoints, setNewExternalPoints] = useState<
    ExternalPoints[] | null
  >(externalPoints.points);
  const [updatedExternalPoints, setUpdatedExternalPoints] = useState<
    ExternalPoints[] | null
  >(copyExternalPointsConfig.points);

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: "id" | "points" | "maxCount",
    index: number
  ) => {
    if (field === "id") {
      setUpdatedExternalPoints((prevState: ExternalPoints[] | null) => {
        if (!prevState) {
          const newExternalPoints: ExternalPoints[] = [
            {
              id: event.target.value,
              points: null,
              maxCount: null,
            },
          ];

          return newExternalPoints;
        }

        const copyCurrentExternalPoints = JSON.parse(
          JSON.stringify(prevState)
        ) as ExternalPoints[];
        copyCurrentExternalPoints[index].id = event.target.value;

        return copyCurrentExternalPoints;
      });
    } else if (field === "points") {
      const pointsNumber = Number(event.target.value);

      if (pointsNumber) {
        setUpdatedExternalPoints((prevState: ExternalPoints[] | null) => {
          if (!prevState) {
            const newExternalPoints: ExternalPoints[] = [
              {
                id: null,
                points: pointsNumber,
                maxCount: null,
              },
            ];

            return newExternalPoints;
          }

          const copyCurrentExternalPoints = JSON.parse(
            JSON.stringify(prevState)
          ) as ExternalPoints[];
          copyCurrentExternalPoints[index].points = pointsNumber;

          return copyCurrentExternalPoints;
        });
      } else {
        // Client has set invalid number (for example -123-32). Then Number() of that value will return 0
        // which is falsy value
        toast.error(ERROR_MESSAGES.INVALID_INTEGER_VALUE);
      }
    } else if (field === "maxCount") {
      setUpdatedExternalPoints((prevState: ExternalPoints[] | null) => {
        if (!prevState) {
          const newExternalPoints: ExternalPoints[] = [
            {
              id: null,
              points: null,
              maxCount: Number(event.target.value),
            },
          ];

          return newExternalPoints;
        }

        const copyCurrentExternalPoints = JSON.parse(
          JSON.stringify(prevState)
        ) as ExternalPoints[];
        copyCurrentExternalPoints[index].maxCount = Number(event.target.value);

        return copyCurrentExternalPoints;
      });
    }
  };

  const addNewExternalPointsConfig = () => {
    const newExternalPointsConfig: ExternalPoints = {
      id: "",
      points: null,
      maxCount: null,
    };

    if (newExternalPoints) {
      newExternalPoints.push(newExternalPointsConfig);
      const copyCurrentExternalPoints = JSON.parse(
        JSON.stringify(newExternalPoints)
      ) as ExternalPoints[];
      setUpdatedExternalPoints(copyCurrentExternalPoints);
    } else {
      setNewExternalPoints([newExternalPointsConfig]);
      setUpdatedExternalPoints([newExternalPointsConfig]);
    }
  };

  const removeExternalPointsConfig = (index: number) => {
    newExternalPoints.splice(index, 1);
    const copyCurrentExternalPoints = JSON.parse(
      JSON.stringify(newExternalPoints)
    ) as ExternalPoints[];
    setUpdatedExternalPoints(copyCurrentExternalPoints);
  };

  const updateExternalPointsConfiguration = async () => {
    const validator = new ExternalPointsConfigValidator();
    validator.validate(updatedExternalPoints);

    if (!validator.isValid) {
      toast.error(validator.message);
      return;
    }

    setIsLoading(true);
    setNewExternalPoints(updatedExternalPoints);
    const reqBodySnakeCase = {
      points: remapKeysToSpecificCase(updatedExternalPoints, "snake"),
    };

    try {
      await clientHttps.updateExternalPointsConfig(reqBodySnakeCase);
      toast.success(SUCCESS_MESSAGES.FEATURES_EXTERNAL_POINTS_UPDATE);
      isExternalPointsFeaturesEdited.current =
        !isExternalPointsFeaturesEdited.current;
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FEATURES_EXTERNAL_POINTS_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Enabled value={externalPoints.enabled} />
      <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
        <h3 className="font-bold mb-2">External points</h3>
        <p className="mb-2">
          Reward users for actions that happen outside of Fans United platform.
        </p>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2">
          {newExternalPoints &&
            newExternalPoints.map(
              (externalPointsValue: ExternalPoints, idx: number) => (
                <div
                  className="bg-blueGray-200 mb-2"
                  key={`${externalPointsValue.id}-${idx}`}
                >
                  <div className="flex flex-col p-2">
                    <div className="flex flex-col mb-2">
                      <div className="flex justify-between items-center mb-2">
                        <label className="font-bold">ID</label>
                        <RemoveButton
                          onClick={() => removeExternalPointsConfig(idx)}
                        />
                      </div>
                      <input
                        className="p-3"
                        defaultValue={externalPointsValue.id}
                        onChange={(event) => onChange(event, "id", idx)}
                        type="text"
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <label className="font-bold mb-2">Points</label>
                      <input
                        className="p-3"
                        defaultValue={externalPointsValue.points}
                        onChange={(event) => onChange(event, "points", idx)}
                        type="number"
                        onKeyDown={(event) => inputNumber(event, true)}
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <div className="flex items-center justify-start mb-2">
                        <label className="font-bold mr-1">Maximum count</label>
                        <MaximumCountTooltip />
                      </div>
                      <input
                        className="p-3"
                        defaultValue={externalPointsValue.maxCount}
                        onChange={(event) => onChange(event, "maxCount", idx)}
                        type="number"
                        onKeyDown={(event) => inputNumber(event, false)}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          <AddButton onClick={addNewExternalPointsConfig} />
        </div>
      </div>
      <UpdateButton
        label={"Update"}
        onClick={updateExternalPointsConfiguration}
        isLoading={isLoading}
      />
    </>
  );
};

export default ExternalPointsComponent;

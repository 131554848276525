import React, { PropsWithChildren } from "react";
import { useHistory } from "react-router";
import BackButton from "../../../../../Buttons/BackButton";
import EditProfileHeader from "../../../../EditProfile/EditProfileHeader";

const EditSuccessRateScopesLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const history = useHistory();

  const backToFeaturesPage = () => history.goBack();

  return (
    <div className="w-full lg:w-8/12 mx-auto">
      <div className="relative min-w-0 break-words w-full py-2 shadow-lg rounded-lg bg-white border-0">
        <div className="flex-auto px-4 lg:px-8 py-10 pt-0">
          <div className="flex flex-row items-center">
            <BackButton onClick={backToFeaturesPage} />
            <div className="mx-auto">
              <EditProfileHeader label={"Edit success rate scopes"} />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default EditSuccessRateScopesLayout;

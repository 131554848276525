import React, { useMemo, useState } from "react";
import { marketMap } from "../../../../constants/constants";
import ShowAllButton from "../../../Buttons/ShowAllButton";
import MarketBreakdown from "./MarketBreakdown";

type PredictionsMarketsBreakdownProps = {
  total: { [key: string]: number };
  marketBreakdown: string[];
  onChangeCheckbox: (market: string) => void;
};

const limitMarkets = 5;

/**
 * Sort markets render list. That way we will show the breakdown in order by the count of predictions for market.
 */

const sortMarketsListByTotalPredictions = (
  markets: string[],
  total: { [key: string]: number }
) => {
  let marketsWithPredictions = Object.keys(total);
  const otherMarkets = markets.filter(
    (market: string) => !marketsWithPredictions.includes(market)
  );
  marketsWithPredictions = [...marketsWithPredictions, ...otherMarkets];

  return marketsWithPredictions;
};

const PredictionsMarketsBreakdown: React.FC<
  PredictionsMarketsBreakdownProps
> = ({ total, marketBreakdown, onChangeCheckbox }) => {
  const totalPredictions = useMemo(() => {
    return total;
  }, []);
  const markets = Array.from(marketMap.keys());
  // Add 'all' breakdown
  markets.unshift("all");
  const allSortedMarkets = sortMarketsListByTotalPredictions(markets, total);
  const [sortedMarkets, setSortedMarkets] = useState<string[]>(
    allSortedMarkets.slice(0, limitMarkets)
  );
  const [showButton, setShowButton] = useState<boolean>(true);

  const showAllMarkets = () => {
    setSortedMarkets(allSortedMarkets);
    setShowButton(false);
  };

  return (
    <>
      <div className="grid lg:grid-cols-5 md:grid-gols-4 grid-cols-1 gap-2 mb-6">
        {sortedMarkets.map((market: string, idx: number) => (
          <MarketBreakdown
            key={`prediction-market-${market}-breakdown-${idx}`}
            market={market}
            count={totalPredictions[market] ? totalPredictions[market] : 0}
            all={totalPredictions.all}
            marketBreakdown={marketBreakdown}
            onChangeCheckbox={onChangeCheckbox}
          />
        ))}
      </div>
      {showButton ? (
        <div className="text-center mb-6">
          <ShowAllButton onClick={showAllMarkets} label={"Show all"} />
        </div>
      ) : null}
    </>
  );
};

export default PredictionsMarketsBreakdown;

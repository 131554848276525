import React, { useState } from "react";
import FootballCompetitionsReport from "./FootballCompetitions/FootballCompetitionsReport";
import FootballFollowsTabs from "./FootballFollowsTabs";
import FootballPlayersReport from "./FootballPlayers/FootballPlayersReport";
import FootballTeamsReport from "./FootballTeams/FootballTeamsReport";

export type FotballFollowsReports = "competitions" | "teams" | "players";

const displayFootballFollowsUI = (
  footballFollowsReports: FotballFollowsReports
) => {
  if (footballFollowsReports === "competitions") {
    return <FootballCompetitionsReport />;
  } else if (footballFollowsReports === "teams") {
    return <FootballTeamsReport />;
  } else if (footballFollowsReports === "players") {
    return <FootballPlayersReport />;
  }
};

const FootballFollows: React.FC = () => {
  const [footballFollowsReport, setFootballFollowsReport] =
    useState<FotballFollowsReports>("competitions");

  return (
    <div className="w-full px-4">
      <FootballFollowsTabs
        footballFollowsReport={footballFollowsReport}
        setFootballFollowsReport={setFootballFollowsReport}
      />
      {displayFootballFollowsUI(footballFollowsReport)}
    </div>
  );
};

export default FootballFollows;

import React, { KeyboardEventHandler, useState } from "react";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { OnChangeValue } from "react-select";

type FlagOption = {
  label: string;
  value: string;
};

const createOption = (label: string) => ({
  label,
  value: label,
});

export const flagsSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "0.25em",
    padding: "6px 1px",
    "&:hover": { borderColor: "gray" },
    border: "1px solid gray",
    boxShadow: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    "&:hover": { backGroundColor: "gray" },
    cursor: "pointer",
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none", // remove on focus blue box shadow
    },
  }),
};

type FlagsSelectProps = {
  defaultValue: string[];
  onChangeBadges: (value: string[]) => void;
};

const FlagsSelect: React.FC<FlagsSelectProps> = ({
  defaultValue,
  onChangeBadges,
}) => {
  const [input, setInput] = useState<string>("");

  const value = defaultValue.map((v) => createOption(v));

  const handleChange = (value: OnChangeValue<FlagOption, true>) => {
    const flags = value.map((v) => v.value);
    onChangeBadges(flags);
  };

  const handleInputChange = (inputValue: string) => {
    setInput(inputValue);
  };

  const handleKeyDown = (event): KeyboardEventHandler<HTMLDivElement> => {
    event.persist();

    const { key } = event;

    if (!input) return;

    switch (key) {
      case "Enter":
      case "Tab":
        if (defaultValue.length === 0) {
          const flags = Array.from(defaultValue);
          flags.push(input);
          onChangeBadges(flags);
        }

        if (defaultValue.length > 0) {
          if (isValidFlagOption()) {
            const flags = Array.from(defaultValue);
            flags.push(input);
            onChangeBadges(flags);
          }
        }

        setInput("");
        event.preventDefault();
    }
  };

  const isValidFlagOption = (): boolean => {
    let isValid = false;
    const labelsFlags: string[] = value.map(
      (option: FlagOption) => option.label
    );

    if (labelsFlags.length && labelsFlags.includes(input)) {
      toast.warn("Flag already exists");

      return isValid;
    }

    isValid = true;

    return isValid;
  };

  return (
    <div className="mb-4">
      <p className="font-bold mb-2">Flags</p>
      <CreatableSelect
        styles={flagsSelectStyles}
        components={{ DropdownIndicator: null }}
        getOptionValue={(option) => option.value}
        inputValue={input}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={""}
        value={value}
      />
    </div>
  );
};

export default FlagsSelect;

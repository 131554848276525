import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiContext } from "../../providers/ApiProvider";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import BadgesExtendedModel from "../../models/features/loyalty/BadgesExtendedModel/BadgesExtendedModel";
import { ERROR_MESSAGES } from "../../enums/enums";
import BadgesBuilder from "../../builders/features/badges/BadgesBuilder";

export const useBadges = () => {
  const [badges, setBadges] = useState<BadgesExtendedModel>(null);

  const { sdk } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getBadges = async () => {
    try {
      const badges = await sdk.loyalty.getConfigV2(true);
      const extendedBadges = new BadgesBuilder(badges.rewards.badges).build();
      setBadges(extendedBadges);
    } catch (e) {
      console.error(e)
      toast.error(ERROR_MESSAGES.BADGE_FEATURE);
      setBadges(new BadgesExtendedModel());
    }
  };

  useEffect(() => {
    getBadges();

    return () => setBadges(null);
  }, [selectedClient]);

  return badges;
};

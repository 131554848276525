import React from "react";
import Badge from "../Badge";
import GameBadgeRequirements from "./GameBadgeRequirements";
import GameBadgesExtended from "../../../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";

type GameBadgeProps = {
  badge: GameBadgesExtended;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
  removeExistingBadge: (uid: string) => void;
};

const GameBadge: React.FC<GameBadgeProps> = ({
  badge,
  onChangeBadges,
  removeExistingBadge,
}) => {
  return (
    <div className="flex flex-col p-2">
      <Badge
        badge={badge}
        onChangeBadges={onChangeBadges}
        removeExistingBadge={removeExistingBadge}
      />
      <GameBadgeRequirements
        uid={badge.uid}
        requirements={badge.requirements}
        onChangeBadges={onChangeBadges}
      />
    </div>
  );
};

export default GameBadge;

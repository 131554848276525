import React, { useState } from "react";
import { Link } from "react-router-dom";
import ClientLabel from "../ClientSelect/ClientLabel";
import ClientSelect from "../ClientSelect/ClientSelect";
import UserDropdown from "../Dropdowns/UserDropdown/UserDropdown";
import Documentation from "../Navigation/Documentation/Documentation";
import Navigation from "../Navigation/Main/Navigation";
import WidgetsManagement from "../Navigation/Manage/WidgetsManagement";
import ClientsBasicInfo from "../../models/Clients/ClientsBasicInfo";

type SideBarProps = {
  onChange: (selectedClient: ClientsBasicInfo) => void;
};

const Sidebar: React.FC<SideBarProps> = ({ onChange }) => {
  const [collapseShow, setCollapseShow] = useState("hidden");

  const onClickNavigationUrlHideMobileMenu = () => setCollapseShow("hidden");

  return (
    <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        {/* Toggler. Will be displayed on mobile version. */}
        <button
          className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
        >
          <i className="fas fa-bars"></i>
        </button>
        {/* Brand */}
        <Link
          className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
          to="/dashboard"
        >
          Fans United
        </Link>
        <div className="md:hidden items-center flex flex-wrap list-none">
          <div className="inline-block relative">
            <UserDropdown />
          </div>
        </div>
        {/* Collapse */}
        <div
          className={
            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
            collapseShow
          }
        >
          {/* Collapse header */}
          <div className="md:min-w-full md:hidden block mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap items-center">
              <div className="w-6/12">
                <Link
                  className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                  to="/"
                  onClick={onClickNavigationUrlHideMobileMenu}
                >
                  Fans United
                </Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={onClickNavigationUrlHideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          {/* Client select options or label for mobile screen */}
          <div className="mb-4 md:hidden">
            <div className="mb-3 pt-0">
              <ClientSelect
                onChange={onChange}
                version={"mobile"}
                onClickNavigationUrlHideMobileMenu={
                  onClickNavigationUrlHideMobileMenu
                }
              />
              <ClientLabel version={"mobile"} />
            </div>
          </div>
          {/* Navigation */}
          <hr className="my-4 md:min-w-full" />
          <Navigation
            onClickNavigationUrlHideMobileMenu={
              onClickNavigationUrlHideMobileMenu
            }
          />
          {/* Manage */}
          <hr className="my-4 md:min-w-full" />
          <WidgetsManagement
            onClickNavigationUrlHideMobileMenu={
              onClickNavigationUrlHideMobileMenu
            }
          />
          {/* Documentation */}
          <hr className="my-4 md:min-w-full" />
          <Documentation />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;

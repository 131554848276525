import React, { useContext, useState } from "react";
import { useTopFollowedFootballEntities } from "../../../../../hooks/useTopFollowedFootballEntities";
import CardReport from "../../../../Cards/CardReport";
import Spinner from "../../../../Spinner/Spinner";
import DatePeriod from "../../Common/DatePeriod";
import ReportInfo from "../../Common/ReportInfo";
import { useFollowedFootballEntities } from "../../../../../hooks/useFollowedFootballEntities";
import EntitySelect from "../../Common/EntityFollows/EntitySelect";
import FootballEntitiesFollows from "../../Common/EntityFollows/FootballEntitiesFollows";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import "../../Reports.css";

const FootballCompetitionsReport: React.FC = () => {
  const [competitionIds, setCompetitionIds] = useState<string[]>([]);
  const [competitionOptions, setCompetitionOptions] = useState<SelectOption[]>(
    []
  );

  const { datePeriod } = useContext(ReportParamsContext);

  const topFollowsFootballCompetitions = useTopFollowedFootballEntities(
    datePeriod,
    competitionIds,
    "competitions"
  );
  const competitionFollowsInfo = useFollowedFootballEntities(
    competitionIds,
    datePeriod,
    "competitions"
  );

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Football competitions report"}
          description={
            "This report shows by default the top 20 most followed competitions specified by period. Also you can search for a specific competition and see how many users are following it specified by gender and country."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} allTimeDefault={true} />
        </div>
      </div>

      <div className="mb-6">
        <EntitySelect
          setEntityIds={setCompetitionIds}
          entity="competitions"
          entities={competitionOptions}
          setEntities={setCompetitionOptions}
        />
      </div>
      {topFollowsFootballCompetitions || competitionFollowsInfo ? (
        <FootballEntitiesFollows
          topFollowsFootballEntities={topFollowsFootballCompetitions}
          entityFollowsInfo={competitionFollowsInfo}
          entity={"competitions"}
          setEntityIds={setCompetitionIds}
          setEntities={setCompetitionOptions}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default FootballCompetitionsReport;

import PointsValue from "../../models/features/loyalty/PointsValue/PointsValue";

export default class FeaturesLoyaltyService {
  private static currentKeyToApiKey = new Map<string, string>()
    .set("correct_outcome_1_x_2", "CORRECT_OUTCOME_1X2")
    .set("correct_home_goals", "CORRECT_HOME_GOALS")
    .set("correct_away_goals", "CORRECT_AWAY_GOALS")
    .set("correct_goal_difference", "CORRECT_GOAL_DIFFERENCE");

  public remapAlternativeKeysToSnakeCase = (points: any) => {
    points.predictor.forEach((value: PointsValue) =>
      this.renameAlternativeKeys(value)
    );

    points.match_quiz.forEach((value: PointsValue) =>
      this.renameAlternativeKeys(value)
    );

    points.top_x.forEach((value: PointsValue) =>
      this.renameAlternativeKeys(value)
    );
  };

  private renameAlternativeKeys = (value: PointsValue) => {
    if (value.id === "CORRECT_SCORE_ADVANCED") {
      Object.keys(value.alternative).forEach((key: string) => {
        value.alternative[FeaturesLoyaltyService.currentKeyToApiKey.get(key)] =
          value.alternative[key];
        delete value.alternative[key];
      });
    }
  };
}

import React from "react";

const FooterAdmin: React.FC = () => {
  return (
    <>
      <footer className="block px-4 pb-4">
        <div className="container mx-auto px-4">
          <hr className="mb-4 border-b-1 border-blueGray-200" />
          <div className="text-sm text-blueGray-500 font-semibold py-1 text-center">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://fansunitedmedia.com/"
              className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
              target="_blank"
              rel="noreferrer"
            >
              Fans United
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterAdmin;

import React from "react";
import Badge from "../Badge";
import MiniGamesBadgesExtended from "../../../../../../../models/features/loyalty/Badges/MiniGames/MiniGamesBadgesExtended";
import MiniGameBadgeRequirements from "./MiniGameBadgeRequirements";

type MiniGameBadgeProps = {
  badge: MiniGamesBadgesExtended;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
  removeExistingBadge: (uid: string) => void;
};

const MiniGameBadge: React.FC<MiniGameBadgeProps> = ({
  badge,
  onChangeBadges,
  removeExistingBadge,
}) => {
  return (
    <div className="flex flex-col p-2">
      <Badge
        badge={badge}
        onChangeBadges={onChangeBadges}
        removeExistingBadge={removeExistingBadge}
      />
      <MiniGameBadgeRequirements
        uid={badge.uid}
        requirements={badge.requirements}
        onChangeBadges={onChangeBadges}
      />
    </div>
  );
};

export default MiniGameBadge;

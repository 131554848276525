import { ClientSelectVersion } from "../types/types";

export const selectStyles = (version: ClientSelectVersion) => {
  const defaultWebSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "210px", // set a fixed width
    }),
    control: (provided: any) => ({
      ...provided,
      fontSize: "14px",
      borderRadius: "0.25em",
      padding: "2px 6px",
      "&:hover": { borderColor: "gray" },
      border: "1px solid lightgray",
    }),
    input: (provided: any) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none", // remove on focus blue box shadow
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#0066cc" : "white", // selected client will have a brand background color
      "&:hover": {
        backgroundColor: state.isSelected ? "#0066cc" : "lightgray",
      },
    }),
  };

  if (version === "mobile") {
    return {
      ...defaultWebSelectStyles,
      container: (provided: any) => ({
        ...provided,
        width: "100%",
      }),
    };
  }

  return defaultWebSelectStyles;
};

export const asyncSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "0.25em",
    padding: "6px 1px",
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    "&:hover": { backGroundColor: "gray" },
    cursor: "pointer",
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none", // remove on focus blue box shadow
    },
  }),
};

export const webhookTypeSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "0.25em",
    padding: "6px 1px",
    border: "1px solid gray",
    boxShadow: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    "&:hover": { backGroundColor: "gray" },
    cursor: "pointer",
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none", // remove on focus blue box shadow
    },
  }),
};

export const webhookMethodSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    marginRight: "0.5rem",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "0.25em",
    padding: "6px 1px",
    border: "1px solid gray",
    boxShadow: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    "&:hover": { backGroundColor: "gray" },
    cursor: "pointer",
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none", // remove on focus blue box shadow
    },
  }),
};

export const reactSelectSingleValueStyle = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "0.25em",
    padding: "6px 1px",
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    "&:hover": { backGroundColor: "gray" },
    cursor: "pointer",
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none", // remove on focus blue box shadow
    },
  }),
  valueContainer: () => ({
    alignItems: "center",
    display: "flex",
    flex: "1 1 0%",
    flexWrap: "wrap",
    position: "relative",
    overflow: "hidden",
    padding: "2px 8px",
    boxSizing: "border-box",
  }),
  singleValue: () => ({
    display: "flex",
    minWidth: "0px",
    backgroundColor: "rgb(230, 230, 230)",
    borderRadius: "2px",
    margin: "2px",
    boxSizing: "border-box",
    padding: "4px",
  }),
  placeholder(provided: any, state: any) {
    return {
      ...provided,
      display: state.isFocused && "none",
    };
  },
};

export const marketSelectStyles = {
  container: (provided) => ({
    ...provided,
    padding: "2px",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "0.25em",
    paddingTop: "4px",
    paddingBottom: "4px",
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    "&:hover": { backGroundColor: "gray" },
    cursor: "pointer",
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none", // remove on focus blue box shadow
    },
  }),
};

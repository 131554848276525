export interface TopFollowedSports {
    name: string;
    logo: string;
    followers: number;
}

export interface TopFollowedEntitiy extends TopFollowedSports {
    countryName: string;
    countryLogo: string;
}


export default class FollowedEntitiesModel {
    public topFollowedCompetitions: TopFollowedEntitiy[] = [];
    public topFollowedTeams: TopFollowedEntitiy[] = [];
    public topFollowedPlayers: TopFollowedEntitiy[] = [];
    public topFollowedSports: TopFollowedSports[] = [];
}


import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../enums/enums";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import EngagementCoefficientsConfiguration from "../../models/Clients/EngagementCoefficientsConfiguration/EngagementCoefficientsConfiguration";
import { ApiContext } from "../../providers/ApiProvider";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";

const constructEntityFilters = (entityIds: string[]) => {
  let filters = {
    competitionIds: [],
    teamIds: [],
    playerIds: [],
  };

  entityIds.forEach((id: string) => {
    if (id.startsWith("fb:c:")) {
      filters.competitionIds.push(id);
    } else if (id.startsWith("fb:t:")) {
      filters.teamIds.push(id);
    } else if (id.startsWith("fb:p:")) {
      filters.playerIds.push(id);
    }
  });

  return filters;
};

export const useEngagementCoefficientsConfig = () => {
  const [engagementCoefficientsConfig, setEngagementCoefficientsConfig] =
    useState<EngagementCoefficientsConfiguration>(null);
  const [whitelistEntities, setWhitelistEntities] = useState<any[]>([]);

  const { clientHttps } = useContext(ApiContext);
  const { sdk } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getEntityModels = async (entityIds: string[]) => {
    const competitionIds = constructEntityFilters(entityIds).competitionIds;
    const teamIds = constructEntityFilters(entityIds).teamIds;
    const playerIds = constructEntityFilters(entityIds).playerIds;
    let entities = [];

    if (competitionIds.length) {
      const competitions = await sdk.football.getCompetitions({
        competitionIds,
      } as CompetitionFilters);
      entities = [...entities, ...competitions];
    }

    if (teamIds.length) {
      const teams = await sdk.football.getTeams({ teamIds } as TeamFilters);
      entities = [...entities, ...teams.data];
    }

    if (playerIds.length) {
      const players = await sdk.football.getPlayers({
        playerIds,
      } as PlayerFilters);
      entities = [...entities, ...players.data];
    }

    return entities;
  };

  const getEngagementCoefficientsConfig = async () => {
    try {
      const config = await clientHttps.getEngagementCoefficientsConfig();
      const entities = await getEntityModels(config.engagementConfig.whitelist);

      setWhitelistEntities(entities);
      setEngagementCoefficientsConfig(config);
    } catch (error) {
      toast.error(ERROR_MESSAGES.ENGAGEMENT_COEFFICIENTS_CONFIG);
      setEngagementCoefficientsConfig(
        new EngagementCoefficientsConfiguration()
      );
    }
  };

  useEffect(() => {
    getEngagementCoefficientsConfig();

    return () => setEngagementCoefficientsConfig(null);
  }, [selectedClient]);

  return { engagementCoefficientsConfig, whitelistEntities };
};

import ActionValueModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Actions/ActionValueModel";
import React from "react";
import { mapIdValueToTitle } from "../../../../../helpers/helpers";

const actionDescriptionMap: Map<string, string> = new Map()
  .set(
    "LIKE",
    "Whenever user clicks a like button on article, comment and etc."
  )
  .set(
    "DISLIKE",
    "Whenever user clicks a dislike button on article, comment and etc."
  )
  .set(
    "PAGE_VIEW",
    "User opens a web page or a screen in a mobile app e.g. an article"
  )
  .set(
    "CONTENT_CONSUMED",
    "Whenever user watches a video, article read, gallery viewed, audio listened to."
  )
  .set("SHARE", "For example user shares an article on social media.")
  .set("COMMENT", "User leaves a comment under a piece of content.")
  .set(
    "CLICK_AD",
    "User clicks on a banner, odds in a widget or other for of advertising."
  )
  .set(
    "CONVERT",
    "When users achieve a particular KPI e.g. they can be applying a discount code"
  )
  .set(
    "PROFILE_UPDATE",
    "Users update their information - birthdate, gender, country and etc."
  )
  .set(
    "MANAGE_INTERESTS",
    "Whenever users add, remove or update their interests."
  )
  .set("PREDICTION_MADE", "Users made predictions for specific match.")
  .set(
    "GAME_PARTICIPATION",
    "Users made predictions for specific games (Top X, Match Quiz and etc.)."
  )
  .set("ARTICLE_CONSUMED", "Users have read some content article.")
  .set("VIDEO_CONSUMED", "Users have watched a video.")
  .set("LONG_VIDEO_CONSUMED", "Users have watched a long video.")
  .set("SHORT_VIDEO_CONSUMED", "Users have watched a short video.")
  .set("AUDIO_CONSUMED", "Users have listened to an audio.")
  .set("GALLERY_CONSUMED", "Users have explored some gallery.")
  .set("IMAGE_CONSUMED", "Users have opened some image.")
  .set("POLL_VOTE", "Users have voted in a poll.")
  .set("POTM_VOTE", "Users have voted in 'Player of the match'.")
  .set("ADD_POST", "User has created a post in Discussions.")
  .set("ADD_REACTION", "User has reacted to a post.")
  .set("DELETE_POST", "User has deleted his own post.")
  .set("PRIVATE_LEAGUE_CREATE", "User has created a Private League.")
  .set("PRIVATE_LEAGUE_JOIN", "User has joined a Private League.")
  .set("PRIVATE_LEAGUE_LEAVE", "User has left a Private League.")
  .set("CLASSIC_QUIZ_PARTICIPATION", "User has participated in Classic Quiz.")
  .set("EITHER_OR_PARTICIPATION", "User has participated in Either/Or.");

type ActionProps = {
  action: ActionValueModel;
};

const Action: React.FC<ActionProps> = ({ action }) => {
  return (
    <div
      key={`${action.id}-user-action`}
      className="bg-blueGray-200 mb-2 rounded-lg p-2"
    >
      <div className="flex flex-col flex-nowrap">
        <div>
          <span className="p-1 mb-2 font-semibold">Action</span>
        </div>
        <div>
          <span className="p-1 mb-2 text-sm">{mapIdValueToTitle(action)}</span>
        </div>
        <div className="px-1">
          <span className="text-xs">{actionDescriptionMap.get(action.id)}</span>
        </div>
        <div className="mt-auto">
          <span className="p-1 font-semibold">Weight</span>
          <div>
            <span className="p-1">{action.weight}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;

import React from "react";
import { usePrivateLeaguesTopTemplates } from "../../../../../hooks/reports/privateLeagues/usePrivateLeaguesTopTemplates";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import Spinner from "../../../../Spinner/Spinner";
import DatePeriod from "../../Common/DatePeriod";
import "../../Reports.css";
import PrivateLeagueTableView from "./PrivateLeagueTableView";

const PrivateLeagueTopTemplates: React.FC = () => {
  const topTemplates = usePrivateLeaguesTopTemplates();

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Leagues top templates"}
          description={
            "This is a report for the top used templates for Private and One vs One leagues for a specified period of time. By default the reports shows data for the last 30 days"
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod defaultDatePeriod={true} allTimeDefault={true} />
        </div>
      </div>
      {topTemplates ? (
        <>
          <PrivateLeagueTableView
            type={"private"}
            data={topTemplates.private}
          />
          <PrivateLeagueTableView
            type={"one_vs_one"}
            data={topTemplates.oneVsOne}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PrivateLeagueTopTemplates;

import {
  comparedToPredictionDatasetColor,
  marketMap,
  previousPeriodDatasetLabel,
  selectedPeriodDatasetLabel,
  selectedPredictionDatasetColor,
} from "../../constants/constants";
import {
  getKeyFromMapByValue,
  removePropertyFromObject,
  upperCase,
} from "../../helpers/helpers";
import DatasetChart from "./DatasetChart";
import ReportPeriods from "./ReportPeriods";

export default class PredictionsByDateDataset {
  public predictionsDatasets: DatasetChart[] = [];
  private totalPredictionsBreakdown: ReportPeriods<any[]> = {
    current: [],
    comparedTo: [],
  };

  constructor(
    totalPredictionsBreakdown: ReportPeriods<any[]> | null,
    selectedPredictionsBreakdown: any[] | null,
    comparedToPredictionsBreakdown: any[] | null,
    marketsBreakdown: string[]
  ) {
    this.totalPredictionsBreakdown = totalPredictionsBreakdown;
    this.predictionsDatasetsChart(
      selectedPredictionsBreakdown,
      marketsBreakdown,
      false
    );
    this.predictionsDatasetsChart(
      comparedToPredictionsBreakdown,
      marketsBreakdown,
      true
    );
  }

  private predictionsDatasetsChart = (
    predictionsBreakdown: any[] | null,
    marketsBreakdown: string[],
    comparedTo: boolean
  ) => {
    if (!predictionsBreakdown) return;
    // Create new predictions breakdown depending on the checkboxes the client has selected.
    const newPredictionsBreakdown = this.predictionsBreakdownByCheckboxValues(
      predictionsBreakdown,
      marketsBreakdown
    );

    // Create dataset list for the chart. It length will depend on the checkboxes the client has checked.
    marketsBreakdown.forEach((marketBreakdown: string) => {
      const newDatasetChart = new DatasetChart();
      let label: string = "";
      let color: string = "";

      if (marketBreakdown === "all") {
        label = upperCase(marketBreakdown);
      } else {
        label = marketMap.get(marketBreakdown);
      }

      if (comparedTo) {
        if (comparedToPredictionDatasetColor.get(marketBreakdown)) {
          color = comparedToPredictionDatasetColor.get(marketBreakdown);
        } else {
          color = "rgba(237, 126, 23)";
        }
      } else {
        if (selectedPredictionDatasetColor.get(marketBreakdown)) {
          color = selectedPredictionDatasetColor.get(marketBreakdown);
        } else {
          color = "rgba(0, 102, 204)";
        }
      }

      newDatasetChart.id = comparedTo
        ? `${marketBreakdown}_previous_period`
        : `${marketBreakdown}_selected_period`;
      newDatasetChart.label = comparedTo
        ? label + previousPeriodDatasetLabel
        : label + `${selectedPeriodDatasetLabel}`;
      newDatasetChart.backgroundColor = color;
      newDatasetChart.borderColor = color;

      this.predictionsDatasets.push(newDatasetChart);
    });

    this.pushDataInDatasets(newPredictionsBreakdown);
  };

  private predictionsBreakdownByCheckboxValues = (
    breakdown: any[],
    marketsBreakdown: string[]
  ) => {
    return breakdown.map((value: { date: string; predictions: any }) => {
      if (value.predictions) {
        const keysToBeRemoved = Object.keys(value.predictions).filter(
          (marketKey: string) => !marketsBreakdown.includes(marketKey)
        );

        keysToBeRemoved.forEach((key: string) =>
          removePropertyFromObject(value.predictions, key)
        );

        return value.predictions;
      } else {
        const predictionsObject = {};

        marketsBreakdown.forEach(
          (value: string) => (predictionsObject[value] = 0)
        );

        return predictionsObject;
      }
    });
  };

  private pushDataInDatasets = (
    newPredictionsBreakdown: {
      [key: string]: any;
    } | null
  ) => {
    if (newPredictionsBreakdown) {
      const marketsValues = Array.from(marketMap.values());
      Object.values(newPredictionsBreakdown).forEach(
        (value: { [key: string]: number }) => {
          this.predictionsDatasets.forEach((datasetChart: DatasetChart) => {
            const searchValue = datasetChart.label.split(":")[0];

            if (
              !datasetChart.label.includes("All") &&
              marketsValues.includes(searchValue)
            ) {
              datasetChart.data.push(
                value[getKeyFromMapByValue(marketMap, searchValue)]
              );
            }
          });
        }
      );
    }

    if (this.totalPredictionsBreakdown.current) {
      this.totalPredictionsBreakdown.current.forEach((totalBreakdown: any) => {
        if (totalBreakdown.predictions) {
          this.predictionsDatasets.forEach((datasetChart: DatasetChart) => {
            if (datasetChart.id === `all_selected_period`)
              datasetChart.data.push(totalBreakdown.predictions.all);
          });
        }
      });
    }

    if (this.totalPredictionsBreakdown.comparedTo) {
      this.totalPredictionsBreakdown.comparedTo.forEach(
        (totalBreakdown: any) => {
          if (totalBreakdown.predictions) {
            this.predictionsDatasets.forEach((datasetChart: DatasetChart) => {
              if (datasetChart.id === `all_previous_period`)
                datasetChart.data.push(totalBreakdown.predictions.all);
            });
          }
        }
      );
    }
  };
}

import BadgesExtended from "../BadgesExtended";

export interface IDiscussionsBadgeRequirements {
  totalDiscussionPoints: number;
  postsCount: number;
  postPoints: number;
  reactionsCount: number;
}

export default class DiscussionsBadgesExtended extends BadgesExtended {
  public requirements: IDiscussionsBadgeRequirements = {
    totalDiscussionPoints: 0,
    postsCount: 0,
    postPoints: 0,
    reactionsCount: 0,
  };
}

import SDKConfigurationModel from "fansunited-sdk-esm/Core/Configurator/Models/SDKConfiguraitonModel";
import { InfrastructureType } from "../../models/Clients/ClientsBasicInfo";
import { initEnvironment } from "../../helpers/helpers";

export const initSDKConfig = (
  clientId: string,
  infrastructure: InfrastructureType
): SDKConfigurationModel => {
  const apiKey =
    infrastructure && infrastructure === "PROD_WATG"
      ? process.env.REACT_APP_FANS_UNITED_API_KEY_WATG
      : process.env.REACT_APP_FANS_UNITED_API_KEY;
  const environment = initEnvironment(infrastructure);

  const config: SDKConfigurationModel = {
    apiKey,
    clientId,
    lang: "en",
    environment,
    idSchema: "native",
    errorHandlingMode: "standard",
    authProvider: {
      getIdToken: () => {
        return "";
      },
      logout: () => {},
    },
  };

  return config;
};

import React from "react";

type EnabledProps = {
  value: boolean;
};

export const booleanToYesNo: any = {
  true: "Yes",
  false: "No",
};

const Enabled: React.FC<EnabledProps> = ({ value }) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 md:w-6/12 bg-blueGray-50">
      <label className="font-bold mb-2">Enabled</label>
      <p
        className={`${
          booleanToYesNo[value?.toString()] === "Yes"
            ? "text-teal-500 font-semibold"
            : "text-red-500 font-semibold"
        }`}
      >
        {booleanToYesNo[value?.toString()]}
      </p>
    </div>
  );
};

export default Enabled;

import React, { Dispatch, SetStateAction, useState } from "react";
import SuccessRateCompetitionSelect from "../../FeaturesPredictor/SuccessRateScopes/EditSuccessRateScopes/SuccessRateCompetitionSelect/SuccessRateCompetitionSelect";
import TeamsSelect from "../../FeaturesPredictor/SuccessRateScopes/EditSuccessRateScopes/TeamsSelect/TeamsSelect";
import PlayersSelect from "../../Common/PlayersSelect/PlayersSelect";

type ECEntitiesWhitelistProps = {
  whitelistEntities: any[];
  setCompetitionIds: Dispatch<SetStateAction<string[]>>;
  setTeamIds: Dispatch<SetStateAction<string[]>>;
  setPlayerIds: Dispatch<SetStateAction<string[]>>;
};

const ECEntitiesWhitelist: React.FC<ECEntitiesWhitelistProps> = ({
  whitelistEntities,
  setCompetitionIds,
  setTeamIds,
  setPlayerIds,
}) => {
  return (
    <div className="mb-2 border border-blueGray-300 rounded-lg p-2 bg-blueGray-100">
      <h3 className="font-bold text-xl mb-2">Entities whitelist</h3>
      <div className="mb-2 border border-blueGray-400 rounded-lg p-2 bg-blueGray-200">
        <h4 className="font-bold text-xl mb-2">Competitions</h4>
        <SuccessRateCompetitionSelect
          loadedCompetitions={whitelistEntities.filter((entity) =>
            entity.id.startsWith("fb:c:")
          )}
          maximumCompetitions={100}
          setCompetitionIds={setCompetitionIds}
        />
      </div>
      <div className="mb-2 border border-blueGray-400 rounded-lg p-2 bg-blueGray-200">
        <h4 className="font-bold text-xl mb-2">Teams</h4>
        <TeamsSelect
          loadedTeams={whitelistEntities.filter((entity) =>
            entity.id.startsWith("fb:t:")
          )}
          setSuccessRateScopeTeamIds={setTeamIds}
        />
      </div>
      <div className="mb-2 border border-blueGray-400 rounded-lg p-2 bg-blueGray-200">
        <h4 className="font-bold text-xl mb-2">Players</h4>
        <PlayersSelect
          loadedPlayers={whitelistEntities.filter((entity) =>
            entity.id.startsWith("fb:p:")
          )}
          setPlayerIds={setPlayerIds}
        />
      </div>
    </div>
  );
};

export default ECEntitiesWhitelist;

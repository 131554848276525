import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import DiscussionsAveragesModel from "../../../models/Reports/Discussions/DiscussionsAveragesModel";

export const useDiscussionsAverages = () => {
  const [discussionsAverages, setDiscussionsAverages] =
    useState<DiscussionsAveragesModel>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getDiscussionsAverages = async () => {
    let averagePosts = null;
    let percentageUsersWithPosts = null;

    try {
      averagePosts = await api.reportingHttps.getDiscussionsPostsAverages();
    } catch (error) {
      toast.error(ERROR_MESSAGES.DISCUSSIONS_POSTS_AVERAGES_REPORT);
    }

    try {
      percentageUsersWithPosts =
        await api.reportingHttps.getPercentageOfUsersWithPosts();
    } catch (error) {
      toast.error(
        ERROR_MESSAGES.DISCUSSIONS_PERCENTAGE_USERS_WITH_POSTS_REPORT
      );
    }

    const discussionsAverages = new DiscussionsAveragesModel();
    discussionsAverages.averagePosts = averagePosts;
    discussionsAverages.percentageUsersWithPosts = percentageUsersWithPosts;
    setDiscussionsAverages(discussionsAverages);
  };

  useEffect(() => {
    getDiscussionsAverages();

    return () => setDiscussionsAverages(null);
  }, [selectedClient]);

  return discussionsAverages;
};

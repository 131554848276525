export interface BadgeAssets {
  mainImageUrl: string;
}

export default class BadgesExtended {
  uid: string = "";
  id: string = "";
  enabled: boolean = false;
  assets: BadgeAssets = null;
  label: string = "";
  description: string = "";
  flags: string[] = [];
}

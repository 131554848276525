import { kebabCase } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import {
  featuresPages,
  pageIcons,
  pageTitles,
  reportsPages,
} from "../../../constants/constants";
import { NavigationButtonProps } from "./NavigationButton";

const SubNavigation: React.FC<NavigationButtonProps> = ({
  navigation,
  onClickNavigationUrlHideMobileMenu,
}) => {
  let subLinks: string[] = [];

  if (navigation === "features") {
    subLinks = Object.values(featuresPages);
  } else if (navigation === "reports") {
    subLinks = Object.values(reportsPages);
  }

  return (
    <>
      {subLinks.map((link: string, index: number) => (
        <li key={index} className="items-center mx-4 my-2">
          <Link
            className={
              "uppercase font-bold block flex items-center " +
              (window.location.href.endsWith(
                `/${pageTitles[navigation.toLocaleUpperCase()]}/${kebabCase(
                  link
                )}`
              )
                ? "text-lightBlue-500 hover:text-lightBlue-600"
                : "text-blueGray-700 hover:text-blueGray-500")
            }
            to={`/${pageTitles[navigation.toLocaleUpperCase()]}/${kebabCase(link)}`}
            onClick={onClickNavigationUrlHideMobileMenu}
          >
            <i
              className={`${pageIcons[navigation]} mr-2 text-sm w-5
                ${
                  window.location.href.endsWith(
                    `/${pageTitles[navigation.toLocaleUpperCase()]}/${kebabCase(
                      link
                    )}`
                  )
                    ? "opacity-75"
                    : "text-blueGray-300"
                }`}
            ></i>{" "}
            <span className="text-xxs">{link}</span>
          </Link>
        </li>
      ))}
    </>
  );
};

export default SubNavigation;

import {
  previousPeriodDatasetLabel,
  selectedPeriodDatasetLabel,
} from "../../constants/constants";
import { upperCase } from "../../helpers/helpers";
import DatasetChart from "../../models/Reports/DatasetChart";
import PrivateLeaguesBreakdownModel, {
  ILeagueBreakdownData,
  IPrivateLeaguesBreakdown,
} from "../../models/Reports/PrivateLeagues/PrivateLeaguesBreakdownModel";
import ReportPeriods from "../../models/Reports/ReportPeriods";

const datasetBreakdownLabelMap = new Map()
  .set("all", "All")
  .set("private", "Private leagues")
  .set("oneVsOne", "One vs One leagues");

export default class PrivateLeaguesBreakdownBuilder {
  private newDatasetsChart: DatasetChart[] = [];
  readonly data: ReportPeriods<PrivateLeaguesBreakdownModel>;
  readonly privateLeaguesBreakdown: string[];

  constructor(
    data: ReportPeriods<PrivateLeaguesBreakdownModel>,
    privateLeaguesBreakdown: string[]
  ) {
    this.data = data;
    this.privateLeaguesBreakdown = privateLeaguesBreakdown;
  }

  public build = () => {
    this.buildPrivateLeaguesDatasetsChart();

    return this.newDatasetsChart;
  };

  private buildPrivateLeaguesDatasetsChart = () => {
    if (this.data) {
      this.createCurrentDatasetChart();

      this.newDatasetsChart.forEach((datasetChart: DatasetChart) => {
        if (datasetChart.id === `all_selected_period`) {
          datasetChart.data = this.calculateTotalLeagues(
            this.data.current.breakdown
          );
        } else if (datasetChart.id === `private_selected_period`) {
          datasetChart.data = this.calculateTotalSpecificLeagues(
            this.data.current.breakdown.private
          );
        } else if (datasetChart.id === `oneVsOne_selected_period`) {
          datasetChart.data = this.calculateTotalSpecificLeagues(
            this.data.current.breakdown.oneVsOne
          );
        }
      });

      if (this.data.comparedTo) {
        this.createComparedToDatasetChart();

        this.newDatasetsChart.forEach((datasetChart: DatasetChart) => {
          if (datasetChart.id === `all_previous_period`) {
            datasetChart.data = this.calculateTotalLeagues(
              this.data.comparedTo.breakdown
            );
          } else if (datasetChart.id === `private_previous_period`) {
            datasetChart.data = this.calculateTotalSpecificLeagues(
              this.data.comparedTo.breakdown.private
            );
          } else if (datasetChart.id === `one_vs_one_previous_period`) {
            datasetChart.data = this.calculateTotalSpecificLeagues(
              this.data.comparedTo.breakdown.oneVsOne
            );
          }
        });
      }
    }
  };

  private createCurrentDatasetChart = () => {
    this.privateLeaguesBreakdown.forEach((privateLeagueBreakdown: string) => {
      const newDatasetChart = new DatasetChart();

      newDatasetChart.id = `${privateLeagueBreakdown}_selected_period`;
      newDatasetChart.label = `${datasetBreakdownLabelMap.get(
        privateLeagueBreakdown
      )}${selectedPeriodDatasetLabel}`;
      newDatasetChart.backgroundColor = this.selectedPeriodColorDataset(
        privateLeagueBreakdown
      );
      newDatasetChart.borderColor = this.selectedPeriodColorDataset(
        privateLeagueBreakdown
      );

      this.newDatasetsChart.push(newDatasetChart);
    });
  };

  private createComparedToDatasetChart = () => {
    this.privateLeaguesBreakdown.forEach((privateLeagueBreakdown: string) => {
      const newDatasetChart = new DatasetChart();

      newDatasetChart.id = `${privateLeagueBreakdown}_previous_period`;
      newDatasetChart.label = `${datasetBreakdownLabelMap.get(
        privateLeagueBreakdown
      )}${previousPeriodDatasetLabel}`;
      newDatasetChart.backgroundColor = this.previousPeriodColorDataset(
        privateLeagueBreakdown
      );
      newDatasetChart.borderColor = this.previousPeriodColorDataset(
        privateLeagueBreakdown
      );

      this.newDatasetsChart.push(newDatasetChart);
    });
  };

  private selectedPeriodColorDataset = (privateLeagueBreakdown: string) => {
    switch (privateLeagueBreakdown) {
      case "all":
        return "rgba(0, 102, 204)";
      case "private":
        return "rgba(4, 133, 81)";
      case "oneVsOne":
        return "rgba(194, 4, 4)";
    }
  };

  private previousPeriodColorDataset = (privateLeagueBreakdown: string) => {
    switch (privateLeagueBreakdown) {
      case "all":
        return "#ed7e17";
      case "private":
        return "#b7ffab";
      case "oneVsOne":
        return "#fa7575";
    }
  };

  private calculateTotalLeagues = (
    breakdown: IPrivateLeaguesBreakdown
  ): number[] => {
    const privateData: number[] = breakdown.private.map(
      (value: ILeagueBreakdownData) => value.value
    );
    const oneVsOneData: number[] = breakdown.oneVsOne.map(
      (value: ILeagueBreakdownData) => value.value
    );

    return privateData.map((value: number, index: number) => {
      return value + oneVsOneData[index];
    });
  };

  private calculateTotalSpecificLeagues = (
    breakdown: ILeagueBreakdownData[]
  ): number[] => {
    return breakdown.map((value: ILeagueBreakdownData) => value.value);
  };
}

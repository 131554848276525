import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../../enums/enums";
import FullProfileInfo from "../../../../models/Clients/FullProfileInfo";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import ProfileValidator from "../../../../validators/ProfileValidator";
import UpdateButton from "../../../Buttons/UpdateButton";
import Spinner from "../../../Spinner/Spinner";
import PasswordRow from "../../ChangePassword/PasswordRow";
import EditProfileHeader from "../../EditProfile/EditProfileHeader";
import EditProfileRow from "../../EditProfile/EditProfileRow";
import StaffRoles from "../StaffRoles/StaffRoles";
import EditStaffMemberTabs from "./EditStaffMemberTabs";
import { ApiContext } from "../../../../providers/ApiProvider";

type EditStaffMemberInfo = {
  name: string;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  roles: string[];
};

const EditStaffMember: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>("NAME");
  const [staffMember, setStaffMember] = useState<FullProfileInfo | null>(null);
  const [editStaffMemberInfo, setEditStaffMemberInfo] =
    useState<EditStaffMemberInfo>({
      name: "",
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      roles: [],
    });

  const { selectedClient, staffId, isLoading, setIsLoading } =
    useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const updateProfileValidator = new ProfileValidator();

  useEffect(() => {
    clientHttps
      ?.getStaffMemberById(staffId)
      .then((staffMember: FullProfileInfo) => {
        setStaffMember(staffMember);
        setEditStaffMemberInfo((prevState) => {
          return {
            ...prevState,
            name: staffMember.name,
            roles: staffMember.roles,
          };
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.STAFF_MEMBER_INFO);
        setStaffMember(null);
      });
  }, [selectedClient]);

  const handleOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditStaffMemberInfo((prevState) => {
      return { ...prevState, name: event.target.value };
    });

  const handleOnChangeRoles = (selectedRole: string) => {
    if (!editStaffMemberInfo.roles.includes(selectedRole)) {
      setEditStaffMemberInfo((prevState) => {
        return { ...prevState, roles: [...prevState.roles, selectedRole] };
      });
    } else {
      const newRoles = editStaffMemberInfo.roles.filter(
        (role: string) => role !== selectedRole
      );

      setEditStaffMemberInfo((prevState) => {
        return { ...prevState, roles: newRoles };
      });
    }
  };

  const handleOnChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditStaffMemberInfo((prevState) => {
      return { ...prevState, newPassword: event.target.value };
    });
  };

  const handleOnChangeRepeatNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditStaffMemberInfo((prevState) => {
      return { ...prevState, repeatNewPassword: event.target.value };
    });
  };

  const updateInformation = async (updateInfo: "staff_member" | "password") => {
    setIsLoading(true);
    if (updateInfo === "staff_member") {
      const validator = updateProfileValidator.validateUpdateName(
        editStaffMemberInfo.name
      );

      if (!validator.isValid) {
        console.warn(validator.message);
        toast.warn(validator.message);
        setIsLoading(false);

        return;
      }

      try {
        await clientHttps?.updateStaffInfo(staffId, {
          name: editStaffMemberInfo.name,
          roles: editStaffMemberInfo.roles,
        });
        toast.success(SUCCESS_MESSAGES.CHANGE_STAFF_MEMBER_INFO);
      } catch (error) {
        console.error(error);
        toast.error(ERROR_MESSAGES.CHANGE_PROFILE_INFO);
      }
    } else if (updateInfo === "password") {
      updateProfileValidator.validatePasswordFieldsStaffMember(
        editStaffMemberInfo.newPassword,
        editStaffMemberInfo.repeatNewPassword
      );
      updateProfileValidator.validatePasswordLength(
        editStaffMemberInfo.newPassword
      );
      updateProfileValidator.validateRepeatPassword(
        editStaffMemberInfo.newPassword,
        editStaffMemberInfo.repeatNewPassword
      );

      if (!updateProfileValidator.isValid) {
        console.error(updateProfileValidator.message);
        toast.error(updateProfileValidator.message);
        setIsLoading(false);

        return;
      }

      try {
        await clientHttps?.updateStaffInfo(staffId, {
          pass: editStaffMemberInfo.newPassword,
        });
        toast.success(SUCCESS_MESSAGES.CHANGE_STAFF_MEMBER_PASSWORD);
      } catch (error) {
        console.error(error);
        toast.error(ERROR_MESSAGES.CHANGE_PASSWORD);
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="w-full mb-24 px-4">
      <div className="flex flex-wrap">
        <div className="w-full relative">
          <EditStaffMemberTabs openTab={openTab} setOpenTab={setOpenTab} />
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div
                  className={openTab === "NAME" ? "block" : "hidden"}
                  id="Edit staff member link"
                >
                  {staffMember ? (
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                      <EditProfileHeader label={"Edit staff member"} />
                      <EditProfileRow
                        label={"Current staff e-mail"}
                        userEmail={staffMember.email}
                      />
                      <EditProfileRow
                        label={"Enter new staff name"}
                        onChange={handleOnChangeName}
                        currentName={staffMember.name}
                      />
                      <StaffRoles
                        onChange={handleOnChangeRoles}
                        loadedRoles={editStaffMemberInfo?.roles}
                      />
                      <UpdateButton
                        label={"Update information"}
                        onClick={() => updateInformation("staff_member")}
                        isLoading={isLoading}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
                <div
                  className={openTab === "PASSWORD" ? "block" : "hidden"}
                  id="Change password link"
                >
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <EditProfileHeader label={"Change password"} />
                    <PasswordRow
                      label={"New password"}
                      onChange={handleOnChangeNewPassword}
                    />
                    <PasswordRow
                      label={"Repeat new password"}
                      onChange={handleOnChangeRepeatNewPassword}
                    />
                    <UpdateButton
                      label={"Change password"}
                      onClick={() => updateInformation("password")}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaffMember;

import React from "react";
import { inputNumber } from "../../../../../helpers/helpers";

type ECRetentionProps = {
  timeFrameInMonths: number;
  onChange: (value: number) => void;
};

const ECRetention: React.FC<ECRetentionProps> = ({
  timeFrameInMonths,
  onChange,
}) => {
  return (
    <div className="mb-2 border border-blueGray-300 rounded-lg p-2 bg-blueGray-100">
      <h3 className="font-bold text-xl mb-2">Retention</h3>
      <p>
        <span className="mr-2">Calculate data for</span>
        <input
          className="w-12 mr-2"
          type="number"
          defaultValue={timeFrameInMonths}
          onKeyDown={(event) => inputNumber(event, false)}
          onChange={(event) => onChange(Number(event.target.value))}
        />
        months back
      </p>
    </div>
  );
};

export default ECRetention;

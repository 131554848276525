import React from "react";
import LoyaltyInput from "../../Common/LoyaltyInput";
import TiersModelExtended from "../../../../../models/features/loyalty/TiersModel/TiersModel";
import EnabledSelect from "../../Common/EnabledSelect";
import AddButton from "../../../../Buttons/AddButton";

type LoyaltyTiersProps = {
  loyaltyTiers: TiersModelExtended[];
  onChange: (
    scenario: "Tiers",
    uid: string,
    value: string | boolean,
    field: string
  ) => void;
  addNewReward: () => void;
  removeExistingReward: (rewardUid: string) => void;
};

const LoyaltyTiers: React.FC<LoyaltyTiersProps> = ({
  loyaltyTiers,
  onChange,
  addNewReward,
  removeExistingReward,
}) => {
  return (
    <div className="lg:grid grid-cols-4 gap-2 justify-between">
      {loyaltyTiers?.map((tier: TiersModelExtended, idx: number) => (
        <div
          className="bg-blueGray-200 mb-2"
          key={`${tier.id}-${tier.points}-${idx}`}
        >
          <div className="flex flex-col p-2">
            <EnabledSelect
              uid={tier.uid}
              enabled={tier.enabled}
              onChangeTiers={onChange}
              scenario="Tiers"
              removeExistingReward={() => removeExistingReward(tier.uid)}
            />
            <LoyaltyInput
              uid={tier.uid}
              label="ID"
              value={tier.id}
              onChangeTiers={onChange}
              scenario={"Tiers"}
            />
            <LoyaltyInput
              uid={tier.uid}
              label="Label"
              value={tier.label}
              onChangeTiers={onChange}
              scenario={"Tiers"}
            />
            <LoyaltyInput
              uid={tier.uid}
              label="Points"
              value={tier.points}
              onChangeTiers={onChange}
              scenario={"Tiers"}
            />
          </div>
        </div>
      ))}
      <AddButton onClick={addNewReward} />
    </div>
  );
};

export default LoyaltyTiers;

import React, { useRef } from "react";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import { usePollFeatures } from "../../../../hooks/features/usePollFeatures";
import FeaturesPoll from "./FeaturesPoll";

const FeaturesPollPage: React.FC = () => {
  // Use to trigger useEitherOrFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const feature = usePollFeatures(isFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Poll"}
      description={"This is poll configuration for client: "}
    >
      {feature ? (
        <FeaturesPoll feature={feature} isFeaturesEdited={isFeaturesEdited} />
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesPollPage;

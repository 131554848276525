import React, { useState } from "react";
import { marketMap } from "../../../../constants/constants";
import { upperCase } from "../../../../helpers/helpers";
import FormattedNumber from "../../../Common/FormattedNumber";

type PredictionsBreakdownProps = {
  entityId: string;
  predictions: { [key: string]: number };
};

const PredictionsBreakdown: React.FC<PredictionsBreakdownProps> = ({
  entityId,
  predictions,
}) => {
  const predictionsKeys = Object.keys(predictions);
  const endIndexToSlice = 5;

  const [endIndexPredictions, setEndIndexPredictions] =
    useState<number>(endIndexToSlice); // Only 5 predictions will be displayed by default.

  return (
    <div className="bg-blueGray-100 rounded border border-blueGray-200 p-2">
      <p className="md:text-xs text-xxs font-bold mb-1">
        Predictions breakdown:
      </p>
      <div className="md:text-xs text-xxs flex flex-wrap flex-row mb-1">
        {predictionsKeys
          .slice(0, endIndexPredictions)
          .map((market: string, index: number, array: string[]) => (
            <p key={`predictions-per-entity-${entityId}-${market}`}>
              <span className="font-normal mr-1">
                {market === "all" ? upperCase(market) : marketMap.get(market)}{" "}
                predictions:
              </span>
              <span className="font-bold mr-1">
                <FormattedNumber numberValue={predictions[market]} />
                {index === array.length - 1 ? "" : ","}
              </span>
            </p>
          ))}
      </div>
      {predictionsKeys.length > endIndexPredictions ? (
        <p className="text-xs">
          <a
            className="text-brandColor hover:text-lightBlue-800 cursor-pointer"
            onClick={() => setEndIndexPredictions(predictionsKeys.length)}
          >
            Show all
          </a>
        </p>
      ) : null}
    </div>
  );
};

export default PredictionsBreakdown;

import { Dispatch, SetStateAction } from "react";
import Select from "react-select";
import { asyncSelectStyles } from "../../../../../constants/styles";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { formatOptions } from "../../../../../helpers/helpers";
import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import { placeholders } from "../../../../../assets/placeholders/placeholders";

export const imageLogoEntity = (option: SelectOption) => {
  let placeholder: string = "";

  if (option.id.startsWith("fb:c:")) {
    placeholder = placeholders.competition;
  } else if (option.id.startsWith("fb:t:")) {
    placeholder = placeholders.team;
  } else if (option.id.startsWith("fb:p:")) {
    placeholder = placeholders.avatar;
  }

  return (
    <img
      src={option.logo ? option.logo : placeholder}
      alt={""}
      className="h-6 w-7 mr-1"
    />
  );
};

type EngagementEntitySelectProps = {
  whitelistEntities: any[];
  setEntityId: Dispatch<SetStateAction<string>>;
};

const EngagementEntitySelect: React.FC<EngagementEntitySelectProps> = ({
  whitelistEntities,
  setEntityId,
}) => {
  const loadedEntities = whitelistEntities?.map(
    (entity: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel) =>
      formatOptions(entity)
  );

  const handleOnChange = (selectedOption: SelectOption) => {
    const competitionIds = selectedOption ? selectedOption.id : "";
    setEntityId(competitionIds);
  };

  return (
    <Select
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      styles={asyncSelectStyles}
      isClearable
      onChange={handleOnChange}
      options={loadedEntities}
      placeholder={""}
      formatOptionLabel={(option) => (
        <div className="flex items-center">
          {imageLogoEntity(option)}
          <span className="mr-2">{option.label}</span>
          <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
          <span className="mr-1">{option.countryName}</span>
        </div>
      )}
    />
  );
};

export default EngagementEntitySelect;

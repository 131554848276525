import React from "react";

type EditProfileHeaderProps = {
  label: string;
};

const EditProfileHeader: React.FC<EditProfileHeaderProps> = ({ label }) => {
  return (
    <h6 className="text-blueGray-600 text-center text-m mt-3 mb-6 font-bold uppercase">
      {label}
    </h6>
  );
};

export default EditProfileHeader;

import { CustomAssetsInterface } from "../models/Clients/FootballSettingsConfigModel";

export default class FootballSettingsValidator {
  isValid: boolean = true;
  message: string = "";

  public validateCustomAssets = (customAssets: CustomAssetsInterface) => {
    const competitionIds = Object.keys(
      customAssets && customAssets.competitionLogos
        ? customAssets.competitionLogos
        : {}
    );
    const teamIds = Object.keys(
      customAssets && customAssets.teamLogos ? customAssets.teamLogos : {}
    );
    const playerIds = Object.keys(
      customAssets && customAssets.playerHeadshots
        ? customAssets.playerHeadshots
        : {}
    );

    if (competitionIds.length) {
      competitionIds.forEach((id: string) => {
        if (!customAssets.competitionLogos[id]) {
          this.message =
            "No URL is provided for competition. Please try again.";
          this.isValid = false;
        }

        if (!id.startsWith("fb:c:")) {
          this.message = "Competition is not selected. Please try again.";
          this.isValid = false;
        }
      });
    }

    if (teamIds.length) {
      teamIds.forEach((id: string) => {
        if (!customAssets.teamLogos[id]) {
          this.message = "No URL is provided for team. Please try again.";
          this.isValid = false;
        }

        if (!id.startsWith("fb:t:")) {
          this.message = "Team is not selected. Please try again.";
          this.isValid = false;
        }
      });
    }

    if (playerIds.length) {
      playerIds.forEach((id: string) => {
        if (!customAssets.playerHeadshots[id]) {
          this.message = "No URL is provided for player. Please try again.";
          this.isValid = false;
        }

        if (!id.startsWith("fb:p:")) {
          this.message = "Player is not selected. Please try again.";
          this.isValid = false;
        }
      });
    }
  };
}

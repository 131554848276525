import React from "react";
import { useDiscussionsAverages } from "../../../../../hooks/reports/discussions/useDiscussionsAverages";
import Spinner from "../../../../Spinner/Spinner";
import CardReport from "../../../../Cards/CardReport";
import "../../Reports.css";
import ReportInfo from "../../Common/ReportInfo";
import DiscussionsAverageStats from "./DiscussionsAverageStats";

const DiscussionsAverages: React.FC = () => {
  const discussionsAverages = useDiscussionsAverages();

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Discussions averages"}
          description={
            "This report shows the average posts for all discussions and percentage of users with at least X posts in discussions."
          }
        />
      </div>
      {discussionsAverages ? (
        <>
          <DiscussionsAverageStats
            type={"average_posts"}
            data={discussionsAverages.averagePosts}
          />
          <DiscussionsAverageStats
            type={"percentage_users_posts"}
            data={discussionsAverages.percentageUsersWithPosts}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default DiscussionsAverages;

import React from "react";
import UsersAquiredChart from "../../../Chart/Line/UsersAquiredChart";

const UsersAquired: React.FC = () => {
  return (
    <div className="w-full px-4 mb-12">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Users Aquired
              </h6>
              <h2 className="text-white text-xl font-semibold">
                Over the past 28 days compared to previous 28 days
              </h2>
            </div>
          </div>
        </div>
        <UsersAquiredChart />
      </div>
    </div>
  );
};

export default UsersAquired;

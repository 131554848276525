import React from "react";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import { useBadges } from "../../../../hooks/features/useBadges";
import LoyaltyBadges from "../FeaturesLoyalty/LoyaltyBadges/LoyaltyBadges";

const FeaturesBadgesPage: React.FC = () => {
  const badges = useBadges();

  return (
    <FeaturesLayout
      title={"Configuration for badges"}
      description={"This is badges configuration for client: "}
    >
      {badges ? <LoyaltyBadges originalBadges={badges} /> : <Spinner />}
    </FeaturesLayout>
  );
};

export default FeaturesBadgesPage;

import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import PredictorSuccessRateScopesModel from "../../../../../models/features/predictor/PredictorSuccessRateScopesModel";
import EditButton from "../../../../Buttons/CreateStaffButton";
import CompetitionsSuccessRateScope from "./Competitions/CompetitionsSuccessRateScope";
import MarketsSuccessRateScope from "./Markets/MarketsSuccessRateScope";
import TeamsSuccessRateScope from "./Teams/TeamsSuccessRateScope";

type SuccessRateScopesPredictorProps = {
  successRateScopes: PredictorSuccessRateScopesModel;
};

const SuccessRateScopesPredictor: React.FC<SuccessRateScopesPredictorProps> = ({
  successRateScopes,
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <div className="flex justify-between">
        <div className="mb-2">
          <h2 className="font-bold">Success Rate Scopes</h2>
          <p className="italic">Success rate percentages will be generated for users for the following competitions, teams and markets.</p>
        </div>
        <div className="self-center">
          <EditButton
            label={"Edit"}
            onClick={() => history.push(`${path}/edit`)}
          />
        </div>
      </div>
      <>
        <CompetitionsSuccessRateScope
          competitions={successRateScopes.competitions}
        />
        <TeamsSuccessRateScope teams={successRateScopes.teams} />
        <MarketsSuccessRateScope markets={successRateScopes.markets} />
      </>
    </div>
  );
};

export default SuccessRateScopesPredictor;

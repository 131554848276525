import React from "react";

type FilledProgressBarProps = {
  percentage: string;
  user: string;
};

const progressBarColor = (user: string) => {
  switch (user) {
    case "all":
      return "bg-emerald-500";
    case "male":
      return "bg-lightBlue-500";
    case "female":
      return "bg-red-500";
    case "unspecified":
      return "bg-blueGray-500";
    case "percent":
      return "bg-indigo-600";
  }
};
const FilledProgressBar: React.FC<FilledProgressBarProps> = ({
  percentage,
  user,
}) => {
  return (
    <div className="overflow-hidden h-2 flex rounded bg-blueGray-200">
      <div
        style={{ width: percentage }}
        className={`whitespace-nowrap ${progressBarColor(user)}`}
      ></div>
    </div>
  );
};

export default FilledProgressBar;

import Tippy from "@tippyjs/react";
import React from "react";

/**
 * Transforms the number to e specific format. Example:
 * - 1323 -> 1.3k
 * - 1500000 -> 1.5M
 * - 100100000 -> 1.1B
 * @param value Number to be formatted
 * @returns Formatted number
 */

const formatNumber = (value: number) => {
  if (value / 1_000_000_000 > 1) {
    return `${(value / 1_000_000_000).toFixed(2)}B`;
  } else if (value / 1_000_000 > 1) {
    return `${(value / 1_000_000).toFixed(2)}M`;
  } else if (value / 1_000 > 1) {
    return `${(value / 1_000).toFixed(2)}k`;
  }

  return value;
};

type FormattedNumberProps = {
  numberValue: number;
};

/**
 * Component will be used for any metrics with big data (numbers) like consumptions, users, predictions and etc.
 * @returns Formatted big number.
 */

const FormattedNumber: React.FC<FormattedNumberProps> = ({ numberValue }) => {
  const numberToString = numberValue.toString();
  const minDigitsToFormat = 3;

  if (
    Number.isInteger(numberValue) &&
    numberToString.length > minDigitsToFormat
  ) {
    return (
      <Tippy content={numberValue.toLocaleString("ru-RU")}>
        <button className="bg-transparent cursor-default">{`${formatNumber(
          numberValue
        )}`}</button>
      </Tippy>
    );
  }

  return <>{numberValue}</>;
};

export default FormattedNumber;

import firebase from "firebase/compat/app";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { firebaseAuth } from "../sdks/firebase/firebase";

export const AuthContext = createContext<firebase.User | null>(null);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const unsubscribe = firebaseAuth().onAuthStateChanged((firebaseUser) => {
    //Telling React to combine the state changes into one update. It's used when you refresh page to avoid showing Login component.
    unstable_batchedUpdates(() => {
      setLoading(false);
      setUser(firebaseUser);
    });
  });

  useEffect(() => {
    unsubscribe();

    return () => {
      setUser(null);
    };
  }, []);

  return (
    <AuthContext.Provider value={user}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useContext, useRef, useState } from "react";
import { createPopper } from "@popperjs/core";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";

type MemberDropdownProps = {
  staffId: string;
  staffEmail: string;
};

const MemberDropdown: React.FC<MemberDropdownProps> = ({
  staffId,
  staffEmail,
}) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] =
    useState<boolean>(false);

  const btnDropdownRef = useRef<HTMLAnchorElement>(null);
  const popoverDropdownRef = useRef<HTMLDivElement>(null);

  const {
    isModalConfirmActionSubmitted,
    setShowModal,
    setStaffId,
    setStaffEmail,
  } = useContext(GlobalStatesContext);

  const isOutside = useOutsideAlerter(
    btnDropdownRef,
    popoverDropdownRef,
    setDropdownPopoverShow
  );

  const openDropdownPopover = () => {
    if (btnDropdownRef && popoverDropdownRef) {
      createPopper(
        btnDropdownRef.current as HTMLAnchorElement,
        popoverDropdownRef.current as HTMLDivElement,
        {
          placement: "left-start",
        }
      );
      setDropdownPopoverShow(true);
    }
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const onClickDelete = () => {
    isModalConfirmActionSubmitted.current = false;
    setStaffId(staffId);
    setStaffEmail(staffEmail);
    setShowModal(true);
    closeDropdownPopover();
  };

  return (
    <>
      <a
        className="text-blueGray-500 p-2"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow && !isOutside ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 hover:bg-blueGray-50 cursor-pointer"
        }
        onClick={onClickDelete}
      >
        <span className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-blueGray-700 bg-transparent">
          Delete
        </span>
      </div>
    </>
  );
};

export default MemberDropdown;

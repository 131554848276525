import React from "react";

type BadgeSoonProps = {
  label: string;
};

const Badge: React.FC<BadgeSoonProps> = ({ label }) => {
  return (
    <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-brandColor mr-4">
      {label}
    </span>
  );
};

export default Badge;

import { createPopper } from "@popperjs/core";
import React, { useRef, useState } from "react";

const MaximumCountTooltip: React.FC = () => {
  const [popoverShow, setPopoverShow] = useState<boolean>(false);

  const iconRef = useRef<SVGSVGElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const openTooltip = () => {
    createPopper(
      iconRef.current as SVGSVGElement,
      popoverRef.current as HTMLDivElement,
      {
        placement: "right",
      }
    );
    setPopoverShow(true);
  };

  const closeTooltip = () => {
    setPopoverShow(false);
  };

  // Handlers for mobile version

  const handleTouchStart = () => {
    openTooltip();
  };

  const handleTouchEnd = () => {
    closeTooltip();
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          ref={iconRef}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
          />
        </svg>
        <div
          className={`${popoverShow ? "" : "hidden"}
                bg-blueGray-600 border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg`}
          ref={popoverRef}
        >
          <div>
            <div className="bg-blueGray-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg">
              Maximum count
            </div>
            <div className="text-white p-3">
              How many times the user will be rewarded for the specified action.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaximumCountTooltip;

import React, { useState } from "react";
import ReportTwoTabs from "../../Common/ReportTwoTabs";
import ClassicQuizBreakdown from "./Breakdown/ClassicQuizBreakdown";
import ClassicQuizPopular from "./Popular/ClassicQuizPopular";

export enum MINI_GAMES_REPORTS_TABS {
  BREAKDOWN = "breakdown",
  POPULAR = "popular",
}

const ClassicQuizReport: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>(
    MINI_GAMES_REPORTS_TABS.BREAKDOWN
  );

  return (
    <div className="w-full px-4">
      <ReportTwoTabs
        tabValue={openTab}
        setTabValue={setOpenTab}
        reportType={"mini_games"}
      />
      {openTab === "breakdown" ? (
        <ClassicQuizBreakdown />
      ) : (
        <ClassicQuizPopular />
      )}
    </div>
  );
};

export default ClassicQuizReport;

import React from "react";
import PredictorBadgesExtended from "../../../../../../../models/features/loyalty/Badges/Predictor/PredictorBadgesExtended";
import Badge from "../Badge";
import PredictorBadgeRequirements from "./PredictorBadgeRequirements";

type PredictorBadgeProps = {
  badge: PredictorBadgesExtended;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
  removeExistingBadge: (uid: string) => void;
};

const PredictorBadge: React.FC<PredictorBadgeProps> = ({
  badge,
  onChangeBadges,
  removeExistingBadge,
}) => {
  return (
    <div className="flex flex-col p-2">
      <Badge
        badge={badge}
        onChangeBadges={onChangeBadges}
        removeExistingBadge={removeExistingBadge}
      />
      <PredictorBadgeRequirements
        uid={badge.uid}
        requirements={badge.requirements}
        onChangeBadges={onChangeBadges}
      />
    </div>
  );
};

export default PredictorBadge;

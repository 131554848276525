import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { setDefaultCoparedToDateApi } from "../helpers/helpers";
import PredictionsBreakdown from "../models/Reports/Predictions/PredictionsBreakdown";
import ReportPeriods from "../models/Reports/ReportPeriods";
import { ApiContext } from "../providers/ApiProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { usePreviousCustom } from "./usePrevious";

/**
 * Sort the total predictions we get from API. The object's properties will be sorted by their value (count of predictions for market).
 */
const sortTotalPredictionsByPredictionsCount = (totalPredictions: {
  [key: string]: number;
}) => {
  const sortableArray = [];
  const sortedTotalPredictions = {};

  Object.keys(totalPredictions).forEach((key: string) => {
    sortableArray.push([key, totalPredictions[key]]);
  });

  sortableArray.sort((a, b) => b[1] - a[1]);

  sortableArray.forEach((item) => (sortedTotalPredictions[item[0]] = item[1]));

  return sortedTotalPredictions;
};

/**
 * TODO: Think about refactoring this custom hook. We have total predictions and breakdown from one endpoint
 * but we make two times API calls for them.
 */

export const usePredictions = (markets: string[]) => {
  const [totalPredictions, setTotalPredictions] = useState<{
    [key: string]: number;
  }>(null);
  const [totalPredictionsBreakdown, setTotalPredictionsBreakdown] = useState<
    ReportPeriods<PredictionsBreakdown[]>
  >({ current: [], comparedTo: [] });
  const [predictionsBreakdown, setPredictionsBreakdown] = useState<
    ReportPeriods<PredictionsBreakdown[]>
  >({ current: [], comparedTo: [] });

  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  // Will be when client changes the clientId and with selected markets to send a request to API with no markets.
  const previousClient = usePreviousCustom(selectedClient.id);

  const api = useContext(ApiContext);

  const getPredictionsPeriod = async () => {
    /**
     * We don't send all as a value in query param markets, because API will return incorrect response.
     * Also when clients changes his project, we received the choosen markets for the previous client
     * so we compare the previous and current client.
     */
    const newMarkets =
      previousClient !== selectedClient.id
        ? []
        : markets.filter((market: string) => market !== "all");
    try {
      const selectedPredictionsByPeriod =
        await api.reportingHttps.getPredictionsForPeriod(
          datePeriod,
          groupBy,
          newMarkets
        );
      const sortedTotalPredictions = sortTotalPredictionsByPredictionsCount(
        selectedPredictionsByPeriod.total
      );
      const { breakdown } = selectedPredictionsByPeriod;

      let comparedToBreakdown: any = null;

      if (comparedTo) {
        const comparedToPredictionsByPeriod =
          await api.reportingHttps.getPredictionsForPeriod(
            comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
            groupBy,
            newMarkets
          );

        const { breakdown } = comparedToPredictionsByPeriod;
        comparedToBreakdown = JSON.parse(JSON.stringify(breakdown));
      }

      return {
        sortedTotalPredictions,
        breakdown,
        comparedToBreakdown,
      };
    } catch (error) {
      toast.error(ERROR_MESSAGES.PREDICTIONS_REPORT);
      setTotalPredictions({ all: 0 });
      setPredictionsBreakdown({ current: [], comparedTo: [] });
    }
  };

  const setTotalPredictionsFromApi = async () => {
    const { sortedTotalPredictions, breakdown, comparedToBreakdown } =
      await getPredictionsPeriod();

    setTotalPredictionsBreakdown({
      current: breakdown,
      comparedTo: comparedToBreakdown,
    });
    setTotalPredictions(sortedTotalPredictions);
  };

  const setPredictionsBreakdownFromApi = async () => {
    const { breakdown, comparedToBreakdown } = await getPredictionsPeriod();

    setPredictionsBreakdown({
      current: breakdown,
      comparedTo: comparedToBreakdown,
    });
  };

  useEffect(() => {
    setTotalPredictionsFromApi();
    setPredictionsBreakdownFromApi();

    return () => {
      setTotalPredictions(null);
      setPredictionsBreakdown({ current: [], comparedTo: [] });
    };
  }, [selectedClient, groupBy, datePeriod, comparedTo, comparedToPeriod]);

  /**
   * Additional effect for case when we change only the checkbox value.
   */

  useEffect(() => {
    if (markets.length === 1 && markets.includes("all")) {
      setTotalPredictionsFromApi();
      setPredictionsBreakdownFromApi();
    } else {
      setPredictionsBreakdownFromApi();
    }
  }, [markets]);

  return { totalPredictions, totalPredictionsBreakdown, predictionsBreakdown };
};

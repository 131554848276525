import UsersBreakdown from "../UsersBreakdown";

export interface IMiniGamesBreakdown {
  date: string;
  participations: UsersBreakdown;
}

export default class MiniGamesParticipations {
  breakdown: IMiniGamesBreakdown[] = [];
  total: UsersBreakdown = new UsersBreakdown();
}

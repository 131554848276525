import React from "react";
import { marketMap } from "../../../../constants/constants";
import FormattedNumber from "../../../Common/FormattedNumber";
import "./MarketBreakdown.css";

type MarketBreakdownProps = {
  market: string;
  count: number;
  all: number;
  marketBreakdown: string[];
  onChangeCheckbox: (market: string) => void;
};

const MarketBreakdown: React.FC<MarketBreakdownProps> = ({
  market,
  count,
  all,
  marketBreakdown,
  onChangeCheckbox,
}) => {
  const percentage = () => {
    let percentage = (count / all) * 100;
    percentage = percentage || 0;

    return percentage.toFixed(2);
  };

  const isChecked = () => marketBreakdown.includes(market);

  const showPercentage = market === "all" ? false : true;

  return (
    <div className="md:mx-auto marketContainer rounded border p-2 w-full">
      <h2 className="textNormalWrap textNoWrap">
        {market === "all" ? "All single predictions" : marketMap.get(market)}
      </h2>
      <p className="font-bold text-xs mb-2 textNormalWrap textNoWrap cursor-default">
        <FormattedNumber numberValue={count} />
        {showPercentage ? ` predictions (${percentage()}%)` : ` predictions`}
      </p>
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={() => onChangeCheckbox(market)}
      >
        <input
          className="mr-1 cursor-pointer"
          type="checkbox"
          checked={isChecked()}
          readOnly
        />
        <span className="textNormalWrap text-xs textNoWrap">Show data</span>
      </div>
    </div>
  );
};

export default MarketBreakdown;

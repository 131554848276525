import React, { useContext, useState } from "react";
import { useFollowedFootballEntities } from "../../../../../hooks/useFollowedFootballEntities";
import { useTopFollowedFootballEntities } from "../../../../../hooks/useTopFollowedFootballEntities";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import CardReport from "../../../../Cards/CardReport";
import Spinner from "../../../../Spinner/Spinner";
import DatePeriod from "../../Common/DatePeriod";
import EntitySelect from "../../Common/EntityFollows/EntitySelect";
import FootballEntitiesFollows from "../../Common/EntityFollows/FootballEntitiesFollows";
import ReportInfo from "../../Common/ReportInfo";
import "../../Reports.css";

const FootballPlayersReport: React.FC = () => {
  const [playerIds, setPlayerIds] = useState<string[]>([]);
  const [playerOptions, setPlayersOptions] = useState<SelectOption[]>([]);

  const { datePeriod } = useContext(ReportParamsContext);

  const topFollowsFootballPlayers = useTopFollowedFootballEntities(
    datePeriod,
    playerIds,
    "players"
  );
  const playerFollowsInfo = useFollowedFootballEntities(
    playerIds,
    datePeriod,
    "players"
  );

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Football players report"}
          description={
            "This report shows by default the top 100 most followed players specified by period. Also you can search for a specific player and see how many users are following it specified by gender and country."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} allTimeDefault={true} />
        </div>
      </div>

      <div className="mb-6">
        <EntitySelect
          setEntityIds={setPlayerIds}
          entity="players"
          entities={playerOptions}
          setEntities={setPlayersOptions}
        />
      </div>
      {topFollowsFootballPlayers || playerFollowsInfo ? (
        <FootballEntitiesFollows
          topFollowsFootballEntities={topFollowsFootballPlayers}
          entityFollowsInfo={playerFollowsInfo}
          entity={"players"}
          setEntityIds={setPlayerIds}
          setEntities={setPlayersOptions}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default FootballPlayersReport;

export default class ProfileValidator {
  public isValid: boolean = true;
  public message: string = "";

  public validateUpdateName = (newName: string) => {
    if (!newName) {
      this.isValid = false;
      this.message = "You need to provide a name to continue";
    }

    return this;
  };

  public validateChangePassword = (
    currentPassword: string,
    newPassword: string,
    repeatNewPassword: string
  ) => {
    this.validatePasswordFields(
      currentPassword,
      newPassword,
      repeatNewPassword
    );
    this.validateRepeatPassword(newPassword, repeatNewPassword);
    this.validatePasswordLength(newPassword);
    return this;
  };

  public isEmailValid = (email: string) => {
    let regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validatedEmail = regex.test(email);

    if (!validatedEmail) {
      this.isValid = false;
      this.message = "E-mail is invalid";
    }
  };

  public validateRepeatPassword = (
    password: string,
    repeatPassword?: string
  ) => {
    if (!repeatPassword || password !== repeatPassword) {
      this.isValid = false;
      this.message = "New password and repeat passsword are mismatching";
    }
  };

  public validatePasswordLength = (newPassword: string) => {
    const limitCharsPassword = 8;
    const maxCharsPassword = 30;

    if (
      newPassword.length < limitCharsPassword ||
      newPassword.length > maxCharsPassword
    ) {
      this.isValid = false;
      this.message = "Password length must be between 8 and 30 characters";
    }
  };

  public validatePasswordFieldsStaffMember = (
    newPassword: string,
    repeatNewPassword: string
  ) => {
    if (!newPassword || !repeatNewPassword) {
      this.isValid = false;
      this.message = "You need to provide a password";
    }
  };

  private validatePasswordFields = (
    currentPassword: string,
    newPassword: string,
    repeatNewPassword: string
  ) => {
    if (!currentPassword || !newPassword || !repeatNewPassword) {
      this.isValid = false;
      this.message = "You need to provide a password";
    }
  };
}

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import MatchFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchFilters";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";

const currentDateInIsoString = () => {
  const isoStringApiFormat = "YYYY-MM-DDTHH:mm:ss[Z]";
  dayjs.extend(utc);

  return dayjs().utc().format(isoStringApiFormat);
};

interface MatchData {
  prevMatches: MatchBasicModel[];
  nextMatches: MatchBasicModel[];
}

export const useMatchDataTeam = (teamId: string) => {
  const [matchData, setMatchData] = useState<MatchData>(null);

  const api = useContext(ApiContext);

  const getMatchData = async () => {
    try {
      const limit = 100;
      const prevMatchesFilters = {
        teamIds: [teamId],
        toDate: currentDateInIsoString(),
        limit,
      } as MatchFilters;
      const nextMatchesFilters = {
        teamIds: [teamId],
        fromDate: currentDateInIsoString(),
        limit,
      } as MatchFilters;

      const responses = await Promise.all([
        api.sdk.football.getMatches(prevMatchesFilters),
        api.sdk.football.getMatches(nextMatchesFilters),
      ]);
      const [prevMatches, nextMatches] = responses;

      const matchData: MatchData = {
        prevMatches: prevMatches.data.reverse(),
        nextMatches: nextMatches.data,
      };
      setMatchData(matchData);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
      setMatchData({ prevMatches: [], nextMatches: [] });
    }
  };

  useEffect(() => {
    getMatchData();

    return () => setMatchData({ prevMatches: [], nextMatches: [] });
  }, []);

  return matchData;
};

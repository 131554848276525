import React, { useState } from "react";
import EngagementCoefficientsConfiguration from "../../../../../models/Clients/EngagementCoefficientsConfiguration/EngagementCoefficientsConfiguration";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import { useEngagementSegments } from "../../../../../hooks/useEngagementSegments";
import EngagementEntitySelect from "../EngagementEntitySelect/EngagementEntitySelect";
import Spinner from "../../../../Spinner/Spinner";
import EngagementSegmentsInfo from "./EngagementSegmentsInfo";
import UserSegments from "../../../../../models/Clients/EngagementCoefficientsConfiguration/UserSegments";
import { snakeCase } from "lodash";

const engagementSegmentKeyToTitle = new Map<string, string>()
  .set("fanatics", "Fanatics")
  .set("fans", "Fans")
  .set("casualUsers", "Casual users")
  .set("atRisk", "At risk")
  .set("inactive", "Inactive")
  .set("new", "New");

type EngagementSegmentsProps = {
  engagementCoefficientsConfig: EngagementCoefficientsConfiguration;
  whitelistEntities: any[];
};

const EngagementSegments: React.FC<EngagementSegmentsProps> = ({
  engagementCoefficientsConfig,
  whitelistEntities,
}) => {
  const [entityId, setEntityId] = useState<string>("");

  const engagementSegments = useEngagementSegments(entityId);

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Engagement segments report"}
          description={
            "Retrieves a breakdown of the number of users the specified client has within each RFE (Recency, Frequency, Engagement) segment."
          }
        />
      </div>
      <div className="mb-6">
        <EngagementEntitySelect
          whitelistEntities={whitelistEntities}
          setEntityId={setEntityId}
        />
      </div>
      {engagementSegments && engagementCoefficientsConfig ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-2 mb-6">
          {Array.from(engagementSegments.breakdown.keys()).map(
            (value: string, index: number) => (
              <EngagementSegmentsInfo
                key={`engagement-segments-${value}-${index}`}
                title={engagementSegmentKeyToTitle.get(value)}
                value={engagementSegments.breakdown.get(value)}
                userSegment={engagementCoefficientsConfig.userSegments.find(
                  (userSegment: UserSegments) =>
                    userSegment.segmentId === snakeCase(value)
                )}
              />
            )
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default EngagementSegments;

import React from "react";
import Select from "react-select";
import ReactSelectBaseOption from "../../../../models/SelectOption/ReactSelectBaseOption";
import {
  webhookMethodSelectStyles,
  webhookTypeSelectStyles,
} from "../../../../constants/styles";

type SelectField = "method" | "type";

const transformValueToSelectOption = (value: string): ReactSelectBaseOption => {
  return {
    id: value,
    value: value,
    label: value,
  };
};

const transformValuesToSelectOptions = (
  values: string[]
): ReactSelectBaseOption[] => {
  return values.map((v: string) => {
    return {
      id: v,
      value: v,
      label: v,
    };
  });
};

const constructOptions = (
  field: SelectField,
  typeWebhook: string
): ReactSelectBaseOption[] => {
  const methodValues =
    typeWebhook && typeWebhook === "KAFKA"
      ? ["GET", "POST"]
      : ["GET", "POST", "PUT"];
  const typeValues = ["HTTP", "KAFKA", "PUB_SUB"];

  switch (field) {
    case "method":
      return transformValuesToSelectOptions(methodValues);
    case "type":
      return transformValuesToSelectOptions(typeValues);
    default:
      return [];
  }
};

type WebhookValueSelectProps = {
  value: string;
  field: SelectField;
  typeWebhook?: string;
  onChange: (value: string) => void;
};

const WebhookValueSelect: React.FC<WebhookValueSelectProps> = ({
  value,
  field,
  typeWebhook,
  onChange,
}) => {
  const onChangeHandler = (selectOption: ReactSelectBaseOption) =>
    onChange(selectOption.value);

  return (
    <Select
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      styles={
        field === "type" ? webhookTypeSelectStyles : webhookMethodSelectStyles
      }
      onChange={onChangeHandler}
      defaultValue={transformValueToSelectOption(value)}
      options={constructOptions(field, typeWebhook)}
    />
  );
};

export default WebhookValueSelect;

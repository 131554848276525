export default class GlobalValidator {
  /**
   * Id field in loyalty features needs to be validate. It will contain only small letters, numbers and underscores.
   * @param idValue Value to be validated
   */
  isIdValueValid = (idValue: string): boolean => {
    if (!idValue.length) return true;

    const regex = /^[a-z0-9_]+$/;

    return regex.test(idValue);
  };

  /**
   * Validates for falsy values in nested object.
   */

  isPropertyWithInvalidValue = (
    obj: { [key: string]: any },
    property: string
  ) => {
    for (const key of Object.keys(obj)) {
      if (
        (key === property && !obj[key]) ||
        (obj[key] &&
          typeof obj[key] === "object" &&
          this.isPropertyWithInvalidValue(obj[key], property))
      ) {
        return true;
      }
    }

    return false;
  };

  isValidURL = (value: string) => {
    if (!value.length) return true;

    const regex = /^(https?:\/\/).*\.(jpg|jpeg|png|gif|bmp)$/;

    return regex.test(value);
  };
}

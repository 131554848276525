import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../../../../enums/enums";
import Spinner from "../../../../../Spinner/Spinner";
import { ApiContext } from "../../../../../../providers/ApiProvider";
import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import MatchFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchFilters";
import { placeholders } from "../../../../../../assets/placeholders/placeholders";
import LoadMoreButton from "../../../../../Buttons/LoadMoreButton";
import SelectOption from "../../../../../../models/SelectOption/SelectOption";

const Match: React.FC<{
  uid: string;
  match: any;
  checked?: boolean;
  onClick: (match: MatchBasicModel) => void;
}> = ({ uid, match, checked, onClick }) => {
  return (
    <div className="flex items-center mt-3">
      <input
        id={match.id}
        type="radio"
        name={`checkbox-match-${uid}`}
        className="h-5 w-5 text-blueGray-600 cursor-pointer"
        value={match.id}
        onChange={() => onClick(match)}
        checked={checked}
      />
      <div className="flex items-center flex-wrap mx-1 text-sm">
        <span className="py-1 mr-1">
          [{dayjs(match.kickoffAt).format("DD.MM.YYYY")}]
        </span>
        {match.homeTeam.name}
        <img
          className="h-4 w-4 m-1"
          src={`${match.homeTeam.assets?.logo || placeholders.team}`}
        />
        {" : "}
        <img
          className="h-4 w-4 m-1"
          src={`${match.awayTeam.assets?.logo || placeholders.team}`}
        />
        {match.awayTeam.name}
      </div>
    </div>
  );
};

type MatchesForTeamsProps = {
  uid: string;
  entityOption: SelectOption;
  loadedMatch: MatchBasicModel;
  onClick: (entity: MatchBasicModel) => void;
};

const MatchesForTeams: React.FC<MatchesForTeamsProps> = ({
  uid,
  entityOption,
  loadedMatch,
  onClick,
}) => {
  const limitMatches = 5;

  const [matches, setMatches] = useState<MatchBasicModel[]>([]);
  const [allMatches, setAllMatches] = useState<MatchBasicModel[]>([]);
  const [limit, setLimit] = useState<number>(limitMatches);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { sdk } = useContext(ApiContext);

  const getMatches = async () => {
    const fromDate = dayjs().toISOString().split(".")[0] + "Z";
    const limit = 100;
    let matchesResponse: MatchBasicModel[] = null;
    let limitMatchesModel: MatchBasicModel[] = [];
    const teamIds = [entityOption.id];
    const matchesFilters: MatchFilters = {
      teamIds,
      fromDate,
      limit,
    };
    setIsLoading(true);

    try {
      const response = await sdk.football.getMatches(matchesFilters);
      matchesResponse = response.data;
    } catch (error) {
      console.warn(error);
      setMatches([]);
      toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
      setIsLoading(false);
      return;
    }

    if (matchesResponse && !matchesResponse.length) {
      setMatches([]);
      setShowMore(false);
      setIsLoading(false);
      return;
    }

    if (matchesResponse && matchesResponse.length > limitMatches) {
      setAllMatches(matchesResponse);
      limitMatchesModel = matchesResponse.slice(0, limit);

      setMatches((prevState) => [...limitMatchesModel, ...prevState]);
      setShowMore(true);
    } else {
      setShowMore(false);
      setMatches((prevState) => [...prevState, ...matchesResponse]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (entityOption) {
      getMatches();
    }
  }, [entityOption]);

  const loadMore = () => {
    const newLimit = limit + limitMatches;
    const newListMatches = allMatches.slice(limit, newLimit);
    const hasMore = allMatches.length > newLimit;

    setLimit(newLimit);
    setShowMore(hasMore);
    setMatches((prevState) => [...prevState, ...newListMatches]);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const displayMatches = () => {
    if (entityOption) {
      return (
        <>
          {matches.map((match: any) => (
            <Match key={match.id} uid={uid} match={match} onClick={onClick} />
          ))}
        </>
      );
    }

    if (loadedMatch) {
      return (
        <Match uid={uid} match={loadedMatch} checked={true} onClick={onClick} />
      );
    }

    return <></>;
  };

  return (
    <>
      <div className="flex flex-col h-56 overflow-hidden overflow-y-scroll p-2 bg-blueGray-100 mb-1">
        {displayMatches()}
        {entityOption && matches.length === 0 && !loadedMatch ? (
          <h2 className="text-center font-bold">No matches found</h2>
        ) : (
          <></>
        )}
      </div>
      {showMore && <LoadMoreButton onClick={loadMore} />}
    </>
  );
};

export default MatchesForTeams;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import ClassicQuizPopular from "../../../models/Reports/MiniGames/ClassicQuizPopular";

export const useClassicQuizPopular = () => {
  const [classicQuizPopular, setClassicQuizPopular] =
    useState<ClassicQuizPopular[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod } = useContext(ReportParamsContext);

  const getClassicQuizPopular = async () => {
    try {
      const classicQuizPopular = await api.reportingHttps.getClassicQuizPopular(
        datePeriod
      );
      const classicQuizIds = classicQuizPopular.map(
        (value) => value.classicQuizId
      );
      const classicQuizzes = await api.sdk.miniGames.getClassicQuizzes(
        { classicQuizIds, limit: classicQuizIds.length },
        false,
        true
      );
      classicQuizPopular.forEach((value) => {
        value.classicQuizModel = classicQuizzes.data.find(
          (quiz) => quiz.id === value.classicQuizId
        ) || null;
      });
      setClassicQuizPopular(classicQuizPopular);
    } catch (error) {
      toast.error(ERROR_MESSAGES.MINI_GAMES_POPULAR_REPORT);
      setClassicQuizPopular([]);
    }
  };

  useEffect(() => {
    getClassicQuizPopular();

    return () => setClassicQuizPopular(null);
  }, [selectedClient, datePeriod]);

  return classicQuizPopular;
};

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import EitherOrPopular from "../../../models/Reports/MiniGames/EitherOrPopular";

export const useEitherOrPopular = () => {
  const [eitherOrPopular, setEitherOrPopular] =
    useState<EitherOrPopular[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod } = useContext(ReportParamsContext);

  const getEitherOrPopular = async () => {
    try {
      const eitherOrPopular = await api.reportingHttps.getEitherOrPopular(
        datePeriod
      );
      const eitherOrIds = eitherOrPopular.map((value) => value.eitherOrId);
      const eitherOrs = await api.sdk.miniGames.getEitherOrs(
        { eitherOrIds, limit: eitherOrIds.length },
        false,
        true
      );
      eitherOrPopular.forEach((value) => {
        value.eitherOrModel = eitherOrs.data.find(
          (eitherOr) => eitherOr.id === value.eitherOrId
        ) || null;
      });
      setEitherOrPopular(eitherOrPopular);
    } catch (error) {
      toast.error(ERROR_MESSAGES.MINI_GAMES_POPULAR_REPORT);
      setEitherOrPopular([]);
    }
  };

  useEffect(() => {
    getEitherOrPopular();

    return () => setEitherOrPopular(null);
  }, [selectedClient, datePeriod]);

  return eitherOrPopular;
};

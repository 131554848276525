import React, { PropsWithChildren, useContext } from "react";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";

type AdditionalParagraphsTypes = "football_settings";

const AdditionalParagraph = (
  additionalParagraph: AdditionalParagraphsTypes | undefined
) => {
  if (!additionalParagraph) return <></>;

  switch (additionalParagraph) {
    case "football_settings":
      return (
        <p>
          When enabled, all assets (your default ones or from Fans United
          Football API) will be overwritten with custom ones.
          <br></br>
          <span className="font-bold">
            N.B Configuration is cached for 1 hour by Client API.
          </span>{" "}
          This means that if you update your configuration, the new
          logo/headshots for competitions, teams or players will be returned
          from Fans United Football API within 1 hour.
        </p>
      );
  }
};
type FeaturesLayoutProps = {
  title: string;
  description: string;
  additionalParagraph?: AdditionalParagraphsTypes;
};

const FeaturesLayout: React.FC<PropsWithChildren & FeaturesLayoutProps> = ({
  children,
  title,
  description,
  additionalParagraph,
}) => {
  const { selectedClient } = useContext(GlobalStatesContext);

  return (
    <div className="w-full px-4">
      <div className="relative min-w-0 break-words bg-white w-full mb-24 shadow-lg rounded p-4">
        <div className="mb-4">
          <h2 className="font-bold">{title}</h2>
          <p>
            {description}
            <span className="font-semibold">{selectedClient.id}</span>
          </p>
          {AdditionalParagraph(additionalParagraph)}
        </div>
        {children}
      </div>
    </div>
  );
};

export default FeaturesLayout;

import React, { useContext } from "react";
import { upperCase } from "../../../../helpers/helpers";
import { ReportParamsContext } from "../../../../providers/ReportParamsProvider";
import "./GroupByPeriod.css";

const groupPeriod = ["day", "week", "month", "year"];

const GroupByPeriod: React.FC = () => {
  const { groupBy, setGroupBy } = useContext(ReportParamsContext);

  return (
    <div className="grid grid-cols-4 gap-1 w-full mb-6" role="tablist">
      {groupPeriod.map((period: string, index: number) => (
        <div
          className="mb-2 md:mr-2 flex-auto text-center groupContainer"
          key={index}
        >
          <a
            className={
              "text-xs font-bold p-2.5 rounded block leading-normal cursor-pointer text-white " +
              (groupBy === period ? "bg-brandColor" : "bg-gray-400")
            }
            onClick={() => setGroupBy(period)}
            data-toggle="tab"
            role="tablist"
          >
            {upperCase(period)}
          </a>
        </div>
      ))}
    </div>
  );
};

export default GroupByPeriod;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { setDefaultCoparedToDateApi } from "../../../helpers/helpers";
import ReportPeriods from "../../../models/Reports/ReportPeriods";
import MiniGamesParticipations from "../../../models/Reports/MiniGames/MiniGamesParticipations";

export const useEitherOrBreakdown = () => {
  const [eitherOrBreakdown, setEitherOrBreakdown] =
    useState<ReportPeriods<MiniGamesParticipations>>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);

  const getEitherOrBreakdown = async () => {
    try {
      const eitherOrParticipation =
        await api.reportingHttps.getEitherOrBreakdown(datePeriod, groupBy);
      let comparedToBreakdown: any = null;

      if (comparedTo) {
        comparedToBreakdown = await api.reportingHttps.getEitherOrBreakdown(
          comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
          groupBy
        );
      }

      setEitherOrBreakdown({
        current: eitherOrParticipation,
        comparedTo: comparedToBreakdown,
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.MINI_GAMES_BREAKDOWN_REPORT);
      setEitherOrBreakdown({
        current: new MiniGamesParticipations(),
        comparedTo: new MiniGamesParticipations(),
      });
    }
  };

  useEffect(() => {
    getEitherOrBreakdown();

    return () => setEitherOrBreakdown(null);
  }, [selectedClient, comparedToPeriod, groupBy, datePeriod, comparedTo]);

  return eitherOrBreakdown;
};

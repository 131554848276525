import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import PrivateLeaguesBreakdownModel from "../../../models/Reports/PrivateLeagues/PrivateLeaguesBreakdownModel";
import ReportPeriods from "../../../models/Reports/ReportPeriods";
import { ApiContext } from "../../../providers/ApiProvider";
import { setDefaultCoparedToDateApi } from "../../../helpers/helpers";
import { ERROR_MESSAGES } from "../../../enums/enums";

export const usePrivateLeaguesBreakdown = () => {
  const [privateLeaguesBreakdown, setPrivateLeaguesBreakdown] =
    useState<ReportPeriods<PrivateLeaguesBreakdownModel>>(null);

  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedTo, comparedToPeriod, groupBy } =
    useContext(ReportParamsContext);
  const api = useContext(ApiContext);

  const getPrivateLeaguesBreakdown = async () => {
    try {
      const currentBreakdown =
        await api.reportingHttps.getPrivateLeagueBreakdown(datePeriod, groupBy);
      let comparedToBreakdown: any = null;

      if (comparedTo) {
        comparedToBreakdown =
          await api.reportingHttps.getPrivateLeagueBreakdown(
            comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
            groupBy
          );
      }
      setPrivateLeaguesBreakdown({
        current: currentBreakdown,
        comparedTo: comparedToBreakdown,
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.PRIVATE_LEAGUES_BREAKDOWN_REPORT);
      setPrivateLeaguesBreakdown({
        current: new PrivateLeaguesBreakdownModel(),
        comparedTo: new PrivateLeaguesBreakdownModel(),
      });
    }
  };

  useEffect(() => {
    getPrivateLeaguesBreakdown();

    return () => setPrivateLeaguesBreakdown(null);
  }, [selectedClient, datePeriod, comparedTo, comparedToPeriod, groupBy]);

  return privateLeaguesBreakdown;
};

import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../../enums/enums";
import { inputNumber } from "../../../../helpers/helpers";
import { useCacheConfigEndpoint } from "../../../../hooks/client/useCacheConfigEndpoint";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import BackButton from "../../../Buttons/BackButton";
import UpdateButton from "../../../Buttons/UpdateButton";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import { ApiContext } from "../../../../providers/ApiProvider";

type CacheConfigurationEndpointProps = {
  apiId: string;
  endpoint: string;
  setEditCacheEndpoint: Dispatch<SetStateAction<boolean>>;
};

const CacheConfigurationEndpoint: React.FC<CacheConfigurationEndpointProps> = (
  props
) => {
  const [configuredCache, setConfiguredCache] = useState<number>(null);

  const cacheConfig = useCacheConfigEndpoint(props.apiId, props.endpoint);

  const { clientHttps } = useContext(ApiContext);
  const { selectedClient, isLoading, setIsLoading } =
    useContext(GlobalStatesContext);

  const updateCache = async () => {
    setIsLoading(true);

    if (configuredCache < cacheConfig.minTtl) {
      setIsLoading(false);
      toast.error(ERROR_MESSAGES.CACHE_CONFIG_VALIDATION);
      return;
    }

    const requestBody = {
      api_id: props.apiId,
      url_path: props.endpoint,
      configured_ttl: configuredCache,
    };

    try {
      await clientHttps.updateClientCacheTtlConfig(requestBody);
      toast.success(SUCCESS_MESSAGES.FEATURES_CACHE_TTL_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.CACHE_CONFIG_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <FeaturesLayout
      title={"Configuration for Cache TTL feature"}
      description={"This is Cache TTL configuration for client: "}
    >
      <div className="p-2 border border-blueGray-200 rounded mb-2 bg-blueGray-50">
        <div className="flex items-center mb-1">
          <BackButton onClick={() => props.setEditCacheEndpoint(false)} />
          <h2 className="font-semibold mb-1 mx-auto">{props.endpoint}</h2>
        </div>
        {cacheConfig ? (
          <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
            <div className="p-2 border border-blueGray-200 rounded bg-blueGray-100">
              <h3 className="font-semibold mb-1">Minimum Cache TTL</h3>
              <p className="mb-1">
                You can't configure cache ttl less than the minimum one.
              </p>
              <div className="flex items-center">
                <span className="text-xl font-bold mr-1">
                  {cacheConfig.minTtl}
                </span>
                <span className="text-xl">seconds</span>
              </div>
            </div>
            <div className="p-2 border border-blueGray-200 rounded bg-blueGray-100">
              <h3 className="font-semibold mb-1">Default Cache TTL</h3>
              <p className="mb-1">
                The cache that is set from Fans United by default.
              </p>
              <span className="text-xl font-bold mr-1">
                {cacheConfig.defaultTtl}
              </span>
              <span className="text-xl">seconds</span>
            </div>
            <div className="p-2 border border-blueGray-200 rounded bg-blueGray-100">
              <h3 className="font-semibold mb-1">Configure Cache TTL</h3>
              <p className="mb-1">
                You can change the cache for the endpoint. Keep in mind that you
                can't provide less than the minimum cache. When no value is
                provided here, the default cache will be used for this endpoint.
              </p>
              <input
                name="configured_cache_ttl"
                type="number"
                defaultValue={cacheConfig.configuredTtl}
                onChange={(event) =>
                  setConfiguredCache(Number(event.target.value))
                }
                onKeyDown={(event) => inputNumber(event, false)}
              />
              <p className="italic my-1 text-xs">
                The cache TTL value is in seconds
              </p>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <UpdateButton
        label={"Update"}
        isLoading={isLoading}
        onClick={updateCache}
      />
    </FeaturesLayout>
  );
};

export default CacheConfigurationEndpoint;

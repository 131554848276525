import dayjs from "dayjs";
import React from "react";
import { MauVsRegistrationBreakdown } from "../../../../hooks/useMauVsRegistrations";
import {
  MAUBreakdown,
  useMonthlyActiveUsers,
} from "../../../../hooks/useMonthlyActiveUsers";
import DatasetChart from "../../../../models/Reports/DatasetChart";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import LineChart from "../Common/LineChart";
import ReportInfo from "../Common/ReportInfo";

export const chartLabels = (
  monthlyActiveUsers: MAUBreakdown[] | MauVsRegistrationBreakdown[]
) => {
  const mauSortedDateLabels = monthlyActiveUsers
    .map((value: any) => value.date)
    .sort((a: string, b: string) => a.localeCompare(b));

  return mauSortedDateLabels.map((sortDate: string) =>
    dayjs(sortDate).format("MMM YYYY")
  );
};

const chartDataset = (monthlyActiveUsers: MAUBreakdown[]) => {
  const chartData = monthlyActiveUsers
    .map((value: { date: string; users: number }) => value.users)
    .reverse();

  return constructDatasetChart(chartData);
};

const constructDatasetChart = (chartData: number[]) => {
  const datasetChart = new DatasetChart();

  datasetChart.fill = false;
  datasetChart.data = chartData;
  datasetChart.label = "MAU";
  datasetChart.backgroundColor = "rgb(0, 102, 204)";
  datasetChart.borderColor = "rgb(0, 102, 204)";

  return [datasetChart];
};

const MonthlyActiveUsers: React.FC = () => {
  const monthlyActiveUsers = useMonthlyActiveUsers();

  return (
    <CardReport>
      <ReportInfo
        title={"Monthly Active Users (MAU)"}
        description={
          "This is a breakdown of your users who were active in particular month. An active user is anyone who has used their own personal authentication token in particular month. This, for instance, can be for placing a prediction, updating interests or even fetching their own profile."
        }
      />
      {monthlyActiveUsers ? (
        <LineChart
          labels={chartLabels(monthlyActiveUsers)}
          datasets={chartDataset(monthlyActiveUsers)}
          isMau={true}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default MonthlyActiveUsers;

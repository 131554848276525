import HighestSuccessRateModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateModel";
import React, { useState } from "react";
import { useSuccessRateUsers } from "../../../../../hooks/useSuccessRateUsers";
import Spinner from "../../../../Spinner/Spinner";
import SuccessRateMarketSelect from "./SuccessRateMarketSelect";
import SuccessRateNumberFilter from "./SuccessRateNumberFIlter";
import TableHeaderSuccessRateUsers from "./TableHeaderSuccessRateUsers";
import TableRowSuccessRateUsers from "./TableRowSuccessRateUsers";

const SuccessRateUsers: React.FC = () => {
  const [market, setMarket] = useState<string>("");
  const [usersCount, setUsersCount] = useState<number>(0);
  const [minimumPredictions, setMinimumPredictions] = useState<number>(0);

  const highestSuccessRates = useSuccessRateUsers(
    market,
    usersCount,
    minimumPredictions
  );

  return (
    <div className="block w-full overflow-x-auto">
      <SuccessRateMarketSelect setMarket={setMarket} />
      <SuccessRateNumberFilter
        placeholder={"Type how many users should be displayed..."}
        setNumberFilter={setUsersCount}
      />
      <SuccessRateNumberFilter
        placeholder={"Type how many predictions user needs to have..."}
        setNumberFilter={setMinimumPredictions}
      />
      <table className="items-center w-full bg-transparent border-collapse">
        <TableHeaderSuccessRateUsers />
        <tbody>
          {highestSuccessRates ? (
            highestSuccessRates?.map((value: HighestSuccessRateModel) => (
              <TableRowSuccessRateUsers
                key={`table-row-success-rate-users-${value.profileId}`}
                value={value}
              />
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <Spinner />
              </td>
            </tr>
          )}
          {highestSuccessRates && !highestSuccessRates.length ? (
            <tr>
              <td colSpan={5}>
                <span className="flex justify-center py-20 font-bold">
                  There are no success rates for any users.
                </span>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default SuccessRateUsers;

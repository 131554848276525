import React, { useState } from "react";
import { MINI_GAMES_REPORTS_TABS } from "../ClassicQuiz/ClassicQuizReport";
import ReportTwoTabs from "../../Common/ReportTwoTabs";
import EitherOrBreakdown from "./Breakdown/EitherOrBreakdown";
import EitherOrPopular from "./Popular/EitherOrPopular";

const EitherOrReport: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>(
    MINI_GAMES_REPORTS_TABS.BREAKDOWN
  );

  return (
    <div className="w-full px-4">
      <ReportTwoTabs
        tabValue={openTab}
        setTabValue={setOpenTab}
        reportType={"mini_games"}
      />
      {openTab === "breakdown" ? <EitherOrBreakdown /> : <EitherOrPopular />}
    </div>
  );
};

export default EitherOrReport;

import React from "react";
import { ISpecificGamesBadgeRequirement } from "../../../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";
import { useGames } from "../../../../../../../hooks/useGames";
import Spinner from "../../../../../../Spinner/Spinner";
import BadgeGame from "./BadgeGame";

type BadgeSpecificGamesProps = {
  specificGames: ISpecificGamesBadgeRequirement[];
  loadedSpecificGames: ISpecificGamesBadgeRequirement[];
  onChangeBadges: (value: ISpecificGamesBadgeRequirement[]) => void;
};

const BadgeSpecificGames: React.FC<BadgeSpecificGamesProps> = ({
  loadedSpecificGames,
  specificGames,
  onChangeBadges,
}) => {
  let games = useGames();

  if (!games) {
    return <Spinner />;
  }

  if (loadedSpecificGames && loadedSpecificGames.length > 0) {
    // Sort the games so that the specific games are at the top
    games = games.sort((a, b) => {
      const aInSpecificGames = loadedSpecificGames.some(
        (game) => game.gameId === a.id
      );
      const bInSpecificGames = loadedSpecificGames.some(
        (game) => game.gameId === b.id
      );

      if (aInSpecificGames && bInSpecificGames) {
        return b.updatedAt.localeCompare(a.updatedAt);
      } else if (aInSpecificGames) {
        return -1;
      } else if (bInSpecificGames) {
        return 1;
      } else {
        return b.updatedAt.localeCompare(a.updatedAt);
      }
    });
  } else {
    games = games.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
  }

  return (
    <div className="mb-2">
      <p className="font-bold mb-2">Specific games</p>
      <div className="flex flex-col h-56 overflow-hidden overflow-y-scroll p-2 bg-blueGray-100">
        {games.map((game) => (
          <BadgeGame
            key={game.id}
            game={game}
            specificGames={specificGames}
            onChangeBadges={onChangeBadges}
          />
        ))}
        {games.length === 0 ? (
          <p className="text-center my-auto font-bold">
            No games found. To create games, you can use our management widget
            (Top X Manager and Match Quiz Manager)
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BadgeSpecificGames;

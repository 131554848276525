import React, { Dispatch, SetStateAction } from "react";
import Select from "react-select";
import { marketMap } from "../../../../../constants/constants";
import { marketSelectStyles } from "../../../../../constants/styles";
import ReactSelectBaseOption from "../../../../../models/SelectOption/ReactSelectBaseOption";

const transformMarketsToSelectOptions = (): ReactSelectBaseOption[] => {
  const markets = Array.from(marketMap.keys());

  return markets.map((market: string) => {
    return {
      id: market,
      value: market,
      label: `${marketMap.get(market)} (${market})`,
    };
  });
};

type SuccessRateMarketSelectProps = {
  setMarket?: Dispatch<SetStateAction<string>>;
  setMarkets?: Dispatch<SetStateAction<string[]>>;
};

const SuccessRateMarketSelect: React.FC<SuccessRateMarketSelectProps> = ({
  setMarket,
  setMarkets,
}) => {
  const isMulti = setMarkets ? true : false;

  const marketsHandler = (
    selectOption: ReactSelectBaseOption | ReactSelectBaseOption[]
  ) => {
    if (!isMulti) {
      selectOption = selectOption as ReactSelectBaseOption;
      setMarket(selectOption ? selectOption.value : "");

      return;
    }

    selectOption = selectOption as ReactSelectBaseOption[];
    setMarkets(
      selectOption.map((option: ReactSelectBaseOption) => option.value)
    );
  };

  return (
    <div className={`${isMulti ? "" : "xl:w-6/12"} w-full mb-3`}>
      <Select
        getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
        styles={marketSelectStyles}
        isClearable
        onChange={marketsHandler}
        options={transformMarketsToSelectOptions()}
        placeholder={isMulti ? "Select markets..." : "Select one market..."}
        isMulti={isMulti}
      />
    </div>
  );
};

export default SuccessRateMarketSelect;

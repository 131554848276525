import React from "react";
import { urlPathsToPageTitle } from "../../../constants/constants";
import UserDropdown from "../../Dropdowns/UserDropdown/UserDropdown";
import StaffProjects from "./StaffProjects";
import ClientsBasicInfo from "../../../models/Clients/ClientsBasicInfo";

const getCurrentTabName = () => {
  const path = window.location.pathname;
  const title = path.split("/")[1];

  return urlPathsToPageTitle[title as keyof typeof urlPathsToPageTitle];
};

type NavbarHeaderProps = {
  onChange: (selectedClient: ClientsBasicInfo) => void;
};

const NavbarHeader: React.FC<NavbarHeaderProps> = ({ onChange }) => {
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <span className="text-white text-sm uppercase hidden lg:inline-block font-semibold">
            {getCurrentTabName()}
          </span>
          <StaffProjects onChange={onChange} />
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
};

export default NavbarHeader;

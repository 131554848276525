import React from "react";
import FeaturesLayout from "../FeaturesLayout";
import { useEngagementCoefficientsConfig } from "../../../../hooks/features/useEngagementCoefficientsConfig";
import Spinner from "../../../Spinner/Spinner";
import EngagementCoefficientsInfo from "./EngagementCoefficientsInfo";

const EngagementCoefficients: React.FC = () => {
  const { engagementCoefficientsConfig, whitelistEntities } =
    useEngagementCoefficientsConfig();

  return (
    <FeaturesLayout
      title={"Configuration for Engagement Coefficients"}
      description={"This is engagement coefficients configuration for client: "}
    >
      {engagementCoefficientsConfig ? (
        <EngagementCoefficientsInfo
          config={engagementCoefficientsConfig}
          whitelistEntities={whitelistEntities}
        ></EngagementCoefficientsInfo>
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default EngagementCoefficients;

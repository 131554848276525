import React from "react";
import { ISpecificGamesBadgeRequirement } from "../../../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";

const getStatusColor = (status: string) => {
  switch (status) {
    case "PENDING":
    case "SETTLED":
      return "text-blueGray-700 bg-blueGray-200";
    case "CLOSED":
    case "CANCELED":
      return "text-red-700 bg-red-100";
    default:
      return "text-emerald-700 bg-emerald-100";
  }
};
const typeToLabelMap = new Map()
  .set("TOP_X", "Top X")
  .set("MATCH_QUIZ", "Match Quiz");

type BadgeGameProps = {
  game: any;
  specificGames: ISpecificGamesBadgeRequirement[];
  onChangeBadges: (value: ISpecificGamesBadgeRequirement[]) => void;
};

const BadgeGame: React.FC<BadgeGameProps> = ({
  game,
  specificGames,
  onChangeBadges,
}) => {
  let checked = false;

  if (specificGames) {
    checked = specificGames.some((sg) => sg.gameId === game.id);
  }

  const onClick = (game) => {
    const newSpecificGames = [
      { gameId: game.id, gameType: game.type, gameModel: null },
    ];

    if (!specificGames) {
      onChangeBadges(newSpecificGames);

      return;
    }

    if (checked) {
      const filteredSpecificGames = specificGames.filter(
        (sg) => sg.gameId !== game.id
      );

      onChangeBadges(filteredSpecificGames);

      return;
    }

    onChangeBadges([...specificGames, ...newSpecificGames]);
  };

  return (
    <label className="flex items-center cursor-pointer w-full border-b border-blueGray-200 mb-2">
      {checked ? (
        <input
          id={game.id}
          type="checkbox"
          name={`checkbox-game-${game.id}`}
          className="h-5 w-5 text-blueGray-600 cursor-pointer mr-1 focus:ring-offset-0"
          value={game.id}
          onChange={() => onClick(game)}
          checked={checked}
        />
      ) : (
        <input
          id={game.id}
          type="checkbox"
          name={`checkbox-game-${game.id}`}
          className="h-5 w-5 text-blueGray-600 cursor-pointer mr-1 focus:ring-offset-0"
          value={game.id}
          onChange={() => onClick(game)}
        />
      )}
      <div className="flex items-center text-xs">
        <span className="mr-1">{game.title}</span>{" "}
        <span className="font-semibold mr-1">
          ({typeToLabelMap.get(game.type)})
        </span>
        <span
          className={`text-center p-1 font-semibold leading-tight ${getStatusColor(
            game.status
          )} rounded`}
        >
          {game.status}
        </span>
      </div>
    </label>
  );
};

export default BadgeGame;

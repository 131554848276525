import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { setDefaultCoparedToDateApi } from "../helpers/helpers";
import ReportPeriods from "../models/Reports/ReportPeriods";
import SuccessRateMarket from "../models/Reports/SucessRate/SuccessRateMarket/SuccessRateMarket";
import { ApiContext } from "../providers/ApiProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export const useSuccessRateMarkets = () => {
  const [successRateMarkets, setSuccessRateMarkets] = useState<
    ReportPeriods<SuccessRateMarket>
  >({ current: null, comparedTo: null });

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedTo, comparedToPeriod } =
    useContext(ReportParamsContext);

  const getSelectedSuccessRateMarkets = async () => {
    try {
      const selectedSuccessRateResponse =
        await api.reportingHttps.getSuccessRateMarkets(datePeriod);

      setSuccessRateMarkets((prevState) => {
        return { ...prevState, current: selectedSuccessRateResponse };
      });
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.SUCCESS_RATE_MARKETS_REPORT);
      setSuccessRateMarkets((prevState) => {
        return { ...prevState, current: null };
      });
    }
  };

  const getComparedToSuccessRateMarkets = async () => {
    try {
      if (comparedTo) {
        const comparedToSuccessRateReponse =
          await api.reportingHttps.getSuccessRateMarkets(
            comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi()
          );

        setSuccessRateMarkets((prevState) => {
          return { ...prevState, comparedTo: comparedToSuccessRateReponse };
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.SUCCESS_RATE_MARKETS_REPORT);
      setSuccessRateMarkets((prevState) => {
        return { ...prevState, comparedTo: null };
      });
    }
  };

  useEffect(() => {
    getSelectedSuccessRateMarkets();

    return () =>
      setSuccessRateMarkets((prevState) => {
        return { ...prevState, current: null };
      });
  }, [selectedClient, datePeriod]);

  useEffect(() => {
    getComparedToSuccessRateMarkets();

    return () =>
      setSuccessRateMarkets((prevState) => {
        return { ...prevState, comparedTo: null };
      });
  }, [selectedClient, comparedTo, comparedToPeriod]);

  return successRateMarkets;
};

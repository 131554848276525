import React from "react";
import CardReport from "../../../../Cards/CardReport";
import GroupByPeriod from "../../Common/GroupByPeriod";
import DatePeriod from "../../Common/DatePeriod";
import ReportInfo from "../../Common/ReportInfo";
import Spinner from "../../../../Spinner/Spinner";
import { useDiscussionsBreakdown } from "../../../../../hooks/reports/discussions/useDiscussionsBreakdown";
import { constructLabelsChart } from "../../../../../helpers/helpers";
import DiscussionsDataset from "../../../../../models/Reports/Discussions/DiscussionsDataset";
import LineChart from "../../Common/LineChart";
import "../../Reports.css";

const DiscussionsBreakdown: React.FC = () => {
  const discussionsBreakdown = useDiscussionsBreakdown();
  const datasets = new DiscussionsDataset(
    discussionsBreakdown?.current,
    discussionsBreakdown?.comparedTo,
    "Discussions"
  ).discussionsDatasets;

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Discussions breakdown"}
          description={
            "This is a breakdown of your discussions for a specified period of time."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod />
        </div>
      </div>
      <GroupByPeriod />
      {discussionsBreakdown && discussionsBreakdown.current ? (
        <LineChart
          labels={constructLabelsChart(
            discussionsBreakdown.current,
            discussionsBreakdown?.comparedTo
          )}
          datasets={datasets}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default DiscussionsBreakdown;

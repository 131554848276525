import React, { useMemo, useState } from "react";
import { usePredictionsPerEntity } from "../../../../hooks/usePredictionsPerEntity";
import PredictionsPerEntityBreakdown from "../../../../models/Reports/Predictions/PredictionsPerEntityBreakdown";
import PredictionsReport from "../../../../models/Reports/Predictions/PredictionsReport";
import SelectOption from "../../../../models/SelectOption/SelectOption";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import DatePeriod from "../Common/DatePeriod";
import EntitySelect from "../Common/EntityFollows/EntitySelect";
import { headerCellStyle } from "../Common/EntityFollows/TableHeaderTopFollows";
import FilterEntity from "../Common/FilterEntity";
import ReportInfo from "../Common/ReportInfo";
import "../Reports.css";
import EntityPredictionsBreakdown from "./EntityPredictionsBreakdown";

const filterPredictionsPerEntity = (
  predictionsPerEntity: PredictionsReport<PredictionsPerEntityBreakdown>,
  filter: string
) => {
  if (filter === "All") return predictionsPerEntity.breakdown;

  const filterMap = new Map<string, string>()
    .set("Competitions", "COMPETITION")
    .set("Teams", "TEAM")
    .set("Players", "PLAYER");

  return predictionsPerEntity.breakdown.filter(
    (breakdown: PredictionsPerEntityBreakdown) =>
      breakdown.type === filterMap.get(filter)
  );
};

const PredictionsPerEntity: React.FC = () => {
  const [competitionOptions, setCompetitionOptions] = useState<SelectOption[]>(
    []
  );
  const [teamOptions, setTeamOptions] = useState<SelectOption[]>([]);
  const [playerOptions, setPlayerOptions] = useState<SelectOption[]>([]);
  const [filterEntity, setFilterEntity] = useState<string>("All");

  const entityIds = useMemo(() => {
    return [
      ...competitionOptions.map((option: SelectOption) => option.id),
      ...teamOptions.map((option: SelectOption) => option.id),
      ...playerOptions.map((option: SelectOption) => option.id),
    ];
  }, [competitionOptions, teamOptions, playerOptions]);

  const predictionsPerEntity = usePredictionsPerEntity(entityIds);

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Single predictions per entity"}
          description={
            "This report shows by default the top 100 most placed single predictions per entity specified by period. Also you can search for specific competitions, teams or players and see how many single predictions have been placed. In this report you will also find all single predictions breakdown by market."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} />
        </div>
      </div>
      <div className="mb-3">
        <EntitySelect
          entity="competitions"
          entities={competitionOptions}
          setEntities={setCompetitionOptions}
          isMulti={true}
        />
      </div>
      <div className="mb-3">
        <EntitySelect
          entity="teams"
          entities={teamOptions}
          setEntities={setTeamOptions}
          isMulti={true}
        />
      </div>
      <div className="mb-3">
        <EntitySelect
          entity="players"
          entities={playerOptions}
          setEntities={setPlayerOptions}
          isMulti={true}
        />
      </div>
      {predictionsPerEntity ? (
        <div className="mb-6 border border-blueGray-200 rounded p-2">
          <h2 className="text-center mb-4 font-bold text-xl">
            Top 100 entities by placed predictions
          </h2>
          <FilterEntity
            filterEntity={filterEntity}
            setFilterEntity={setFilterEntity}
          />
          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className={`${headerCellStyle} lg:text-left text-center`}>
                    Name
                  </th>
                  <th
                    className={`${headerCellStyle} xl:table-cell hidden text-right`}
                  >
                    All predicitons
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterPredictionsPerEntity(
                  predictionsPerEntity,
                  filterEntity
                ).map((breakdown: PredictionsPerEntityBreakdown) => (
                  <EntityPredictionsBreakdown
                    key={breakdown.entity}
                    breakdown={breakdown}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PredictionsPerEntity;

import React from "react";
import QuantityInput from "../../Common/QuantityInput";

type QuantityFixturesProps = {
  title: string;
  description: string;
  value: number;
  incrementFixture: (title: string) => void;
  decrementFixture: (title: string) => void;
};

const QuantityFixtures: React.FC<QuantityFixturesProps> = ({
  title,
  description,
  value,
  incrementFixture,
  decrementFixture,
}) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 w-full bg-blueGray-50">
      <label className="font-bold mb-2">{title}</label>
      <p className="mb-2">{description}</p>
      <div className="flex justify-center">
        <QuantityInput
          title={title}
          value={value}
          incrementFixture={incrementFixture}
          decrementFixture={decrementFixture}
        />
      </div>
    </div>
  );
};

export default QuantityFixtures;

import React from "react";
import { IFootballFantasyPlayers } from "../../../../models/features/fantasy/FootballFantasyFeature";
import { inputNumber } from "../../../../helpers/helpers";

const keyToLabel = new Map<string, string>()
  .set("total", "Total players")
  .set("minGoalkeepers", "Minimum goalkeepers")
  .set("maxGoalkeepers", "Maximum goalkeepers")
  .set("minDefenders", "Minimum defenders")
  .set("maxDefenders", "Maximum defenders")
  .set("minMidfielders", "Minimum midfielders")
  .set("maxMidfielders", "Maximum midfielders")
  .set("minStrikers", "Minimum strikers")
  .set("maxStrikers", "Maximum strikers");

type FantasyPlayersProps = {
  players: IFootballFantasyPlayers;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FantasyPlayers: React.FC<FantasyPlayersProps> = (props) => {
  return (
    <div className="border border-blueGray-200 rounded-lg p-2 bg-blueGray-50">
      <h3 className="font-bold mb-2">Players</h3>
      <div className="flex flex-col mb-2">
        <label className="mb-2">Total players</label>
        <input
          name="total"
          className="p-3"
          defaultValue={props.players.total}
          onChange={props.onChange}
          type="text"
          onKeyDown={(event) => inputNumber(event, true)}
        />
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Goalkeepers</h4>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-2">
          {["minGoalkeepers", "maxGoalkeepers"].map((key) => (
            <div
              key={`football-fantasy-players-goalkeepers-${key}`}
              className="flex flex-col mb-2"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.players[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Defenders</h4>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-2">
          {["minDefenders", "maxDefenders"].map((key) => (
            <div
              key={`football-fantasy-players-defenders-${key}`}
              className="flex flex-col mb-2"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.players[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Midfielders</h4>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-2">
          {["minMidfielders", "maxMidfielders"].map((key) => (
            <div
              key={`football-fantasy-players-midfielders-${key}`}
              className="flex flex-col mb-2"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.players[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Strikers</h4>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-2">
          {["minStrikers", "maxStrikers"].map((key) => (
            <div
              key={`football-fantasy-players-strikers-${key}`}
              className="flex flex-col mb-2"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.players[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FantasyPlayers;

import React from "react";

type QuantityInputProps = {
  title: string;
  value: number;
  incrementFixture: (title: string) => void;
  decrementFixture: (title: string) => void;
};

const QuantityInput: React.FC<QuantityInputProps> = ({
  title,
  value,
  incrementFixture,
  decrementFixture,
}) => {
  return (
    <div className="w-14">
      <div className="flex flex-col">
        {/** Arrow up */}
        <div
          className="flex items-center justify-center"
          onClick={() => incrementFixture(title)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </div>
        {/** Input */}
        <input
          className="h-16 text-2xl text-brandColor bg-blueGray-100 border border-bluGray-200 rounded my-1 text-center font-bold"
          type="number"
          name="quantity"
          value={value}
          readOnly
        ></input>
        {/** Arrow down */}
        <div
          className="flex items-center justify-center"
          onClick={() => decrementFixture(title)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default QuantityInput;

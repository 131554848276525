import React from "react";
import { WebhookConfigType } from "../../../../../models/features/webhook/WebhookFeatureModel";
import WebhookBaseCredentials from "../../../../../models/features/webhook/credentials/WebhookBaseCredentials";
import KafkaWebHookCredentials from "../../../../../models/features/webhook/credentials/KafkaWebhookCredentials";
import PubSubWebhookCredentials from "../../../../../models/features/webhook/credentials/PubSubWebhookCredentials";
import KafkaWebhookCredentialsComponent from "./KafkaWebhookCredentials";
import PubSubWebhookCredentialsComponent from "./PubSubWebhookCredentialsComponent";
import WebhookTooltip from "../WebhookTooltip";
import WebhookValueSelect from "../WebhookValueSelect";

export type AllCredentialsFields =
  | "name"
  | "password"
  | "topic"
  | "securityProtocol"
  | "saslMechanism"
  | "loginModule"
  | "requestRequiredAcks"
  | "additionalProperties"
  | "projectId"
  | "topicName"
  | "json";

type WebhookCredentialsProps = {
  credentialsType: WebhookConfigType;
  value:
    | WebhookBaseCredentials
    | KafkaWebHookCredentials
    | PubSubWebhookCredentials;
  defaultUrl: string;
  defaultMethod: string;
  onChange: (value: string, field: AllCredentialsFields) => void;
  onChangeUrl: (value: string, field: string) => void;
  onChangeMethod: (value: string, field: string) => void;
};

const WebhookCredentials: React.FC<WebhookCredentialsProps> = ({
  credentialsType,
  value,
  defaultUrl,
  defaultMethod,
  onChange,
  onChangeMethod,
  onChangeUrl,
}) => {
  return (
    <>
      {credentialsType === "HTTP" || credentialsType === "KAFKA" ? (
        <>
          <div className="flex flex-col mb-2">
            <label className="font-bold mb-2">URL</label>
            <div className="flex items-center">
              <input
                className="p-3 w-full mr-2"
                defaultValue={defaultUrl}
                onChange={(event) => onChangeUrl(event.target.value, "url")}
                type="text"
              />
              <WebhookTooltip
                tooltipType={credentialsType === "KAFKA" ? "kafka" : "http"}
                property={"url"}
              />
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <label className="font-bold mb-2">Method</label>
            <div className="flex items-center">
              <WebhookValueSelect
                value={defaultMethod}
                field={"method"}
                typeWebhook={credentialsType}
                onChange={(value) => onChangeMethod(value, "method")}
              />
              <WebhookTooltip
                tooltipType={credentialsType === "KAFKA" ? "kafka" : "http"}
                property={"method"}
              />
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <label className="font-bold mb-2">
              {credentialsType === "HTTP" ? "Basic auth username" : "Username"}
            </label>
            <div className="flex items-center">
              <input
                className="p-3 w-full mr-2"
                //@ts-ignore
                defaultValue={value.name}
                onChange={(event) => onChange(event.target.value, "name")}
                type="text"
              />
              <WebhookTooltip
                tooltipType={credentialsType === "KAFKA" ? "kafka" : "http"}
                property={"name"}
              />
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <label className="font-bold mb-2">
              {credentialsType === "HTTP" ? "Basic auth password" : "Password"}
            </label>
            <div className="flex items-center">
              <input
                className="p-3 w-full mr-2"
                //@ts-ignore
                defaultValue={value.password}
                onChange={(event) => onChange(event.target.value, "password")}
                type="text"
              />
              <WebhookTooltip
                tooltipType={credentialsType === "KAFKA" ? "kafka" : "http"}
                property={"password"}
              />
            </div>
          </div>
        </>
      ) : null}
      {credentialsType === "KAFKA" ? (
        <KafkaWebhookCredentialsComponent
          value={value as KafkaWebHookCredentials}
          onChange={onChange}
        />
      ) : null}
      {credentialsType === "PUB_SUB" ? (
        <PubSubWebhookCredentialsComponent
          value={value as PubSubWebhookCredentials}
          onChange={onChange}
        />
      ) : null}
    </>
  );
};

export default WebhookCredentials;

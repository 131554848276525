import React, { Dispatch, SetStateAction } from "react";
import { upperCase } from "../../../../helpers/helpers";
import { FotballFollowsReports } from "./FootballFollows";

const footballFollowsReports = ["competitions", "teams", "players"];

type FootballFollowsTabsProps = {
  footballFollowsReport: FotballFollowsReports;
  setFootballFollowsReport: Dispatch<SetStateAction<FotballFollowsReports>>;
};

const FootballFollowsTabs: React.FC<FootballFollowsTabsProps> = ({
  footballFollowsReport,
  setFootballFollowsReport,
}) => {
  return (
    <div
      className="relative grid md:grid-cols-3 grid-cols-1 gap-1 w-full mb-6"
      role="tablist"
    >
      {footballFollowsReports.map((value: string, index: number) => (
        <div
          className="mb-2 md:mr-2 last:mr-0 flex-auto text-center"
          key={index}
        >
          <a
            className={
              "text-xs font-bold p-4 rounded block leading-normal cursor-pointer " +
              (footballFollowsReport === value
                ? "bg-white text-brandColor"
                : "bg-gray-400 text-white")
            }
            onClick={() =>
              setFootballFollowsReport(value as FotballFollowsReports)
            }
            data-toggle="tab"
            role="tablist"
          >
            {upperCase(value)}
          </a>
        </div>
      ))}
    </div>
  );
};

export default FootballFollowsTabs;

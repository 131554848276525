import React from "react";
import { boxStyle } from "../EditProfile/EditProfileRow";

type PasswordRowProps = {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordRow: React.FC<PasswordRowProps> = ({ label, onChange }) => {
  return (
    <div className="w-full lg:w-6/12">
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor={label}
        >
          {label}
        </label>
        <input
          type="password"
          className={boxStyle}
          onChange={onChange}
          autoComplete="new-password"
        />
      </div>
    </div>
  );
};

export default PasswordRow;

import React, { Dispatch, SetStateAction } from "react";
import { upperCase } from "../../../../helpers/helpers";
import { PredictionsReports } from "./Predictions";

const predictionsReports = ["breakdown", "entities", "matches", "engagement"];

type PredictionsTabsProps = {
  predictionsReport: PredictionsReports;
  setPredictionsReport: Dispatch<SetStateAction<PredictionsReports>>;
};

const PredictionsTabs: React.FC<PredictionsTabsProps> = ({
  predictionsReport,
  setPredictionsReport,
}) => {
  return (
    <div
      className="relative grid md:grid-cols-4 grid-cols-1 gap-1 w-full mb-6"
      role="tablist"
    >
      {predictionsReports.map((value: string, index: number) => (
        <div
          className="mb-2 md:mr-2 last:mr-0 flex-auto text-center"
          key={index}
        >
          <a
            className={
              "text-xs font-bold p-4 rounded block leading-normal cursor-pointer " +
              (predictionsReport === value
                ? "bg-white text-brandColor"
                : "bg-gray-400 text-white")
            }
            onClick={() => setPredictionsReport(value as PredictionsReports)}
            data-toggle="tab"
            role="tablist"
          >
            {upperCase(value)}
          </a>
        </div>
      ))}
    </div>
  );
};

export default PredictionsTabs;

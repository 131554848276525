import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import DiscussionsBreakdownModel from "../../../models/Reports/Discussions/DiscussionsBreakdownModel";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { setDefaultCoparedToDateApi } from "../../../helpers/helpers";
import { sortBreakdown } from "./useDiscussionsBreakdown";
import ReportPeriods from "../../../models/Reports/ReportPeriods";

export const usePostsBreakdown = () => {
  const [postsBreakdown, setPostsBreakdown] =
    useState<ReportPeriods<DiscussionsBreakdownModel[]>>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);

  const getPostsBreakdown = async () => {
    try {
      const postsBreakdown = await api.reportingHttps.getPostsBreakdown(
        datePeriod,
        groupBy
      );
      let comparedToBreakdown: any = null;

      if (comparedTo) {
        comparedToBreakdown = await api.reportingHttps.getDiscussionsBreakdown(
          comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
          groupBy
        );
      }

      setPostsBreakdown({
        current: sortBreakdown(postsBreakdown.breakdown),
        comparedTo: sortBreakdown(comparedToBreakdown?.breakdown),
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.DISCUSSIONS_POSTS_BREAKDOWN_REPORT);
      setPostsBreakdown({ current: [], comparedTo: [] });
    }
  };

  useEffect(() => {
    getPostsBreakdown();

    return () => setPostsBreakdown(null);
  }, [selectedClient, datePeriod, comparedToPeriod, groupBy]);

  return postsBreakdown;
};

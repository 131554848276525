import React, { useState } from "react";
import { WebhookEvent } from "../../../../../models/features/webhook/WebhookFeatureModel";
import WebhookTooltip from "../WebhookTooltip";

const allEvents = [
  "profile",
  "profile_activity",
  "football_matches",
  "predictions",
  "match_quiz",
  "top_x",
  "leaderboard_templates",
  "private_league",
  "one_vs_one",
  "discussion",
  "classic_quiz"
];

export const eventsMap = new Map<string, string>()
  .set("profile", "Profile Management")
  .set("profile_activity", "Profile Activity")
  .set("football_matches", "Football Match related")
  .set("predictions", "Predictions")
  .set("match_quiz", "Match Quiz Game")
  .set("top_x", "Top X Game")
  .set("leaderboard_templates", "Leaderboard template")
  .set("private_league", "Private league")
  .set("one_vs_one", "Challenge (1 vs 1)")
  .set("discussion", "Discussions")
  .set("classic_quiz", "Classic Quiz");

type WebhookEventsComponentProps = {
  events: WebhookEvent[];
  onChange: (event: string) => void;
};

const WebhookEventsComponent: React.FC<WebhookEventsComponentProps> = ({
  events,
  onChange,
}) => {
  const [loadedEvents, setLoadedEvents] = useState<WebhookEvent[]>(
    events ? events : []
  );

  const isChecked = (event: string) => {
    const transformEvents: string[] = loadedEvents.map(
      (event: WebhookEvent) => event.id
    );

    return transformEvents && transformEvents.includes(event);
  };

  const onClick = (value) => {
    onChange(value);
    let currentEvents = loadedEvents.map((loadedEvent) => loadedEvent.id);

    if (currentEvents.includes(value)) {
      currentEvents = currentEvents.filter((event: string) => event !== value);
    } else {
      currentEvents.push(value);
    }

    const newEvents = currentEvents.map((id: string) => {
      return { id };
    });
    setLoadedEvents(newEvents);
  };

  return (
    <div className="flex flex-col mb-2 mt-5">
      <label className="font-bold mb-2">Events</label>
      <ul>
        {allEvents.map((event: string, index: number) => (
          <div
            key={`event-index-${index}`}
            className="flex items-center p-4 bg-blueGray-100 cursor-pointer"
            onClick={() => onClick(event)}
          >
            <input
              type="checkbox"
              className="mr-2 focus:outline-none outline-none"
              value={event}
              checked={isChecked(event)}
              readOnly
            />
            <li className="text-blueGray-600">{eventsMap.get(event)}</li>
            <WebhookTooltip property={event} tooltipType={"events"} />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default WebhookEventsComponent;

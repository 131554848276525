import React, { PropsWithChildren } from "react";

const CardEditProfile: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="w-full lg:w-8/12 mx-auto">
      <div className="relative min-w-0 break-words w-full py-2 shadow-lg rounded-lg bg-white border-0">
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">{children}</div>
      </div>
    </div>
  );
};

export default CardEditProfile;

import ProfileCountryModel from "fansunited-sdk-esm/Core/Namespaces/Profile/Models/ProfileCountryModel";
import React from "react";
import { placeholders } from "../../../../assets/placeholders/placeholders";
import FormattedNumber from "../../../Common/FormattedNumber";
import FilledProgressBar from "./TotalUsers/FilledProgressBar";

type CountryFollowsBreakdownProps = {
  profileCountries: Map<string, ProfileCountryModel>;
  breakdown: { [key: string]: any }[];
  allFollows: number;
};

const CountryFollowsBreakdown: React.FC<CountryFollowsBreakdownProps> = ({
  profileCountries,
  breakdown,
  allFollows,
}) => {
  const countryFollowsPercantage = (countryFollows: number) => {
    let percentage = (countryFollows / allFollows) * 100;
    percentage = percentage || 0;

    return `${percentage.toFixed(2)}%`;
  };

  return (
    <>
      {breakdown.map((value: { [key: string]: any }) => (
        <div key={`profile-country-competition-${value.id}`}>
          <div className="xl:flex xl:items-center xl:justify-between mb-4">
            <div className="flex items-center p-2 lg:w-3/12">
              <img
                src={
                  profileCountries.get(value.id) &&
                  //@ts-ignore
                  profileCountries.get(value.id).assets.flag
                    ? //@ts-ignore
                      profileCountries.get(value.id).assets.flag
                    : placeholders.country
                }
                className="w-16 w-12 h-12 mr-4"
              ></img>
              <h3 className="font-bold">
                {profileCountries.get(value.id)
                  ? profileCountries.get(value.id).name
                  : "Unknown country"}
              </h3>
            </div>
            <div className="p-2 xl:w-6/12">
              <div className="flex flex-row items-center justify-between">
                <p className="font-bold">
                  {countryFollowsPercantage(value.users.all)}
                </p>
                <div className="bg-emerald-500 rounded p-1 mb-2 cursor-default">
                  <span className="text-sm text-white">
                    Total: <FormattedNumber numberValue={value.users.all} />
                  </span>
                </div>
              </div>
              <FilledProgressBar
                percentage={countryFollowsPercantage(value.users.all)}
                user={"percent"}
              />
            </div>
            <div className="xl:flex xl:flex-row p-2 xl:w-3/12 xl:justify-end">
              <div className="bg-lightBlue-500 md:mr-1 rounded p-1 md:mb-4 mb-2 cursor-default">
                <span className="text-sm text-white">
                  Male: <FormattedNumber numberValue={value.users.male} />
                </span>
              </div>
              <div className="bg-red-500 md:mr-1 rounded p-1 md:mb-4 mb-2 cursor-default">
                <span className="text-sm text-white">
                  Female: <FormattedNumber numberValue={value.users.female} />
                </span>
              </div>
              <div className="bg-blueGray-300 rounded p-1 md:mb-4 mb-2 cursor-default">
                <span className="text-sm">
                  Non-specified:{" "}
                  <FormattedNumber numberValue={value.users.unspecified} />
                </span>
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
      ))}
    </>
  );
};

export default CountryFollowsBreakdown;

import React, { useContext } from "react";
import Select, { SingleValue } from "react-select";
import { selectStyles } from "../../constants/styles";
import ClientsBasicInfo from "../../models/Clients/ClientsBasicInfo";
import { StaffAccessContext } from "../../providers/StaffAccessProvider";
import { ClientSelectVersion, SelectedClient } from "../../types/types";

type ClientSelectProps = {
  onChange: (selectedClient: ClientsBasicInfo) => void;
  version: ClientSelectVersion;
  onClickNavigationUrlHideMobileMenu: () => void;
};

const transformProfileClients = (profileClient: ClientsBasicInfo) => {
  return {
    value: profileClient.id,
    label: profileClient.name,
    infrastructure: profileClient.infrastructure,
  };
};

const transformOptionToClient = (
  selectedClient: SingleValue<SelectedClient>
) => {
  const model = new ClientsBasicInfo();

  if (selectedClient) {
    model.id = selectedClient.value;
    model.name = selectedClient.label;
    model.infrastructure = selectedClient.infrastructure;
  }

  return model;
};

const ClientSelect: React.FC<ClientSelectProps> = ({
  onChange,
  version,
  onClickNavigationUrlHideMobileMenu,
}) => {
  const staffClients = useContext(StaffAccessContext);
  const defaultValue = staffClients
    ? staffClients.map((value: ClientsBasicInfo) =>
        transformProfileClients(value)
      )[0]
    : { value: "", label: "" };
  const selectOptions = staffClients?.map((value: ClientsBasicInfo) =>
    transformProfileClients(value)
  );

  const onChangeSelect = (selectedClient) => {
    onChange(transformOptionToClient(selectedClient));
    if (version === "mobile") {
      onClickNavigationUrlHideMobileMenu();
    }
  };

  return (
    <>
      {staffClients && staffClients.length > 1 ? (
        <Select
          onChange={onChangeSelect}
          defaultValue={defaultValue}
          options={selectOptions}
          styles={selectStyles(version)}
        />
      ) : null}
    </>
  );
};

export default ClientSelect;

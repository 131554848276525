import React from "react";
import FormattedNumber from "../../../../Common/FormattedNumber";

type MetricFieldProps = {
  paragraph: string;
  value: number;
};

const MetricField: React.FC<MetricFieldProps> = ({ paragraph, value }) => {
  return (
    <div className="flex flex-row flex-wrap items-center mb-2">
      <p className="mr-1">{paragraph}:</p>
      <span className="font-bold cursor-default">
        <FormattedNumber numberValue={value} />
      </span>
    </div>
  );
};

export default MetricField;

import dayjs from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import { placeholders } from "../../../../assets/placeholders/placeholders";
import PredictionsPerMatchBreakdown from "../../../../models/Reports/Predictions/PredictionsPerMatchBreakdown";
import FormattedNumber from "../../../Common/FormattedNumber";

type MatchBreakdownProps = {
  breakdown: PredictionsPerMatchBreakdown;
  matchId: string;
  setMatchId: Dispatch<SetStateAction<string>>;
};

const MatchBreakdown: React.FC<MatchBreakdownProps> = ({
  breakdown,
  matchId,
  setMatchId,
}) => {
  return (
    <tr
      className="px-4 border-b border-blueGray-100 hover:bg-blueGray-100 cursor-pointer"
      onClick={() => setMatchId(breakdown.match)}
    >
      <th className="border-t-0 border-l-0 border-r-0 whitespace-nowrap p-3 lg:text-left text-center">
        <div className="flex items-center">
          <input
            id={breakdown.match}
            value={breakdown.match}
            checked={matchId === breakdown.match}
            type="radio"
            className="w-5 h-5 mr-1 cursor-pointer"
            readOnly
          ></input>
          <span className="mr-1 md:block hidden">
            [{dayjs(breakdown.matchModel.kickoffAt).format("DD-MM")}]
          </span>
          <span className="font-semibold mr-1 whitespace-nowrap md:text-base text-xxs">
            {breakdown.matchModel.homeTeam.name}
          </span>
          <img
            className="w-6 mr-1 md:block hidden"
            src={
              breakdown.matchModel.homeTeam.assets &&
              //@ts-ignore
              breakdown.matchModel.homeTeam.assets.logo
                ? //@ts-ignore
                  breakdown.matchModel.homeTeam.assets.logo
                : placeholders.team
            }
          />
          <span className="mr-1">-</span>
          <img
            className="w-6 mr-1 md:block hidden"
            src={
              breakdown.matchModel.awayTeam.assets &&
              //@ts-ignore
              breakdown.matchModel.awayTeam.assets.logo
                ? //@ts-ignore
                  breakdown.matchModel.awayTeam.assets.logo
                : placeholders.team
            }
          />
          <span className="font-semibold mr-1 whitespace-nowrap md:text-base text-xxs">
            {breakdown.matchModel.awayTeam.name}
          </span>
        </div>
      </th>
      <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-right text-blueGray-500">
        <FormattedNumber numberValue={breakdown.count} />
      </td>
    </tr>
  );
};

export default MatchBreakdown;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { useState } from "react";
import { placeholders } from "../../../../../../assets/placeholders/placeholders";
import ExpandButton from "../../../../../Buttons/ExpandButton";

type CompetitionsSuccessRateScopeProps = {
  competitions: CompetitionBasicModel[];
};

const CompetitionsSuccessRateScope: React.FC<
  CompetitionsSuccessRateScopeProps
> = ({ competitions }) => {
  const [competitionsSuccessRate, setCompetitionsSuccessRate] =
    useState<CompetitionBasicModel[]>(competitions);
  return (
    <div className="mb-2">
      <h2 className="font-semibold mb-2 text-center">Competitions</h2>
      <div className="lg:grid grid-cols-4 gap-2 justify-between">
        {competitionsSuccessRate.map((competition: CompetitionBasicModel) => (
          <div
            key={`${competition.id}-success-rate-competition`}
            className="bg-blueGray-200 mb-2 rounded-lg border-b border-blueGray-200 last:border-none h-24 flex flex-col justify-center px-2"
          >
            <div className="flex flex-row items-center p-1">
              <img
                src={
                  //@ts-ignore
                  competition.assets && competition.assets.logo
                    ? //@ts-ignore
                      competition.assets.logo
                    : placeholders.competition
                }
                className="w-5 h-5 mr-2"
              ></img>
              <span>{competition.name}</span>
            </div>
            <div className="flex flex-row items-center p-1">
              <img
                src={
                  competition.country.assets &&
                  //@ts-ignore
                  competition.country.assets.flag
                    ? //@ts-ignore
                      competition.country.assets.flag
                    : placeholders.country
                }
                className="w-4 h-3 rounded mr-2"
              ></img>
              <span className="text-xs">{competition.country.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompetitionsSuccessRateScope;

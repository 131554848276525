import { remapKeysToSpecificCase } from "../../helpers/helpers";
import { MauVsRegistrationBreakdown } from "../../hooks/useMauVsRegistrations";
import { InfrastructureType } from "../../models/Clients/ClientsBasicInfo";
import ConsumptionModel from "../../models/Reports/Consumption/ConsumptionModel";
import RFMGroupModel from "../../models/Reports/Engagement/RFMGroupModel";
import EngagementThroughPredictionsModel from "../../models/Reports/Predictions/Engagement/EngagementThroughPredictionsModel";
import { Entity } from "../../types/types";
import Https from "./Https";

const initBaseURL = (infrastructure: InfrastructureType) => {
  switch (infrastructure) {
    case "DEV":
      return "http://localhost:8020/v1";
    case "LOCAL":
      return "http://localhost:8020/v1";
    case "STAGE":
      return "https://reporting.fansunitedstagingapi.com/v1";
    case "PROD":
      return "https://reporting.fansunitedapi.com/v1";
    case "PROD_WATG":
      return "https://reporting.watg.fansunitedapi.com/v1";
  }
};

type ReportingTypeEndpoints =
  | "total_users"
  | "aquired_users"
  | "predictions_period"
  | "predictions_total"
  | "predictions_per_entity"
  | "predictions_per_match"
  | "predictions_engagement"
  | "average_follows"
  | "total_requests"
  | "top_followed_competitions"
  | "top_followed_teams"
  | "top_followed_players"
  | "top_followed_sports"
  | "monthly_active_users"
  | "mau_vs_registrations"
  | "success_rates_entity"
  | "success_rates_markets"
  | "rfm_groups"
  | "engagement_segments"
  | "average_number_posts"
  | "percentage_users_posts"
  | "top_100_contributors_discussions"
  | "top_100_discussions"
  | "discussions_breakdown"
  | "posts_breakdown"
  | "classic_quiz_breakdown"
  | "classic_quiz_popular"
  | "either_or_breakdown"
  | "either_or_popular"
  | "private_league_breakdown"
  | "private_league_top_templates"
  | "private_league_users";

const constructPathParams = (typeEndpoint: ReportingTypeEndpoints) => {
  switch (typeEndpoint) {
    case "total_users":
      return `/users/total`;
    case "aquired_users":
      return `/users`;
    case "average_follows":
      return `/follows/averages`;
    case "predictions_total":
      return `/predictions/football/total`;
    case "predictions_period":
      return `/predictions/football`;
    case "predictions_per_entity":
      return `/predictions/football/entities`;
    case "predictions_per_match":
      return `/predictions/football/matches`;
    case "predictions_engagement":
      return `/predictions/football/engagements`;
    case "total_requests":
      return `/metrics/cloud-cdn`;
    case "top_followed_competitions":
      return `/follows/football/competitions`;
    case "top_followed_teams":
      return `/follows/football/teams`;
    case "top_followed_players":
      return `/follows/football/players`;
    case "top_followed_sports":
      return `/follows/sports`;
    case "monthly_active_users":
      return `/users/mau`;
    case "mau_vs_registrations":
      return `/users/mauvsreg`;
    case "success_rates_entity":
      return `/success-rates/football/entities`;
    case "success_rates_markets":
      return `/success-rates/football/markets`;
    case "rfm_groups":
      return `/users/rfmgroups`;
    case "engagement_segments":
      return `/users/rfmsegments`;
    case "average_number_posts":
      return `/discussion/averages`;
    case "percentage_users_posts":
      return `/discussion/contributing-users`;
    case "top_100_contributors_discussions":
      return `/discussion/top-contributors`;
    case "top_100_discussions":
      return `/discussion/top-discussions`;
    case "discussions_breakdown":
      return `/discussion/total-discussions`;
    case "posts_breakdown":
      return `/discussion/total-posts`;
    case "classic_quiz_breakdown":
      return `/classic-quizzes/participations`;
    case "classic_quiz_popular":
      return `/classic-quizzes/popular`;
    case "either_or_breakdown":
      return `/either-or/participations`;
    case "either_or_popular":
      return `/either-or/popular`;
    case "private_league_breakdown":
      return `/private-league/football`;
    case "private_league_top_templates":
      return `/private-league/football/templates`;
    case "private_league_users":
      return `/private-league/football/users`;
  }
};

export default class ReportingHttps extends Https {
  readonly clientId: string = "";

  constructor(clientId: string, infrastructure: InfrastructureType) {
    super(infrastructure);
    this.clientId = clientId;
    this.axiosInstance.defaults.baseURL = initBaseURL(infrastructure);
    this.axiosInstance.defaults.params = {
      ...this.axiosInstance.defaults.params,
      client_id: clientId,
    };
  }

  public getTotalUsers = async () => {
    const pathParams = constructPathParams("total_users");

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching total users for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getAcquiredUsers = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("aquired_users");
    this.addNewQueryParamsToDefaultOnes(period, groupBy);

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching aquired users for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPredictionsForPeriod = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string,
    markets?: string[]
  ) => {
    const pathParams = constructPathParams("predictions_period");

    if (period) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        from_date: period.fromDate,
        to_date: period.toDate,
      };
    }

    if (groupBy) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        group_by: groupBy,
      };
    }

    if (markets && markets.length) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        markets: markets.join(","),
      };
    }

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching predictions for period for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getTotalPredictions = async () => {
    const pathParams = constructPathParams("predictions_total");

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching total predictions for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPredictionsPerEntity = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    entities?: string[]
  ) => {
    const pathParams = constructPathParams("predictions_per_entity");

    if (period) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        from_date: period.fromDate,
        to_date: period.toDate,
      };
    }

    if (entities && entities.length) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        entities: entities.join(","),
      };
    }

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching predictions per entity for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPredictionsPerMatch = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    competitions?: string[],
    teams?: string[],
    markets?: string[]
  ) => {
    const pathParams = constructPathParams("predictions_per_match");

    if (period) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        from_date: period.fromDate,
        to_date: period.toDate,
      };
    }

    if (competitions && competitions.length) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        competitions: competitions.join(","),
      };
    }

    if (teams && teams.length) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        teams: teams.join(","),
      };
    }

    if (markets && markets.length) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        markets: markets.join(","),
      };
    }

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching predictions per match for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPredictionsEngagement = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    type?: string
  ) => {
    const pathParams = constructPathParams("predictions_engagement");

    if (period) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        from_date: period.fromDate,
        to_date: period.toDate,
      };
    }

    if (type) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        type,
      };
    }

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();

      return remapKeysToSpecificCase(
        data.data,
        "camel"
      ) as EngagementThroughPredictionsModel;
    } catch (error) {
      console.warn(
        `There was a problem fetching engagement through predctions data for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getAverageFollows = async () => {
    const pathParams = constructPathParams("average_follows");

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching average follows for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getTotalRequests = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("total_requests");

    if (period) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        from_date: period.fromDate,
        to_date: period.toDate,
      };
    }

    if (groupBy) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        group_by: groupBy,
      };
    }

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return remapKeysToSpecificCase(data.data, "camel") as ConsumptionModel;
    } catch (error) {
      console.warn(
        `There was a problem fetching platform consumption for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getTopFollowsEntity = async (
    entity: Entity,
    period?: {
      fromDate: string;
      toDate: string;
    }
  ) => {
    const pathParams = this.setEntityPathParam(entity);
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching top follows for ${entity} for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getEntityFollowsInformation = async (
    entity: Entity,
    entityId: string,
    period?: {
      fromDate: string;
      toDate: string;
    }
  ) => {
    let pathParams = this.setEntityPathParam(entity);
    pathParams = `${pathParams}/${entityId}`;
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching follows information for ${entity} for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getMonthlyActiveUsers = async () => {
    const pathParams = constructPathParams("monthly_active_users");

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching monthly active users for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getMauVsRegistrations = async () => {
    const pathParams = constructPathParams("mau_vs_registrations");

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(
        data.data.breakdown,
        "camel"
      ) as MauVsRegistrationBreakdown[];
    } catch (error) {
      console.warn(
        `There was a problem fetching monthly active users vs registrations for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getSuccessRatesEntities = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("success_rates_entity");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching success rates entities for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getSuccessRateMarkets = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("success_rates_markets");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return data.data;
    } catch (error) {
      console.warn(
        `There was a problem fetching success rate markets for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getRFMGroups = async (entityId: string) => {
    const pathParams = constructPathParams("rfm_groups");

    if (entityId) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        entity_id: entityId,
      };
    }

    try {
      const response = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return response.data.data as RFMGroupModel;
    } catch (error) {
      console.warn(
        `There was a problem fetching engagement RFM groups for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getEngagementSegments = async (entityId: string) => {
    const pathParams = constructPathParams("engagement_segments");

    if (entityId) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        entity_id: entityId,
      };
    }

    try {
      const response = await this.axiosInstance.get(pathParams);

      this.deleteQueryParams();
      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching engagement RFM groups for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getDiscussionsPostsAverages = async () => {
    const pathParams = constructPathParams("average_number_posts");

    try {
      const response = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching discussions average number of posts for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPercentageOfUsersWithPosts = async () => {
    const pathParams = constructPathParams("percentage_users_posts");

    try {
      const response = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching percentage of users with posts for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getTop100DiscussionsContributors = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("top_100_contributors_discussions");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching top 100 discussions contributors for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getTop100Discussions = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("top_100_discussions");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching top 100 discussions for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getDiscussionsBreakdown = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("discussions_breakdown");
    this.addNewQueryParamsToDefaultOnes(period, groupBy);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching discussions breakdown for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPostsBreakdown = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("posts_breakdown");
    this.addNewQueryParamsToDefaultOnes(period, groupBy);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching posts breakdown for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getClassicQuizBreakdown = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("classic_quiz_breakdown");
    this.addNewQueryParamsToDefaultOnes(period, groupBy);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching Classic Quiz breakdown for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getClassicQuizPopular = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("classic_quiz_popular");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching popular Classic Quizzes for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getEitherOrBreakdown = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("either_or_breakdown");
    this.addNewQueryParamsToDefaultOnes(period, groupBy);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching Either/Or breakdown for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getEitherOrPopular = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("either_or_popular");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching popular Either/Or games for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPrivateLeagueBreakdown = async (
    period?: {
      fromDate: string;
      toDate: string;
    },
    groupBy?: string
  ) => {
    const pathParams = constructPathParams("private_league_breakdown");
    this.addNewQueryParamsToDefaultOnes(period, groupBy);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching private leagues breakdown for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPrivateLeagueTopTemplates = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("private_league_top_templates");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching private leagues top templates report for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getPrivateLeagueUserStats = async (period?: {
    fromDate: string;
    toDate: string;
  }) => {
    const pathParams = constructPathParams("private_league_users");
    this.addNewQueryParamsToDefaultOnes(period);

    try {
      const response = await this.axiosInstance.get(pathParams);
      this.deleteQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching private leagues user stats report for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  private setEntityPathParam = (entity: Entity) => {
    switch (entity) {
      case "competitions":
        return constructPathParams("top_followed_competitions");
      case "teams":
        return constructPathParams("top_followed_teams");
      case "players":
        return constructPathParams("top_followed_players");
      case "sports":
        return constructPathParams("top_followed_sports");
    }
  };

  private addNewQueryParamsToDefaultOnes = (
    period?: { fromDate: string; toDate: string },
    groupBy?: string
  ) => {
    if (period) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        from_date: period.fromDate,
        to_date: period.toDate,
      };
    }

    if (groupBy) {
      this.axiosInstance.defaults.params = {
        ...this.axiosInstance.defaults.params,
        group_by: groupBy,
      };
    }
  };

  /**
   * When we fetch reports we use additional query params like from_date, to_date, group_by and etc.
   * When that request is fullfilled we need to delete that query params.
   */
  private deleteQueryParams = () => {
    const axiosQueryParams = JSON.parse(
      JSON.stringify(this.axiosInstance.defaults.params)
    );
    if (axiosQueryParams.from_date) delete axiosQueryParams.from_date;

    if (axiosQueryParams.to_date) delete axiosQueryParams.to_date;

    if (axiosQueryParams.group_by) delete axiosQueryParams.group_by;

    if (axiosQueryParams.markets) delete axiosQueryParams.markets;

    if (axiosQueryParams.entities) delete axiosQueryParams.entities;

    if (axiosQueryParams.competitions) delete axiosQueryParams.competitions;

    if (axiosQueryParams.teams) delete axiosQueryParams.teams;

    if (axiosQueryParams.type) delete axiosQueryParams.type;

    if (axiosQueryParams.entity_id) delete axiosQueryParams.entity_id;

    this.axiosInstance.defaults.params = axiosQueryParams;
  };
}

import HighestSuccessRateModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateModel";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";

export const useSuccessRateUsers = (
  market: string,
  usersCount: number,
  minimumPredictions: number
) => {
  const [successRateUsers, setSuccessRateUsers] =
    useState<HighestSuccessRateModel[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod } = useContext(ReportParamsContext);

  const getSuccessRateUsers = async () => {
    const maxUsersCount = 1_000;
    const maxMinimumPredictions = 1_000_000;

    if (usersCount > maxUsersCount) {
      toast.error(ERROR_MESSAGES.SUCCESS_RATES_USERS_COUNT);
      setSuccessRateUsers([]);

      return;
    }

    if (minimumPredictions > maxMinimumPredictions) {
      toast.error(ERROR_MESSAGES.SUCCESS_RATES_MINIMUM_PREDICTIONS);
      setSuccessRateUsers([]);

      return;
    }

    const filters = {
      market,
      fromDate: datePeriod ? datePeriod.fromDate : "",
      toDate: datePeriod ? datePeriod.toDate : "",
      usersCount,
      minimumPredictions,
    };

    try {
      const successRateResponse = await api.sdk.loyalty.getHighestSuccessRate(
        filters
      );
      setSuccessRateUsers(successRateResponse);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.SUCCESS_RATE_MARKETS_REPORT);
      setSuccessRateUsers([]);
    }
  };

  useEffect(() => {
    getSuccessRateUsers();

    return () => setSuccessRateUsers(null);
  }, [selectedClient, market, usersCount, minimumPredictions, datePeriod]);

  return successRateUsers;
};

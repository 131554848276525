import React from "react";
import ReportInfo from "../../Common/ReportInfo";
import RequestsInfo from "./RequestsInfo";
import { formatProvidedPeriodReport } from "../../../../../helpers/helpers";

type SummaryProps = {
  fromDate: string | null;
  toDate: string | null;
  totalRequests: { count: number };
  period: "selected" | "compared_to";
};

const Summary: React.FC<SummaryProps> = ({
  fromDate,
  toDate,
  totalRequests,
  period,
}) => {
  if (!totalRequests) return null;

  const [summaryFromDate, summaryToDate] = formatProvidedPeriodReport(
    fromDate,
    toDate,
    period
  );
  return (
    <div>
      <ReportInfo
        title={`Summary from ${summaryFromDate} to ${summaryToDate}`}
        description={"An overview of your usage."}
      />
      <hr className="mb-4"></hr>
      <RequestsInfo
        title={"Requests"}
        description={"Requests served from Fans United Platform."}
        totalRequests={totalRequests.count}
        period={period}
      />
    </div>
  );
};

export default Summary;

import WebhookFeatureModel, { WebhookConfigType } from "./WebhookFeatureModel";
import KafkaWebHookCredentials from "./credentials/KafkaWebhookCredentials";
import PubSubWebhookCredentials from "./credentials/PubSubWebhookCredentials";
import WebhookBaseCredentials from "./credentials/WebhookBaseCredentials";
import { WebhookConfig } from "./WebhookFeatureModel";

export default class WebhookFeatureFactory {
  public createNewWebhookFeatures = (
    webhook: WebhookFeatureModel,
    configType: WebhookConfigType
  ) => {
    switch (configType) {
      case "HTTP":
        return this.initHttpWebhookFeature(webhook, configType);
      case "KAFKA":
        return this.initKafkaWebhookFeature(webhook, configType);
      case "PUB_SUB":
        return this.initPubsubWebhookFeature(webhook, configType);
    }
  };

  private initHttpWebhookFeature = (
    webhook: WebhookFeatureModel,
    configType: WebhookConfigType
  ) => {
    const newWebhookFeature = this.initBasePropertiesInWebhook(
      webhook,
      configType
    );
    const newWebhookCredentials = new WebhookBaseCredentials();
    const copyCurrentCredentials = {
      ...webhook.config.credentials,
    } as WebhookBaseCredentials;

    newWebhookFeature.config.type = webhook.config.type;
    newWebhookFeature.config.method = webhook.config.method;
    newWebhookFeature.config.url = webhook.config.url;

    newWebhookCredentials.name = copyCurrentCredentials.name;
    newWebhookCredentials.password = copyCurrentCredentials.password;

    newWebhookFeature.config.credentials = newWebhookCredentials;

    return newWebhookFeature;
  };

  private initKafkaWebhookFeature = (
    webhook: WebhookFeatureModel,
    configType: WebhookConfigType
  ) => {
    const newWebhookFeature = this.initBasePropertiesInWebhook(
      webhook,
      configType
    );
    const newWebhookCredentials = new KafkaWebHookCredentials();
    const copyCurrentCredentials = {
      ...webhook.config.credentials,
    } as KafkaWebHookCredentials;

    newWebhookFeature.config.type = webhook.config.type;
    newWebhookFeature.config.method = webhook.config.method;
    newWebhookFeature.config.url = webhook.config.url;

    newWebhookCredentials.name = copyCurrentCredentials.name;
    newWebhookCredentials.password = copyCurrentCredentials.password;
    newWebhookCredentials.additionalProperties =
      copyCurrentCredentials.additionalProperties
        ? copyCurrentCredentials.additionalProperties
        : "";
    newWebhookCredentials.loginModule = copyCurrentCredentials.loginModule
      ? copyCurrentCredentials.loginModule
      : "";
    newWebhookCredentials.requestRequiredAcks =
      copyCurrentCredentials.requestRequiredAcks
        ? copyCurrentCredentials.requestRequiredAcks
        : "";
    newWebhookCredentials.saslMechanism = copyCurrentCredentials.saslMechanism
      ? copyCurrentCredentials.saslMechanism
      : "";
    newWebhookCredentials.securityProtocol =
      copyCurrentCredentials.securityProtocol
        ? copyCurrentCredentials.securityProtocol
        : "";
    newWebhookCredentials.topic = copyCurrentCredentials.topic
      ? copyCurrentCredentials.topic
      : "";

    newWebhookFeature.config.credentials = newWebhookCredentials;

    return newWebhookFeature;
  };

  private initPubsubWebhookFeature = (
    webhook: WebhookFeatureModel,
    configType: WebhookConfigType
  ) => {
    const newWebhookFeature = this.initBasePropertiesInWebhook(
      webhook,
      configType
    );
    const newWebhookCredentials = new PubSubWebhookCredentials();
    const copyCurrentCredentials = {
      ...webhook.config.credentials,
    } as PubSubWebhookCredentials;

    newWebhookFeature.config.type = webhook.config.type;
    newWebhookFeature.config.method = webhook.config.method;
    newWebhookFeature.config.url = webhook.config.url;

    newWebhookCredentials.json = copyCurrentCredentials.json
      ? copyCurrentCredentials.json
      : "";
    newWebhookCredentials.projectId = copyCurrentCredentials.projectId
      ? copyCurrentCredentials.projectId
      : "";
    newWebhookCredentials.topicName = copyCurrentCredentials.topicName
      ? copyCurrentCredentials.topicName
      : "";

    newWebhookFeature.config.credentials = newWebhookCredentials;

    return newWebhookFeature;
  };

  private initBasePropertiesInWebhook = (
    webhook: WebhookFeatureModel,
    configType: WebhookConfigType
  ) => {
    const newWebhookFeature = new WebhookFeatureModel();
    let credentials = null;

    switch (configType) {
      case "HTTP":
        credentials = new WebhookBaseCredentials();
        break;
      case "KAFKA":
        credentials = new KafkaWebHookCredentials();
        break;
      case "PUB_SUB":
        credentials = new PubSubWebhookCredentials();
    }

    const newConfig: WebhookConfig = {
      type: "HTTP",
      url: "",
      method: "GET",
      credentials,
    };

    newWebhookFeature.id = webhook.id;
    newWebhookFeature.name = webhook.name;
    newWebhookFeature.events = webhook.events;
    newWebhookFeature.retryLimit = webhook.retryLimit;
    newWebhookFeature.config = newConfig;

    return newWebhookFeature;
  };
}

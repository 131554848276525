import Chart, { ChartDataSets } from "chart.js";
import DatasetChart from "../../models/Reports/DatasetChart";
import dayjs from "dayjs";

export default class LineChartConfigBuilder {
  private lineChartConfiguration: Chart.ChartConfiguration = null;

  public build = (
    lineChartContext: any,
    labels: string[],
    datasets: DatasetChart[]
  ) => {
    this.buildConfig(lineChartContext, labels, datasets);

    return this.lineChartConfiguration;
  };

  /**
   * Set the type, labels, datasets and most of the options for the chart.
   * @param lineChartContext The context we get from the chartjs.
   * @param labels Dates of the period.
   * @param datasets All dates and data with configured colors and other dataset options.
   */
  private buildConfig = (
    lineChartContext: any,
    labels: string[],
    datasets: any[]
  ) => {
    this.lineChartConfiguration = {
      type: "line",
      data: {
        labels,
        datasets,
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                callback: (label: string) => {
                  if (label.includes("#")) {
                    label = label.split("#")[0];
                  }

                  return label;
                },
              },
            },
          ],
        },
        responsive: true, // Instruct chart js to respond nicely.
        maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
        legend: {
          display: false,
        },
        legendCallback: (chart: Chart) => this.createCustomLegend(chart),
        hover: {
          mode: "nearest",
        },
        //@ts-ignore
        tooltips: this.buildTooltipChart(lineChartContext),
      },
    };
  };

  /**
   * Disables the default chart tooltip behaviour. Provides custom callback for initializing the external HTML.
   * @param lineChartContext The context we get from the chartjs.
   * @returns Our custom tooltip. It will be an external HTML.
   */
  private buildTooltipChart = (lineChartContext: any) => {
    return {
      // Disable the on-canvas tooltip
      enabled: false,
      mode: "index",
      intersect: false,
      custom: (tooltipModel: any) => {
        const tooltipEl = this.createTooltipOnFirstRender();
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = "0";

          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        // Set Text
        this.setTooltipText(tooltipModel, tooltipEl);

        const position = lineChartContext.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = "1";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + "px";
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = "11px";
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding =
          tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
        tooltipEl.style.pointerEvents = "none";
      },
    };
  };

  /**
   * Creates new html div element which will be used as custom tooltip.
   * @returns Basic tooltip html div element with custom style.
   */
  private createTooltipOnFirstRender = () => {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(0, 0, 0, 0.8)";
      tooltipEl.style.borderRadius = "6px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = "1";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.padding = "6px";

      tooltipEl.id = "chartjs-tooltip";
      document.body.appendChild(tooltipEl);
    } else {
      // Remove all existing child elements
      while (tooltipEl.firstChild) {
        tooltipEl.removeChild(tooltipEl.lastChild);
      }
    }

    return tooltipEl;
  };

  /**
   * Sets the content for the custom tooltip.
   * @param tooltipModel The default tooltip model we receive as a argument from the callback option.
   * @param tooltipEl Basic tooltip html div element that we created
   */
  private setTooltipText = (tooltipModel: any, tooltipEl: HTMLElement) => {
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(
        (bodyItem: any) => bodyItem.lines
      );

      let contentContainer = document.createElement("div");
      contentContainer.style.padding = "2px";

      let titleSelectedPeriodElement = this.createHeading();
      let titlePreviousPeriodElement = this.createHeading();

      titleLines.forEach((title: string) => {
        if (title.includes("#")) {
          const selectedAndPreviousTitles = title.split("#");

          titleSelectedPeriodElement.innerText = selectedAndPreviousTitles[0];
          titlePreviousPeriodElement.innerText = selectedAndPreviousTitles[1];
        } else {
          titleSelectedPeriodElement.innerText = title;
        }
      });

      contentContainer.appendChild(titleSelectedPeriodElement);
      const selectedAllValue = this.createBody(
        bodyLines,
        tooltipModel,
        contentContainer,
        "Selected"
      );
      contentContainer.appendChild(titlePreviousPeriodElement);
      const previousAllValue = this.createBody(
        bodyLines,
        tooltipModel,
        contentContainer,
        "Previous"
      );

      if (previousAllValue) {
        const footer = this.createFooter(selectedAllValue, previousAllValue);
        contentContainer.appendChild(footer);
      }

      tooltipEl.appendChild(contentContainer);
    }
  };

  /**
   * Creates heading for our custom tooltip. It can be one or two headings depending on the 'compared to' checkbox.
   * @returns h3 heading for our tooltip.
   */
  private createHeading = () => {
    const heading = document.createElement("h3");

    heading.style.fontWeight = "bold";
    heading.style.marginTop = "6px";
    heading.style.marginBottom = "6px";

    return heading;
  };

  /**
   *
   * @param bodyLines The content we have from chart js dataset configuration
   * @param tooltipModel The default tooltip model we receive as a argument from the callback option.
   * @param contentContainer Main container for the whole content.
   * @param period The tooltip will have two bodies if 'compared to' checkbox is checked.
   * @returns String value that we extract from the bodyLines nested array. Will be used to create the footer of the tooltip.
   */
  private createBody = (
    bodyLines: any[],
    tooltipModel: any,
    contentContainer: HTMLElement,
    period: "Selected" | "Previous"
  ) => {
    let allValue: string = "";

    bodyLines.forEach((body: string[], index: number) => {
      if (body[0].includes(period)) {
        const colors = tooltipModel.labelColors[index];
        let bodyContainer = document.createElement("div");
        bodyContainer.style.display = "flex";
        bodyContainer.style.alignItems = "center";
        bodyContainer.style.marginBottom = "2px";
        const point = document.createElement("div");
        point.style.background = colors.backgroundColor;
        point.style.borderColor = colors.borderColor;
        point.style.borderWidth = "2px";
        point.style.borderRadius = "100%";
        point.style.width = "10px";
        point.style.height = "10px";
        point.style.marginRight = "5px";
        bodyContainer.appendChild(point);
        const spanTitleLabel = document.createElement("span");
        spanTitleLabel.style.marginRight = "5px";
        const labelAndValue = body[0].split(":");

        if (labelAndValue[0] === "CDN" || labelAndValue[0] === "Origin") {
          spanTitleLabel.innerText = `${labelAndValue[0]}: ${labelAndValue[1]}`;
        } else {
          spanTitleLabel.innerText = `${labelAndValue[0]}: `;
        }

        bodyContainer.appendChild(spanTitleLabel);

        const spanValue = document.createElement("span");
        spanValue.style.fontWeight = "bold";

        if (labelAndValue[0] === "All") {
          allValue = labelAndValue[2];
        }

        spanValue.innerText = labelAndValue[labelAndValue.length - 1];
        bodyContainer.appendChild(spanValue);

        contentContainer.appendChild(bodyContainer);
      }
    });

    return allValue;
  };

  /**
   * Creates HTML element containg information about the change between the datasets in percantage.
   * @param selectedAllValue Selected all value from the data for the selected dataset.
   * @param previousAllValue Previous all value from the data for the previous dataset.
   * @returns html div element which will be the footer of the custom tooltip.
   */
  private createFooter = (
    selectedAllValue: string,
    previousAllValue: string
  ) => {
    const footer = document.createElement("div");
    footer.style.display = "flex";
    footer.style.alignItems = "center";
    footer.style.marginTop = "6px";
    const point = document.createElement("div");
    point.style.width = "10px";
    point.style.height = "10px";
    point.style.marginRight = "5px";
    footer.appendChild(point);
    const footerLabel = document.createElement("span");
    footerLabel.style.marginRight = "5px";
    footerLabel.innerHTML = "Change: ";
    const footerPercent = document.createElement("span");
    footerPercent.style.fontWeight = "bold";
    const difference = Number(selectedAllValue) - Number(previousAllValue);

    if (difference) {
      // Difference is not 0. Prevent displaying NaN value.
      const percentage = (
        (difference / Number(previousAllValue)) *
        100
      ).toFixed(2);

      footerPercent.innerHTML = `${percentage}%`;
    } else {
      footerPercent.innerHTML = `0.00%`;
    }

    footer.appendChild(footerLabel);
    footer.appendChild(footerPercent);

    return footer;
  };

  private createCustomLegend = (chart: Chart) => {
    let text = [];
    chart.data.datasets?.forEach((dataset: ChartDataSets) => {
      text.push('<div class="flex items-center">');
      text.push(
        '<span style="margin-right: 5px; font-weight: bold;">' +
          this.extractLabelFromDatasets(chart.data.labels, dataset.label) +
          "</span>"
      );
      text.push(
        `<div style="border-width: 2px; border-radius: 100%; width: 10px; height: 10px; margin-right: 5px; background-color: ${dataset.backgroundColor}; border-color: ${dataset.borderColor};"></div>`
      );
      text.push("<span>" + this.setLegendLabel(dataset.label) + "</span>");
      text.push("</div>");
    });

    return text.join("");
  };

  private extractLabelFromDatasets = (labels: any[], dataLabel: string) => {
    const dateLabels = [...labels];

    if (!dateLabels.length) {
      return "";
    }

    // When the report is for a year, we have only one date label
    if (dateLabels.length === 1) {
      const startDate = dateLabels[0];
      const splittedStartDate = startDate.split(",");
      const endDate = dayjs(splittedStartDate[0]).add(1, "year");
      const finalEndDate = endDate.format("DD MMM YYYY, dddd");

      return startDate + " - " + finalEndDate + ":";
    }

    if (dataLabel.includes("Previous")) {
      const startDate = dateLabels.shift().split("#")[1];
      const endDate = dateLabels.pop().split("#")[1];

      return startDate + " - " + endDate + ":";
    }

    const startDate = dateLabels.shift().split("#")[0];
    const endDate = dateLabels.pop().split("#")[0];

    return startDate + " - " + endDate + ":";
  };

  private setLegendLabel = (datasetLabel: string) => {
    const splitedLabel = datasetLabel.split(":");

    if (splitedLabel.length > 2) {
      return splitedLabel[0] + ": " + splitedLabel[1];
    }

    return splitedLabel[0];
  };
}

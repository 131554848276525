import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import EngagementThroughPredictionsModel from "../models/Reports/Predictions/Engagement/EngagementThroughPredictionsModel";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";

export const useEngagementPredictions = (type: string) => {
  const [predictionsEngagement, setPredictionsEngagement] =
    useState<EngagementThroughPredictionsModel>(null);

  const { datePeriod } = useContext(ReportParamsContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const api = useContext(ApiContext);

  const getPredictionsEngagement = async () => {
    try {
      const predictionsEngagement =
        await api.reportingHttps.getPredictionsEngagement(datePeriod, type);

      if (
        Object.keys(predictionsEngagement.breakdown.predictions).includes(
          "atLeastThree"
        )
      ) {
        // Only for games we have a key 'atLeastThree'
        predictionsEngagement.typePredictionsEngagement = "GAMES";
      } else {
        predictionsEngagement.typePredictionsEngagement = "SINGLES";
      }

      setPredictionsEngagement(predictionsEngagement);
    } catch (error) {
      toast.error(ERROR_MESSAGES.ENGAGEMENT_THROUGH_PREDICTIONS);
      setPredictionsEngagement(new EngagementThroughPredictionsModel());
    }
  };

  useEffect(() => {
    getPredictionsEngagement();

    return () => setPredictionsEngagement(null);
  }, [selectedClient, datePeriod, type]);

  return predictionsEngagement;
};

import React from "react";
import Badge from "../Badge";
import DiscussionsBadgesExtended from "../../../../../../../models/features/loyalty/Badges/Discussions/DiscussionsBadgesExtended";
import DiscussionsBadgeRequirements from "./DiscussionsBadgeRequirements";

type DiscussionsBadgeProps = {
  badge: DiscussionsBadgesExtended;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
  removeExistingBadge: (uid: string) => void;
};

const DiscussionsBadge: React.FC<DiscussionsBadgeProps> = ({
  badge,
  onChangeBadges,
  removeExistingBadge,
}) => {
  return (
    <div className="flex flex-col p-2">
      <Badge
        badge={badge}
        onChangeBadges={onChangeBadges}
        removeExistingBadge={removeExistingBadge}
      />
      <DiscussionsBadgeRequirements
        uid={badge.uid}
        requirements={badge.requirements}
        onChangeBadges={onChangeBadges}
      />
    </div>
  );
};

export default DiscussionsBadge;

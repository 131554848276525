import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import {
  extractEntityIds,
  extractUnselectedCompetitions,
  remapKeysToSpecificCase,
} from "../../../../helpers/helpers";
import MatchQuizFeaturesModel from "../../../../models/features/matchQuiz/MatchQuizFeaturesModel";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import UpdateButton from "../../../Buttons/UpdateButton";
import Enabled from "../Common/Enabled";
import SuccessRateAllMarketsList from "../FeaturesPredictor/SuccessRateScopes/EditSuccessRateScopes/Markets/SuccessRateAllMarketsList";
import { ApiContext } from "../../../../providers/ApiProvider";
import MatchQuizCompetitionsWhitelist from "./MatchQuizCompetitionsWhitelist/MatchQuizCompetitionsWhitelist";

type FeaturesMatchQuizProps = {
  matchQuizFeatures: MatchQuizFeaturesModel;
  allMarkets: string[];
  fullCoverageCompetitions: CompetitionBasicModel[];
};

const FeaturesMatchQuiz: React.FC<FeaturesMatchQuizProps> = ({
  matchQuizFeatures,
  allMarkets,
  fullCoverageCompetitions,
}) => {
  const extractCompetitionIds = extractEntityIds(
    matchQuizFeatures?.competitionsWhitelist
  );

  const [defaultMarkets, setDefaultMarkets] = useState<string[]>(
    matchQuizFeatures?.defaultMarkets
  );
  const [competitionIds, setCompetitionIds] = useState<string[]>(
    extractCompetitionIds
  );
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const unselectedCompetitions = useMemo(() => {
    return extractUnselectedCompetitions(
      fullCoverageCompetitions,
      competitionIds
    );
  }, [competitionIds]);

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const updateMatchQuizFeatures = async () => {
    setIsLoading(true);
    const requestBody = {
      defaultMarkets,
      competitionsWhitelist: competitionIds,
    };
    const requestBodyInSnakeCase = remapKeysToSpecificCase(
      requestBody,
      "snake"
    );

    try {
      await clientHttps.updateFeaturesForClient(
        requestBodyInSnakeCase,
        FansUnitedFeatures.MATCH_QUIZ
      );
      toast.success(SUCCESS_MESSAGES.FEATURES_MATCH_QUIZ_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FEATURES_MATCH_QUIZ_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Enabled value={matchQuizFeatures.enabled} />
      <div className="w-full mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
        <label className="font-bold mb-2" htmlFor={"Default Markets"}>
          Default Markets
        </label>
        <p className="mb-2">
          The following markets will be selected if no markets are choosen when
          creating the game.
        </p>
        <SuccessRateAllMarketsList
          allMarkets={allMarkets}
          loadedMarkets={defaultMarkets}
          setMarkets={setDefaultMarkets}
        />
      </div>
      <MatchQuizCompetitionsWhitelist
        title={"Competitions whitelist"}
        description={
          "Only matches in the following competitions are allowed for the creation of Match Quiz games. Keep in mind all competitions in this list need to be full-coverage ones. The maximum number of competitions are 15."
        }
        loadedCompetitions={matchQuizFeatures.competitionsWhitelist}
        setCompetitionIds={setCompetitionIds}
        unselectedCompetitions={unselectedCompetitions}
        setDisabledButton={setDisabledButton}
        maximumCompetitions={15}
      />
      <UpdateButton
        label={"Update match quiz features"}
        onClick={updateMatchQuizFeatures}
        isLoading={isLoading}
        disableButton={disabledButton}
      />
    </>
  );
};

export default FeaturesMatchQuiz;

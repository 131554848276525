import React, { useMemo, useState } from "react";
import { usePredictionsPerMatch } from "../../../../hooks/usePredictionsPerMatch";
import PredictionsPerMatchBreakdown from "../../../../models/Reports/Predictions/PredictionsPerMatchBreakdown";
import SelectOption from "../../../../models/SelectOption/SelectOption";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import DatePeriod from "../Common/DatePeriod";
import EntitySelect from "../Common/EntityFollows/EntitySelect";
import { headerCellStyle } from "../Common/EntityFollows/TableHeaderTopFollows";
import ReportInfo from "../Common/ReportInfo";
import SuccessRateMarketSelect from "../SuccessRate/Users/SuccessRateMarketSelect";
import MatchBreakdown from "./MatchBreakdown";
import { useMatchSummary } from "../../../../hooks/useMatchSummary";
import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import "../Reports.css";
import MatchSummary from "../Football/FootballTeams/MatchSummary";

const matchModelForMatchSummary = (
  matchId: string,
  predictionsPerMatchBreakdown: PredictionsPerMatchBreakdown[]
) => {
  let model: MatchBasicModel = null;

  predictionsPerMatchBreakdown.forEach(
    (breakdown: PredictionsPerMatchBreakdown) => {
      if (breakdown.match === matchId) {
        model = breakdown.matchModel;
      }
    }
  );

  return model;
};

const PredictionsPerMatch: React.FC = () => {
  const [competitionOptions, setCompetitionOptions] = useState<SelectOption[]>(
    []
  );
  const [teamOptions, setTeamOptions] = useState<SelectOption[]>([]);
  const [markets, setMarkets] = useState<string[]>([]);
  const [choosenMatchId, setChoosenMatchId] = useState<string>("");

  const predictionsPerMatch = usePredictionsPerMatch(
    competitionOptions,
    teamOptions,
    markets
  );

  const matchId = useMemo(() => {
    if (
      !choosenMatchId &&
      predictionsPerMatch &&
      predictionsPerMatch.breakdown.length
    ) {
      return predictionsPerMatch.breakdown[0].match;
    } else if (choosenMatchId) {
      return choosenMatchId;
    }

    return "";
  }, [choosenMatchId, predictionsPerMatch]);

  const { pastMatchSummary } = useMatchSummary(matchId);

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Single predictions per match"}
          description={
            "This report shows by default the top 100 most predicted matches for the specified period. You can change the specified period, filter by competitions, teams and markets and see how many single predictions have been placed."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} />
        </div>
      </div>
      <div className="mb-3">
        <EntitySelect
          entity="competitions"
          entities={competitionOptions}
          setEntities={setCompetitionOptions}
          isMulti={true}
        />
      </div>
      <div className="mb-3">
        <EntitySelect
          entity="teams"
          entities={teamOptions}
          setEntities={setTeamOptions}
          isMulti={true}
        />
      </div>
      <div className="mb-3">
        <SuccessRateMarketSelect setMarkets={setMarkets} />
      </div>
      {predictionsPerMatch ? (
        <div className="mb-6 border border-blueGray-200 rounded p-2">
          <h2 className="text-center font-bold text-xl mb-4">
            Top 100 matches by placed predictions
          </h2>
          {predictionsPerMatch.breakdown.length ? (
            <div className="headingContainer">
              <div className="w-full overflow-x-auto fullHeight h-72 overflow-y-auto mb-4 border border-blueGray-200 rounded">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className={`${headerCellStyle} text-left`}>Match</th>
                      <th className={`${headerCellStyle} text-right`}>
                        Single predicitons
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {predictionsPerMatch.breakdown.map(
                      (breakdown: PredictionsPerMatchBreakdown) => (
                        <MatchBreakdown
                          key={breakdown.match}
                          breakdown={breakdown}
                          matchId={choosenMatchId ? choosenMatchId : matchId}
                          setMatchId={setChoosenMatchId}
                        />
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <div className="w-full">
                <MatchSummary
                  summary={pastMatchSummary}
                  model={matchModelForMatchSummary(
                    matchId,
                    predictionsPerMatch.breakdown
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PredictionsPerMatch;

import { snakeCase, upperCase } from "lodash";
import React, { Dispatch, SetStateAction } from "react";

const mauReports = [
  "monthly active users",
  "monthly active users vs registrations",
];

const engagementReports = ["rfe groups", "engagement segments"];

const miniGamesReports = ["breakdown", "popular"];

const tabValues = (reportType: "mau" | "engagement" | "mini_games") => {
  switch (reportType) {
    case "mau":
      return mauReports;
    case "engagement":
      return engagementReports;
    case "mini_games":
      return miniGamesReports;
  }
};

type ReportTwoTabsProps = {
  reportType: "mau" | "engagement" | "mini_games";
  tabValue: string;
  setTabValue: Dispatch<SetStateAction<string>>;
};

const ReportTwoTabs: React.FC<ReportTwoTabsProps> = ({
  reportType,
  tabValue,
  setTabValue,
}) => {
  const values = tabValues(reportType);

  return (
    <div
      className="relative grid md:grid-cols-2 grid-cols-1 gap-1 w-full mb-6"
      role="tablist"
    >
      {values.map((value: string, index: number) => (
        <div
          className="mb-2 md:mr-2 last:mr-0 flex-auto text-center"
          key={index}
        >
          <a
            className={
              "text-xs font-bold p-4 rounded block leading-normal cursor-pointer " +
              (tabValue === snakeCase(value)
                ? "bg-white text-brandColor"
                : "bg-gray-400 text-white")
            }
            onClick={() => setTabValue(snakeCase(value))}
            data-toggle="tab"
            role="tablist"
          >
            {upperCase(value)}
          </a>
        </div>
      ))}
    </div>
  );
};

export default ReportTwoTabs;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import DiscussionsBreakdownModel from "../../../models/Reports/Discussions/DiscussionsBreakdownModel";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import ReportPeriods from "../../../models/Reports/ReportPeriods";
import { setDefaultCoparedToDateApi } from "../../../helpers/helpers";

/**
 * The breakdown is not sorted in appropriate way. We need it sorted by date in ascending order.
 */
export const sortBreakdown = (breakdown: DiscussionsBreakdownModel[]) => {
  if (!breakdown) return null;

  return breakdown.sort(
    (a: DiscussionsBreakdownModel, b: DiscussionsBreakdownModel) => {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    }
  );
};

export const useDiscussionsBreakdown = () => {
  const [discussionsBreakdown, setDiscussionsBreakdown] =
    useState<ReportPeriods<DiscussionsBreakdownModel[]>>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);

  const getDiscussionsBreakdown = async () => {
    try {
      const discussionsBreakdown =
        await api.reportingHttps.getDiscussionsBreakdown(datePeriod, groupBy);
      let comparedToBreakdown: any = null;

      if (comparedTo) {
        comparedToBreakdown = await api.reportingHttps.getDiscussionsBreakdown(
          comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
          groupBy
        );
      }

      setDiscussionsBreakdown({
        current: sortBreakdown(discussionsBreakdown.breakdown),
        comparedTo: sortBreakdown(comparedToBreakdown?.breakdown),
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.DISCUSSIONS_BREAKDOWN_REPORT);
      setDiscussionsBreakdown({ current: [], comparedTo: [] });
    }
  };

  useEffect(() => {
    getDiscussionsBreakdown();

    return () => setDiscussionsBreakdown(null);
  }, [selectedClient, datePeriod, comparedToPeriod, groupBy]);

  return discussionsBreakdown;
};

import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import PrivateLeaguesUsersModel from "../../../models/Reports/PrivateLeagues/PrivateLeaguesUsersModel";

export const usePrivateLeaguesUsers = () => {
  const [privateLeaguesUsers, setPrivateLeaguesUsers] =
    useState<PrivateLeaguesUsersModel>(null);

  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod } = useContext(ReportParamsContext);
  const api = useContext(ApiContext);

  const getPrivateLeaguesUsers = async () => {
    try {
      const userStats = await api.reportingHttps.getPrivateLeagueUserStats(
        datePeriod
      );
      setPrivateLeaguesUsers(userStats);
    } catch (error) {
      toast.error(ERROR_MESSAGES.PRIVATE_LEAGUES_USERS_REPORT);
      setPrivateLeaguesUsers(new PrivateLeaguesUsersModel());
    }
  };

  useEffect(() => {
    getPrivateLeaguesUsers();

    return () => setPrivateLeaguesUsers(null);
  }, [selectedClient, datePeriod]);

  return privateLeaguesUsers;
};

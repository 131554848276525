import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router";
import { placeholders } from "../../../../assets/placeholders/placeholders";
import { formatProfileDate } from "../../../../helpers/helpers";
import ProfileInfo from "../../../../models/Clients/ProfileInfo";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { firebaseAuth } from "../../../../sdks/firebase/firebase";
import Badge from "../../../Badge/Badge";
import EditButton from "../../../Buttons/CreateStaffButton";
import { imageStyle } from "../../Dashboard/FollowedEntities/TopFollowedEntityRow";
import MemberDropdown from "./MemberDropdown";

const dataCellStyle =
  "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";

type StaffMemberProps = {
  staffMember: ProfileInfo;
};

const StaffMember: React.FC<StaffMemberProps> = ({ staffMember }) => {
  const isStaffMemberLoggedInUser = useMemo(() => {
    return firebaseAuth().currentUser?.uid === staffMember.id;
  }, [staffMember.id]);

  const history = useHistory();

  const { setStaffId } = useContext(GlobalStatesContext);

  const editStaffMember = () => {
    setStaffId(staffMember.id);
    history.push(`/staff/edit/${staffMember.id}`);
  };

  return (
    <tr className="px-4 border border-b border-blueGray-100">
      <th className={`${dataCellStyle} text-left flex items-center`}>
        <img
          src={staffMember.avatar ? staffMember.avatar : placeholders.avatar}
          className={imageStyle}
          alt={staffMember.name}
        ></img>
        <span className="ml-3 font-bold text-blueGray-600 mr-2">
          {staffMember.name}
        </span>
        {isStaffMemberLoggedInUser ? <Badge label="Me" /> : null}
      </th>
      <td className={`${dataCellStyle} lg:table-cell hidden`}>
        {staffMember.email}
      </td>
      <td className={`${dataCellStyle} lg:table-cell hidden`}>
        {formatProfileDate(staffMember.createdAt)}
      </td>
      <td className={`${dataCellStyle} lg:table-cell hidden`}>
        {formatProfileDate(staffMember.updatedAt)}
      </td>
      <td className={dataCellStyle}>
        <div
          className="flex items-center justify-end"
          style={{ marginRight: isStaffMemberLoggedInUser ? "1.50em" : "" }}
        >
          <EditButton label={"Edit"} onClick={editStaffMember} />
          {!isStaffMemberLoggedInUser ? (
            <MemberDropdown
              staffId={staffMember.id}
              staffEmail={staffMember.email}
            />
          ) : null}
        </div>
      </td>
    </tr>
  );
};

export default StaffMember;

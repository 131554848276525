import MarketsConfigModel from "fansunited-sdk-esm/Core/Namespaces/Predictor/Models/Config/MarketsConfigModel";
import React from "react";
import ListGroupMarkets from "./ListGroupMarkets";

type FeaturesPredictorMarketsProps = {
  markets: MarketsConfigModel;
};

const FeaturesPredictorMarkets: React.FC<FeaturesPredictorMarketsProps> = ({
  markets,
}) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <label className="font-bold mb-2">Markets</label>
      <div className="flex flex-col">
        <div className="md:grid md:grid-cols-2 md:gap-2">
          <ListGroupMarkets
            title={"Main"}
            paragraph={
              "Predictions for the markets in this list are accepted for matches in all competitions with no limitations."
            }
            markets={markets && markets.main ? markets.main : []}
          />
          <ListGroupMarkets
            title={"All"}
            paragraph={
              "Predictions for the markets in this list are accepted only for matches in the full-coverage competitions (see below)."
            }
            markets={markets && markets.all ? markets.all : []}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturesPredictorMarkets;

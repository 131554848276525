import React from "react";
import { marketMap } from "../../../../../../constants/constants";
import MarketTooltip from "../../Markets/MarketTooltip";

type MarketsSuccessRateScopeProps = {
  markets: string[];
};

const MarketsSuccessRateScope: React.FC<MarketsSuccessRateScopeProps> = ({
  markets,
}) => {
  return (
    <div className="mb-2">
      <h2 className="font-semibold mb-2 text-center">Markets</h2>
      <div className="lg:grid grid-cols-4 gap-2 justify-between">
        {markets.map((market: string) => (
          <div
            className="flex items-center bg-blueGray-200 mb-2 rounded-lg border-b border-blueGray-200 last:border-none h-24 px-2"
            key={`${market}-success-rate-market`}
          >
            <div className="p-1">{`${marketMap.get(market)} (${market})`}</div>
            <MarketTooltip market={market} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketsSuccessRateScope;

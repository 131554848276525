import React, { useRef } from "react";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import FeaturesFantasy from "./FeaturesFantasy";
import { useFeatures } from "../../../../hooks/features/useFeatures";

const FeaturesFantasyPage: React.FC = () => {
  // Use to trigger useFantasyFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const features = useFeatures(isFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Football Fantasy"}
      description={"This is Football Fantasy configuration for client: "}
    >
      {features ? (
        <FeaturesFantasy
          feature={features.footballFantasy}
          isFeaturesEdited={isFeaturesEdited}
          fullCoverageCompetitions={features.predictor.fullCoverageCompetitions}
        />
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesFantasyPage;

import React, { useRef, useState } from "react";
import { createPopper } from "@popperjs/core";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import NavigationDropdown from "./NavigationDropdown";
import ClientIcon from "./ClientIcon";

const UserDropdown: React.FC = () => {
  const [dropdownPopoverShow, setDropdownPopoverShow] =
    useState<boolean>(false);
  const btnDropdownRef = useRef<HTMLAnchorElement>(null);
  const popoverDropdownRef = useRef<HTMLDivElement>(null);
  const isOutside = useOutsideAlerter(
    btnDropdownRef,
    popoverDropdownRef,
    setDropdownPopoverShow
  );

  const openDropdownPopover = () => {
    if (btnDropdownRef && popoverDropdownRef) {
      createPopper(
        btnDropdownRef.current as HTMLAnchorElement,
        popoverDropdownRef.current as HTMLDivElement,
        {
          placement: "bottom-start",
        }
      );
      setDropdownPopoverShow(true);
    }
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
      <ClientIcon
        btnDropdownRef={btnDropdownRef}
        dropdownPopoverShow={dropdownPopoverShow}
        closeDropdownPopover={closeDropdownPopover}
        openDropdownPopover={openDropdownPopover}
      />
      <NavigationDropdown
        popoverDropdownRef={popoverDropdownRef}
        dropdownPopoverShow={dropdownPopoverShow}
        isOutside={isOutside}
        onClickLink={closeDropdownPopover}
      />
    </>
  );
};

export default UserDropdown;

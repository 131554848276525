import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { Dispatch, SetStateAction } from "react";
import SuccessRateCompetitionSelect from "../../FeaturesPredictor/SuccessRateScopes/EditSuccessRateScopes/SuccessRateCompetitionSelect/SuccessRateCompetitionSelect";

type TopXCompetitionsWhitelistProps = {
  title: string;
  description: string;
  loadedCompetitions: CompetitionBasicModel[];
  setCompetitionIds: Dispatch<SetStateAction<string[]>>;
};

const TopXCompetitionsWhitelist: React.FC<TopXCompetitionsWhitelistProps> = ({
  title,
  description,
  loadedCompetitions,
  setCompetitionIds,
}) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <label className="font-bold mb-2">{title}</label>
      <p className="mb-2">{description}</p>
      <SuccessRateCompetitionSelect
        loadedCompetitions={loadedCompetitions}
        maximumCompetitions={15}
        setCompetitionIds={setCompetitionIds}
      />
    </div>
  );
};

export default TopXCompetitionsWhitelist;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import React, { Dispatch, SetStateAction } from "react";
import { formatOptions } from "../../../../../helpers/helpers";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import FormattedNumber from "../../../../Common/FormattedNumber";
import EntityInformation from "./EntityInformation";

type TableRowsTopFollowsProps = {
  followBreakdown: { [key: string]: any };
  models: Map<
    string,
    CompetitionBasicModel | TeamBasicModel | PlayerBasicModel
  >;
  successRates: Map<string, number | string>;
  showEntityType: boolean;
  showInfoThatPlayersDontMatch: boolean;
  setEntityIds: Dispatch<SetStateAction<string[]>>;
  setEntities: Dispatch<SetStateAction<SelectOption[]>>;
};

const TableRowsTopFollows: React.FC<TableRowsTopFollowsProps> = ({
  followBreakdown,
  models,
  successRates,
  showEntityType,
  showInfoThatPlayersDontMatch,
  setEntityIds,
  setEntities,
}) => {
  const loadDetailedComponent = (
    model: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel
  ) => {
    setEntities([formatOptions(model)]);
    setEntityIds([model.id]);
  };

  return (
    <tr
      className="px-4 border-b border-blueGray-100 hover:bg-blueGray-100 cursor-pointer"
      onClick={() => loadDetailedComponent(models.get(followBreakdown.id))}
    >
      <th
        className={`border-t-0 border-l-0 border-r-0 whitespace-nowrap p-3 lg:text-left text-center`}
      >
        <EntityInformation
          entity={models.get(followBreakdown.id)}
          showType={showEntityType}
          showInfoThatPlayerDontMatch={showInfoThatPlayersDontMatch}
        />
      </th>
      <td
        className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 lg:table-cell hidden text-right text-blueGray-500`}
      >
        <FormattedNumber numberValue={followBreakdown.follows.all} />
      </td>
      <td
        className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 lg:table-cell hidden text-right text-blueGray-500`}
      >
        <FormattedNumber numberValue={followBreakdown.follows.favourites} />
      </td>
      {showInfoThatPlayersDontMatch ? (
        <td
          className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 xl:table-cell hidden text-right text-blueGray-500`}
        >
          {successRates.get(followBreakdown.id)}
        </td>
      ) : null}
    </tr>
  );
};

export default TableRowsTopFollows;

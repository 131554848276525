import React from "react";

type LoadMoreButtonProps = {
  onClick: () => void;
};

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="rounded bg-brandColor text-white hover:bg-lightBlue-800 uppercase text-xs font-bold py-2 px-3 mb-1"
    >
      Load more
    </button>
  );
};

export default LoadMoreButton;

import { ERROR_MESSAGES } from "../../enums/enums";

type FirebaseAuthCodes =
  | "auth/user-not-found"
  | "auth/wrong-password"
  | "auth/too-many-requests";

export const authErrorMessage = (code: FirebaseAuthCodes): string => {
  switch (code) {
    case "auth/user-not-found":
      return ERROR_MESSAGES.USER_NOT_FOUND;
    case "auth/wrong-password":
      return ERROR_MESSAGES.PASSWORD_INVALID;
    case "auth/too-many-requests":
      return ERROR_MESSAGES.ACCOUNT_TEMPORARILY_DISABLED;
    default:
      return "Something went wrong. Please try again.";
  }
};

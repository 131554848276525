import dayjs from "dayjs";
import React, { useContext, useRef } from "react";
import Flatpickr from "react-flatpickr";
import {
  setDefaultComparedToFlatpickrDate,
  setDefaultPeriodFlatpickrDate,
} from "../../../../helpers/helpers";
import { ReportParamsContext } from "../../../../providers/ReportParamsProvider";
import ClearButton from "../../../Buttons/ClearButton";

const setDefaultDateFlatpickr = (
  defaultDate: any,
  scenario: "period" | "compared_to"
) => {
  if (!defaultDate && scenario === "period")
    return setDefaultPeriodFlatpickrDate();

  if (!defaultDate && scenario === "compared_to")
    return setDefaultComparedToFlatpickrDate();

  if (defaultDate && Object.keys(defaultDate).length)
    return [defaultDate.fromDate, defaultDate.toDate];

  return defaultDate;
};

type DatePeriodProps = {
  defaultDatePeriod?: boolean;
  allTimeDefault?: boolean;
};

const DatePeriod: React.FC<DatePeriodProps> = ({
  defaultDatePeriod,
  allTimeDefault,
}) => {
  const {
    datePeriod,
    comparedToPeriod,
    comparedTo,
    setComparedTo,
    onChangePeriod,
  } = useContext(ReportParamsContext);

  const currentRefFlatpickr = useRef(null);
  const comparedToRefFlatpickr = useRef(null);

  const clearCurrentPeriod = () =>
    currentRefFlatpickr.current.flatpickr.clear();
  const clearComparedToPeriod = () =>
    comparedToRefFlatpickr.current.flatpickr.clear();

  return (
    <>
      {!defaultDatePeriod ? (
        <div className="flex w-full">
          <div
            className={`${
              comparedTo ? "w-full" : "w-64"
            } flex border-blueGray-200 border rounded mb-2 mr-2`}
          >
            <Flatpickr
              options={{
                mode: "range",
                enableTime: false,
                dateFormat: "Y-m-d",
                altFormat: "Y-m-d",
                maxDate: dayjs().add(-1, "day").toDate(),
                altInput: true,
                position: "below",
                defaultDate: setDefaultDateFlatpickr(datePeriod, "period"),
                altInputClass: "p-1.5 px-3 text-sm w-full border-0",
              }}
              onChange={(date: any[]) => onChangePeriod(date, "period")}
              placeholder="Choose period..."
              ref={currentRefFlatpickr}
            />
            <ClearButton onClick={clearCurrentPeriod} />
          </div>
          <div
            className="flex items-center mb-2 sm:whitespace-nowrap cursor-pointer"
            onClick={() => setComparedTo(!comparedTo)}
          >
            <input
              type="checkbox"
              className="mr-1 cursor-pointer"
              checked={comparedTo}
              readOnly
            />
            <span className="mr-2">Compared to</span>
          </div>
          {comparedTo ? (
            <div className="w-full flex border-blueGray-200 border rounded mb-2 md:mr-2">
              <Flatpickr
                options={{
                  mode: "range",
                  enableTime: false,
                  dateFormat: "Y-m-d",
                  altFormat: "Y-m-d",
                  maxDate: dayjs().add(-1, "day").toDate(),
                  altInput: true,
                  position: "below",
                  defaultDate: setDefaultDateFlatpickr(
                    comparedToPeriod,
                    "compared_to"
                  ),
                  altInputClass: "p-1.5 px-3 text-sm w-full border-0",
                }}
                onChange={(date: any[]) => onChangePeriod(date, "compared_to")}
                placeholder="Choose period..."
                ref={comparedToRefFlatpickr}
              />
              <ClearButton onClick={clearComparedToPeriod} />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="flex border-blueGray-200 border rounded mb-2">
          <Flatpickr
            options={{
              mode: "range",
              enableTime: false,
              dateFormat: "Y-m-d",
              altFormat: "Y-m-d",
              maxDate: dayjs().add(-1, "day").toDate(),
              altInput: true,
              position: "below",
              defaultDate: allTimeDefault
                ? setDefaultDateFlatpickr(datePeriod, "period")
                : null,
              altInputClass: "p-1.5 px-3 text-sm xl:w-56 w-full border-0",
            }}
            onChange={(date: any[]) => onChangePeriod(date, "period")}
            placeholder="Choose period..."
            ref={currentRefFlatpickr}
          />
          <ClearButton onClick={clearCurrentPeriod} />
        </div>
      )}
    </>
  );
};

export default DatePeriod;

import React from "react";
import { placeholders } from "../../../assets/placeholders/placeholders";

type ProfileAvatarProps = {
  userAvatar: string | undefined | null;
};

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ userAvatar }) => {
  return (
    <div className="items-center flex">
      <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
        <img
          alt=""
          className="w-full rounded-full align-middle border-none shadow-lg"
          src={userAvatar ? userAvatar : placeholders.avatar}
        />
      </span>
    </div>
  );
};

export default ProfileAvatar;

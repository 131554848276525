import ReactSelectBaseOption from "./ReactSelectBaseOption";

/**
 * Option model for Competition/Team async react-select
 */
export default class SelectOption extends ReactSelectBaseOption {
  public logo: string;
  public countryName: string;
  public countryFlag: string;
}

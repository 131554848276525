import React from "react";
import FormattedNumber from "../../../Common/FormattedNumber";

type StatsProps = {
  statsName: string;
  value: any;
  icon: string;
  iconColor: string;
  label: string;
  isSuccessRateMarketReport?: boolean;
  percentage?: boolean;
};

const Stats: React.FC<StatsProps> = ({
  statsName,
  value,
  icon,
  iconColor,
  label,
  isSuccessRateMarketReport,
  percentage,
}) => {
  return (
    <>
      <div className="flex mb-4">
        <div className="relative w-full pr-2 max-w-full flex-grow flex-1">
          <h5 className="text-blueGray-400 uppercase font-bold md:text-xs text-xxs">
            {statsName}
          </h5>
          <span className="font-semibold text-2xl text-blueGray-700 cursor-default">
            <FormattedNumber numberValue={value || 0} />
            {percentage ? "%" : ""}
          </span>
        </div>
        {icon ? (
          <div className="relative w-auto flex-initial">
            <div
              className={`text-white p-3 text-center inline-flex items-center justify-center ${
                isSuccessRateMarketReport ? "xl:w-8 xl:h-8" : ""
              } w-12 h-12 shadow-lg rounded-full ${iconColor}`}
            >
              <i className={icon}></i>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <p className="text-sm text-blueGray-400 font-bold">{label}</p>
    </>
  );
};

export default Stats;

import React from "react";
import UserSegments from "../../../../../models/Clients/EngagementCoefficientsConfiguration/UserSegments";
import {
  isCheckboxChecked,
  segmentsGroups,
} from "../../../Reports/Engagement/EngagementSegments/EngagementSegmentsInfo";

const segmentLabelMap = new Map()
  .set("fans", "Fans")
  .set("fanatics", "Fanatics")
  .set("casual_users", "Casual users")
  .set("at_risk", "At risk")
  .set("inactive", "Inactive")
  .set("new", "New");

type ECUserSegmentsProps = {
  userSegments: UserSegments[];
};

const ECUserSegments: React.FC<ECUserSegmentsProps> = ({ userSegments }) => {
  return (
    <div className="mb-2 border border-blueGray-300 rounded-lg p-2 bg-blueGray-100 overflow-x-auto overflow-y-auto">
      <h3 className="font-bold text-xl mb-2">User segments</h3>
      <table className="w-full table-auto border border-blueGray-400 mb-2">
        <thead>
          <tr>
            <th></th>
            <th className="font-normal border border-blueGray-400">Recency</th>
            <th className="font-normal border border-blueGray-400">
              Frequency
            </th>
            <th className="font-normal border border-blueGray-400">
              Engagement
            </th>
          </tr>
        </thead>
        <tbody>
          {userSegments.map((segment: UserSegments, index: number) => (
            <tr key={`user-segment-${segment.segmentId}-${index}`}>
              <th className="font-normal border border-blueGray-400">
                <span className="ml-2 bg-blueGray-100 md:w-full">
                  {segmentLabelMap.get(segment.segmentId) || segment.segmentId}
                </span>
              </th>
              <th className="font-normal border border-blueGray-400">
                <div className="flex items-center justify-center">
                  {segmentsGroups.map((value) => (
                    <div
                      key={`user-recency-segment-${value}`}
                      className="flex flex-col items-center mb-2 mr-2"
                    >
                      <span>{value + 1}</span>
                      <input
                        type="checkbox"
                        readOnly
                        checked={isCheckboxChecked(
                          "recency",
                          segment.criteriaList,
                          value + 1
                        )}
                      />
                    </div>
                  ))}
                </div>
              </th>
              <th className="font-normal border border-blueGray-400">
                <div className="flex items-center justify-center">
                  {segmentsGroups.map((value) => (
                    <div
                      key={`user-recency-segment-${value}`}
                      className="flex flex-col items-center mb-2 mr-2"
                    >
                      <span>{value + 1}</span>
                      <input
                        type="checkbox"
                        readOnly
                        checked={isCheckboxChecked(
                          "frequency",
                          segment.criteriaList,
                          value + 1
                        )}
                      />
                    </div>
                  ))}
                </div>
              </th>
              <th className="font-normal border border-blueGray-400">
                <div className="flex items-center justify-center">
                  {segmentsGroups.map((value) => (
                    <div
                      key={`user-recency-segment-${value}`}
                      className="flex flex-col items-center mb-2 mr-2"
                    >
                      <span>{value + 1}</span>
                      <input
                        type="checkbox"
                        readOnly
                        checked={isCheckboxChecked(
                          "engagement",
                          segment.criteriaList,
                          value + 1
                        )}
                      />
                    </div>
                  ))}
                </div>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ECUserSegments;

import React from "react";
import LoyaltyInput from "../../../../Common/LoyaltyInput";
import RangeCalendar from "../../../../Common/RangeCalendar";
import { IMiniGamesBadgeRequirements } from "../../../../../../../models/features/loyalty/Badges/MiniGames/MiniGamesBadgesExtended";

type MiniGameBadgeRequirementsProps = {
  uid: string;
  requirements: IMiniGamesBadgeRequirements;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
};

const MiniGameBadgeRequirements: React.FC<MiniGameBadgeRequirementsProps> = ({
  uid,
  requirements,
  onChangeBadges,
}) => {
  return (
    <div className="mb-2">
      <label className="font-bold mb-2 text-center">Requirements:</label>
      <LoyaltyInput
        uid={uid}
        label="Participation Count"
        value={requirements.participationCount}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "participationCount", value)
        }
        scenario="Badges"
      />
      {/**TODO: When from date and to date is implemented then enable that (FZ-2694) */}
      {/* <RangeCalendar
        defaultDate={[requirements.fromDate, requirements.toDate]}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "dateRange", value)
        }
      /> */}
    </div>
  );
};

export default MiniGameBadgeRequirements;

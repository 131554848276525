import React, { useState } from "react";
import DiscussionsReportTabs from "./DiscussionsReportTabs";
import DiscussionReportUI from "./DiscussionReportUI";

export enum DISCUSSIONS_REPORTS_TABS {
  AVERAGES = "averages",
  TOP_100_CONTRIBUTORS = "top_100_contributors",
  TOP_100_DISCUSSIONS = "top_100_discussions",
  DISCUSSIONS_BREAKDOWN = "discussions_breakdown",
  POSTS_BREAKDOWN = "posts_breakdown",
}

const DiscussionsReport: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>(
    DISCUSSIONS_REPORTS_TABS.AVERAGES
  );

  return (
    <div className="w-full px-4">
      <DiscussionsReportTabs tabValue={openTab} setTabValue={setOpenTab} />
      <DiscussionReportUI openTab={openTab} />
    </div>
  );
};

export default DiscussionsReport;

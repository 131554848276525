import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import MarketsConfigModel from "fansunited-sdk-esm/Core/Namespaces/Predictor/Models/Config/MarketsConfigModel";
import PredictorSuccessRateScopesModel from "./PredictorSuccessRateScopesModel";

export default class PredictorFeaturesModel {
  public enabled: boolean = false;
  public markets: MarketsConfigModel = { main: [], all: [] };
  public fullCoverageCompetitions: CompetitionBasicModel[] = [];
  public successRateScopes: PredictorSuccessRateScopesModel =
    new PredictorSuccessRateScopesModel();
}

import React, { useState } from "react";
import FootballEntityFollows from "../../../../../models/Reports/FootballCompetitionsFollows/FootballEntityFollows";
import { Entity } from "../../../../../types/types";
import Stats from "../../../Dashboard/Stats/Stats";
import StatsContainer from "../../../Dashboard/Stats/StatsContainer";
import CountryFollowsBreakdown from "../../Common/CountryFollowsBreakdown";
import EntityInformation from "../../Common/EntityFollows/EntityInformation";
import TotalUsers from "../../Common/TotalUsers/TotalUsers";
import FootballTeamsMatchData from "../../Football/FootballTeams/FootballTeamsMatchData";
import EntityFollowsTabs from "./EntityFollowsTabs";

type EntityFollowsProps = {
  enittyFollowsInfo: FootballEntityFollows;
  entity: Entity;
};

export enum EntityFollowsTabsEnum {
  FOLLOWERS = "followers",
  MATCHES = "matches",
}

const EntityFollows: React.FC<EntityFollowsProps> = ({
  enittyFollowsInfo,
  entity,
}) => {
  const [tab, setTab] = useState<string>(EntityFollowsTabsEnum.FOLLOWERS);

  return (
    <>
      {entity === "teams" ? (
        <EntityFollowsTabs openTab={tab} setOpenTab={setTab} />
      ) : null}
      <div className="mb-6 border border-blueGray-200 rounded p-2">
        {tab === EntityFollowsTabsEnum.FOLLOWERS ? (
          <>
            <div className="flex justify-between mb-4">
              <EntityInformation
                entity={enittyFollowsInfo.model}
                showType={entity === "competitions" ? true : false}
                showInfoThatPlayerDontMatch={
                  entity === "players" ? false : true
                }
              />
            </div>
            {entity !== "players" ? (
              <div className="mb-4">
                <StatsContainer isSuccessRate={true}>
                  <Stats
                    statsName="Success rate"
                    value={
                      typeof enittyFollowsInfo.successRate === "number"
                        ? `${enittyFollowsInfo.successRate}%`
                        : enittyFollowsInfo.successRate
                    }
                    icon={"fas fa-star"}
                    iconColor={"bg-emerald-500"}
                    label={"Across all markets for the past 30 days"}
                  />
                </StatsContainer>
              </div>
            ) : null}
            <h2 className="text-center mb-4 font-bold text-xl">
              Followers summary
            </h2>
            <TotalUsers
              total={enittyFollowsInfo.reportInformation.total}
              reportType={"follows_entity"}
            />
            <hr className="mb-4"></hr>
            <div className="xl:flex xl:flex-row xl:items-center xl:justify-between p-2 hidden">
              <h2 className="font-bold">Country</h2>
              <h2 className="font-bold">Percent</h2>
              <h2 className="font-bold">Breakdown</h2>
            </div>
            <hr className="mb-4"></hr>
            <CountryFollowsBreakdown
              profileCountries={enittyFollowsInfo.profileCountries}
              breakdown={enittyFollowsInfo.reportInformation.breakdown}
              allFollows={enittyFollowsInfo.reportInformation.total.all}
            />
          </>
        ) : (
          <FootballTeamsMatchData teamId={enittyFollowsInfo.model.id} />
        )}
      </div>
    </>
  );
};

export default EntityFollows;

import React from "react";

export const boxStyle =
  "border-0 px-3 py-3 text-blueGray-600 bg-blueGray-100 rounded text-sm shadow w-full outline-none focus:outline-none focus:ring ease-linear transition-all duration-150";

const boxStyleFaded =
  "border-0 px-3 py-3 text-blueGray-300 bg-blueGray-100 rounded text-sm shadow w-full outline-none focus:outline-none focus:ring ease-linear transition-all duration-150";

type EditProfileRowProps = {
  label: string;
  userEmail?: string;
  currentName?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const EditProfileRow: React.FC<EditProfileRowProps> = ({
  label,
  userEmail,
  currentName,
  onChange,
}) => {
  return (
    <div className="w-full lg:w-6/12">
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor={label}
        >
          {label}
        </label>
        {userEmail ? (
          <div className={boxStyleFaded}>{userEmail}</div>
        ) : (
          <input
            type="text"
            className={boxStyle}
            onChange={onChange}
            defaultValue={currentName}
          />
        )}
      </div>
    </div>
  );
};

export default EditProfileRow;

import HighestSuccessRateModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateModel";
import React from "react";
import { placeholders } from "../../../../../assets/placeholders/placeholders";
import FormattedNumber from "../../../../Common/FormattedNumber";

type TableRowSuccessRateUsersProps = {
  value: HighestSuccessRateModel;
};

const TableRowSuccessRateUsers: React.FC<TableRowSuccessRateUsersProps> = ({
  value,
}) => {
  return (
    <tr className="px-4 border-b border-blueGray-100">
      <th className="border-t-0 border-l-0 border-r-0 whitespace-nowrap px-8 lg:text-left lg:table-cell hidden text-center text-sm">
        {value.position}
      </th>
      <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 lg:text-left text-center text-blueGray-500">
        <div className="md:flex md:items-center text-left">
          <img
            src={
              value.profileModel && value.profileModel.avatar
                ? value.profileModel.avatar
                : placeholders.avatar
            }
            className="w-12 h-12 mr-4 rounded-full hidden md:block"
          ></img>
          <div>
            <h3 className="font-bold">
              {value.profileModel?.name ? value.profileModel?.name : "Unknown"}
            </h3>
            <p className="text-sm">{value.profileModel?.nickname}</p>
          </div>
        </div>
      </td>
      <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 lg:table-cell hidden text-right text-blueGray-500">
        <FormattedNumber numberValue={value.predictionsMade} />
      </td>
      <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 lg:table-cell hidden text-right text-blueGray-500">
        <FormattedNumber numberValue={value.points} />
      </td>
      <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 sm:text-right text-blueGray-500">
        {`${value.successRatePercent}%`}
      </td>
    </tr>
  );
};

export default TableRowSuccessRateUsers;

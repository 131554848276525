import MD5 from "crypto-js/md5";
import WordArray from "crypto-js/lib-typedarrays";
import Base64 from "crypto-js/enc-base64";

export default class HashService {
  public readFileAndHash = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const wordArray = WordArray.create(new Uint8Array(data as ArrayBuffer));
        const hashed = MD5(wordArray);
        const base64 = hashed.toString(Base64);
        resolve(base64);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  public generateHashFileName = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const wordArray = WordArray.create(new Uint8Array(data as ArrayBuffer));
        const hashed = MD5(wordArray).toString();
        resolve(hashed);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };
}

import React from "react";
import LoadingButton from "./LoadingButton";

type LoginButtonProps = {
  isLoading: boolean;
  signIn: () => void;
};

const LoginButton: React.FC<LoginButtonProps> = ({ isLoading, signIn }) => {
  return (
    <>
      {!isLoading ? (
        <div className="text-center mt-6">
          <button
            className="bg-brandColor text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg hover:bg-lightBlue-800 outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
            onClick={signIn}
          >
            Sign In
          </button>
        </div>
      ) : (
        <LoadingButton type={"login"} isLoading={isLoading} />
      )}
    </>
  );
};

export default LoginButton;

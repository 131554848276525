import React, { useState } from "react";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import "../../Reports.css";
import RFMGroupEntitySelectProps from "../EngagementEntitySelect/EngagementEntitySelect";
import { useRFMGroups } from "../../../../../hooks/useRFMGroups";
import Spinner from "../../../../Spinner/Spinner";
import RFMInfo from "./RFMInfo";
import InfoTooltip from "../../Common/InfoTooltip/InfoTooltip";

type RFMGroupsProps = {
  whitelistEntities: any[];
};

const RFMGroups: React.FC<RFMGroupsProps> = ({ whitelistEntities }) => {
  const [entityId, setEntityId] = useState<string>("");

  const rfmGroups = useRFMGroups(entityId);

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={
            "Comprehensive RFE (Recency, Frequency, Engagement) Group Analysis"
          }
          description={
            "Our in-depth report categorizes users into five distinct groups, from the least engaged (Group 1) to the most engaged (Group 5), based on their interactions."
          }
        />
      </div>
      <div className="mb-6">
        <RFMGroupEntitySelectProps
          whitelistEntities={whitelistEntities}
          setEntityId={setEntityId}
        />
      </div>
      {rfmGroups ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div className="border border-blueGray-200 p-2 rounded">
            <div className="flex justify-center items-center mb-2">
              <h3 className="text-xl font-bold mr-2">Recency</h3>
              <InfoTooltip title={"Recency"} />
            </div>
            {Object.keys(rfmGroups.recency).map((key: string) => (
              <RFMInfo
                keyGroup={key}
                key={key}
                value={rfmGroups.recency[key]}
              ></RFMInfo>
            ))}
          </div>
          <div className="border border-blueGray-200 p-2 rounded">
            <div className="flex justify-center items-center mb-2">
              <h3 className="text-xl font-bold mr-2">Frequency</h3>
              <InfoTooltip title={"Frequency"} />
            </div>
            {Object.keys(rfmGroups.frequency).map((key: string) => (
              <RFMInfo
                keyGroup={key}
                key={key}
                value={rfmGroups.frequency[key]}
              ></RFMInfo>
            ))}
          </div>
          <div className="border border-blueGray-200 p-2 rounded">
            <div className="flex justify-center items-center mb-2">
              <h3 className="text-xl font-bold mr-2">Engagement</h3>
              <InfoTooltip title={"Engagement"} />
            </div>
            {Object.keys(rfmGroups.monetary).map((key: string) => (
              <RFMInfo
                keyGroup={key}
                key={key}
                value={rfmGroups.monetary[key]}
              ></RFMInfo>
            ))}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default RFMGroups;

import KafkaWebHookCredentials from "./credentials/KafkaWebhookCredentials";
import PubSubWebhookCredentials from "./credentials/PubSubWebhookCredentials";
import WebhookBaseCredentials from "./credentials/WebhookBaseCredentials";

export type WebhookEvent = {
  id: string;
};
export type WebhookConfigType = "HTTP" | "KAFKA" | "PUB_SUB";
export type WebhookConfigMethod = "GET" | "POST" | "PUT";

export type WebhookConfig = {
  type: WebhookConfigType;
  url: string;
  method: WebhookConfigMethod;
  credentials:
    | WebhookBaseCredentials
    | KafkaWebHookCredentials
    | PubSubWebhookCredentials; // Credentials depends on the type
};

export default class WebhookFeatureModel {
  id: string = null;
  name: string = null;
  config: WebhookConfig = {
    type: "HTTP",
    url: "",
    method: "GET",
    credentials: {
      name: "",
      password: ""
    }
  }; // The type depends on the config.type
  events: WebhookEvent[] = [];
  retryLimit: number = null;
}

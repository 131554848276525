import React, { useContext, useMemo } from "react";
import { globalRoles, rolesMap } from "../../../../constants/constants";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import RoleTooltip from "./RoleTooltip";

type StaffRolesProps = {
  onChange: (role: string) => void;
  loadedRoles?: string[];
};

const StaffRoles: React.FC<StaffRolesProps> = ({ onChange, loadedRoles }) => {
  const { loggedInUser } = useContext(GlobalStatesContext);

  const isChecked = (role: string) => {
    return loadedRoles && loadedRoles.includes(role);
  };

  /**
    Roles platform_operator and service_user are excluded.
   */
  const rolesKeys = useMemo(() => {
    const roles = Array.from(rolesMap.keys());

    return roles.filter((role: string) => !globalRoles.includes(role));
  }, [loggedInUser]);

  return (
    <div className="w-full lg:w-6/12">
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="Roles"
        >
          Roles
        </label>
        <ul>
          {rolesKeys.map((role: string, index: number) => (
            <div
              key={`role-index-${index}`}
              className="flex items-center p-2 bg-blueGray-100 cursor-pointer"
              onClick={() => onChange(role)}
            >
              <input
                type="checkbox"
                className="mr-2 focus:outline-none outline-none"
                value={role}
                checked={isChecked(role)}
                readOnly
              />
              <li className="text-blueGray-600">{rolesMap.get(role)}</li>
              <RoleTooltip role={role} />
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StaffRoles;

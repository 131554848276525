import React from "react";
import FormattedNumber from "../../../../Common/FormattedNumber";
import FilledProgressBar from "./FilledProgressBar";
import "./Users.css";

type UsersProps = {
  user: string;
  usersBreakdown: string[];
  count: number;
  all: number;
  checkboxLabel: string;
  onChange: (userBreakdown: string) => void;
  reportType: "users_acquired" | "follows_entity";
};

const Users: React.FC<UsersProps> = ({
  user,
  usersBreakdown,
  count,
  all,
  checkboxLabel,
  onChange,
  reportType,
}) => {
  const title = () => {
    switch (user) {
      case "all":
        return "Total users";
      case "male":
        return "Male";
      case "female":
        return "Female";
      case "unspecified":
        return "Non-specified";
      default:
        break;
    }
  };

  const percentage = () => {
    let percentage = (count / all) * 100;
    percentage = percentage || 0;

    return percentage.toFixed(2);
  };

  const isChecked = () => usersBreakdown.includes(user);

  const showPercentage = user === "all" ? false : true;

  return (
    <div
      className={`w-full ${
        reportType === "users_acquired" ? "usersContainer" : ""
      }`}
    >
      <div
        className={`${
          reportType === "follows_entity" ? "usersContainer" : ""
        } md:text-center cursor-default`}
      >
        <h2>{title()}</h2>
        <p className="font-bold mb-2">
          <FormattedNumber numberValue={count} />
          {showPercentage ? ` users (${percentage()}%)` : ` users`}
        </p>
      </div>
      {reportType === "users_acquired" ? (
        <div
          className="flex flex-row justify-center items-center cursor-pointer mb-2"
          onClick={() => onChange(user)}
        >
          <input
            className="mr-1"
            type="checkbox"
            checked={isChecked()}
            readOnly
          />
          <span className="text-xs">{checkboxLabel}</span>
        </div>
      ) : (
        <FilledProgressBar percentage={`${percentage()}%`} user={user} />
      )}
    </div>
  );
};

export default Users;

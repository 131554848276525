import PredictorConfigModel from "fansunited-sdk-esm/Core/Namespaces/Predictor/Models/Config/PredictorConfigModel";
import PredictorFeaturesModel from "../../../models/features/predictor/PredictorFeaturesModel";
import FeaturesBuilder from "../FeaturesBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class PredictorFeaturesBuilder extends FeaturesBuilder {
  private predictorConfigFeatures: PredictorConfigModel;
  private predictorFeaturesModel: PredictorFeaturesModel =
    new PredictorFeaturesModel();

  constructor(
    sdk: FansUnitedSDKModel,
    predictorConfigFeatures: PredictorConfigModel
  ) {
    super(sdk);
    this.predictorConfigFeatures = predictorConfigFeatures;
  }

  public build = async (): Promise<PredictorFeaturesModel> => {
    this.predictorFeaturesModel.enabled = this.predictorConfigFeatures.enabled;
    this.predictorFeaturesModel.markets = this.predictorConfigFeatures.markets;
    this.predictorFeaturesModel.fullCoverageCompetitions =
      await this.getCompetitions(
        this.predictorConfigFeatures.fullCoverageCompetitions
      );
    this.predictorFeaturesModel.successRateScopes.competitions =
      await this.getCompetitions(
        this.predictorConfigFeatures.successRateScopes.competitions
      );
    this.predictorFeaturesModel.successRateScopes.teams = await this.getTeams(
      this.predictorConfigFeatures.successRateScopes.teams
    );
    this.predictorFeaturesModel.successRateScopes.markets =
      this.predictorConfigFeatures.successRateScopes.markets;

    return this.predictorFeaturesModel;
  };
}

import { useContext, useEffect, useState } from "react";
import LoyaltyFeaturesBuilder from "../../builders/features/loyalty/LoyaltyFeaturesBuilder";
import MatchQuizFeaturesBuilder from "../../builders/features/matchQuiz/MatchQuizFeaturesBuilder";
import PredictorFeaturesBuilder from "../../builders/features/predictor/PredictorFeaturesBuilder";
import TopXFeaturesBuilder from "../../builders/features/topX/TopXFeaturesBuilder";
import ClientFeaturesModel from "../../models/features/ClientFeaturesModel";
import { ApiContext } from "../../providers/ApiProvider";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import FootballFantasyFeaturesBuilder from "../../builders/features/footballFantasy/FootballFantasyFeaturesBuilder";

export const useFeatures = (isFeaturesEdited: any) => {
  const [clientFeatures, setClientFeatures] =
    useState<ClientFeaturesModel>(null);

  const { clientHttps } = useContext(ApiContext);
  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getClientFeatures = async () => {
    const features = await clientHttps.getClientFeatures();

    const clientFeaturesModel = new ClientFeaturesModel();
    const predictorFeaturesBuilder = new PredictorFeaturesBuilder(
      api?.sdk,
      features.predictor
    );
    const topXFeaturesBuilder = new TopXFeaturesBuilder(
      api?.sdk,
      features.topX
    );
    const matchQuizFeaturesBuilder = new MatchQuizFeaturesBuilder(
      api?.sdk,
      features.matchQuiz,
      features.predictor.markets.all
    );
    const loyaltyFeaturesBuilder = new LoyaltyFeaturesBuilder(
      api?.sdk,
      features.loyalty
    );
    const footballFantasyFeaturesBuilder = new FootballFantasyFeaturesBuilder(
      api?.sdk,
      features.footballFantasy
    );

    const predictorFeaturesModel = await predictorFeaturesBuilder.build();
    const topXFeaturesModel = await topXFeaturesBuilder.build();
    const matchQuizFeaturesModel = await matchQuizFeaturesBuilder.build();
    const loyaltyFeaturesModel = loyaltyFeaturesBuilder.build();

    clientFeaturesModel.predictor = predictorFeaturesModel;
    clientFeaturesModel.topX = topXFeaturesModel;
    clientFeaturesModel.matchQuiz = matchQuizFeaturesModel;
    clientFeaturesModel.loyalty = loyaltyFeaturesModel;
    clientFeaturesModel.externalPoints = features.externalPoints;
    clientFeaturesModel.webhook = features.webhook;
    clientFeaturesModel.footballFantasy =
      await footballFantasyFeaturesBuilder.build();

    setClientFeatures(clientFeaturesModel);
  };

  useEffect(() => {
    getClientFeatures();

    return () => setClientFeatures(null);
  }, [selectedClient, isFeaturesEdited.current]);

  return clientFeatures;
};

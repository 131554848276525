import React, { useContext, useState } from "react";
import WebhookBaseModel from "../../../../models/features/webhook/WebhookBaseModel";
import Enabled from "../Common/Enabled";
import UpdateButton from "../../../Buttons/UpdateButton";
import AddButton from "../../../Buttons/AddButton";
import RemoveButton from "../../../Buttons/RemoveButton";
import WebhookFeatureModel, {
  WebhookConfigType,
  WebhookEvent,
} from "../../../../models/features/webhook/WebhookFeatureModel";
import {
  inputNumber,
  remapKeysToSpecificCase,
} from "../../../../helpers/helpers";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import WebhookCredentials, {
  AllCredentialsFields,
} from "./Credentials/WebhookCredentials";
import WebhookEventsComponent from "./Events/WebhookEventsComponent";
import WebhookCredentialsCard from "./Credentials/WebhookCredentialsCard";
import WebhookFeatureFactory from "../../../../models/features/webhook/WebhookFeatureFactory";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import { ApiContext } from "../../../../providers/ApiProvider";
import WebhookValueSelect from "./WebhookValueSelect";

const factory = new WebhookFeatureFactory();

/**
 * For Pub Sub webhook configuration we don't need the URL, but URL
 * is required from Client API. So we will set the same value as topicName.
 */
const finalTouchRequestBody = (webhookFeatureModel: WebhookFeatureModel) => {
  if (webhookFeatureModel.config.type === "PUB_SUB") {
    webhookFeatureModel.config.url =
      //@ts-ignore
      webhookFeatureModel.config.credentials.topicName;

    return webhookFeatureModel;
  }

  return webhookFeatureModel;
};

type WebhookComponentProps = {
  webhook: WebhookBaseModel;
  isWebhookFeatureEdited: any;
};

const WebhooksComponent: React.FC<WebhookComponentProps> = ({
  webhook,
  isWebhookFeatureEdited,
}) => {
  const [copyWebhooksConfig, setCopyWebhooksConfig] = useState<
    WebhookFeatureModel[]
  >(webhook.webhooks);
  const newWebhooksConfigurations = copyWebhooksConfig
    ? copyWebhooksConfig.map((value: WebhookFeatureModel, index: number) =>
        factory.createNewWebhookFeatures(
          value,
          copyWebhooksConfig[index].config.type
        )
      )
    : [];
  const [newWebhooksConfig, setNewWebhooksConfig] = useState<
    WebhookFeatureModel[]
  >(newWebhooksConfigurations);

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const onChangeMainWebhookProps = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: "id" | "name" | "retryLimit" | "url",
    index: number
  ) => {
    const copyCurrentWebhookConfigs = JSON.parse(
      JSON.stringify(newWebhooksConfig)
    ) as WebhookFeatureModel[];
    //@ts-ignore
    copyCurrentWebhookConfigs[index][field] = event.target.value;
    setNewWebhooksConfig(copyCurrentWebhookConfigs);
  };

  const onChangeConfigWebhookProps = (
    value: string,
    field: "method" | "type" | "url",
    index: number
  ) => {
    const copyCurrentWebhookConfigs = JSON.parse(
      JSON.stringify(newWebhooksConfig)
    ) as WebhookFeatureModel[];
    //@ts-ignore
    copyCurrentWebhookConfigs[index].config[field] = value;
    setNewWebhooksConfig(copyCurrentWebhookConfigs);

    if (field === "type") {
      const copyCurrentConfig = [...newWebhooksConfig];
      copyCurrentConfig[index].config.type = value as WebhookConfigType;
      setNewWebhooksConfig(copyCurrentConfig);
      setCopyWebhooksConfig(copyCurrentConfig);
    }
  };

  const onChangeEvents = (selectedEvent: string, index: number) => {
    let currentEvents: string[] = newWebhooksConfig[index].events.map(
      (event: WebhookEvent) => event.id
    );
    if (!currentEvents.includes(selectedEvent)) {
      const newEvent = { id: selectedEvent };
      let copyCurrentEvents = JSON.parse(
        JSON.stringify(newWebhooksConfig[index].events)
      );
      const copyCurrentWebhooksConfig = JSON.parse(
        JSON.stringify(newWebhooksConfig)
      ) as WebhookFeatureModel[];
      copyCurrentEvents = [...copyCurrentEvents, newEvent];
      copyCurrentWebhooksConfig[index].events = copyCurrentEvents;
      setNewWebhooksConfig(copyCurrentWebhooksConfig);
    } else {
      const newEvents = currentEvents.filter(
        (event: string) => event !== selectedEvent
      );
      const eventsModel = newEvents.map((event: string) => {
        return {
          id: event,
        };
      });
      const copyCurrentWebhooksConfig = JSON.parse(
        JSON.stringify(newWebhooksConfig)
      ) as WebhookFeatureModel[];
      copyCurrentWebhooksConfig[index].events = eventsModel;
      setNewWebhooksConfig(copyCurrentWebhooksConfig);
    }
  };

  const onChangeCredentials = (
    value: string,
    field: AllCredentialsFields,
    idx: number
  ) => {
    const copyCurrentWebhookConfigs = JSON.parse(
      JSON.stringify(newWebhooksConfig)
    ) as WebhookFeatureModel[];
    //@ts-ignore
    copyCurrentWebhookConfigs[idx].config.credentials[field] = value;
    setNewWebhooksConfig(copyCurrentWebhookConfigs);
  };

  const addNewWebhookConfig = () => {
    const newWebhookConfig = new WebhookFeatureModel();
    setNewWebhooksConfig((prevState) => {
      return [...prevState, newWebhookConfig];
    });
    setCopyWebhooksConfig((prevState) => {
      return [...prevState, newWebhookConfig];
    });
  };

  const removeWebhookConfig = (index: number) => {
    newWebhooksConfig.splice(index, 1);
    const copyWebhooksConfig = JSON.parse(
      JSON.stringify(newWebhooksConfig)
    ) as WebhookFeatureModel[];
    setNewWebhooksConfig(copyWebhooksConfig);
    setCopyWebhooksConfig(copyWebhooksConfig);
  };

  const updateWebhooksConfig = async () => {
    setIsLoading(true);
    setCopyWebhooksConfig(newWebhooksConfig);
    const requestBody = newWebhooksConfig.map((value: WebhookFeatureModel) =>
      finalTouchRequestBody(value)
    );
    const reqBodySnakeCase = {
      webhooks: remapKeysToSpecificCase(requestBody, "snake"),
    };

    try {
      await clientHttps.updateFeaturesForClient(
        reqBodySnakeCase,
        FansUnitedFeatures.WEBHOOK
      );
      toast.success(SUCCESS_MESSAGES.FEATURES_WEBHOOKS_CONFIG_UPDATE);
      isWebhookFeatureEdited.current = !isWebhookFeatureEdited.current;
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FEATURES_WEBHOOKS_CONFIG_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Enabled value={webhook.enabled} />
      <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
        <h3 className="font-bold mb-2">Webhooks</h3>
        <p className="mb-2">
          You can created multiple webhooks, each using different delivery and
          subscribing to different events.
        </p>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2">
          {copyWebhooksConfig &&
            copyWebhooksConfig.map(
              (webhookConfig: WebhookFeatureModel, idx: number) => (
                <div
                  className="bg-blueGray-200 mb-2"
                  key={`${webhookConfig.id}-${idx}`}
                >
                  <div className="flex flex-col p-2">
                    <div className="flex flex-col mb-2">
                      <div className="flex justify-between items-center mb-2">
                        <label className="font-bold">ID</label>
                        <RemoveButton
                          onClick={() => removeWebhookConfig(idx)}
                        />
                      </div>
                      <input
                        className="p-3"
                        defaultValue={webhookConfig.id}
                        onChange={(event) =>
                          onChangeMainWebhookProps(event, "id", idx)
                        }
                        type="text"
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <label className="font-bold mb-2">Name</label>
                      <input
                        className="p-3"
                        defaultValue={webhookConfig.name}
                        onChange={(event) =>
                          onChangeMainWebhookProps(event, "name", idx)
                        }
                        type="text"
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <label className="font-bold mb-2">Retry limit</label>
                      <input
                        className="p-3"
                        defaultValue={webhookConfig.retryLimit}
                        onChange={(event) =>
                          onChangeMainWebhookProps(event, "retryLimit", idx)
                        }
                        type="number"
                        onKeyDown={(event) => inputNumber(event, false)}
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <label className="font-bold mb-2">Type</label>
                      <WebhookValueSelect
                        value={webhookConfig.config.type}
                        field={"type"}
                        onChange={(value) =>
                          onChangeConfigWebhookProps(value, "type", idx)
                        }
                      />
                    </div>
                    <div className="flex flex-col mt-5">
                      <label className="font-bold mb-2">Properties</label>
                    </div>
                    {/** Credentials depending on the type */}
                    <WebhookCredentialsCard>
                      <WebhookCredentials
                        credentialsType={webhookConfig.config.type}
                        value={newWebhooksConfig[idx].config.credentials}
                        onChange={(value, field) =>
                          onChangeCredentials(value, field, idx)
                        }
                        defaultUrl={webhookConfig.config.url}
                        defaultMethod={webhookConfig.config.method}
                        onChangeUrl={(value: string, field: string) =>
                          //@ts-ignore
                          onChangeConfigWebhookProps(value, field, idx)
                        }
                        onChangeMethod={(value: string, field: string) =>
                          //@ts-ignore
                          onChangeConfigWebhookProps(value, field, idx)
                        }
                      />
                    </WebhookCredentialsCard>
                    <WebhookEventsComponent
                      events={webhookConfig.events}
                      onChange={(event: string) => onChangeEvents(event, idx)}
                    />
                  </div>
                </div>
              )
            )}
          <AddButton onClick={addNewWebhookConfig} />
        </div>
      </div>
      <UpdateButton
        label={"Update"}
        onClick={updateWebhooksConfig}
        isLoading={isLoading}
      />
    </>
  );
};

export default WebhooksComponent;

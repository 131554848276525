import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../providers/ApiProvider";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { ERROR_MESSAGES, FansUnitedFeatures } from "../../enums/enums";
import ClassicQuizFeatureModel from "../../models/features/classicQuiz/ClassicQuizFeatureModel";
import { toast } from "react-toastify";

export const useClassicQuizFeatures = (isFeaturesEdited: any) => {
  const [classicQuizFeature, setClassicQuizFeature] =
    useState<ClassicQuizFeatureModel>(null);

  const { clientHttps } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getClassicQuizClientFeature = async () => {
    try {
      const feature = await clientHttps.getClientSpecificFeature(
        FansUnitedFeatures.CLASSIC_QUIZ
      );
      setClassicQuizFeature(feature);
    } catch (e) {
      toast.error(ERROR_MESSAGES.CLASSIC_QUIZ_FEATURE);
      setClassicQuizFeature(new ClassicQuizFeatureModel());
    }
  };

  useEffect(() => {
    getClassicQuizClientFeature();

    return () => setClassicQuizFeature(null);
  }, [selectedClient, isFeaturesEdited.current]);

  return classicQuizFeature;
};

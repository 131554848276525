import React, { useContext } from "react";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import LoadingButton from "../Buttons/LoadingButton";

type ConfirmationModalProps = {
  title: string;
  content: string;
  declineButtonLabel: string;
  confirmButtonLabel: string;
  confirmAction: () => void;
};

//TODO: Make this component universal if needed. At the moment it is used only for deleting a staff member.

const ConfirmationalModal: React.FC<ConfirmationModalProps> = ({
  title,
  content,
  declineButtonLabel,
  confirmButtonLabel,
  confirmAction,
}) => {
  const { staffEmail, showModal, isLoading, setShowModal } =
    useContext(GlobalStatesContext);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden absolute inset-0 z-50 top-0 right-0 bottom-0 left-0 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded">
                  <h3 className="text-2xl font-semibold">{title}</h3>
                  <button
                    className="flex items-center ml-auto bg-transparent border-0 text-black float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none text-red-500 hover:text-red-600">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative whitespace-nowrap p-3 flex items-center justify-between text-blueGray-500 text-lg">
                  <p className="my-4 leading-relaxed">{content}</p>
                  <span className="font-bold">&nbsp;{staffEmail}?</span>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b">
                  <LoadingButton
                    type={"delete_staff_member"}
                    isLoading={isLoading}
                  />
                  {!isLoading ? (
                    <>
                      <button
                        className="text-red-500 hover:text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        {declineButtonLabel}
                      </button>
                      <button
                        className="bg-red-600 hover:bg-red-700 text-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={confirmAction}
                      >
                        {confirmButtonLabel}
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-80 absolute inset-0 z-40 bg-black top-0 right-0 bottom-0 left-0"></div>
        </>
      ) : null}
    </>
  );
};

export default ConfirmationalModal;

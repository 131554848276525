import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import PredictionsModel from "fansunited-sdk-esm/Core/Namespaces/Predictor/Models/Predictions/PredictionsModel";
import React from "react";
import { placeholders } from "../../../../../assets/placeholders/placeholders";
import { playerSummaryKeys, summaryMap } from "../../../../../constants/constants";
import Spinner from "../../../../Spinner/Spinner";
import SummaryStats from "./SummaryStats";

/**
 * Used to display a paragraph when no predictions are placed for the match.
 */
const isMatchWithNoPredictions = (summary: PredictionsModel) => {
  const summaries = Object.values(summary);

  return summaries.every((summaryValue: any) => {
    // Values are empty objects
    if (!Object.keys(summaryValue).length) return true;

    // Values are object with yes: 0 and no: 0
    return Object.keys(summaryValue).every(
      (value: any) => !summaryValue[value]
    );
  });
};

/**
 * Extracts the keys from the predictions match summary and order them by the total predictions count
 * @param obj Match predictions summary from Football API.
 * @returns List with sorted objects by total predictions.
 */

const sortSummaryByTotalSumValues = (obj: PredictionsModel) => {
  const arr: any[] = [];

  if (!obj) return arr;

  Object.keys(obj).forEach((summaryKey: string) => {
    const nestedObj = obj[summaryKey];
    let sum: number = 0;

    if (playerSummaryKeys.includes(summaryKey)) {
      Object.keys(nestedObj).forEach(
        (key: string) => (sum += nestedObj[key].yes + nestedObj[key].no)
      );
    } else {
      Object.keys(nestedObj).forEach((key: string) => {
        if (key) sum += nestedObj[key];
      });
    }

    arr.push({ summaryKey, sum });
  });

  arr.sort((a, b) => b.sum - a.sum);

  return arr;
};

const Heading = (model: MatchBasicModel | null) => {
  if (!model)
    return (
      <h2 className="text-center font-bold mb-4 bg-blueGray-100 py-2">
        Match predictions
      </h2>
    );

  return (
    <>
      <div className="bg-blueGray-200 text-center pt-2">
        Full predictions report (including games) for
      </div>
      <div className="flex items-center justify-center p-2 bg-blueGray-200 mb-2">
        <p className="font-semibold mr-1 md:flex md:items-center">
          <span>{model.homeTeam.name}</span>
        </p>
        <img
          className="w-6 mr-1"
          src={
            model.homeTeam.assets &&
            //@ts-ignore
            model.homeTeam.assets.logo
              ? //@ts-ignore
                model.homeTeam.assets.logo
              : placeholders.team
          }
        />
        <span className="mr-1">-</span>
        <img
          className="w-6 mr-1"
          src={
            model.awayTeam.assets &&
            //@ts-ignore
            model.awayTeam.assets.logo
              ? //@ts-ignore
                model.awayTeam.assets.logo
              : placeholders.team
          }
        />
        <span className="font-semibold mr-1">{model.awayTeam.name}</span>
      </div>
    </>
  );
};

type MatchSummaryProps = {
  summary: PredictionsModel;
  model: MatchBasicModel;
};

const MatchSummary: React.FC<MatchSummaryProps> = ({ summary, model }) => {
  const sortedSummaryArrayByTotalValue = sortSummaryByTotalSumValues(summary);

  return (
    <div className="overflow-visible shadow-l border border-blueGray-200 rounded bg-blueGray-100 mb-6 w-full">
      {Heading(model)}
      {summary ? (
        <div className="p-2">
          {!isMatchWithNoPredictions(summary) ? (
            sortedSummaryArrayByTotalValue.map(
              (sortedValue: { summaryKey: string; sum: number }) => (
                <SummaryStats
                  key={`summary-${sortedValue.summaryKey}`}
                  statsName={summaryMap.get(sortedValue.summaryKey)}
                  summaryKey={sortedValue.summaryKey}
                  summary={summary[sortedValue.summaryKey]}
                />
              )
            )
          ) : (
            <p className="font-bold text-center mb-2">
              No predictions are placed for this match
            </p>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default MatchSummary;

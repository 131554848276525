import React, { PropsWithChildren } from "react";

const CardStats: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="w-full pl-4 mb-12">
      <div className="flex flex-wrap">{children}</div>
    </div>
  );
};

export default CardStats;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { setDefaultCoparedToDateApi } from "../helpers/helpers";
import ReportPeriods from "../models/Reports/ReportPeriods";
import UsersAcquired from "../models/Reports/UsersAcquired/UsersAcquired";
import { ApiContext } from "../providers/ApiProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export const useUsersAcquired = () => {
  const [usersAcquired, setUsersAcquired] = useState<
    ReportPeriods<UsersAcquired>
  >({ current: null, comparedTo: null });

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { groupBy, datePeriod, comparedTo, comparedToPeriod } =
    useContext(ReportParamsContext);

  const getUsersAcquired = async () => {
    try {
      const currentUsersAcquired = await api.reportingHttps.getAcquiredUsers(
        datePeriod,
        groupBy
      );
      let comparedToUsersAcquired: UsersAcquired = null;

      if (comparedTo) {
        comparedToUsersAcquired = await api.reportingHttps.getAcquiredUsers(
          comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
          groupBy
        );
      }

      setUsersAcquired({
        current: currentUsersAcquired,
        comparedTo: comparedToUsersAcquired,
      });
    } catch (erorr) {
      toast.error(ERROR_MESSAGES.USERS_AQUIRED_REPORT);
      const reportPeriods: ReportPeriods<UsersAcquired> = {
        current: new UsersAcquired(),
        comparedTo: new UsersAcquired(),
      };
      setUsersAcquired(reportPeriods);
    }
  };

  useEffect(() => {
    getUsersAcquired();

    return () => setUsersAcquired({ current: null, comparedTo: null });
  }, [selectedClient, groupBy, datePeriod, comparedToPeriod, comparedTo]);

  return usersAcquired;
};

import React, { Dispatch, SetStateAction } from "react";
import { SUCCESS_RATE_TABS } from "./SuccessRateReport";

type SuccessRateTabsReportProps = {
  openTab: string;
  setOpenTab: Dispatch<SetStateAction<string>>;
};

const SuccessRateTabsReport: React.FC<SuccessRateTabsReportProps> = ({
  openTab,
  setOpenTab,
}) => {
  return (
    <ul className="flex mb-0 list-none flex-wrap py-3 flex-row" role="tablist">
      <li className="mb-2 mr-2 flex-auto text-center">
        <a
          className={
            "text-xs font-bold uppercase p-4 rounded block leading-normal text-white " +
            (openTab === SUCCESS_RATE_TABS.MARKETS
              ? "bg-brandColor"
              : "bg-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(SUCCESS_RATE_TABS.MARKETS);
          }}
          data-toggle="tab"
          href="#markets"
          role="tablist"
        >
          {SUCCESS_RATE_TABS.MARKETS}
        </a>
      </li>
      <li className="mb-2 flex-auto text-center">
        <a
          className={
            "text-xs font-bold uppercase p-4 rounded block leading-normal text-white " +
            (openTab === SUCCESS_RATE_TABS.USERS
              ? "bg-brandColor"
              : "bg-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(SUCCESS_RATE_TABS.USERS);
          }}
          data-toggle="tab"
          href="#users"
          role="tablist"
        >
          {SUCCESS_RATE_TABS.USERS}
        </a>
      </li>
    </ul>
  );
};

export default SuccessRateTabsReport;

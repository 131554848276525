import React, { useContext } from "react";
import { useConsumption } from "../../../../hooks/useConsumption";
import { ReportParamsContext } from "../../../../providers/ReportParamsProvider";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import DatePeriod from "../Common/DatePeriod";
import GroupByPeriod from "../Common/GroupByPeriod";
import ReportInfo from "../Common/ReportInfo";
import Breakdowns from "./Breakdowns/Breakdowns";
import Summary from "./Summary/Summary";
import Trends from "./Trends/Trends";
import "../Reports.css";

const Consumption: React.FC = () => {
  const { datePeriod, comparedToPeriod } = useContext(ReportParamsContext);

  const platformConsumption = useConsumption();

  return (
    <div className="w-full px-4">
      <CardReport>
        <div className="headingContainer mb-6">
          <ReportInfo
            title={"Metrics report"}
            description={
              "This is a breakdown of your platform usage for a specified period of time. In this report you will find a breakdown by day, week or month."
            }
          />
          <div className="xl:flex xl:flex-row xl:items-center">
            <DatePeriod />
          </div>
        </div>
        <GroupByPeriod />
        {platformConsumption.current ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Summary
                fromDate={datePeriod?.fromDate}
                toDate={datePeriod?.toDate}
                totalRequests={platformConsumption.current.total}
                period="selected"
              />
              {platformConsumption.comparedTo ? (
                <Summary
                  fromDate={comparedToPeriod?.fromDate}
                  toDate={comparedToPeriod?.toDate}
                  totalRequests={platformConsumption.comparedTo.total}
                  period="compared_to"
                />
              ) : null}
            </div>
            <Trends platformConsumption={platformConsumption} />
            <Breakdowns breakdown={platformConsumption.current.breakdown} />
          </>
        ) : (
          <Spinner />
        )}
      </CardReport>
    </div>
  );
};

export default Consumption;

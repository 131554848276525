import React, { useState } from "react";
import { formatOptions, upperCase } from "../../../../../../helpers/helpers";
import BadgeEntitySelect from "./BadgeEntitySelect";
import BadgeMatchSelect from "./BadgeMatchSelect";

const entities = ["competition", "team", "player", "match"];

type EntityProps = {
  entityId: string;
  entityType: string;
  entityModel: any;
  uid: string;
  onChangeEntityIdRequirement: (value: string) => void;
  onChangeEntityTypeRequirement: (value: string) => void;
};

const Entity: React.FC<EntityProps> = (props) => {
  const [entityType, setEntityType] = useState<string>(
    props.entityType ? props.entityType : null
  );

  const onChangeEntityType = (entityType: string) => {
    setEntityType(entityType);
    props.onChangeEntityTypeRequirement(entityType);
  };

  return (
    <>
      <div className="mb-2">
        <label className="font-bold mb-2 text-center">Entity type</label>
        <div className="flex items-center justify-start flex-wrap">
          {entities.map((entity) => (
            <label
              key={`radio-button-entity-${entity}`}
              className="flex items-center mr-2 cursor-pointer"
            >
              <input
                id={`radio-button-entity-${props.uid}`}
                name={`radio-button-entity-${props.uid}`}
                type="radio"
                readOnly
                checked={entityType === entity}
                className="mr-1 cursor-pointer"
                onChange={() => onChangeEntityType(entity)}
              />
              {upperCase(entity)}
            </label>
          ))}
        </div>
      </div>
      <div className="mb-2">
        <label className="font-bold mb-2 text-center">Entity</label>
        {entityType &&
        entityType !== "match" &&
        props.entityType !== "match" ? (
          <BadgeEntitySelect
            entityType={entityType}
            onChangeEntity={(entityId) =>
              props.onChangeEntityIdRequirement(entityId)
            }
            loadedEntityOption={
              props.entityModel ? formatOptions(props.entityModel) : null
            }
          />
        ) : (
          <></>
        )}
        {entityType && entityType === "match" ? (
          <BadgeMatchSelect
            loadedMatch={
              entityType === props.entityType ? props.entityModel : null
            }
            onChangeEntity={(entity) =>
              props.onChangeEntityIdRequirement(entity.id)
            }
            uid={props.uid}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Entity;

import React, { useState } from "react";
import { toast } from "react-toastify";
import { placeholders } from "../../assets/placeholders/placeholders";
import LoginButton from "../../components/Buttons/LoginButton";
import { authErrorMessage } from "../../sdks/firebase/authErrorMessage";
import { firebaseAuth } from "../../sdks/firebase/firebase";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const toggleIsLoading = (toggle: boolean) => setIsLoading(toggle);

  const signIn = async () => {
    toggleIsLoading(true);
    try {
      await firebaseAuth().signInWithEmailAndPassword(email, password);
    } catch (error: any) {
      console.error(error);
      toast.error(authErrorMessage(error.code));
    }
    toggleIsLoading(false);
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <form className="w-full lg:w-4/12 px-4" onSubmit={signIn}>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <img
                  src={placeholders.logoTransparent}
                  className="mx-auto h-20 w-25"
                  alt=""
                />
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    onChange={handleEmail}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    onChange={handlePassword}
                  />
                </div>
                <LoginButton isLoading={isLoading} signIn={signIn} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;

import React from "react";

const headerCellStyle =
  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100";

const StaffMembersHeaderCells: React.FC = () => {
  return (
    <thead>
      <tr>
        <th className={headerCellStyle}>Name</th>
        <th className={`${headerCellStyle} lg:table-cell hidden`}>Email</th>
        <th className={`${headerCellStyle} lg:table-cell hidden`}>Created</th>
        <th className={`${headerCellStyle} lg:table-cell hidden`}>Updated</th>
        <th className={headerCellStyle}></th>
      </tr>
    </thead>
  );
};

export default StaffMembersHeaderCells;

import React from "react";
import {
  AveragePostsModel,
  PercentageUsersWithPostsModel,
} from "../../../../../models/Reports/Discussions/DiscussionsAveragesModel";
import Stats from "../../../Dashboard/Stats/Stats";

const averagePostsTitleMap = new Map()
  .set(
    "averageNumberPerPublicDiscussion",
    "Average number of posts per public discussion"
  )
  .set("averageNumberPerUser", "Average number of posts per user")
  .set(
    "averageNumberPerContributor",
    "Average number of posts per contributor"
  );

const averagePostsDescriptionMap = new Map()
  .set(
    "averageNumberPerPublicDiscussion",
    "Average number of posts per public discussion"
  )
  .set("averageNumberPerUser", "Average number of posts per user")
  .set(
    "averageNumberPerContributor",
    "Average number of posts per contributor (user with at least 1 post per discussion)"
  );

const averagePostsKeys = [
  "averageNumberPerPublicDiscussion",
  "averageNumberPerUser",
  "averageNumberPerContributor",
];

const percentageUsersWithPostsTitleMap = new Map()
  .set("atLeastOne", "At least 1 post")
  .set("atLeastFive", "At least 5 posts")
  .set("atLeastTen", "At least 10 posts")
  .set("atLeastFifty", "At least 50 posts")
  .set("atLeastOneHundred", "At least 100 posts");

const percentageUsersWithPostsDescriptionMap = new Map()
  .set("atLeastOne", "Percentage of users with at least 1 post in discussions")
  .set(
    "atLeastFive",
    "Percentage of users with at least 5 posts in discussions"
  )
  .set(
    "atLeastTen",
    "Percentage of users with at least 10 posts in discussions"
  )
  .set(
    "atLeastFifty",
    "Percentage of users with at least 50 posts in discussions"
  )
  .set(
    "atLeastOneHundred",
    "Percentage of users with at least 100 posts in discussions"
  );

const percentageUsersWithPostsKeys = [
  "atLeastOne",
  "atLeastFive",
  "atLeastTen",
  "atLeastFifty",
  "atLeastOneHundred",
];

type DiscussionsAverageStatsProps = {
  type: "average_posts" | "percentage_users_posts";
  data: AveragePostsModel | PercentageUsersWithPostsModel | null;
};

const DiscussionsAverageStats: React.FC<DiscussionsAverageStatsProps> = ({
  type,
  data,
}) => {
  const title =
    type === "average_posts"
      ? "Average number of posts"
      : "Percentage users with posts";
  const keys =
    type === "average_posts" ? averagePostsKeys : percentageUsersWithPostsKeys;
  const statsTitleMap =
    type === "average_posts"
      ? averagePostsTitleMap
      : percentageUsersWithPostsTitleMap;
  const statsDescriptionMap =
    type === "average_posts"
      ? averagePostsDescriptionMap
      : percentageUsersWithPostsDescriptionMap;

  return (
    <div className="mb-6">
      <div className="mb-4">
        <h2 className="font-bold mb-2">{title}</h2>
        <hr className="mb-2"></hr>
      </div>
      <div className="lg:grid lg:grid-cols-3 lg:gap-2 mb-4">
        {keys.map((key: string, idx: number) => (
          <div
            key={`average-follows-${key}-${idx}`}
            className="w-full p-4 min-w-0 break-words bg-blueGray-50 rounded shadow-lg mb-4"
          >
            <Stats
              statsName={statsTitleMap.get(key)}
              label={statsDescriptionMap.get(key)}
              value={data[key] || 0}
              icon={""}
              iconColor={""}
              percentage={type === "percentage_users_posts"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiscussionsAverageStats;

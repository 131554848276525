import React, { useRef } from "react";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import FeaturesEitherOr from "./FeaturesEitherOr";
import { useEitherOrFeatures } from "../../../../hooks/features/useEitherOrFeatures";

const FeaturesEitherOrPage: React.FC = () => {
  // Use to trigger useEitherOrFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const feature = useEitherOrFeatures(isFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Either/Or"}
      description={"This is Either/Or configuration for client: "}
    >
      {feature ? (
        <FeaturesEitherOr
          feature={feature}
          isFeaturesEdited={isFeaturesEdited}
        />
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesEitherOrPage;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import PredictionsPerMatchBuilder from "../builders/reports/PredictionsPerMatchBuilder";
import { ERROR_MESSAGES } from "../enums/enums";
import PredictionsPerMatchBreakdown from "../models/Reports/Predictions/PredictionsPerMatchBreakdown";
import PredictionsReport from "../models/Reports/Predictions/PredictionsReport";
import SelectOption from "../models/SelectOption/SelectOption";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";

export const usePredictionsPerMatch = (
  competitions: SelectOption[],
  teams: SelectOption[],
  markets: string[]
) => {
  const [predictionsPerMatch, setPredictionsPerMatch] =
    useState<PredictionsReport<PredictionsPerMatchBreakdown>>(null);

  const { datePeriod } = useContext(ReportParamsContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const api = useContext(ApiContext);

  const getPredictionsPerEntitiy = async () => {
    try {
      const predictionsPerMatch =
        await api.reportingHttps.getPredictionsPerMatch(
          datePeriod,
          competitions.map((value: SelectOption) => value.id),
          teams.map((value: SelectOption) => value.id),
          markets
        );

      await new PredictionsPerMatchBuilder(
        api,
        predictionsPerMatch.breakdown
      ).build();
      setPredictionsPerMatch(predictionsPerMatch);
    } catch (error) {
      toast.error(ERROR_MESSAGES.PREDICTIONS_PER_ENTITY_REPORT);
      setPredictionsPerMatch(
        new PredictionsReport<PredictionsPerMatchBreakdown>()
      );
    }
  };

  useEffect(() => {
    getPredictionsPerEntitiy();

    return () => setPredictionsPerMatch(null);
  }, [selectedClient, datePeriod, competitions, teams, markets]);

  return predictionsPerMatch;
};

import React, { useContext } from "react";
import { Switch, Redirect } from "react-router-dom";
import FooterAdmin from "../components/Footers/FooterAdmin";
import ReportParamsProvider from "../providers/ReportParamsProvider";
import { UserAccessPagesContext } from "../providers/UserAccessPagesProvider";
import { routes } from "../routes/routes";
import RouteWithSubRoutes from "../routes/RouteWithSubRoutes";

const getAccessRoutes = (pages: string[]) => {
  return routes.filter((route: any) => {
    const hasAccess = pages.some((page: string) => route.path.includes(page));

    if (hasAccess) return route;
  });
};

const MainRouting: React.FC = () => {
  const { pages } = useContext(UserAccessPagesContext);

  const accessRoutes = getAccessRoutes(pages);

  return (
    <ReportParamsProvider>
      <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <Switch>
          {accessRoutes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Redirect from="/" to="/dashboard" />
        </Switch>
        <FooterAdmin />
      </div>
    </ReportParamsProvider>
  );
};

export default MainRouting;

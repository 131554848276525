export interface DefaultAssetsInterface {
  competitionLogo: string;
  teamLogo: string;
  playerHeadshot: string;
}

export interface CustomAssetsInterface {
  competitionLogos: any;
  teamLogos: any;
  playerHeadshots: any;
}

export default class FootballSettingsConfigModel {
  disableAvailableAssets: boolean = false;
  defaultAssets: DefaultAssetsInterface = {
    competitionLogo: "",
    teamLogo: "",
    playerHeadshot: "",
  };
  customAssets: CustomAssetsInterface = {
    competitionLogos: {},
    teamLogos: {},
    playerHeadshots: {},
  };
}

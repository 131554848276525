import React from "react";
import PointsValue from "../../../../../models/features/loyalty/PointsValue/PointsValue";
import {
  alternativeAdvanceCorrectScoreSnakeCaseMap,
  alternativeAdvanceCorrectScoreTitleMap,
  generalPointsMap,
  marketMap,
} from "../../../../../constants/constants";
import { inputNumber } from "../../../../../helpers/helpers";
import Points from "../../../../../models/features/loyalty/Points/Points";

type LoyaltyGeneralPointsProps = {
  type: string;
  generalPoints: Points[] | PointsValue[];
  onChange: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
    correctScoreAdvancedAlternativeKey?: string
  ) => void;
};

const PointsComponent: React.FC<LoyaltyGeneralPointsProps> = ({
  type,
  generalPoints,
  onChange,
}) => {
  const tabsNoMarkets = ["general", "activity", "discussion post", "voting"];
  const isOpenTabWithMarkets = !tabsNoMarkets.includes(type);
  let pointsAdvancedCorrectScore: PointsValue = null;

  if (isOpenTabWithMarkets) {
    if (generalPoints && generalPoints.length) {
      //@ts-ignore
      pointsAdvancedCorrectScore = generalPoints.filter(
        (value: PointsValue) => value.id === "CORRECT_SCORE_ADVANCED"
      )[0];
    }
  }

  return (
    <div
      className={`grid ${
        type === "voting" ? "mr-2" : "xl:grid-cols-4"
      } grid-cols-1 gap-2`}
    >
      {generalPoints &&
        generalPoints.length &&
        generalPoints.map((points: any, idx: number) => (
          <div
            className="bg-blueGray-200 mb-2 p-2"
            key={`${points.id}-${points.points}-${idx}`}
          >
            <div className="flex flex-col justify-between items-center">
              <label className="uppercase text-sm text-center">
                {tabsNoMarkets.includes(type)
                  ? generalPointsMap.get(points.id)
                  : marketMap.get(points.id)}
              </label>
              {isOpenTabWithMarkets ? (
                <label className="uppercase text-sm text-center mb-2">{`(${points.id})`}</label>
              ) : null}
              <input
                className="h-16 w-16 text-2xl text-brandColor bg-blueGray-100 border border-bluGray-200 rounded mt-auto text-center font-bold my-2"
                type="number"
                name="Loyalty general points"
                defaultValue={points.points}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(points.uid, event)
                }
                onKeyDown={(event) => inputNumber(event, false)}
              ></input>
            </div>
          </div>
        ))}
      {pointsAdvancedCorrectScore
        ? Object.keys(pointsAdvancedCorrectScore.alternative).map(
            (alternativePoints: any, idx: number) => (
              <div
                className="bg-blueGray-200 mb-2 p-2"
                key={`${pointsAdvancedCorrectScore.id}-${alternativePoints}-${idx}`}
              >
                <div
                  className="flex flex-col justify-between items-center"
                  key={`${type}-${alternativePoints}`}
                >
                  <label className="uppercase text-sm text-center">
                    {alternativeAdvanceCorrectScoreTitleMap.get(
                      alternativePoints
                    )}
                  </label>
                  <label className="uppercase text-sm text-center mb-2">{`(${alternativeAdvanceCorrectScoreSnakeCaseMap.get(
                    alternativePoints
                  )})`}</label>
                  <input
                    className="h-16 w-16 text-2xl text-brandColor bg-blueGray-100 border border-bluGray-200 rounded mt-auto text-center font-bold my-2"
                    type="number"
                    name="Loyalty alternative points"
                    defaultValue={
                      pointsAdvancedCorrectScore.alternative[alternativePoints]
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(
                        pointsAdvancedCorrectScore.uid,
                        event,
                        alternativePoints
                      )
                    }
                    onKeyDown={(event) => inputNumber(event, false)}
                  ></input>
                </div>
              </div>
            )
          )
        : null}
    </div>
  );
};

export default PointsComponent;

import React from "react";
import UsersAquired from "./UsersAquired/UsersAquired";
import StatsLayout from "./Stats/StatsLayout";
import FollowedEntities from "./FollowedEntities/FollowedEntities";

const Dashboard: React.FC = () => {
  return (
    <>
      <StatsLayout />
      <UsersAquired />
      <FollowedEntities />
    </>
  );
};

export default Dashboard;

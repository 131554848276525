import React, { useRef } from "react";
import { useFeatures } from "../../../../hooks/features/useFeatures";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import FeaturesTopX from "./FeaturesTopX";

const FeaturesTopXPage: React.FC = () => {
  // Use to trigger useFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const features = useFeatures(isFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Top X feature"}
      description={"This is Top X configuration for client: "}
    >
      {features ? (
        <FeaturesTopX
          topXFeatures={features.topX}
          fullCoverageCompetitions={features.predictor.fullCoverageCompetitions}
        />
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesTopXPage;

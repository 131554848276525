import React from "react";

type MainCardProps = {};

const MainHeader: React.FC<MainCardProps> = (props) => {
  return (
    <div className="relative bg-brandColor md:pt-32 pb-32 pt-12">
      {/* <div className="px-4 md:px-10 mx-auto w-full"></div> */}
    </div>
  );
};

export default MainHeader;

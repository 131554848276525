import React from "react";
import {
  averageFollowsColorMap,
  averageFollowsDescription,
  averageFollowsIconsMap,
  averageFollowsTitles,
} from "../../../../../constants/constants";
import Stats from "../../../Dashboard/Stats/Stats";
import AverageFollowEntityLayout from "../AverageFollowEntity/AverageFollowEntityLayout";
import AverageFollowsContainer from "./AverageFollowsContainer";

type AverageFollowsProps = {
  title: string;
  entities: string[];
  averageFollows: Map<string, number>;
};

const AverageFollows: React.FC<AverageFollowsProps> = ({
  title,
  entities,
  averageFollows,
}) => {
  return (
    <>
      <div className="mb-4">
        <h2 className="font-bold mb-2">{title}</h2>
        <hr className="mb-2"></hr>
      </div>
      <AverageFollowEntityLayout>
        {entities.map((entity: string, idx: number) => (
          <AverageFollowsContainer key={`average-follows-${entity}-${idx}`}>
            <Stats
              statsName={averageFollowsTitles.get(entity)}
              label={averageFollowsDescription.get(entity)}
              value={averageFollows?.get(entity) ? averageFollows?.get(entity) : 0}
              icon={averageFollowsIconsMap.get(entity)}
              iconColor={averageFollowsColorMap.get(entity)}
            />
          </AverageFollowsContainer>
        ))}
      </AverageFollowEntityLayout>
    </>
  );
};

export default AverageFollows;

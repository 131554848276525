import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";

export const useMatchSummary = (prevMatchId: string, nextMatchId?: string) => {
  const [pastMatchSummary, setPastMatchSummary] = useState<any>(null);
  const [upcomingMatchSummary, setUpcomingMatchSummary] = useState<any>(null);

  const api = useContext(ApiContext);

  const getPastMatchSummary = async () => {
    try {
      const prevMatchSummary = await api.sdk.predictor.getMatchSummary(
        prevMatchId
      );

      setPastMatchSummary(prevMatchSummary.predictions);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
      setPastMatchSummary({});
    }
  };

  const getUpcomingMatchSummary = async () => {
    try {
      const nextMatchSummary = await api.sdk.predictor.getMatchSummary(
        nextMatchId
      );

      setUpcomingMatchSummary(nextMatchSummary.predictions);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
      setUpcomingMatchSummary({});
    }
  };

  useEffect(() => {
    if (prevMatchId) {
      getPastMatchSummary();
    } else {
      setPastMatchSummary({});
    }

    return () => setPastMatchSummary(null);
  }, [prevMatchId]);

  useEffect(() => {
    if (nextMatchId) {
      getUpcomingMatchSummary();
    }

    return () => setUpcomingMatchSummary(null);
  }, [nextMatchId]);

  return { pastMatchSummary, upcomingMatchSummary };
};

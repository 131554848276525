import React, { Dispatch, SetStateAction } from "react";
import { marketMap } from "../../../../../../../constants/constants";
import Select, { MultiValue } from "react-select";
import { asyncSelectStyles } from "../../../../../../../constants/styles";
import ReactSelectBaseOption from "../../../../../../../models/SelectOption/ReactSelectBaseOption";

type SuccessRateAllMarketsListProps = {
  allMarkets: string[];
  loadedMarkets: string[];
  marketsHandler?: (selectOption: MultiValue<ReactSelectBaseOption>) => void;
  setMarkets: Dispatch<SetStateAction<string[]>>;
};

const transformMarketsToSelectOptions = (
  markets: string[]
): ReactSelectBaseOption[] => {
  return markets.map((market: string) => {
    return {
      id: market,
      value: market,
      label: `${marketMap.get(market)} (${market})`,
    };
  });
};

const extractUnselectedMarkets = (
  allMarkets: string[],
  loadedMarkets: string[]
): ReactSelectBaseOption[] => {
  if (allMarkets && allMarkets.length) {
    const unSelectedMarkets = allMarkets.filter(
      (market: string) => !loadedMarkets.includes(market)
    );

    return transformMarketsToSelectOptions(unSelectedMarkets);
  }

  return [];
};

const extractPlayerRelatedMarketsFromAllMarkets = (allMarkets: string[]) => {
  const playerMarkets = [
    "PLAYER_SCORE",
    "PLAYER_SCORE_TWICE",
    "PLAYER_SCORE_HATTRICK",
    "PLAYER_YELLOW_CARD",
    "PLAYER_RED_CARD",
  ];

  return allMarkets.filter((market: string) => !playerMarkets.includes(market));
};

const SuccessRateAllMarketsList: React.FC<SuccessRateAllMarketsListProps> = ({
  allMarkets,
  loadedMarkets,
  setMarkets,
}) => {
  if (!allMarkets || !loadedMarkets) return null;

  const markets = extractPlayerRelatedMarketsFromAllMarkets(allMarkets);

  const marketsHandler = (selectOption: MultiValue<ReactSelectBaseOption>) =>
    setMarkets(selectOption.map((option: ReactSelectBaseOption) => option.id));

  return (
    <div className="w-full">
      <Select
        getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
        styles={asyncSelectStyles}
        isMulti
        isClearable
        onChange={marketsHandler}
        defaultValue={transformMarketsToSelectOptions(loadedMarkets)}
        options={extractUnselectedMarkets(markets, loadedMarkets)}
        placeholder={""}
      />
    </div>
  );
};

export default SuccessRateAllMarketsList;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import RFMGroupModel from "../models/Reports/Engagement/RFMGroupModel";

const sortKeysRFMGroup = (rfmGroup: any) => {
  const sortedKeys = Object.keys(rfmGroup).sort((a: string, b: string) =>
    b.localeCompare(a)
  );
  const sortedObject: any = {};
  sortedKeys.forEach((key: string) => {
    sortedObject[key] = rfmGroup[key];
  });

  return sortedObject;
};

export const useRFMGroups = (entityId: string) => {
  const [rfmGroup, setRfmGroup] = useState<RFMGroupModel>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getRFMGroupReport = async () => {
    try {
      const rfmGroup = await api.reportingHttps.getRFMGroups(entityId);

      /**
       * When no ECs group is available, API returns null for each group. We will set them to 0.
       * When ECs group is available, we will sort the keys for each group - API returns from 1 to 5
       * we will turn them backwards - from 5 to 1.
       * */
      if (!rfmGroup.frequency) {
        rfmGroup.frequency = {
          F5: 0,
          F4: 0,
          F3: 0,
          F2: 0,
          F1: 0,
        };
      } else {
        rfmGroup.frequency = sortKeysRFMGroup(rfmGroup.frequency);
      }

      if (!rfmGroup.recency) {
        rfmGroup.recency = {
          R5: 0,
          R4: 0,
          R3: 0,
          R2: 0,
          R1: 0,
        };
      } else {
        rfmGroup.recency = sortKeysRFMGroup(rfmGroup.recency);
      }

      if (!rfmGroup.monetary) {
        rfmGroup.monetary = {
          M5: 0,
          M4: 0,
          M3: 0,
          M2: 0,
          M1: 0,
        };
      } else {
        rfmGroup.monetary = sortKeysRFMGroup(rfmGroup.monetary);
      }

      setRfmGroup(rfmGroup);
    } catch (error) {
      toast.error(ERROR_MESSAGES.ENGAGEMENT_RFM_GROUP_REPORT);
      setRfmGroup(new RFMGroupModel());
    }
  };

  useEffect(() => {
    getRFMGroupReport();

    return () => setRfmGroup(null);
  }, [selectedClient, entityId]);

  return rfmGroup;
};

import React, { useContext, useMemo, useState } from "react";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../../providers/ApiProvider";
import FootballFantasyFeatureModel from "../../../../models/features/fantasy/FootballFantasyFeature";
import Enabled from "../Common/Enabled";
import Spinner from "../../../Spinner/Spinner";
import UpdateButton from "../../../Buttons/UpdateButton";
import {
  extractEntityIds,
  extractUnselectedCompetitions,
  remapKeysToSpecificCase,
} from "../../../../helpers/helpers";
import FantasyCoefficients from "./FantasyCoefficients";
import FantasyPlayers from "./FantasyPlayers";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import { toast } from "react-toastify";
import MatchQuizCompetitionsWhitelist from "../FeaturesMatchQuiz/MatchQuizCompetitionsWhitelist/MatchQuizCompetitionsWhitelist";
import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";

type FeaturesFantasyProps = {
  feature: FootballFantasyFeatureModel;
  fullCoverageCompetitions: CompetitionBasicModel[];
  isFeaturesEdited: { current: boolean };
};

const FeaturesFantasy: React.FC<FeaturesFantasyProps> = (props) => {
  const extractCompetitionIds = extractEntityIds(
    props.feature.competitionsWhitelist
  );

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const [copyFeature, setCopyFeature] = useState<FootballFantasyFeatureModel>(
    props.feature
  );
  const [competitionIds, setCompetitionIds] = useState<string[]>(
    extractCompetitionIds
  );
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const unselectedCompetitions = useMemo(() => {
    return extractUnselectedCompetitions(
      props.fullCoverageCompetitions,
      competitionIds
    );
  }, [competitionIds]);

  const onChangeAddPoints = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCopyFeature((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const onChangeCoefficients = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCopyFeature((prevState) => ({
      ...prevState,
      coefficients: {
        ...prevState.coefficients,
        [name]: Number(value),
      },
    }));
  };

  const onChangePlayers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCopyFeature((prevState) => ({
      ...prevState,
      players: {
        ...prevState.players,
        [name]: Number(value),
      },
    }));
  };

  const updateFeature = async () => {
    setIsLoading(true);
    const requestBody = {
      enabled: copyFeature.enabled,
      addPointsToProfileTotal: copyFeature.addPointsToProfileTotal,
      competitions_whitelist: competitionIds,
      coefficients: copyFeature.coefficients,
      players: copyFeature.players,
    };
    const finalizeRequestBody = remapKeysToSpecificCase(requestBody, "snake");

    try {
      await clientHttps.updateFeaturesForClient(
        finalizeRequestBody,
        FansUnitedFeatures.FOOTBALL_FANTASY
      );
      toast.success(SUCCESS_MESSAGES.FEATURES_FOOTBALL_FANTASY_UPDATE);
      props.isFeaturesEdited.current = !props.isFeaturesEdited.current;
    } catch (e) {
      console.error(e);
      toast.error(ERROR_MESSAGES.FOOTBALL_FANTASY_FEATURE_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      {copyFeature ? (
        <>
          <Enabled value={copyFeature.enabled} />
          <div className="mb-4 border border-gray-200 rounded-lg p-2 md:w-6/12 bg-blueGray-50">
            <label className="font-bold cursor-pointer">
              <input
                name="addPointsToProfileTotal"
                type="checkbox"
                className="mr-1 cursor-pointer"
                onChange={onChangeAddPoints}
              />{" "}
              Add points to profile's total points
            </label>
            <p className="mb-2 text-xs">
              The calculated points (coefficients multiplied by player's
              statistics) can be added to the profile's statistics e.g. total
              points
            </p>
          </div>
          <div className="mb-4 grid md:grid-cols-2 grid-cols-1 gap-2">
            <FantasyCoefficients
              coefficients={copyFeature.coefficients}
              onChange={onChangeCoefficients}
            />
            <FantasyPlayers
              players={copyFeature.players}
              onChange={onChangePlayers}
            />
          </div>
          <MatchQuizCompetitionsWhitelist
            title={"Competitions whitelist"}
            description={
              "Only matches in the following competitions are allowed for the creation of Football Fantasy predictions. Keep in mind all competitions in this list need to be full-coverage ones"
            }
            loadedCompetitions={copyFeature.competitionsWhitelist}
            setCompetitionIds={setCompetitionIds}
            unselectedCompetitions={unselectedCompetitions}
            setDisabledButton={setDisabledButton}
            maximumCompetitions={100}
          />
          <UpdateButton
            label={"Update"}
            onClick={updateFeature}
            isLoading={isLoading}
            disableButton={disabledButton}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default FeaturesFantasy;

import React, { useRef } from "react";
import { useClassicQuizFeatures } from "../../../../hooks/features/useClassicQuizFeatures";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import FeaturesClassicQuiz from "./FeaturesClassicQuiz";

const FeaturesClassicQuizPage: React.FC = () => {
  // Use to trigger useClassicQuizFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const feature = useClassicQuizFeatures(isFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Classic Quiz"}
      description={"This is Classic Quiz configuration for client: "}
    >
      {feature ? (
        <FeaturesClassicQuiz
          feature={feature}
          isFeaturesEdited={isFeaturesEdited}
        />
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesClassicQuizPage;

export type InfrastructureType =
  | "DEV"
  | "LOCAL"
  | "STAGE"
  | "PROD"
  | "PROD_WATG";

export default class ClientsBasicInfo {
  public id: string = "";
  public name: string = "";
  public infrastructure: InfrastructureType = "PROD";
}

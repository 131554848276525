import React from "react";
import {
  MauVsRegistrationBreakdown,
  useMauVsRegistrations,
} from "../../../../hooks/useMauVsRegistrations";
import DatasetChart from "../../../../models/Reports/DatasetChart";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import LineChart from "../Common/LineChart";
import ReportInfo from "../Common/ReportInfo";
import { chartLabels } from "./MonthlyActiveUsers";

const chartDataset = (mauVsRegistrations: MauVsRegistrationBreakdown[]) => {
  const chartMauData = mauVsRegistrations
    .map((value: MauVsRegistrationBreakdown) => value.activeUsers)
    .reverse();
  const chartRegistrationData = mauVsRegistrations
    .map((value: MauVsRegistrationBreakdown) => value.registrations)
    .reverse();

  return [
    constructDatasetChart(chartMauData, "MAU"),
    constructDatasetChart(chartRegistrationData, "Registrations"),
  ];
};

const constructDatasetChart = (
  chartData: number[],
  chartType: "MAU" | "Registrations"
) => {
  const datasetChart = new DatasetChart();
  const chartColor = chartType === "MAU" ? "#0066cc" : "#ed7e17";

  datasetChart.fill = false;
  datasetChart.data = chartData;
  datasetChart.label = chartType;
  datasetChart.backgroundColor = chartColor;
  datasetChart.borderColor = chartColor;

  return datasetChart;
};

const MauVsRegistrations: React.FC = () => {
  const mauVsRegistrations = useMauVsRegistrations();

  return (
    <CardReport>
      <ReportInfo
        title={"Monthly Active Users (MAU) vs Registrations"}
        description={
          "This is a breakdown of your new registrated users and users who were active in particular month. An active user is anyone who has used their own personal authentication token in particular month. This, for instance, can be for placing a prediction, updating interests or even fetching their own profile. Registrations are counted from the beginning until and including the specific month."
        }
      />
      {mauVsRegistrations ? (
        <LineChart
          labels={chartLabels(mauVsRegistrations)}
          datasets={chartDataset(mauVsRegistrations)}
          isMau={true}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default MauVsRegistrations;

import ReportingHttps from "../../services/https/ReportingHttps";
import ReportingBuilder from "../reports/ReportingBuilder";

export default class StatsBuilder extends ReportingBuilder {
  private statsMap: Map<string, number> = new Map<string, number>();

  constructor(https: ReportingHttps) {
    super(https);
  }

  public build = () => {
    return Promise.all([
      this.buildTotalUsers(),
      this.buildAverageFollows(),
      this.buildTotalRequests(),
      this.buildTotalPredictions()
    ]).then((responses: any[]) => {
      this.statsMap.set("TOTAL_USERS", responses[0]);
      this.statsMap.set("AVERAGE_FOLLOWS", responses[1]);
      this.statsMap.set("TOTAL_REQUESTS", responses[2]);
      this.statsMap.set("PREDICTIONS_PERIOD", responses[3]);

      return this.statsMap;
    });
  };

  private buildTotalUsers = async () => {
    const { all } = await this.https.getTotalUsers();

    return all;
  };

  private buildTotalPredictions = async () => {
    const { total } = await this.https.getPredictionsForPeriod();

    return total.all;
  };

  private buildAverageFollows = async () => {
    const { average } = await this.https.getAverageFollows();

    return average;
  };

  private buildTotalRequests = async () => {
    const { total } = await this.https.getTotalRequests();

    return total.count;
  };
}

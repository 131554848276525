import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/styles/tailwind.css";
import Main from "./layouts/Main";
import AuthProvider from "./providers/AuthProvider";
import { ToastContainer } from "react-toastify";
import { StaffAccessProvider } from "./providers/StaffAccessProvider";
import { GlobalStatesProvider } from "./providers/GlobalStatesProvider";
import { UserAccessPagesProvider } from "./providers/UserAccessPagesProvider";
import { ApiProvider } from "./providers/ApiProvider";

ReactDOM.render(
  <AuthProvider>
    <StaffAccessProvider>
      <GlobalStatesProvider>
        <ApiProvider>
          <UserAccessPagesProvider>
            <BrowserRouter>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                limit={3}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
              />
              <Switch>
                <Route path="/" component={Main} />
                <Redirect from="*" to="/dashboard" />
              </Switch>
            </BrowserRouter>
          </UserAccessPagesProvider>
        </ApiProvider>
      </GlobalStatesProvider>
    </StaffAccessProvider>
  </AuthProvider>,
  document.getElementById("root")
);

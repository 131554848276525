import React, { useContext, useState } from "react";
import EngagementCoefficientsConfiguration, {
  RecencyGroupInterface,
} from "../../../../models/Clients/EngagementCoefficientsConfiguration/EngagementCoefficientsConfiguration";
import Enabled from "../Common/Enabled";
import ECRetention from "./ECRetention/ECRetention";
import ECRecencyConfig from "./ECRecencyConfig/ECRecencyConfig";
import ECUserSegments from "./ECUserSegments/ECUserSegments";
import UserSegments from "../../../../models/Clients/EngagementCoefficientsConfiguration/UserSegments";
import ECEntitiesWhitelist from "./ECEntitiesWhitelist/ECEntitiesWhitelist";
import { BoundRangeInterface } from "../../../../models/Clients/EngagementCoefficientsConfiguration/CriteriaList";
import { remapKeysToSpecificCase } from "../../../../helpers/helpers";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../../enums/enums";
import UpdateButton from "../../../Buttons/UpdateButton";
import { ApiContext } from "../../../../providers/ApiProvider";

type EngagementCoefficientsInfoProps = {
  config: EngagementCoefficientsConfiguration;
  whitelistEntities: any[];
};

const EngagementCoefficientsInfo: React.FC<EngagementCoefficientsInfoProps> = ({
  config,
  whitelistEntities,
}) => {
  const [newConfig, setNewConfig] =
    useState<EngagementCoefficientsConfiguration>(config);
  const [competitionIds, setCompetitionIds] = useState<string[]>(
    config.engagementConfig.whitelist.filter((id: string) =>
      id.startsWith("fb:c:")
    )
  );
  const [teamIds, setTeamIds] = useState<string[]>(
    config.engagementConfig.whitelist.filter((id: string) =>
      id.startsWith("fb:t:")
    )
  );
  const [playerIds, setPlayerIds] = useState<string[]>(
    config.engagementConfig.whitelist.filter((id: string) =>
      id.startsWith("fb:p:")
    )
  );

  const { clientHttps } = useContext(ApiContext);
  const { setIsLoading, isLoading } = useContext(GlobalStatesContext);

  const onChangeRecencyGroups = (id: number, value: number) => {
    const copyCurrentConfig = { ...newConfig };
    const recencyGroup = copyCurrentConfig.recencyGroups.find(
      (recency: RecencyGroupInterface) => recency.id === id
    );
    recencyGroup.period = value;
    setNewConfig(copyCurrentConfig);
  };

  const onChangeTimeFrame = (value: number) => {
    const copyCurrentConfig = { ...newConfig };
    copyCurrentConfig.engagementConfig.timeFrameInMonths = value;
    setNewConfig(copyCurrentConfig);
  };

  const updateConfig = async () => {
    setIsLoading(true);
    delete newConfig.enabled;
    const whitelist = [...competitionIds, ...teamIds, ...playerIds];
    newConfig.engagementConfig.whitelist = whitelist;
    const requestBody = remapKeysToSpecificCase(newConfig, "snake");

    try {
      await clientHttps.updateEngagementCoefficientsConfig(requestBody);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.ENGAGEMENT_COEFFICIENTS_CONFIG_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Enabled value={config.enabled} />
      <div className="mb-2 border border-blueGray-200 rounded-lg p-2 bg-blueGray-50">
        <ECUserSegments userSegments={config.userSegments} />
        <ECRecencyConfig
          recencyGroups={config.recencyGroups}
          onChange={onChangeRecencyGroups}
        />
        <ECEntitiesWhitelist
          whitelistEntities={whitelistEntities}
          setCompetitionIds={setCompetitionIds}
          setTeamIds={setTeamIds}
          setPlayerIds={setPlayerIds}
        />
        <ECRetention
          timeFrameInMonths={config.engagementConfig.timeFrameInMonths}
          onChange={onChangeTimeFrame}
        />
        <UpdateButton
          label={"Update"}
          isLoading={isLoading}
          onClick={updateConfig}
        />
      </div>
    </>
  );
};

export default EngagementCoefficientsInfo;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import {
  extractEntityIds,
  remapKeysToSpecificCase,
} from "../../../../helpers/helpers";
import TopXRequestBody from "../../../../models/Clients/featuresRequestBodies/topX/TopXRequestBody";
import TopXFeaturesModel from "../../../../models/features/topX/TopXFeaturesModel";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import UpdateButton from "../../../Buttons/UpdateButton";
import Enabled from "../Common/Enabled";
import TopXCompetitionsWhitelist from "./TopXCompetitionsWhitelist/TopXCompetitionsWhitelist";
import QuantityFixtures from "./QuantityFixtures/QuantityFixtures";
import { ApiContext } from "../../../../providers/ApiProvider";

type FeaturesTopXProps = {
  topXFeatures: TopXFeaturesModel;
  fullCoverageCompetitions: CompetitionBasicModel[];
};

/**
 * Titles for QuantityFixtures component
 */
enum QuantityFixturesTitles {
  MIN_FIXTURES = "Minimum fixtures",
  MAX_FIXTURES = "Maximum fixtures",
}

const FeaturesTopX: React.FC<FeaturesTopXProps> = ({
  topXFeatures,
  fullCoverageCompetitions,
}) => {
  const validMinFixtures = 1;
  const validMaxFixtures = 20;
  const extractCompetitionIds = extractEntityIds(
    topXFeatures.competitionsWhitelist
  );

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const [minFixtures, setMinFixtures] = useState<number>(
    topXFeatures.minFixtures
  );
  const [maxFixtures, setMaxFixtures] = useState<number>(
    topXFeatures.maxFixtures
  );
  const [competitionIds, setCompetitionIds] = useState<string[]>(
    extractCompetitionIds
  );

  const incrementFixtures = (title: string) => {
    if (title === QuantityFixturesTitles.MIN_FIXTURES) {
      if (minFixtures === validMaxFixtures) return;
      setMinFixtures((prevState) => prevState + 1);
    } else if (title === QuantityFixturesTitles.MAX_FIXTURES) {
      if (maxFixtures === validMaxFixtures) return;
      setMaxFixtures((prevState) => prevState + 1);
    }
  };

  const decrementFixture = (title: string) => {
    if (title === QuantityFixturesTitles.MIN_FIXTURES) {
      if (minFixtures === validMinFixtures) return;
      setMinFixtures((prevState) => prevState - 1);
    } else if (title === QuantityFixturesTitles.MAX_FIXTURES) {
      if (maxFixtures === validMinFixtures) return;
      setMaxFixtures((prevState) => prevState - 1);
    }
  };

  const updateTopXFeatures = async () => {
    setIsLoading(true);
    const requestBody = {
      minFixtures,
      maxFixtures,
      competitionsWhitelist: competitionIds,
    };
    const requestBodyInSnakeCase = remapKeysToSpecificCase(
      requestBody,
      "snake"
    ) as TopXRequestBody;

    try {
      await clientHttps.updateFeaturesForClient(
        requestBodyInSnakeCase,
        FansUnitedFeatures.TOP_X
      );
      toast.success(SUCCESS_MESSAGES.FEATURES_TOP_X_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FEATURES_TOP_X_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Enabled value={topXFeatures.enabled} />
      <div className="md:grid md:grid-cols-2 md:gap-2">
        <QuantityFixtures
          title={QuantityFixturesTitles.MIN_FIXTURES}
          description={
            "Minimum fixtures that are required to create a Top X game instance."
          }
          value={minFixtures}
          incrementFixture={incrementFixtures}
          decrementFixture={decrementFixture}
        />
        <QuantityFixtures
          title={QuantityFixturesTitles.MAX_FIXTURES}
          description={
            "Maximum fixtures that are acceptable to create a Top X game instance."
          }
          value={maxFixtures}
          incrementFixture={incrementFixtures}
          decrementFixture={decrementFixture}
        />
      </div>
      <TopXCompetitionsWhitelist
        title={"Competitions whitelist"}
        description={
          "Only matches in the following competitions are allowed for the creation of Top X games. The maximum number of competitions are 15."
        }
        loadedCompetitions={topXFeatures.competitionsWhitelist}
        setCompetitionIds={setCompetitionIds}
      />
      <UpdateButton
        label={"Update top x features"}
        onClick={updateTopXFeatures}
        isLoading={isLoading}
      />
    </>
  );
};

export default FeaturesTopX;

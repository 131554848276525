import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import DiscussionsTop100Model from "../../../models/Reports/Discussions/DiscussionsTop100Model";

export const useTop100Discussions = (period) => {
  const [top100Discussions, setTop100Discussions] =
    useState<DiscussionsTop100Model[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getTop100Contributors = async () => {
    try {
      const top100Discussions = await api.reportingHttps.getTop100Discussions(
        period
      );
      setTop100Discussions(top100Discussions);
    } catch (error) {
      toast.error(ERROR_MESSAGES.DISCUSSIONS_TOP_100_REPORT);
      setTop100Discussions([]);
    }
  };

  useEffect(() => {
    getTop100Contributors();

    return () => setTop100Discussions(null);
  }, [selectedClient]);

  return top100Discussions;
};

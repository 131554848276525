import React from "react";
import { DefaultAssetsInterface } from "../../../../../models/Clients/FootballSettingsConfigModel";
import { Entity } from "../../../../../types/types";
import FileUploader from "../../../../Buttons/FileUploader";

type DefaultAssetsProps = {
  defaultValue: DefaultAssetsInterface;
  onChange: (entity: Entity, value: string) => void;
};

const DefaultAssets: React.FC<DefaultAssetsProps> = ({
  defaultValue,
  onChange,
}) => {
  return (
    <div className="mb-2 border border-blueGray-300 rounded-lg p-2 bg-blueGray-100">
      <h3 className="font-bold text-xl mb-2">Default assets:</h3>
      <p className="mb-2">Competition logo</p>
      <div className="flex items-center mb-2">
        <input
          className="w-full mr-1"
          type="text"
          value={defaultValue.competitionLogo || ""}
          onChange={(event) => onChange("competitions", event.target.value)}
        />
        <FileUploader
          type={"default_competition_logo"}
          id={""}
          onChangeImages={(url) => onChange("competitions", url)}
        />
      </div>
      <p className="mb-2">Team logo</p>
      <div className="flex items-center mb-2">
        <input
          className="mr-1 w-full"
          type="text"
          value={defaultValue.teamLogo || ""}
          onChange={(event) => onChange("teams", event.target.value)}
        />
        <FileUploader
          type={"default_team_logo"}
          id={""}
          onChangeImages={(url) => onChange("teams", url)}
        />
      </div>
      <p className="mb-2">Player headshot</p>
      <div className="flex items-center mb-2">
        <input
          className="mr-1 w-full"
          type="text"
          value={defaultValue.playerHeadshot || ""}
          onChange={(event) => onChange("players", event.target.value)}
        />
        <FileUploader
          type={"default_player_headshot"}
          id={""}
          onChangeImages={(url) => onChange("players", url)}
        />
      </div>
    </div>
  );
};

export default DefaultAssets;

import React, { Dispatch, SetStateAction } from "react";
import TopFollowsFootballEntitiesModel from "../../../../../models/Reports/FootballCompetitionsFollows/TopFollowsFootballEntitiesModel";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { Entity } from "../../../../../types/types";
import TableHeaderTopFollows from "./TableHeaderTopFollows";
import TableRowsTopFollows from "./TableRowsTopFollows";

type TopEntitiesProps = {
  topFollowsFootballEntities: TopFollowsFootballEntitiesModel;
  entity: Entity;
  setEntityIds: Dispatch<SetStateAction<string[]>>;
  setEntities: Dispatch<SetStateAction<SelectOption[]>>;
};

const TopEntities: React.FC<TopEntitiesProps> = ({
  topFollowsFootballEntities,
  entity,
  setEntityIds,
  setEntities,
}) => {
  const showEntityType = entity === "competitions" ? true : false;
  const showInfoThatPlayersDontMatch = entity === "players" ? false : true;

  return (
    <div className="mb-6 border border-blueGray-200 rounded p-2">
      <h2 className="text-center mb-4 font-bold text-xl">{`Top ${topFollowsFootballEntities.models.size} followed ${entity}`}</h2>
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          <TableHeaderTopFollows
            showSuccessRatePercent={showInfoThatPlayersDontMatch}
          />
          <tbody>
            {topFollowsFootballEntities.topEntities?.breakdown.map(
              (followBreakdown: { [key: string]: any }) => (
                <TableRowsTopFollows
                  key={`table-row-top-folllow-competition-${followBreakdown.id}`}
                  followBreakdown={followBreakdown}
                  models={topFollowsFootballEntities.models}
                  successRates={topFollowsFootballEntities.successRates}
                  setEntityIds={setEntityIds}
                  setEntities={setEntities}
                  showEntityType={showEntityType}
                  showInfoThatPlayersDontMatch={showInfoThatPlayersDontMatch}
                />
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopEntities;

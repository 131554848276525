import React, { PropsWithChildren } from "react";

const Auth: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative bg-blueGray-100 h-screen">
      <div className="relative bg-brandColor pt-32 pb-32">
        <div className="px-4 mx-auto w-full"></div>
      </div>
      <div className="px-4 mx-auto w-full -m-24">{children}</div>
      <footer className="block mt-32">
        <div className="container mx-auto">
          <hr className="mb-4 border-b-1 border-blueGray-200" />
          <div className="text-sm text-blueGray-500 font-semibold py-1 text-center">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://fansunitedmedia.com/"
              className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
              target="_blank"
              rel="noreferrer"
            >
              Fans United
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Auth;

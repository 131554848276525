import React, { useContext, useEffect } from "react";
import NavbarHeader from "../components/Navbars/NavbarHeader/NavbarHeader";
import Sidebar from "../components/Sidebar/Sidebar";
import { AuthContext } from "../providers/AuthProvider";
import Login from "../views/auth/Login";
import Auth from "./Auth";
import { StaffAccessContext } from "../providers/StaffAccessProvider";
import MainRouting from "./MainRouting";
import MainHeader from "../components/Headers/MainHeader";
import ClientsBasicInfo from "../models/Clients/ClientsBasicInfo";
import ConfirmationalModal from "../components/Modals/ConfirmationalModal";
import {
  ERROR_MESSAGES,
  PARAGRAPH_MESSAGES,
  SUCCESS_MESSAGES,
} from "../enums/enums";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner/Spinner";
import EngagementSegmentsBreakdownModal from "../components/Modals/EngagementSegmentsBreakdownModal";
import { ApiContext } from "../providers/ApiProvider";

const Main: React.FC = () => {
  const user = useContext(AuthContext);
  const staffClients = useContext(StaffAccessContext);
  const {
    selectedClient,
    staffId,
    isModalConfirmActionSubmitted,
    setShowModal,
    setIsLoading,
    setSelectedClient,
  } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const handleOnChangeClientId = (selectedClient: ClientsBasicInfo) =>
    setSelectedClient(selectedClient);

  const deleteStaffMember = async () => {
    setIsLoading(true);

    try {
      await clientHttps?.deleteStaffMember(staffId);
      toast.success(SUCCESS_MESSAGES.DELETE_STAFF_MEMBER);
      isModalConfirmActionSubmitted.current = true;
      setShowModal(false);
    } catch (error) {
      console.error(error);
      toast.error(`${ERROR_MESSAGES.DELETE_STAFF_MEMBER} ${selectedClient.id}`);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (staffClients && staffClients.length > 0) {
      setSelectedClient(staffClients[0]);
    }

    return () => setSelectedClient(new ClientsBasicInfo());
  }, [staffClients]);

  if (!user) {
    return (
      <Auth>
        <Login />
      </Auth>
    );
  }

  return (
    <>
      {selectedClient.id ? (
        <>
          <Sidebar onChange={handleOnChangeClientId} />
          <ConfirmationalModal
            title={"Delete staff member"}
            content={PARAGRAPH_MESSAGES.DELETE_STAFF_MEMBER_PARAGRAPH}
            declineButtonLabel={"Cancel"}
            confirmButtonLabel={"Delete"}
            confirmAction={deleteStaffMember}
          />
          <EngagementSegmentsBreakdownModal />
          <div className="relative md:ml-64 bg-blueGray-100">
            <NavbarHeader onChange={handleOnChangeClientId} />
            {/* Header */}
            <MainHeader />
            <MainRouting />
          </div>
        </>
      ) : (
        <Spinner isInitial={true} />
      )}
    </>
  );
};

export default Main;

interface EngagementGenderBreakdown {
  unspecified: number;
  female: number;
  male: number;
}

export interface MainBreakdown {
  users: number;
  byGender: EngagementGenderBreakdown;
  byCountry: Map<string, number>;
}

export default class EngagementSegmentsModel {
  breakdown: Map<string, MainBreakdown> = new Map();
  total: MainBreakdown = {
    users: 0,
    byGender: {
      unspecified: 0,
      female: 0,
      male: 0,
    },
    byCountry: new Map(),
  };
}

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import ClientsBasicInfo from "../models/Clients/ClientsBasicInfo";
import { AuthContext } from "./AuthProvider";
import axios from "axios";
import { getToken } from "../sdks/firebase/getToken";
import { testingClients } from "../constants/constants";

const constructClientsBasicInfo = (response: any[]) => {
  const sortedClients = sortClients(response);

  return sortedClients.map((value: any) => {
    const model = new ClientsBasicInfo();

    model.id = value.id;
    model.name = value.name;
    model.infrastructure = value.infrastructure;

    return model;
  });
};

const sortClients = (clients: any[]) => {
  return clients.sort((a, b) => {
    const indexA = testingClients.indexOf(a.id);
    const indexB = testingClients.indexOf(b.id);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    }

    return 0;
  });
};

const clientApiBaseUrl = () => {
  const environment =
    process.env.REACT_APP_MANAGEMENT_PORTAL_ENVIRONMENT || "prod";

  if (environment === "dev") {
    return "http://localhost:8070/v1";
  }

  return "https://client.fansunitedapi.com/v1";
};

const StaffAccessContext = createContext<ClientsBasicInfo[] | null>(null);

const StaffAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [staffClients, setStaffClients] = useState<ClientsBasicInfo[] | null>(
    null
  );

  const user = useContext(AuthContext);

  const getStaffAccess = async () => {
    try {
      const apiKey = process.env.REACT_APP_FANS_UNITED_API_KEY;
      const token = await getToken();
      const baseURL = clientApiBaseUrl();
      const url = `${baseURL}/profile/clients?key=${apiKey}`; // This request needs to be always to PROD
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const clientsBasicInfo = constructClientsBasicInfo(response.data.data);
      setStaffClients(clientsBasicInfo);
    } catch (error) {
      console.warn(`There was a problem fetching staff's clients`, error);
      toast.error(ERROR_MESSAGES.STAFF_CLIENTS);
      setStaffClients(null);
    }
  };

  useEffect(() => {
    if (user) getStaffAccess();
    return () => setStaffClients(null);
  }, [user]);

  return (
    <StaffAccessContext.Provider value={staffClients}>
      {children}
    </StaffAccessContext.Provider>
  );
};

export { StaffAccessContext, StaffAccessProvider };

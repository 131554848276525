import React, { PropsWithChildren } from "react";

const CardReport: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-24 shadow-lg rounded p-4">
      {children}
    </div>
  );
};

export default CardReport;

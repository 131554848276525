import React, { useState } from "react";
import PredictionsPerEntity from "../PredictionsPerEntity/PredictionsPerEntity";
import PredictionsPerMatch from "../PredictionsPerMatch/PredictionsPerMatch";
import PredictionsBreakdown from "./PredictionsBreakdown";
import EngagementThroughPredictions from "./PredictionsEngagement/EngagementThroughPredictions";
import PredictionsTabs from "./PredictionsTabs";

export type PredictionsReports = "breakdown" | "entities" | "matches" | "engagement";

const displayPredictionsUI = (predictionsReport: PredictionsReports) => {
  if (predictionsReport === "breakdown") {
    return <PredictionsBreakdown />;
  } else if (predictionsReport === "entities") {
    return <PredictionsPerEntity />;
  } else if (predictionsReport === "matches") {
    return <PredictionsPerMatch />;
  } else if (predictionsReport === "engagement") {
    return <EngagementThroughPredictions />;
  }
};

const Predictions: React.FC = () => {
  const [predictionsReport, setPredictionsReport] =
    useState<PredictionsReports>("breakdown");

  return (
    <div className="w-full px-4">
      <PredictionsTabs
        predictionsReport={predictionsReport}
        setPredictionsReport={setPredictionsReport}
      />
      {displayPredictionsUI(predictionsReport)}
    </div>
  );
};

export default Predictions;

import React from "react";
import Badge from "../Badge";
import VotingBadgesExtended from "../../../../../../../models/features/loyalty/Badges/Voting/VotingBadgesExtended";
import VotingBadgeRequirements from "./VotingBadgeRequirements";

type PollBadgeProps = {
  badge: VotingBadgesExtended;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
  removeExistingBadge: (uid: string) => void;
};

const PollBadge: React.FC<PollBadgeProps> = ({
  badge,
  onChangeBadges,
  removeExistingBadge,
}) => {
  return (
    <div className="flex flex-col p-2">
      <Badge
        badge={badge}
        onChangeBadges={onChangeBadges}
        removeExistingBadge={removeExistingBadge}
      />
      <VotingBadgeRequirements
        uid={badge.uid}
        requirements={badge.requirements}
        onChangeBadges={onChangeBadges}
      />
    </div>
  );
};

export default PollBadge;

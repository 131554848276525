import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import React from "react";
import { placeholders } from "../../../../../assets/placeholders/placeholders";
import {
  playerSummaryKeys,
  specialPlayerIdsTitles,
} from "../../../../../constants/constants";
import { upperCase } from "../../../../../helpers/helpers";
import FormattedNumber from "../../../../Common/FormattedNumber";

/**
 * Sorts the summary keys by their predictions
 * @param summary Market summary for a match received from Football API.
 * @returns List with sorted objects containing summary key and predictions.
 */

const sortSummaryKeysByPredictions = (summary: any, summaryKey: string) => {
  const arr: any[] = [];

  if (playerSummaryKeys.includes(summaryKey)) {
    Object.keys(summary).forEach((playerId: string) => {
      const predictions = summary[playerId].yes + summary[playerId].no;
      arr.push({ playerId, predictions });
    });

    arr.sort((a: any, b: any) => b.predictions - a.predictions);

    return arr;
  }

  Object.keys(summary).forEach((key: string) => {
    const predictions = summary[key];
    arr.push({ key, predictions });
  });

  arr.sort((a: any, b: any) => b.predictions - a.predictions);

  return arr;
};

const totalPredictions = (values: any[]) => {
  values = values.filter((value: any) => typeof value === "number");

  return values.reduce(
    (prevValue: number, currValue: number) => prevValue + currValue,
    0
  );
};

const isMarketSummaryEmpty = (summary: any) => {
  if (Object.keys(summary).length) {
    const values = Object.values(summary);

    const hasNumbers = values.every((value: any) => typeof value === "number");

    if (hasNumbers) {
      const total = totalPredictions(values);

      if (!total) return true;

      return false;
    } else {
      /* Here are the player markets summary. Their keys are the ids of players, and the values are no/yes object.
        When we are here, that guarantees that we have summary for player markets as well.
        */
      return false;
    }
  }

  return true;
};

const additionalTitle = (summaryValue: any, playerId: string) => {
  if (playerId === "NOBODY" || playerId === "OWN_GOAL") {
    return (
      <h4 className="text-blueGray-400 font-bold">
        {specialPlayerIdsTitles.get(playerId)}
      </h4>
    );
  }

  const playerClubTeam = summaryValue.model.teams.filter(
    (team: TeamBasicModel) => !team.national
  )[0];

  return (
    <div className="flex items-center">
      <div>
        <img
          src={
            summaryValue.model.assets && summaryValue.model.assets.headshot
              ? summaryValue.model.assets.headshot
              : placeholders.avatar
          }
          className="w-12 h-12 rounded-full mr-1"
        />
      </div>
      <div>
        <span className="text-blueGray-400 font-bold">
          {summaryValue.model.name}
        </span>
        <div className="flex items-center">
          <img
            src={
              playerClubTeam && playerClubTeam.assets && playerClubTeam.assets.logo
                ? playerClubTeam.assets.logo
                : placeholders.team
            }
            className="w-6 h-6 rounded-full mr-1"
          />
          <span className="text-blueGray-600 font-italic">
            {playerClubTeam ? playerClubTeam.name : 'No team'}
          </span>
        </div>
      </div>
    </div>
  );
};

type SummaryStatsProps = {
  statsName: string;
  summary: any;
  summaryKey: string;
};

const SummaryStats: React.FC<SummaryStatsProps> = ({
  statsName,
  summaryKey,
  summary,
}) => {
  if (isMarketSummaryEmpty(summary)) return null;

  const predictionPercent = (value: number, playerId?: string) => {
    if (!playerSummaryKeys.includes(summaryKey)) {
      const total = totalPredictions(Object.values(summary));

      return `${((value / total) * 100).toFixed(2)}%`;
    }

    const total = totalPredictions(Object.values(summary[playerId]));

    return `${((value / total) * 100).toFixed(2)}%`;
  };

  const playerPercent = (value: number) => {
    const total = Object.keys(summary)
      .map((key: string) => summary[key].yes + summary[key].no)
      .reduce(
        (prevValue: number, currValue: number) => prevValue + currValue,
        0
      );

    return `${((value / total) * 100).toFixed(2)}%`;
  };

  if (playerSummaryKeys.includes(summaryKey)) {
    const sortedPlayerSummaryKeys = sortSummaryKeysByPredictions(
      summary,
      summaryKey
    );
    // Summary stats for player markets
    return (
      <div className="flex flex-col p-4 min-w-0 break-words bg-white rounded mb-4 shadow-lg">
        <div className="w-full">
          <h3 className="text-blueGray-400 uppercase font-bold md:text-xs text-xxs mb-2">
            {statsName}
          </h3>
          <div className="md:grid md:grid-cols-2 md:gap-2">
            {sortedPlayerSummaryKeys.map(
              (value: { playerId: string; predictions: number }) => (
                <div
                  key={`summary-${summaryKey}-${value.playerId}`}
                  className="mb-4 border-b pb-4"
                >
                  {additionalTitle(summary[value.playerId], value.playerId)}
                  <div className="flex items-center">
                    <span className="font-semibold text-2xl text-blueGray-700 cursor-default mr-1">
                      <FormattedNumber
                        numberValue={totalPredictions(
                          Object.values(summary[value.playerId])
                        )}
                      />
                    </span>
                    <span className="font-semibold text-xl text-blueGray-700 cursor-default">
                      ({playerPercent(value.predictions)})
                    </span>
                  </div>
                  <div className="xl:grid xl:grid-cols-2 xl:gap-2">
                    {Object.keys(summary[value.playerId])
                      .filter((key: string) => key !== "model")
                      .map((key: string) => (
                        <p
                          key={`${summaryKey}-${value.playerId}-${key}`}
                          className="text-sm text-blueGray-400 font-bold mr-1"
                        >
                          {`${upperCase(key)}: ${
                            summary[value.playerId][key]
                          } (${predictionPercent(
                            summary[value.playerId][key],
                            value.playerId
                          )})`}
                        </p>
                      ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  const sortedSummaryKeys = sortSummaryKeysByPredictions(summary, summaryKey);

  return (
    <div className="flex flex-col p-4 min-w-0 break-words bg-white rounded mb-4 shadow-lg">
      <div className="relative lg:w-8/12 w-full pr-2 max-w-full flex-grow flex-1">
        <h3 className="text-blueGray-400 uppercase font-bold md:text-xs text-xxs">
          {statsName}
        </h3>
        <span className="font-semibold text-2xl text-blueGray-700 cursor-default">
          <FormattedNumber
            numberValue={totalPredictions(Object.values(summary))}
          />
        </span>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2">
        {sortedSummaryKeys.map(
          (value: { key: string; predictions: number }) => (
            <p
              key={`${summaryKey}-${value.key}`}
              className="text-sm text-blueGray-400 font-bold mr-1"
            >
              {`${upperCase(value.key)}: ${
                summary[value.key]
              } (${predictionPercent(summary[value.key])})`}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default SummaryStats;

import React from "react";
import KafkaWebHookCredentials from "../../../../../models/features/webhook/credentials/KafkaWebhookCredentials";
import { AllCredentialsFields } from "./WebhookCredentials";
import WebhookTooltip from "../WebhookTooltip";

type KafkaWebhookCredentialsComponentProps = {
  value: KafkaWebHookCredentials;
  onChange: (value: string, field: AllCredentialsFields) => void;
};

const KafkaWebhookCredentialsComponent: React.FC<
  KafkaWebhookCredentialsComponentProps
> = ({ value, onChange }) => {
  return (
    <>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Topic</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.topic}
            onChange={(event) => onChange(event.target.value, "topic")}
            type="text"
          />
          <WebhookTooltip tooltipType={"kafka"} property={"topic"} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Security Protocol</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.securityProtocol}
            onChange={(event) =>
              onChange(event.target.value, "securityProtocol")
            }
            type="text"
          />
          <WebhookTooltip tooltipType={"kafka"} property={"securityProtocol"} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">SASL Mechanism</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.saslMechanism}
            onChange={(event) => onChange(event.target.value, "saslMechanism")}
            type="text"
          />
          <WebhookTooltip tooltipType={"kafka"} property={"saslMechanism"} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Login Module</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.loginModule}
            onChange={(event) => onChange(event.target.value, "loginModule")}
            type="text"
          />
          <WebhookTooltip tooltipType={"kafka"} property={"loginModule"} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Request Required Acks</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.requestRequiredAcks}
            onChange={(event) =>
              onChange(event.target.value, "requestRequiredAcks")
            }
            type="text"
          />
          <WebhookTooltip
            tooltipType={"kafka"}
            property={"requestRequiredAcks"}
          />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Additional Properties</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.additionalProperties}
            onChange={(event) =>
              onChange(event.target.value, "additionalProperties")
            }
            type="text"
          />
          <WebhookTooltip
            tooltipType={"kafka"}
            property={"additionalProperties"}
          />
        </div>
      </div>
    </>
  );
};

export default KafkaWebhookCredentialsComponent;

import BadgesExtended from "../BadgesExtended";

export interface IPredictorBadgeRequirements {
  predictionsNumber: number;
  correctPredictions: number;
  points: number;
  fromDate: string;
  toDate: string;
}

export default class PredictorBadgesExtended extends BadgesExtended {
  public requirements: IPredictorBadgeRequirements = {
    predictionsNumber: 0,
    correctPredictions: 0,
    points: 0,
    fromDate: null,
    toDate: null,
  };
}

import { snakeCase, upperCase } from "lodash";
import React, { Dispatch, SetStateAction } from "react";

const tabs = [
  "Averages",
  "Top 100 contributors",
  "Top 100 discussions",
  "Discussions breakdown",
  "Posts breakdown",
];

type DiscussionsReportTabsProps = {
  tabValue: string;
  setTabValue: Dispatch<SetStateAction<string>>;
};

const DiscussionsReportTabs: React.FC<DiscussionsReportTabsProps> = ({
  tabValue,
  setTabValue,
}) => {
  return (
    <div
      className="relative grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-1 w-full mb-6"
      role="tablist"
    >
      {tabs.map((value: string, index: number) => (
        <div
          className="mb-2 md:mr-2 last:mr-0 flex-auto text-center"
          key={index}
        >
          <a
            className={
              "text-xs font-bold p-4 rounded block leading-normal cursor-pointer " +
              (tabValue === snakeCase(value)
                ? "bg-white text-brandColor"
                : "bg-gray-400 text-white")
            }
            onClick={() => setTabValue(snakeCase(value))}
            data-toggle="tab"
            role="tablist"
          >
            {upperCase(value)}
          </a>
        </div>
      ))}
    </div>
  );
};

export default DiscussionsReportTabs;

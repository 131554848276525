import BadgesExtended from "../BadgesExtended";

export interface IVotingBadgeRequirements {
  points: number;
  voteCount: number;
  entityId: string;
  entityType: string;
  entityModel: null;
}

export default class VotingBadgesExtended extends BadgesExtended {
  public requirements: IVotingBadgeRequirements = {
    points: 0,
    voteCount: 0,
    entityId: null,
    entityType: null,
    entityModel: null,
  };
}

import ExternalPointsModel from "./externalPoints/ExternalPointsModel";
import FootballFantasyFeatureModel from "./fantasy/FootballFantasyFeature";
import LoyaltyFeaturesModel from "./loyalty/LoyaltyFeaturesModel";
import MatchQuizFeaturesModel from "./matchQuiz/MatchQuizFeaturesModel";
import PredictorFeaturesModel from "./predictor/PredictorFeaturesModel";
import TopXFeaturesModel from "./topX/TopXFeaturesModel";
import WebhookBaseModel from "./webhook/WebhookBaseModel";

export default class ClientFeaturesModel {
  predictor: PredictorFeaturesModel;
  topX: TopXFeaturesModel;
  matchQuiz: MatchQuizFeaturesModel;
  loyalty: LoyaltyFeaturesModel;
  externalPoints: ExternalPointsModel;
  webhook: WebhookBaseModel;
  footballFantasy: FootballFantasyFeatureModel;
}

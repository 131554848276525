import React from "react";
import {
  TopFollowedEntitiy,
  TopFollowedSports,
} from "../../../../models/Reports/FollowedEntitiesModel";
import TableHeader from "./TableHeader";
import TopFollowedEntityRow from "./TopFollowedEntityRow";

type TopFollowedEntityProps = {
  entity: string;
  topFollowedEntity: TopFollowedEntitiy[] | TopFollowedSports[];
};

export const cellHeader =
  "align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  bg-blueGray-50 text-blueGray-500 border-blueGray-100";

const topFollowedEntitiesTitle: any = {
  topFollowedCompetitions: "Top 5 followed competitions",
  topFollowedTeams: "Top 5 followed teams",
  topFollowedPlayers: "Top 5 followed players",
  topFollowedSports: "Top 5 followed sports",
};

const TopFollowedEntity: React.FC<TopFollowedEntityProps> = ({
  entity,
  topFollowedEntity,
}) => {
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white h-full">
      <TableHeader title={topFollowedEntitiesTitle[entity]} entityKey={entity} />
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th className={`${cellHeader} text-left px-6`}>Name</th>
              {entity !== "topFollowedSports" ? (
                <th className={`${cellHeader} text-left px-6`}>Country</th>
              ) : null}
              <th
                className={`${cellHeader} ${
                  entity === "topFollowedSports" ? "text-right px-6" : ""
                }`}
              >
                Followers
              </th>
            </tr>
          </thead>
          <tbody>
            {topFollowedEntity.map(
              (value: TopFollowedEntitiy | TopFollowedSports) => (
                <TopFollowedEntityRow
                  key={`${value.name} + ${Math.random()}`}
                  value={value}
                  entity={entity}
                />
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopFollowedEntity;

import { IPrivateLeaguesBreakdownTotal } from "./PrivateLeaguesBreakdownModel";

export interface IBasicPrivateLeagueUsers {
  usersInAtLeastOne: number;
  usersInAtLeastTwo: number;
  usersInAtLeastThree: number;
  usersInAtLeastFive: number;
  usersInAtLeastTen: number;
}

export interface IFullPrivateLeagueUsers extends IBasicPrivateLeagueUsers {
  averageUsersInLeague: number;
}

export interface IPrivateLeaguesUsersBreakdown {
  private: IFullPrivateLeagueUsers;
  oneVsOne: IBasicPrivateLeagueUsers;
}

export default class PrivateLeaguesUsersModel {
  breakdown: IPrivateLeaguesUsersBreakdown = {
    private: {
      usersInAtLeastOne: 0,
      usersInAtLeastTwo: 0,
      usersInAtLeastThree: 0,
      usersInAtLeastFive: 0,
      usersInAtLeastTen: 0,
      averageUsersInLeague: 0,
    },
    oneVsOne: {
      usersInAtLeastOne: 0,
      usersInAtLeastTwo: 0,
      usersInAtLeastThree: 0,
      usersInAtLeastFive: 0,
      usersInAtLeastTen: 0,
    },
  };
  total: IPrivateLeaguesBreakdownTotal = {
    all: 0,
    private: 0,
    oneVsOne: 0,
  };
}

interface RecencyInterface {
  R5: number;
  R4: number;
  R3: number;
  R2: number;
  R1: number;
}

interface FrequencyInterface {
  F5: number;
  F4: number;
  F3: number;
  F2: number;
  F1: number;
}

interface MonetaryInterface {
  M5: number;
  M4: number;
  M3: number;
  M2: number;
  M1: number;
}

export default class RFMGroupModel {
  recency: RecencyInterface = {
    R5: 0,
    R4: 0,
    R3: 0,
    R2: 0,
    R1: 0,
  };
  frequency: FrequencyInterface = {
    F5: 0,
    F4: 0,
    F3: 0,
    F2: 0,
    F1: 0,
  };
  monetary: MonetaryInterface = {
    M5: 0,
    M4: 0,
    M3: 0,
    M2: 0,
    M1: 0,
  };
}

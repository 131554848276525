import React, { Dispatch, SetStateAction } from "react";
import { EntityFollowsTabsEnum } from "./EntityFollows";

type EntityFollowsTabsProps = {
  openTab: string;
  setOpenTab: Dispatch<SetStateAction<string>>;
};

const EntityFollowsTabs: React.FC<EntityFollowsTabsProps> = ({
  openTab,
  setOpenTab,
}) => {
  return (
    <ul className="flex mb-0 list-none flex-wrap py-3 flex-row" role="tablist">
      <li className="mb-2 mr-2 flex-auto text-center">
        <a
          className={
            "text-xs font-bold uppercase p-4 rounded block leading-normal text-white " +
            (openTab === EntityFollowsTabsEnum.FOLLOWERS
              ? "bg-brandColor"
              : "bg-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(EntityFollowsTabsEnum.FOLLOWERS);
          }}
          data-toggle="tab"
          href="#markets"
          role="tablist"
        >
          {EntityFollowsTabsEnum.FOLLOWERS}
        </a>
      </li>
      <li className="mb-2 flex-auto text-center">
        <a
          className={
            "text-xs font-bold uppercase p-4 rounded block leading-normal text-white " +
            (openTab === EntityFollowsTabsEnum.MATCHES
              ? "bg-brandColor"
              : "bg-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(EntityFollowsTabsEnum.MATCHES);
          }}
          data-toggle="tab"
          href="#users"
          role="tablist"
        >
          {EntityFollowsTabsEnum.MATCHES}
        </a>
      </li>
    </ul>
  );
};

export default EntityFollowsTabs;

import React, { useContext } from "react";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { upperCase } from "../../helpers/helpers";
import FormattedNumber from "../Common/FormattedNumber";

const EngagementSegmentsBreakdownModal: React.FC = () => {
  const {
    engagementSegmentsBreakdown,
    showEngagementBreakdownModal,
    setShowEngagementBreakdownModal,
  } = useContext(GlobalStatesContext);

  return (
    <>
      {showEngagementBreakdownModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto absolute z-50 top-0 right-0 bottom-0 left-0 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4">
                {/*header*/}
                <h3 className="text-2xl font-semibold p-2 border-b border-solid border-blueGray-200">
                  Breakdown by gender and country
                </h3>
                {/*body*/}
                <div className="relative whitespace-nowrap p-2 text-blueGray-500">
                  <p className="text-lg my-2">By gender:</p>
                  <ul className="list-disc">
                    <li className="ml-6 mb-2">
                      <div className="flex items-center">
                        <span className="mr-1">Male:</span>
                        <div className="font-bold">
                          &nbsp;
                          <FormattedNumber
                            numberValue={
                              engagementSegmentsBreakdown.byGender.male
                            }
                          />
                        </div>
                      </div>
                    </li>
                    <li className="ml-6 mb-2">
                      <div className="flex items-center">
                        <span className="mr-1">Female:</span>
                        <div className="font-bold">
                          <FormattedNumber
                            numberValue={
                              engagementSegmentsBreakdown.byGender.female
                            }
                          />
                        </div>
                      </div>
                    </li>
                    <li className="ml-6 mb-2">
                      <div className="flex items-center">
                        <p className="mr-1">Unspecified:</p>
                        <div className="font-bold">
                          &nbsp;
                          <FormattedNumber
                            numberValue={
                              engagementSegmentsBreakdown.byGender.unspecified
                            }
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <p className="text-lg my-2">By country:</p>
                  <ul className="list-disc">
                    {Object.keys(engagementSegmentsBreakdown.byCountry).map(
                      (key: string) => (
                        <li className="ml-6 mb-2" key={key}>
                          <div className="flex items-center">
                            <span className="mr-1">{upperCase(key)}:</span>
                            <div className="font-bold">
                              &nbsp;
                              <FormattedNumber
                                numberValue={
                                  engagementSegmentsBreakdown.byCountry[key]
                                }
                              />
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 hover:text-red-600 background-transparent font-bold uppercase p-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowEngagementBreakdownModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-80 absolute inset-0 z-40 bg-black top-0 right-0 bottom-0 left-0"></div>
        </>
      ) : null}
    </>
  );
};

export default EngagementSegmentsBreakdownModal;

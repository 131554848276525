import WebhookBaseCredentials from "./WebhookBaseCredentials";

export default class KafkaWebHookCredentials extends WebhookBaseCredentials {
  topic: string;
  securityProtocol: string;
  saslMechanism: string;
  loginModule: string;
  requestRequiredAcks: string;
  additionalProperties: string;
}

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export interface MAUBreakdown {
  date: string;
  users: number;
}

export const useMonthlyActiveUsers = () => {
  const [monthlyActiveUsers, setMonthlyActiveUsers] =
    useState<MAUBreakdown[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getMonthlyActiveUsers = async () => {
    try {
      const mau = await api.reportingHttps.getMonthlyActiveUsers();

      setMonthlyActiveUsers(mau.breakdown);
    } catch (error) {
      toast.error(ERROR_MESSAGES.MAU_REPORT);
      setMonthlyActiveUsers([{ date: "", users: 0 }]);
    }
  };

  useEffect(() => {
    getMonthlyActiveUsers();

    return () => setMonthlyActiveUsers(null);
  }, [selectedClient]);

  return monthlyActiveUsers;
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { usePredictions } from "../../../../hooks/usePredictions";
import PredictionsByDateDataset from "../../../../models/Reports/PredictionsByDateDataset";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import LineChart from "../Common/LineChart";
import DatePeriod from "../Common/DatePeriod";
import GroupByPeriod from "../Common/GroupByPeriod";
import ReportInfo from "../Common/ReportInfo";
import PredictionsMarketsBreakdown from "./PredictionsMarketsBreakdown";
import { constructLabelsChart } from "../../../../helpers/helpers";
import { ReportParamsContext } from "../../../../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import "../Reports.css";

const PredictionsBreakdown: React.FC = () => {
  const [marketsBreakdown, setMarketsBreakdown] = useState<string[]>(["all"]);

  const { setGroupBy } = useContext(ReportParamsContext);
  // Used to skip useEffect execution on component render
  const firstRun = useRef(true);

  const { selectedClient } = useContext(GlobalStatesContext);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    setMarketsBreakdown(["all"]);
    setGroupBy("week");
  }, [selectedClient]);

  const { totalPredictions, totalPredictionsBreakdown, predictionsBreakdown } =
    usePredictions(marketsBreakdown);

  const onChangeCheckbox = (market: string) => {
    const currentMarketsBreakdown = [...marketsBreakdown];

    if (
      currentMarketsBreakdown.includes(market) &&
      currentMarketsBreakdown.length !== 1
    ) {
      const newMarketsBreakdown = currentMarketsBreakdown.filter(
        (value: string) => value !== market
      );
      setMarketsBreakdown(newMarketsBreakdown);
    } else if (!currentMarketsBreakdown.includes(market)) {
      currentMarketsBreakdown.push(market);
      setMarketsBreakdown(currentMarketsBreakdown);
    }
  };

  const datasetsChart = new PredictionsByDateDataset(
    JSON.parse(JSON.stringify(totalPredictionsBreakdown)),
    JSON.parse(JSON.stringify(predictionsBreakdown.current)),
    JSON.parse(JSON.stringify(predictionsBreakdown.comparedTo)),
    marketsBreakdown
  ).predictionsDatasets;

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Single predictions by date"}
          description={
            "This is a breakdown of your users single predictions for a specified period of time. In this report you will also find a breakdown by market."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod />
        </div>
      </div>
      <GroupByPeriod />
      {totalPredictions && predictionsBreakdown.current ? (
        <>
          <PredictionsMarketsBreakdown
            total={totalPredictions}
            marketBreakdown={marketsBreakdown}
            onChangeCheckbox={onChangeCheckbox}
          />
          <LineChart
            labels={constructLabelsChart(
              predictionsBreakdown.current,
              predictionsBreakdown?.comparedTo
            )}
            datasets={datasetsChart}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PredictionsBreakdown;

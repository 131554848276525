import React, { LegacyRef } from "react";
import { firebaseAuth } from "../../../sdks/firebase/firebase";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { localstorageKey } from "../../../constants/constants";

const linkStyle =
  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer hover:bg-blueGray-50";

type NavigationDropdownProps = {
  popoverDropdownRef: null | LegacyRef<HTMLDivElement>;
  dropdownPopoverShow: boolean;
  isOutside: boolean | undefined;
  onClickLink: () => void;
};

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({
  popoverDropdownRef,
  dropdownPopoverShow,
  isOutside,
  onClickLink,
}) => {
  const history = useHistory();

  const logout = async () => {
    try {
      await firebaseAuth().signOut();
      // Remove the stored pages access that user has
      localStorage.removeItem(localstorageKey);
      history.push("/");
    } catch (error) {
      console.error("There was a problem with logout", error);
    }
  };

  return (
    <div
      ref={popoverDropdownRef}
      className={
        (dropdownPopoverShow && !isOutside ? "block " : "hidden ") +
        "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      }
    >
      <Link to={"/api-keys"} className={linkStyle} onClick={onClickLink}>
        API keys
      </Link>
      <Link to={"/edit-profile"} className={linkStyle} onClick={onClickLink}>
        Edit profile
      </Link>
      <Link to={"/change-password"} className={linkStyle} onClick={onClickLink}>
        Change password
      </Link>
      <div className="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        className={linkStyle}
        onClick={() => {
          logout();
        }}
      >
        Logout
      </a>
    </div>
  );
};

export default NavigationDropdown;

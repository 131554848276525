import React, { useContext } from "react";
import { formatProvidedPeriodReport } from "../../../../../helpers/helpers";
import { useSuccessRateMarkets } from "../../../../../hooks/useSuccessRateMarkets";
import ReportInfo from "../../Common/ReportInfo";
import Spinner from "../../../../Spinner/Spinner";
import {
  marketDescriptionMap,
  marketMap,
} from "../../../../../constants/constants";
import SuccessRateStats from "./SuccessRateStats";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import { camelCase } from "lodash";

type SuccessRateMarketsProps = {
  period: "current" | "compared_to";
};

const SuccessRateMarkets: React.FC<SuccessRateMarketsProps> = ({
  period,
}) => {
  const { datePeriod, comparedToPeriod } = useContext(ReportParamsContext);
  const [breakdownFromDate, breakdownToDate] =
    period === "current"
      ? formatProvidedPeriodReport(
          datePeriod?.fromDate,
          datePeriod?.toDate,
          "selected"
        )
      : formatProvidedPeriodReport(
          comparedToPeriod?.fromDate,
          comparedToPeriod?.toDate,
          period
        );

  const successRateMarkets = useSuccessRateMarkets();

  if (successRateMarkets && successRateMarkets[camelCase(period)]) {
    successRateMarkets[camelCase(period)].breakdown = Object.fromEntries(
      Object.entries(successRateMarkets[camelCase(period)].breakdown).sort(
        //@ts-ignore
        (x, y) => y[1].success_rate_percent - x[1].success_rate_percent
      )
    );
  }

  return (
    <div className="border border-blueGray-200 rounded p-2 mb-6">
      <ReportInfo
        title={`Breakdown from ${breakdownFromDate} to ${breakdownToDate}`}
        description={"An overview for the specified period"}
      />
      {successRateMarkets[camelCase(period)] ? (
        <>
          <div className="p-6 grid grid-cols-1 lg:grid-cols-3 gap-4">
            <SuccessRateStats
              statsName={"Overall success rate"}
              value={`${successRateMarkets[camelCase(period)].total.overall}%`}
              label={"Across all markets"}
            />
          </div>
          <div className="p-6 grid grid-cols-1 lg:grid-cols-3 gap-4">
            {Object.keys(successRateMarkets[camelCase(period)].breakdown).length
              ? Object.keys(
                  successRateMarkets[camelCase(period)].breakdown
                ).map((market: string) => (
                  <SuccessRateStats
                    key={`success-rate-market-${market}`}
                    statsName={`${marketMap.get(market)} (${market})`}
                    value={`${
                      successRateMarkets[camelCase(period)].breakdown[market]
                        .success_rate_percent
                    }%`}
                    label={marketDescriptionMap.get(market)}
                  />
                ))
              : null}
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SuccessRateMarkets;

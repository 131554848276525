import MarketsConfigModel from "fansunited-sdk-esm/Core/Namespaces/Predictor/Models/Config/MarketsConfigModel";
import React, { useContext } from "react";
import ClientFeaturesModel from "../../../../models/features/ClientFeaturesModel";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import Spinner from "../../../Spinner/Spinner";
import Enabled from "../Common/Enabled";
import FullCoverageCompetitionsPredictor from "./FullCoverageCompetitions/FullCoverageCompetitionsPredictor";
import FeaturesPredictorMarkets from "./Markets/FeaturesPredictorMarkets";
import SuccessRateScopesPredictor from "./SuccessRateScopes/SuccessRateScopesPredictor";

type ComponentProps = {
  features: ClientFeaturesModel | null;
};

const FeaturesPredictor: React.FC<ComponentProps> = ({ features }) => {
  const { selectedClient } = useContext(GlobalStatesContext);

  return (
    <div className="w-full px-4">
      <div className="relative min-w-0 break-words bg-white w-full mb-24 shadow-lg rounded p-4">
        <div className="mb-4">
          <h2 className="font-bold">Configuration for Predictor feature</h2>
          <p>
            This is Predictor configuration set by Fans United for client:{" "}
            <span className="font-semibold">{selectedClient.id}</span>. Keep in
            mind that you can edit only
            <span className="font-semibold"> success rate scopes</span>.
          </p>
        </div>
        {features ? (
          <>
            <Enabled value={features.predictor.enabled as boolean} />
            <FeaturesPredictorMarkets
              markets={features.predictor.markets as MarketsConfigModel}
            />
            <FullCoverageCompetitionsPredictor
              competitions={features.predictor.fullCoverageCompetitions}
            />
            <SuccessRateScopesPredictor
              successRateScopes={features.predictor.successRateScopes}
            />
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default FeaturesPredictor;

import DiscussionsAverages from "./Averages/DiscussionsAverages";
import DiscussionsBreakdown from "./Breakdown/DiscussionsBreakdown";
import PostsBreakdown from "./Breakdown/PostsBreakdown";
import { DISCUSSIONS_REPORTS_TABS } from "./DiscussionsReport";
import Top100Contributors from "./Top100Contributors/Top100Contributors";
import Top100Discussions from "./Top100Discussions/Top100Discussions";

type DiscussionReportUIProps = {
  openTab: string;
};

const DiscussionReportUI: React.FC<DiscussionReportUIProps> = ({ openTab }) => {
  if (openTab === DISCUSSIONS_REPORTS_TABS.AVERAGES) {
    return <DiscussionsAverages />;
  }

  if (openTab === DISCUSSIONS_REPORTS_TABS.TOP_100_CONTRIBUTORS) {
    return <Top100Contributors />;
  }

  if (openTab === DISCUSSIONS_REPORTS_TABS.TOP_100_DISCUSSIONS) {
    return <Top100Discussions />;
  }

  if (openTab === DISCUSSIONS_REPORTS_TABS.DISCUSSIONS_BREAKDOWN) {
    return <DiscussionsBreakdown />;
  }

  return <PostsBreakdown />;
};

export default DiscussionReportUI;

export interface AveragePostsModel {
  averageNumberPerPublicDiscussion: number;
  averageNumberPerUser: number;
  averageNumberPerContributor: number;
}

export interface PercentageUsersWithPostsModel {
  atLeastOne: number;
  atLeastFive: number;
  atLeastTen: number;
  atLeastFifty: number;
  atLeastOneHundred: number;
}

export default class DiscussionsAveragesModel {
  averagePosts: AveragePostsModel;
  percentageUsersWithPosts: PercentageUsersWithPostsModel;
}

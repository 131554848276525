import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { parseJwt } from "../helpers/helpers";
import { AuthContext } from "./AuthProvider";
import { MainBreakdown } from "../models/Reports/Engagement/EngagementSegmentsModel";
import ClientsBasicInfo from "../models/Clients/ClientsBasicInfo";

type ShowModalContextType = {
  showModal: boolean;
  showEngagementBreakdownModal: boolean;
  engagementSegmentsBreakdown: MainBreakdown;
  staffId: string;
  staffEmail: string;
  loggedInUser: any;
  isLoading: boolean;
  selectedClient: ClientsBasicInfo;
  isModalConfirmActionSubmitted: { current: boolean }; // Used in ConfirmationalModal to change it current value to true when confirming the modal action. Triggers to call getStaffMembers.
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setShowEngagementBreakdownModal: Dispatch<SetStateAction<boolean>>;
  setEngagementSegmentsBreakdown: Dispatch<SetStateAction<MainBreakdown>>;
  setStaffId: Dispatch<SetStateAction<string>>;
  setStaffEmail: Dispatch<SetStateAction<string>>;
  setLoggedInUser: Dispatch<SetStateAction<any>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedClient: Dispatch<SetStateAction<ClientsBasicInfo>>;
};

//@ts-ignore
const GlobalStatesContext = createContext<ShowModalContextType>();

const GlobalStatesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEngagementBreakdownModal, setShowEngagementBreakdownModal] =
    useState<boolean>(false);
  const [engagementSegmentsBreakdown, setEngagementSegmentsBreakdown] =
    useState<MainBreakdown>({
      users: 0,
      byGender: {
        unspecified: 0,
        female: 0,
        male: 0,
      },
      byCountry: new Map(),
    });
  const [staffId, setStaffId] = useState<string>("");
  const [staffEmail, setStaffEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [selectedClient, setSelectedClient] = useState<ClientsBasicInfo>(
    new ClientsBasicInfo()
  );

  const isModalConfirmActionSubmitted = useRef(false);

  const user = useContext(AuthContext);

  /**
   * Fetches token from user and decode it. The purpose is to have the basic information about current logged in user.
   * @returns Basic information about user.
   */

  const getUserInfo = () => {
    return user?.getIdToken().then((idToken: string) => {
      return parseJwt(idToken);
    });
  };

  useEffect(() => {
    getUserInfo()?.then((user: any) => setLoggedInUser(user));
  }, [user]);

  return (
    <GlobalStatesContext.Provider
      value={{
        showModal,
        showEngagementBreakdownModal,
        engagementSegmentsBreakdown,
        staffId,
        staffEmail,
        loggedInUser,
        isLoading,
        selectedClient,
        isModalConfirmActionSubmitted,
        setShowModal,
        setShowEngagementBreakdownModal,
        setEngagementSegmentsBreakdown,
        setStaffId,
        setStaffEmail,
        setLoggedInUser,
        setIsLoading,
        setSelectedClient,
      }}
    >
      {children}
    </GlobalStatesContext.Provider>
  );
};

export { GlobalStatesContext, GlobalStatesProvider };

import React from "react";
import { useEitherOrPopular } from "../../../../../../hooks/reports/miniGames/useEitherOrPopular";
import CardReport from "../../../../../Cards/CardReport";
import ReportInfo from "../../../Common/ReportInfo";
import DatePeriod from "../../../Common/DatePeriod";
import { headerCellStyle } from "../../../Common/EntityFollows/TableHeaderTopFollows";
import FormattedNumber from "../../../../../Common/FormattedNumber";
import Spinner from "../../../../../Spinner/Spinner";
import "../../../Reports.css";

const EitherOrPopular: React.FC = () => {
  const popularEitherOrs = useEitherOrPopular();

  return (
    <div>
      <CardReport>
        <div className="headingContainer mb-6">
          <ReportInfo
            title={"Top 50 popular Either/Ors"}
            description={
              "This report shows the top 50 most participated Either/Ors for the latest 30 days by default. This report can be specified by period."
            }
          />
          <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
            <DatePeriod defaultDatePeriod={true} allTimeDefault={true} />
          </div>
        </div>
        {popularEitherOrs ? (
          <div className="mb-6 border border-blueGray-200 rounded p-2">
            <h2 className="text-center mb-4 font-bold text-xl">{`Top ${popularEitherOrs.length} popular Either/Ors`}</h2>
            <div className="block w-full overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th className={`${headerCellStyle} text-left`}>Title</th>
                    <th className={`${headerCellStyle} text-right`}>
                      Participations count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {popularEitherOrs.map((record) => (
                    <tr
                      key={record.eitherOrId}
                      className="px-4 border-b border-blueGray-100"
                    >
                      <td
                        className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-left font-bold text-blueGray-600`}
                      >
                        {record.eitherOrModel?.title ||
                          "The Either/Or was deleted"}
                      </td>
                      <td
                        className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-right text-blueGray-500`}
                      >
                        <FormattedNumber numberValue={record.participations} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </CardReport>
    </div>
  );
};

export default EitherOrPopular;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import FootballPaginationModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Pagination/FootballPaginationModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { placeholders } from "../../../../../assets/placeholders/placeholders";
import { reactSelectSingleValueStyle } from "../../../../../constants/styles";
import { ERROR_MESSAGES } from "../../../../../enums/enums";
import { formatOptions } from "../../../../../helpers/helpers";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../providers/ApiProvider";
import { Entity } from "../../../../../types/types";

export const imageLogoEntity = (entity: Entity, option: SelectOption) => {
  let placeholder: string = "";

  if (entity === "competitions") {
    placeholder = placeholders.competition;
  } else if (entity === "teams") {
    placeholder = placeholders.team;
  } else if (entity === "players") {
    placeholder = placeholders.avatar;
  }

  return (
    <img
      src={option.logo ? option.logo : placeholder}
      alt={""}
      className="h-5 w-5 mr-1"
    />
  );
};

type EntitySelectProps = {
  entity: Entity;
  entities: SelectOption[];
  isMulti?: boolean;
  setEntities: Dispatch<SetStateAction<SelectOption[]>>;
  setEntityIds?: Dispatch<SetStateAction<string[]>>;
};

const EntitySelect: React.FC<EntitySelectProps> = ({
  entity,
  entities,
  isMulti,
  setEntities,
  setEntityIds,
}) => {
  const api = useContext(ApiContext);

  const minInputLength = 3;
  const entitySingular = entity.replace("s", "");

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (inputValue.length < minInputLength) return callback([]);

    let competitionFilters: CompetitionFilters = null;
    let teamFilters: TeamFilters = null;
    let playerFilters: PlayerFilters = null;
    let options: SelectOption[] = null;

    if (inputValue && entity === "competitions") {
      competitionFilters = {
        name: inputValue,
      } as CompetitionFilters;
    } else if (inputValue && entity === "teams") {
      teamFilters = {
        name: inputValue,
      } as TeamFilters;
    } else if (inputValue && entity === "players") {
      playerFilters = {
        name: inputValue,
      } as PlayerFilters;
    }

    if (entity === "competitions") {
      api?.sdk.football
        .getCompetitions(competitionFilters)
        .then((response: CompetitionBasicModel[]) => {
          options = response.map((competition: CompetitionBasicModel) =>
            formatOptions(competition)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    } else if (entity === "teams") {
      api?.sdk.football
        .getTeams(teamFilters)
        .then((response: FootballPaginationModel) => {
          //Sometimes API will return teams with name like: "Chelsea/Manchester City"
          const actualTeams = response.data.filter(
            (team: TeamBasicModel) => !team.name.includes("/")
          );
          options = actualTeams.map((team: TeamBasicModel) =>
            formatOptions(team)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    } else if (entity === "players") {
      api?.sdk.football
        .getPlayers(playerFilters)
        .then((response: FootballPaginationModel) => {
          options = response.data.map((player: PlayerBasicModel) =>
            formatOptions(player)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    }
  };

  const handleOnChange = (selectedOption: SelectOption | SelectOption[]) => {
    if (!isMulti) {
      selectedOption = selectedOption as SelectOption;
      setEntityIds(selectedOption ? [selectedOption.id] : []);
      setEntities([selectedOption]);

      return;
    }

    selectedOption = selectedOption as SelectOption[];
    setEntities(selectedOption);
  };

  const loadOptions = _.debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? `No ${entitySingular} found`
      : "Type at least 3 characters";

  return (
    <AsyncSelect
      components={{ DropdownIndicator: null }}
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      //@ts-ignore
      styles={reactSelectSingleValueStyle}
      isMulti={isMulti}
      onChange={handleOnChange}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Loading..."}
      value={entities}
      loadOptions={loadOptions}
      isClearable={true}
      placeholder={`Type ${entitySingular} name...`}
      formatOptionLabel={(option) => (
        <div className="flex items-center">
          {imageLogoEntity(entity, option)}
          <span className="mr-2">{option.label}</span>
          <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
          <span className="mr-1">{option.countryName}</span>
        </div>
      )}
    />
  );
};

export default EntitySelect;

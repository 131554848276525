import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import StatsBuilder from "../../../../builders/dashboard/StatsBuilder";
import {
  statsComponentsTypes,
  statsIcons,
  statsIconsColor,
  statsLabels,
} from "../../../../constants/constants";
import { ERROR_MESSAGES } from "../../../../enums/enums";
import { ApiContext } from "../../../../providers/ApiProvider";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import ReportingHttps from "../../../../services/https/ReportingHttps";
import CardStats from "../../../Cards/CardStats";
import Stats from "./Stats";
import StatsContainer from "./StatsContainer";

const StatsLayout: React.FC = () => {
  const [statsMap, setStatsMap] = useState<Map<string, number | string>>();

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const stats = Object.keys(statsComponentsTypes);
  const defaultValue = "N/A";

  useEffect(() => {
    new StatsBuilder(api?.reportingHttps as ReportingHttps)
      .build()
      .then((statsMap: Map<string, number>) => {
        setStatsMap(statsMap);
      })
      .catch((error) => {
        const statsMap: Map<string, string> = new Map()
          .set("TOTAL_USERS", defaultValue)
          .set("AVERAGE_FOLLOWS", defaultValue)
          .set("TOTAL_REQUESTS", defaultValue)
          .set("PREDICTIONS_PERIOD", defaultValue);
        setStatsMap(statsMap);

        console.error(error);
        toast.error(ERROR_MESSAGES.STATS_INFORMATION);
      });
  }, [selectedClient]);

  return (
    <>
      <CardStats>
        {stats.map((statsType: string, index: number) => (
          <StatsContainer key={`${statsType + index}`}>
            <Stats
              statsName={
                statsComponentsTypes[
                  statsType as keyof typeof statsComponentsTypes
                ]
              }
              value={statsMap ? statsMap.get(statsType) : defaultValue}
              icon={statsIcons[statsType as keyof typeof statsIcons]}
              iconColor={
                statsIconsColor[statsType as keyof typeof statsIconsColor]
              }
              label={statsLabels[statsType as keyof typeof statsLabels]}
            />
          </StatsContainer>
        ))}
      </CardStats>
    </>
  );
};

export default StatsLayout;

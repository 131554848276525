import React, { useRef } from "react";
import FeaturesLayout from "../FeaturesLayout";
import { useFeatures } from "../../../../hooks/features/useFeatures";
import WebhooksComponent from "./WebhooksComponent";
import Spinner from "../../../Spinner/Spinner";

const FeaturesWebhooks: React.FC = () => {
  const isWebhookFeatureEdited = useRef<boolean>(false);

  const features = useFeatures(isWebhookFeatureEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Webhooks feature"}
      description={"This is Webhooks configuration for client: "}
    >
      {features ? (
        <WebhooksComponent
          webhook={features.webhook}
          isWebhookFeatureEdited={isWebhookFeatureEdited}
        ></WebhooksComponent>
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesWebhooks;

import dayjs from "dayjs";
import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { placeholders } from "../../assets/placeholders/placeholders";

type MatchProps = {
  match: MatchBasicModel;
  matchId: string;
  setMatchId: Dispatch<SetStateAction<string>>;
  setMatchModel: Dispatch<SetStateAction<MatchBasicModel>>;
};

const Match: React.FC<MatchProps> = ({
  match,
  matchId,
  setMatchId,
  setMatchModel,
}) => {
  const matchRef = useRef<HTMLDivElement>();

  const onClick = () => {
    const matchId = matchRef.current?.id ? matchRef.current.id : "";

    setMatchModel(match);
    if (matchId) setMatchId(matchId);
  };

  return (
    <div
      className="mb-4 cursor-pointer"
      onClick={onClick}
      ref={matchRef}
      id={match.id}
    >
      <div className="flex items-center md:px-2 px-0 mb-4">
        <input
          id={match.id}
          value={match.id}
          checked={matchId === match.id}
          type="radio"
          className="w-5 h-5 mr-1 cursor-pointer"
          readOnly
        ></input>
        <span className="mr-1 md:block hidden">
          [{dayjs(match.kickoffAt).format("DD-MM-YYYY HH:mm")}]
        </span>
        <span className="font-semibold mr-1 whitespace-nowrap md:text-base text-xxs">
          {match.homeTeam.name}
        </span>
        <img
          className="w-6 mr-1 md:block hidden"
          src={
            match.homeTeam.assets &&
            //@ts-ignore
            match.homeTeam.assets.logo
              ? //@ts-ignore
                match.homeTeam.assets.logo
              : placeholders.team
          }
        />
        <span className="mr-1">-</span>
        <img
          className="w-6 mr-1 md:block hidden"
          src={
            match.awayTeam.assets &&
            //@ts-ignore
            match.awayTeam.assets.logo
              ? //@ts-ignore
                match.awayTeam.assets.logo
              : placeholders.team
          }
        />
        <span className="font-semibold mr-1 whitespace-nowrap md:text-base text-xxs">
          {match.awayTeam.name}
        </span>
      </div>
      <hr></hr>
    </div>
  );
};

export default Match;

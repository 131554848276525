import React from "react";
import LoyaltyInput from "../../../../Common/LoyaltyInput";
import { IFootballBadgeRequirements } from "../../../../../../../models/features/loyalty/Badges/Football/FootballBadgesExtended";
import Entity from "../Entity";

type FootballBadgeRequirementsProps = {
  uid: string;
  requirements: IFootballBadgeRequirements;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
};

const FootballBadgeRequirements: React.FC<FootballBadgeRequirementsProps> = ({
  uid,
  requirements,
  onChangeBadges,
}) => {
  const onChangeEntityIdRequirement = (entityId: string) => {
    onChangeBadges("requirements", "entityId", entityId);
  };

  const onChangeEntityTypeRequirement = (entityType: string) => {
    onChangeBadges("requirements", "entityType", entityType);
  };

  return (
    <div className="mb-2">
      <label className="font-bold mb-2 text-center">Requirements:</label>
      <LoyaltyInput
        uid={uid}
        label="Predictions Number"
        value={requirements.predictionsNumber}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "predictionsNumber", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Correct Predictions"
        value={requirements.correctPredictions}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "correctPredictions", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Points"
        value={requirements.points}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "points", value)
        }
        scenario="Badges"
      />
      <Entity
        entityId={requirements.entityId}
        entityType={requirements.entityType}
        entityModel={requirements.entityModel}
        uid={uid}
        onChangeEntityIdRequirement={onChangeEntityIdRequirement}
        onChangeEntityTypeRequirement={onChangeEntityTypeRequirement}
      />
    </div>
  );
};

export default FootballBadgeRequirements;

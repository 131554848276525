import React from "react";

export const headerCellStyle =
  "border border-solid p-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold bg-blueGray-50 text-blueGray-500 border-blueGray-100";

type TableHeaderTopFollowsProps = {
  showSuccessRatePercent: boolean;
};

const TableHeaderTopFollows: React.FC<TableHeaderTopFollowsProps> = ({
  showSuccessRatePercent,
}) => {
  return (
    <thead>
      <tr>
        <th className={`${headerCellStyle} lg:text-left text-center`}>Name</th>
        <th className={`${headerCellStyle} lg:table-cell hidden text-right`}>
          All
        </th>
        <th className={`${headerCellStyle} lg:table-cell hidden text-right`}>
          Favourites
        </th>
        {showSuccessRatePercent ? (
          <th className={`${headerCellStyle} xl:table-cell hidden text-right`}>
            Success rate
          </th>
        ) : null}
      </tr>
    </thead>
  );
};

export default TableHeaderTopFollows;

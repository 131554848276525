import React, { useState } from "react";
import { ApiIdToNameMap, endpointToApi } from "../../../../constants/constants";
import EditButton from "../../../Buttons/CreateStaffButton";
import FeaturesLayout from "../FeaturesLayout";
import CacheConfigurationEndpoint from "./CacheConfigurationEndpoint";

const FeaturesCacheTtl: React.FC = () => {
  const [apiId, setApiId] = useState<string>("");
  const [endpoint, setEndpoint] = useState<string>("");
  const [editCacheEndpoint, setEditCacheEndpoint] = useState<boolean>(false);

  const cacheTtlEndpoints = Array.from(endpointToApi.keys());
  const headCellStyle =
    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100";
  const bodyCellStyle =
    "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";

  const editEndpointCache = (apiId: string, endpoint: string) => {
    setApiId(apiId);
    setEndpoint(endpoint);
    setEditCacheEndpoint(true);
  };

  if (editCacheEndpoint) {
    return (
      <CacheConfigurationEndpoint
        apiId={apiId}
        endpoint={endpoint}
        setEditCacheEndpoint={setEditCacheEndpoint}
      />
    );
  }

  return (
    <FeaturesLayout
      title={"Configuration for Cache TTL feature"}
      description={"This is Cache TTL configuration for client: "}
    >
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th className={headCellStyle}>API Name</th>
              <th className={headCellStyle}>API Endpoint</th>
              <th className={headCellStyle}></th>
            </tr>
          </thead>
          <tbody>
            {cacheTtlEndpoints.map((endpoint: string) => (
              <tr
                key={endpoint}
                className="px-4 border border-b border-blueGray-100"
              >
                <th className={`${bodyCellStyle} text-left`}>
                  {ApiIdToNameMap.get(endpointToApi.get(endpoint))}
                </th>
                <td className={bodyCellStyle}>{endpoint}</td>
                <td className={`${bodyCellStyle} text-right`}>
                  <EditButton
                    label={"Edit"}
                    onClick={() =>
                      editEndpointCache(endpointToApi.get(endpoint), endpoint)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </FeaturesLayout>
  );
};

export default FeaturesCacheTtl;

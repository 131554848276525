import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TopFollowsFootballEntitiesModel from "../../models/Reports/FootballCompetitionsFollows/TopFollowsFootballEntitiesModel";
import { ApiContextValueType } from "../../providers/ApiProvider";
import { Entity } from "../../types/types";
import EntityFollowsBuilder from "./EntityFollowsBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class TopFollowsFootballEntitiesBuilder extends EntityFollowsBuilder {
  public footballEntitiesFollows = new TopFollowsFootballEntitiesModel();
  private successRatesEntitiesBreakdown: {
    [key: string]: { [key: string]: number };
  };
  private entity: Entity;

  constructor(
    api: ApiContextValueType,
    successRatesEntitiesBreakdown: any,
    topFollowedEntities: any,
    entity: Entity
  ) {
    super(api);
    this.successRatesEntitiesBreakdown = successRatesEntitiesBreakdown;
    this.footballEntitiesFollows.topEntities = topFollowedEntities;
    this.sdk = api.sdk as FansUnitedSDKModel;
    this.entity = entity;
  }

  public build = async () => {
    await this.buildSuccessRatesForEntity();

    return this.footballEntitiesFollows;
  };

  private buildSuccessRatesForEntity = async () => {
    let entityModels:
      | CompetitionBasicModel[]
      | TeamBasicModel[]
      | PlayerBasicModel[] = [];
    const newMap = new Map<
      string,
      CompetitionBasicModel | TeamBasicModel | PlayerBasicModel
    >();
    const successRatesMap = new Map<string, string>();
    const topFollowedEntityIds =
      this.footballEntitiesFollows.topEntities.breakdown.map(
        (value: any) => value.id
      );
    const limit = topFollowedEntityIds.length;

    try {
      if (this.entity === "competitions") {
        entityModels = await this.getCompetitions(topFollowedEntityIds);
      } else if (this.entity === "teams") {
        entityModels = await this.getTeams(topFollowedEntityIds, limit);
      } else if (this.entity === "players") {
        entityModels = await this.getPlayers(topFollowedEntityIds, limit);
      }
    } catch (error) {}

    topFollowedEntityIds.forEach((topId: string) => {
      entityModels.forEach(
        (model: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel) => {
          if (model.id === topId) {
            newMap.set(topId, model);
          }
        }
      );

      return newMap;
    });
    this.footballEntitiesFollows.models = newMap;

    // When we use this method for entity players, we don't have any success rates.
    if (!this.successRatesEntitiesBreakdown) {
      delete this.footballEntitiesFollows.successRates;
      return;
    }

    const successRatesEntityIds = Object.keys(
      this.successRatesEntitiesBreakdown
    ).filter(
      (id: string) =>
        id.includes(this.getEntityRootId(this.entity)) &&
        topFollowedEntityIds.includes(id)
    );

    Object.keys(this.successRatesEntitiesBreakdown).forEach((key: string) => {
      if (
        key.includes(this.getEntityRootId(this.entity)) &&
        topFollowedEntityIds.includes(key)
      ) {
        successRatesMap.set(
          key,
          `${this.successRatesEntitiesBreakdown[key].success_rate_percent}%`
        );
      }
    });

    if (entityModels.length) {
      entityModels.forEach(
        (model: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel) => {
          if (!successRatesEntityIds.includes(model.id)) {
            successRatesMap.set(model.id, "N/A");
          }
        }
      );
    }

    this.footballEntitiesFollows.successRates = successRatesMap;
  };
}

import React, { useState } from "react";
import {
  engagementGamesPredictionsKeyToContent,
  engagementSinglePredictionsKeyToContent,
} from "../../../../../constants/constants";
import { useEngagementPredictions } from "../../../../../hooks/useEngagementPredictions";
import CardReport from "../../../../Cards/CardReport";
import FormattedNumber from "../../../../Common/FormattedNumber";
import Spinner from "../../../../Spinner/Spinner";
import DatePeriod from "../../Common/DatePeriod";
import { headerCellStyle } from "../../Common/EntityFollows/TableHeaderTopFollows";
import ReportInfo from "../../Common/ReportInfo";
import EngagementSelectType from "./EngagementSelectType";

export type PredictionsEngagementTypes =
  | "ALL"
  | "GAME"
  | "TOP_X"
  | "MATCH_QUIZ"
  | "SINGLE";

const tableHeadingContent = (typeEngagement: PredictionsEngagementTypes) => {
  switch (typeEngagement) {
    case "ALL":
    case "SINGLE":
      return "Number of predictions";
    case "GAME":
      return "Number of game participations";
    case "TOP_X":
      return "Number of TOP X participations";
    case "MATCH_QUIZ":
      return "Number of Match Quiz participations";
  }
};

const EngagementThroughPredictions: React.FC = () => {
  const [type, setType] = useState<PredictionsEngagementTypes>("ALL");

  const engagementThroughPredictions = useEngagementPredictions(type);

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Users engagement through predictions"}
          description={
            "This report shows by default all time data for users engagement through predictions and games. You can specify the type for the predictions (singles or game ones). Also you can specify a period for the report."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} />
        </div>
      </div>
      <div className="mb-3">
        <EngagementSelectType setType={setType} />
      </div>
      {engagementThroughPredictions ? (
        <div className="mb-6 border border-blueGray-200 rounded p-2">
          <h2 className="text-center mb-4 font-bold text-xl">
            Engagement through predictions
          </h2>
          <p className="text-center mb-4 italic">
            Total users:{" "}
            <FormattedNumber
              numberValue={engagementThroughPredictions.total.allUsers}
            ></FormattedNumber>
          </p>
          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className={`${headerCellStyle} lg:text-left text-center`}>
                    {tableHeadingContent(type)}
                  </th>
                  <th className={`${headerCellStyle} lg:text-left text-center`}>
                    Users
                  </th>
                  <th
                    className={`${headerCellStyle} text-right`}
                  >
                    % of total users
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(
                  engagementThroughPredictions.breakdown.predictions
                ).map((key: string) => (
                  <tr
                    className="px-4 border-b border-blueGray-100"
                    key={`engagement-through-${engagementThroughPredictions.typePredictionsEngagement}-predictions-${key}`}
                  >
                    <td className="border-t-0 border-l-0 border-r-0 whitespace-nowrap p-3 lg:text-left text-center">
                      {engagementThroughPredictions.typePredictionsEngagement ===
                      "SINGLES"
                        ? engagementSinglePredictionsKeyToContent.get(key)
                        : engagementGamesPredictionsKeyToContent.get(key)}
                    </td>
                    <td className="border-t-0 border-l-0 border-r-0 whitespace-nowrap p-3 lg:text-left text-center text-blueGray-500">
                      <FormattedNumber
                        numberValue={
                          engagementThroughPredictions.breakdown.predictions[
                            key
                          ].usersCount
                        }
                      ></FormattedNumber>
                    </td>
                    <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-right text-blueGray-500">
                      {
                        engagementThroughPredictions.breakdown.predictions[key]
                          .percent
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default EngagementThroughPredictions;

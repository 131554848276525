import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../providers/ApiProvider";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { ERROR_MESSAGES, FansUnitedFeatures } from "../../enums/enums";
import { toast } from "react-toastify";
import PollFeatureModel from "../../models/features/poll/PollFeatureModel";

export const usePollFeatures = (isFeaturesEdited: any) => {
  const [pollFeature, setPollFeature] = useState<PollFeatureModel>(null);

  const { clientHttps } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getPollClientFeature = async () => {
    try {
      const feature = await clientHttps.getClientSpecificFeature(
        FansUnitedFeatures.POLL
      );
      setPollFeature(feature);
    } catch (e) {
      toast.error(ERROR_MESSAGES.POLL_FEATURE);
      setPollFeature(new PollFeatureModel());
    }
  };

  useEffect(() => {
    getPollClientFeature();

    return () => setPollFeature(null);
  }, [selectedClient, isFeaturesEdited.current]);

  return pollFeature;
};

import APIKeys from "../components/Pages/APIKeys/APIKeys";
import ChangePassword from "../components/Pages/ChangePassword/ChangePassword";
import Dashboard from "../components/Pages/Dashboard/Dashboard";
import EditProfile from "../components/Pages/EditProfile/EditProfile";
import FeaturesCacheTtl from "../components/Pages/Features/CacheTtl/FeaturesCacheTtl";
import FeaturesClassicQuizPage from "../components/Pages/Features/ClassicQuiz/FeaturesClassicQuizPage";
import FeaturesEitherOrPage from "../components/Pages/Features/EitherOr/FeaturesEitherOrPage";
import EngagementCoefficients from "../components/Pages/Features/EngagementCoefficients/EngagementCoefficients";
import FeaturesExternalPoints from "../components/Pages/Features/ExternalPoints/FeaturesExternalPoints";
import FeaturesBadgesPage from "../components/Pages/Features/FeaturesBadges/FeaturesBadgesPage";
import FeaturesFantasyPage from "../components/Pages/Features/FeaturesFantasy/FeaturesFantasyPage";
import FeaturesLoyaltyPage from "../components/Pages/Features/FeaturesLoyalty/FeaturesLoyaltyPage";
import FeaturesMatchQuizPage from "../components/Pages/Features/FeaturesMatchQuiz/FeaturesMatchQuizPage";
import FeaturesPredictorPage from "../components/Pages/Features/FeaturesPredictor/FeaturesPredictorPage";
import FeaturesTopXPage from "../components/Pages/Features/FeaturesTopX/FeaturesTopXPage";
import FeaturesWebhooks from "../components/Pages/Features/FeaturesWebhook/FeaturesWebhooks";
import FootballSettings from "../components/Pages/Features/FootballSettings/FootballSettings";
import FeaturesPollPage from "../components/Pages/Features/Poll/FeaturesPollPage";
import Averages from "../components/Pages/Reports/Averages/Averages";
import Consumption from "../components/Pages/Reports/Consumption/Consumption";
import DiscussionsReport from "../components/Pages/Reports/Discussions/DiscussionsReport";
import EngagementReport from "../components/Pages/Reports/Engagement/EngagementReport";
import FootballFollows from "../components/Pages/Reports/Football/FootballFollows";
import ClassicQuizReport from "../components/Pages/Reports/MiniGames/ClassicQuiz/ClassicQuizReport";
import EitherOrReport from "../components/Pages/Reports/MiniGames/EitherOr/EitherOrReport";
import MonthlyActiveUsersReport from "../components/Pages/Reports/MonthlyActiveUsers/MonthlyActiveUsersReport";
import Predictions from "../components/Pages/Reports/Predictions/Predictions";
import PrivateLeaguesReport from "../components/Pages/Reports/PrivateLeagues/PrivateLeaguesReport";
import SuccessRateReport from "../components/Pages/Reports/SuccessRate/SuccessRateReport";
import UsersAcquiredReport from "../components/Pages/Reports/UsersAcquiredReport/UsersAcquiredReport";
import CreateStaffMember from "../components/Pages/Staff/CreateStaffMember/CreateStaffMember";
import EditStaffMember from "../components/Pages/Staff/EditStaffMember/EditStaffMember";
import Staff from "../components/Pages/Staff/Staff";
import ManagementWidget from "../components/Widgets/ManagementWidget";
import { MainPagesEnum, ManagementWidgetsPagesEnum } from "../enums/enums";

export const routes = [
  {
    path: `/${MainPagesEnum.DASHBOARD}`,
    component: Dashboard,
  },
  {
    path: `/${MainPagesEnum.STAFF}`,
    component: Staff,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.STAFF}/edit/:staffId`,
    component: EditStaffMember,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.STAFF}/create`,
    component: CreateStaffMember,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/predictor`,
    component: FeaturesPredictorPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/top-x`,
    component: FeaturesTopXPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/match-quiz`,
    component: FeaturesMatchQuizPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/loyalty`,
    component: FeaturesLoyaltyPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/cache-ttl`,
    component: FeaturesCacheTtl,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/external-points`,
    component: FeaturesExternalPoints,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/webhook`,
    component: FeaturesWebhooks,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/football-settings`,
    component: FootballSettings,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/engagement-coefficients`,
    component: EngagementCoefficients,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/classic-quiz`,
    component: FeaturesClassicQuizPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/either-or`,
    component: FeaturesEitherOrPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/poll`,
    component: FeaturesPollPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/badges`,
    component: FeaturesBadgesPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.FEATURES}/football-fantasy`,
    component: FeaturesFantasyPage,
    exact: true,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/users-acquired`,
    component: UsersAcquiredReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/averages`,
    component: Averages,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/consumption`,
    component: Consumption,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/predictions`,
    component: Predictions,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/monthly-active-users`,
    component: MonthlyActiveUsersReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/football`,
    component: FootballFollows,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/success-rate`,
    component: SuccessRateReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/engagement`,
    component: EngagementReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/private-leagues`,
    component: PrivateLeaguesReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/discussions`,
    component: DiscussionsReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/classic-quiz`,
    component: ClassicQuizReport,
  },
  {
    path: `/${MainPagesEnum.REPORTS}/either-or`,
    component: EitherOrReport,
  },
  {
    path: `/api-keys`,
    component: APIKeys,
  },
  {
    path: `/edit-profile`,
    component: EditProfile,
  },
  {
    path: `/change-password`,
    component: ChangePassword,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.TOP_X_GAMES}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.MATCH_QUIZ_GAMES}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.COMPETITION_GAME}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.TEAMS_GAME}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.MATCHES_GAME}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.FANTASY_GAME}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.CUSTOM_GAME}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.CLASSIC_QUIZZES}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.DISCUSSIONS}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.EITHER_OR}`,
    component: ManagementWidget,
  },
  {
    path: `/${ManagementWidgetsPagesEnum.POLL}`,
    component: ManagementWidget,
  },
];

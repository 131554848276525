import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../enums/enums";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import FootballSettingsConfigModel from "../../models/Clients/FootballSettingsConfigModel";
import { ApiContext } from "../../providers/ApiProvider";

export const useFootballSettingsConfig = (isFootballSettingsEdited: {
  current: boolean;
}) => {
  const [footballSettingsConfig, setFootballSettingsConfig] =
    useState<FootballSettingsConfigModel>(null);

  const { selectedClient } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const getFootballSettingsConfig = async () => {
    try {
      const footballSettingsConfig =
        await clientHttps.getFootballSettingsConfig();

      setFootballSettingsConfig(footballSettingsConfig);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FOOTBALL_SETTINGS_CONFIG);
      setFootballSettingsConfig(new FootballSettingsConfigModel());
    }
  };

  useEffect(() => {
    getFootballSettingsConfig();

    return () => setFootballSettingsConfig(null);
  }, [selectedClient, isFootballSettingsEdited.current]);

  return footballSettingsConfig;
};

import React from "react";
import AddButton from "../../../../../Buttons/AddButton";
import PredictorBadge from "./Predictor/PredictorBadge";
import GamesBadge from "./Games/GamesBadge";
import FootballBadge from "./Football/FootballBadge";
import MiniGameBadge from "./MiniGames/MiniGameBadge";
import DiscussionsBadge from "./DIscussions/DiscussionsBadge";
import GameBadge from "./Game/GameBadge";
import PollBadge from "./Voting/PollBadge";
import PlayerOfTheMatchBadge from "./Voting/PlayerOfTheMatchBadge";
import GamesBadgesExtended from "../../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";

const displayBadgeEntitiesByType = (
  type: string,
  badge: any,
  requestBodyGameBadges: GamesBadgesExtended[],
  onChangeBadges: (
    uid: string,
    field: string,
    requirement: string,
    value: any
  ) => void,
  removeExistingBadge: (uid: string) => void
) => {
  switch (type) {
    case "predictor":
      return (
        <PredictorBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "top_x":
      return (
        <GameBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "match_quiz":
      return (
        <GameBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "football":
      return (
        <FootballBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "games":
      const specificGames =
        requestBodyGameBadges?.find((gameBadge) => gameBadge.uid === badge.uid)
          .requirements.specificGames || [];

      return (
        <GamesBadge
          badge={badge}
          specificGames={specificGames}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "classic_quizzes":
      return (
        <MiniGameBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "either_or":
      return (
        <MiniGameBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "discussions":
      return (
        <DiscussionsBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "voting_poll":
      return (
        <PollBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
    case "voting_potm":
      return (
        <PlayerOfTheMatchBadge
          badge={badge}
          onChangeBadges={(field, requirementField, value) =>
            onChangeBadges(badge.uid, field, requirementField, value)
          }
          removeExistingBadge={removeExistingBadge}
        />
      );
  }
};

type BadgeEntityProps = {
  badges: any[];
  type: string;
  requestBodyGameBadges?: GamesBadgesExtended[];
  onChangeBadges: (
    uid: string,
    field: string,
    requirement: string,
    value: any
  ) => void;
  addNewBadge: (idx: number) => void;
  removeExistingBadge: (uid: string) => void;
};

const BadgeEntity: React.FC<BadgeEntityProps> = ({
  badges,
  type,
  requestBodyGameBadges,
  onChangeBadges,
  addNewBadge,
  removeExistingBadge,
}) => {
  return (
    <div className="grid lg:grid-cols-4 gap-2">
      {badges.map((badge: any, idx: number) => (
        <div className="bg-blueGray-200 mb-2" key={`${badge.uid}-${idx}`}>
          {displayBadgeEntitiesByType(
            type,
            badge,
            requestBodyGameBadges,
            onChangeBadges,
            removeExistingBadge
          )}
        </div>
      ))}
      <AddButton onClick={() => addNewBadge(badges.length)} />
    </div>
  );
};

export default BadgeEntity;

import BadgesExtended from "../BadgesExtended";

export interface IFootballBadgeRequirements {
  predictionsNumber: number;
  correctPredictions: number;
  points: number;
  entityId: string;
  entityType: string;
  entityModel: any;
}

export default class FootballBadgesExtended extends BadgesExtended {
  public requirements: IFootballBadgeRequirements = {
    predictionsNumber: 0,
    correctPredictions: 0,
    points: 0,
    entityId: null,
    entityType: null,
    entityModel: null,
  };
}

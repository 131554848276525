import React from "react";
import ClientSelect from "../../ClientSelect/ClientSelect";
import ClientLabel from "../../ClientSelect/ClientLabel";
import ClientsBasicInfo from "../../../models/Clients/ClientsBasicInfo";

type StaffProjectsProps = {
  onChange: (selectedClient: ClientsBasicInfo) => void;
};

const StaffProjects: React.FC<StaffProjectsProps> = ({ onChange }) => {
  return (
    <div className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
      <div className="relative flex w-full flex-wrap items-stretch">
        <ClientSelect
          onChange={onChange}
          version={"web"}
          onClickNavigationUrlHideMobileMenu={() => {}}
        />
        <ClientLabel version={"web"} />
      </div>
    </div>
  );
};

export default StaffProjects;

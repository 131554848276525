import React, { Dispatch, SetStateAction, useState } from "react";
import { CustomAssetsInterface } from "../../../../../models/Clients/FootballSettingsConfigModel";
import AddButton from "../../../../Buttons/AddButton";
import { Entity } from "../../../../../types/types";
import AssetsSelect from "./AssetsSelect";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import FileUploader from "../../../../Buttons/FileUploader";
import { useFootballEntityOptions } from "../../../../../hooks/useFootballEntityOptions";

export const prefixNewCompetitionAssetsId = "competition-logo-";

export const prefixNewTeamAssetsId = "team-logo-";

export const prefixNewPlayerAssetsId = "player-logo-";

type CustomAssetsProps = {
  loadedCustomAssets: CustomAssetsInterface;
  customAssets: CustomAssetsInterface;
  setCustomAssets: Dispatch<SetStateAction<CustomAssetsInterface>>;
};

const CustomAssets: React.FC<CustomAssetsProps> = ({
  loadedCustomAssets,
  customAssets,
  setCustomAssets,
}) => {
  const competitionIds = Object.keys(
    customAssets.competitionLogos ? customAssets.competitionLogos : {}
  );
  const teamIds = Object.keys(
    customAssets.teamLogos ? customAssets.teamLogos : {}
  );
  const playerIds = Object.keys(
    customAssets.playerHeadshots ? customAssets.playerHeadshots : {}
  );

  const [selectedOptionCompetition, setSelectedOptionCompetition] = useState<
    Map<string, SelectOption>
  >(new Map());
  const [selectedOptionTeam, setSelectedOptionTeam] = useState<
    Map<string, SelectOption>
  >(new Map());
  const [selectedOptionPlayer, setSelectedOptionPlayer] = useState<
    Map<string, SelectOption>
  >(new Map());

  const options = useFootballEntityOptions({
    competitionIds,
    teamIds,
    playerIds,
  });

  const addAssets = (entity: Entity, index: number) => {
    const copyAssets = { ...customAssets };

    switch (entity) {
      case "competitions":
        const newCompetitionAssets = {
          [`${prefixNewCompetitionAssetsId}${index}`]: "",
        };
        copyAssets.competitionLogos = {
          ...copyAssets.competitionLogos,
          ...newCompetitionAssets,
        };
        setCustomAssets(copyAssets);
        break;
      case "teams":
        const newTeamAssets = {
          [`${prefixNewTeamAssetsId}${index}`]: "",
        };
        copyAssets.teamLogos = {
          ...copyAssets.teamLogos,
          ...newTeamAssets,
        };
        setCustomAssets(copyAssets);
        break;
      case "players":
        const newPlayerAssets = {
          [`${prefixNewPlayerAssetsId}${index}`]: "",
        };
        copyAssets.playerHeadshots = {
          ...copyAssets.playerHeadshots,
          ...newPlayerAssets,
        };
        setCustomAssets(copyAssets);
        break;
    }
  };

  const handleOnChangeURL = (entity: Entity, key: string, value: string) => {
    const copyCustomAssets = { ...customAssets };

    switch (entity) {
      case "competitions":
        copyCustomAssets.competitionLogos[key] = value;
        setCustomAssets(copyCustomAssets);
        break;
      case "teams":
        copyCustomAssets.teamLogos[key] = value;
        setCustomAssets(copyCustomAssets);
        break;
      case "players":
        copyCustomAssets.playerHeadshots[key] = value;
        setCustomAssets(copyCustomAssets);
        break;
    }
  };

  return (
    <div className="mb-2 border border-blueGray-300 rounded-lg p-2 bg-blueGray-100">
      <h3 className="font-bold text-xl mb-2">Custom assets:</h3>
      <div className="mb-2 border border-blueGray-400 rounded-lg p-2 bg-blueGray-150">
        <h4 className="mb-2 font-semibold">Competitions</h4>
        {competitionIds.length ? (
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-1">
            <span>Competition</span>
            <span>New competition logo URL</span>
          </div>
        ) : null}
        {competitionIds.map((competitionId: string) => (
          <div
            key={`competition-assets-${competitionId}`}
            className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-1"
          >
            <AssetsSelect
              entity={"competitions"}
              selectedOption={
                options?.competitions.find((c) => c.id === competitionId) ||
                selectedOptionCompetition.get(competitionId) ||
                null
              }
              setSelectedOption={setSelectedOptionCompetition}
              setCustomAssets={setCustomAssets}
              entityId={competitionId}
            />
            <div className="flex items-center self-stretch mb-2">
              <input
                className="w-full mr-1"
                type="text"
                value={customAssets.competitionLogos[competitionId] || ""}
                onChange={(event) =>
                  handleOnChangeURL(
                    "competitions",
                    competitionId,
                    event.target.value
                  )
                }
              />
              <FileUploader
                type={"custom_competition_logo"}
                id={competitionId}
                onChangeImages={(url) =>
                  handleOnChangeURL("competitions", competitionId, url)
                }
              />
            </div>
          </div>
        ))}
        <AddButton
          onClick={() => addAssets("competitions", competitionIds.length)}
        />
      </div>
      <div className="mb-2 border border-blueGray-400 rounded-lg p-2 bg-blueGray-150">
        <h4 className="mb-2 font-semibold">Teams</h4>
        {teamIds.length ? (
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-1">
            <span>Team</span>
            <span>New team logo URL</span>
          </div>
        ) : null}
        {teamIds.map((teamId: string) => (
          <div
            key={`team-assets-${teamId}`}
            className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-1"
          >
            <AssetsSelect
              entity={"teams"}
              selectedOption={
                options?.teams.find((t) => t.id === teamId) ||
                selectedOptionTeam.get(teamId) ||
                null
              }
              setSelectedOption={setSelectedOptionTeam}
              setCustomAssets={setCustomAssets}
              entityId={teamId}
            />
            <div className="flex items-center self-stretch mb-2">
              <input
                className="w-full mr-1"
                type="text"
                defaultValue={customAssets.teamLogos[teamId]}
                onChange={(event) =>
                  handleOnChangeURL("teams", teamId, event.target.value)
                }
              />
              <FileUploader
                type={"custom_team_logo"}
                id={teamId}
                onChangeImages={(url) =>
                  handleOnChangeURL("teams", teamId, url)
                }
              />
            </div>
          </div>
        ))}
        <AddButton onClick={() => addAssets("teams", teamIds.length)} />
      </div>
      <div className="mb-2 border border-blueGray-400 rounded-lg p-2 bg-blueGray-150">
        <h4 className="mb-2 font-semibold">Players</h4>
        {playerIds.length ? (
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-1">
            <span>Player</span>
            <span>New player headshot URL</span>
          </div>
        ) : null}
        {playerIds.map((playerId: string) => (
          <div
            key={`player-assets-${playerId}`}
            className="grid md:grid-cols-2 grid-cols-1 gap-2 mb-1"
          >
            <AssetsSelect
              entity={"players"}
              selectedOption={
                options?.players.find((p) => p.id === playerId) ||
                selectedOptionPlayer.get(playerId) ||
                null
              }
              setSelectedOption={setSelectedOptionPlayer}
              setCustomAssets={setCustomAssets}
              entityId={playerId}
            />
            <div className="flex items-center self-stretch mb-2">
              <input
                type="text"
                className="w-full mr-1"
                defaultValue={customAssets.playerHeadshots[playerId]}
                onChange={(event) =>
                  handleOnChangeURL("players", playerId, event.target.value)
                }
              />
              <FileUploader
                type={"custom_player_headshot"}
                id={playerId}
                onChangeImages={(url) =>
                  handleOnChangeURL("players", playerId, url)
                }
              />
            </div>
          </div>
        ))}
        <AddButton onClick={() => addAssets("players", playerIds.length)} />
      </div>
    </div>
  );
};

export default CustomAssets;

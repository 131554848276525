import React from "react";
import { TopDiscussionReportType } from "./DiscussionTableView";
import { placeholders } from "../../../../assets/placeholders/placeholders";
import { imageStyle } from "../../Dashboard/FollowedEntities/TopFollowedEntityRow";
import FormattedNumber from "../../../Common/FormattedNumber";

type DiscussionRecordProps = {
  type: TopDiscussionReportType;
  record: any;
};

const DiscussionRecord: React.FC<DiscussionRecordProps> = ({
  type,
  record,
}) => {
  let name = "";

  if (record.profileModel) {
    name = record.profileModel?.nickname || record.profileModel?.name;
  } else {
    name = "-------------------";
  }

  return (
    <tr className="px-4 border-b border-blueGray-100">
      {type === "top_contributors" ? (
        <td
          className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-left text-blueGray-500 flex items-center`}
        >
          {type === "top_contributors" ? (
            <img
              src={record.profileModel?.avatar || placeholders.avatar}
              className={imageStyle}
              alt={record.profileModel?.name}
            />
          ) : (
            <></>
          )}
          <span className="ml-3 font-bold text-blueGray-600 mr-2">{name}</span>
        </td>
      ) : (
        <td
          className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-left text-blueGray-500`}
        >
          <span className="ml-3 font-bold text-blueGray-600 mr-2">{name}</span>
        </td>
      )}
      <td
        className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-right text-blueGray-500`}
      >
        <FormattedNumber numberValue={record.postsCount} />
      </td>
    </tr>
  );
};

export default DiscussionRecord;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export const useAverageFollows = () => {
  const [averageFollow, setAverageFollows] =
    useState<Map<string, number>>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getAverageFollows = async () => {
    try {
      const averageFollows = await api.reportingHttps.getAverageFollows();
      let averageFollowsMap = new Map<string, number>();
      averageFollows.breakdown.forEach(
        (value: { average: number; source: string; type: string }) => {
          averageFollowsMap.set(value.type, value.average);
        }
      );
      averageFollowsMap.set("average", averageFollows.average);

      setAverageFollows(averageFollowsMap);
    } catch (error) {
      toast.error(ERROR_MESSAGES.AVERAGES_REPORT);
      setAverageFollows(new Map<string, number>().set("no_data_available", 0));
    }
  };

  useEffect(() => {
    getAverageFollows();

    return () => setAverageFollows(null);
  }, [selectedClient]);

  return averageFollow;
};

import React, { useContext } from "react";
import { StaffAccessContext } from "../../providers/StaffAccessProvider";
import { ClientSelectVersion } from "../../types/types";

type CLientLabelProps = {
  version: ClientSelectVersion;
};

/**
 * When staff members enters the management portal display a label with the name of the project he has access to
 */
const ClientLabel: React.FC<CLientLabelProps> = ({ version }) => {
  const staffClients = useContext(StaffAccessContext);

  return (
    <>
      {staffClients && staffClients.length === 1 ? (
        <label
          className={`${
            version === "web" ? "text-white" : "text-brandColor"
          } font-bold`}
        >
          {staffClients[0].name}
        </label>
      ) : null}
    </>
  );
};

export default ClientLabel;

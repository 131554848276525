import React, { Dispatch, SetStateAction } from "react";

type EditStaffMemberTabsProps = {
  openTab: string;
  setOpenTab: Dispatch<SetStateAction<string>>;
};

const EditStaffMemberTabs: React.FC<EditStaffMemberTabsProps> = ({
  openTab,
  setOpenTab,
}) => {
  return (
    <ul
      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
      role="tablist"
    >
      <li className="sm:-mb-px mb-2 sm:mr-2 mr-0 last:mr-0 flex-auto text-center">
        <a
          className={
            "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal " +
            (openTab === "NAME"
              ? "text-lightBlue-600 bg-white"
              : "text-white bg-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab("NAME");
          }}
          data-toggle="tab"
          href="#link1"
          role="tablist"
        >
          Edit staff member
        </a>
      </li>
      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <a
          className={
            "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal " +
            (openTab === "PASSWORD"
              ? "text-lightBlue-600 bg-white"
              : "text-white bg-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab("PASSWORD");
          }}
          data-toggle="tab"
          href="#link2"
          role="tablist"
        >
          Change password
        </a>
      </li>
    </ul>
  );
};

export default EditStaffMemberTabs;

import React from "react";

type StaffButtonProps = {
  label: string;
  onClick: () => void;
};

const EditButton: React.FC<StaffButtonProps> = ({ label, onClick }) => {
  return (
    <button
      className={`${
        label.includes("Edit")
          ? "bg-yellow-300 hover:bg-yellow-400 text-black"
          : "bg-brandColor hover:bg-lightBlue-800 text-white"
      } text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default EditButton;

import React from "react";
import { ITopTemplates } from "../../../../../models/Reports/PrivateLeagues/PrivateLeaguesTopTemplatesModel";
import { headerCellStyle } from "../../Common/EntityFollows/TableHeaderTopFollows";
import FormattedNumber from "../../../../Common/FormattedNumber";

const typeToLabelMap = new Map()
  .set("private", "Private")
  .set("one_vs_one", "One vs One");

type PrivateLeagueTableViewProps = {
  type: "private" | "one_vs_one";
  data: ITopTemplates[];
};

const PrivateLeagueTableView: React.FC<PrivateLeagueTableViewProps> = ({
  type,
  data,
}) => {
  return (
    <div className="mb-6 border border-blueGray-200 rounded p-2">
      <h2 className="text-center mb-4 font-bold text-xl">{`Top ${
        data.length
      } templates for ${typeToLabelMap.get(type)} leagues`}</h2>
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th className={`${headerCellStyle} text-left`}>Name</th>
              <th className={`${headerCellStyle} text-right`}>Template usage</th>
            </tr>
          </thead>
          <tbody>
            {data.map((record) => (
              <tr key={record.id} className="px-4 border-b border-blueGray-100">
                <td
                  className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-left text-blueGray-500`}
                >
                  <span className="font-bold text-blueGray-600">
                    {record.model.name || "----------------"}
                  </span>
                </td>
                <td
                  className={`border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-right text-blueGray-500`}
                >
                  <FormattedNumber numberValue={record.count} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrivateLeagueTableView;

import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (
  btnDropdownRef: any,
  popoverDropdownRef: any,
  setDropdownPopoverShow: Dispatch<SetStateAction<boolean>>
) => {
  const [isOutside, setIsOutside] = useState<boolean>();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverDropdownRef.current &&
      popoverDropdownRef.current.contains(event.target)
    ) {
      setIsOutside(false);
      setDropdownPopoverShow(true);
      return;
    }
    if (
      btnDropdownRef.current &&
      !btnDropdownRef.current.contains(event.target)
    ) {
      setIsOutside(true);
      setDropdownPopoverShow(false);
      return;
    }

    setIsOutside(false);
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [btnDropdownRef, popoverDropdownRef]);

  return isOutside;
};

export default useOutsideAlerter;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import EngagementSegmentsModel from "../models/Reports/Engagement/EngagementSegmentsModel";

export const useEngagementSegments = (entityId: string) => {
  const [engagementSegments, setEngagementSegments] =
    useState<EngagementSegmentsModel>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getEngagementSegmentsReport = async () => {
    try {
      const engagementSegments = await api.reportingHttps.getEngagementSegments(
        entityId
      );

      /**
       * Reconstruct breakdown. From API it comes in list of Map (and each map is with one segment).
       * It will be reconstruct to one common Map with all segments in it. Also will be sorted from
       * fanatics to newest users.
       */
      if (engagementSegments.breakdown.length) {
        const newMap = new Map();
        const order = [
          "fanatics",
          "fans",
          "casualUsers",
          "atRisk",
          "inactive",
          "new",
        ];
        const sortedBreakdown = engagementSegments.breakdown.sort((a, b) => {
          return (
            order.indexOf(Object.keys(a)[0]) - order.indexOf(Object.keys(b)[0])
          );
        });

        sortedBreakdown.forEach((value: Object) => {
          const key = Object.keys(value)[0];

          newMap.set(key, value[key]);
        });

        engagementSegments.breakdown = newMap;
      } else {
        engagementSegments.breakdown = new Map();
      }

      setEngagementSegments(engagementSegments);
    } catch (error) {
      toast.error(ERROR_MESSAGES.ENGAGEMENT_SEGMENTS_REPORT);
      setEngagementSegments(new EngagementSegmentsModel());
    }
  };

  useEffect(() => {
    getEngagementSegmentsReport();

    return () => setEngagementSegments(null);
  }, [selectedClient, entityId]);

  return engagementSegments;
};

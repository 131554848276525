import React, { PropsWithChildren } from "react";

type StatsContainerProps = {
  isSuccessRate?: boolean; // Used to change the breakpoint for xl screen when the component is used for success rate in follows report
  isSuccessRateMarketReport?: boolean;
};

const StatsContainer: React.FC<StatsContainerProps & PropsWithChildren> = ({
  isSuccessRate,
  isSuccessRateMarketReport,
  children,
}) => {
  return (
    <div
      className={`w-full lg:w-6/12 ${
        isSuccessRate ? "xl:w-4/12" : "xl:w-3/12"
      } pr-4`}
    >
      <div
        className={`relative flex flex-col min-w-0 break-words ${
          isSuccessRateMarketReport ? "lg:h-72 bg-blueGray-50" : "bg-white xl:mb-0"
        } rounded mb-6 shadow-lg`}
      >
        <div className="flex-auto p-4">{children}</div>
      </div>
    </div>
  );
};

export default StatsContainer;

import React from "react";
import LoyaltyInput from "../../../../Common/LoyaltyInput";
import RangeCalendar from "../../../../Common/RangeCalendar";
import {
  IGamesBadgeRequirements,
  ISpecificGamesBadgeRequirement,
} from "../../../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";
import BadgeSpecificGames from "./BadgeSpecificGames";

type GamesBadgeRequirementsProps = {
  uid: string;
  specificGames: ISpecificGamesBadgeRequirement[];
  requirements: IGamesBadgeRequirements;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
};

const GamesBadgeRequirements: React.FC<GamesBadgeRequirementsProps> = ({
  uid,
  specificGames,
  requirements,
  onChangeBadges,
}) => {
  return (
    <div className="mb-2">
      <label className="font-bold mb-2 text-center">Requirements:</label>
      <LoyaltyInput
        uid={uid}
        label="Participation Count"
        value={requirements.participationCount}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "participationCount", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Points"
        value={requirements.points}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "points", value)
        }
        scenario="Badges"
      />
      {/**TODO: When from date and to date is implemented then enable that (FZ-2694) */}
      {/* <RangeCalendar
        defaultDate={[requirements.fromDate, requirements.toDate]}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "dateRange", value)
        }
      /> */}
      <BadgeSpecificGames
        specificGames={specificGames}
        loadedSpecificGames={requirements.specificGames}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "specificGames", value)
        }
      />
    </div>
  );
};

export default GamesBadgeRequirements;

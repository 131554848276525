import React, { useState } from "react";
import RFMGroups from "./RFMGroups/RFMGroups";
import ReportTwoTabs from "../Common/ReportTwoTabs";
import { useEngagementCoefficientsConfig } from "../../../../hooks/features/useEngagementCoefficientsConfig";
import EngagementSegments from "./EngagementSegments/EngagementSegments";

export enum ENGAGEMENT_TABS {
  RFE_GROUPS = "rfe_groups",
  ENGAGEMENT_SEGMENTS = "engagement_segments",
}

const EngagementReport: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>(ENGAGEMENT_TABS.RFE_GROUPS);

  const { engagementCoefficientsConfig, whitelistEntities } =
    useEngagementCoefficientsConfig();

  return (
    <div className="w-full px-4">
      <ReportTwoTabs
        reportType={"engagement"}
        tabValue={openTab}
        setTabValue={setOpenTab}
      />
      {openTab === ENGAGEMENT_TABS.RFE_GROUPS ? (
        <RFMGroups whitelistEntities={whitelistEntities} />
      ) : (
        <EngagementSegments
          engagementCoefficientsConfig={engagementCoefficientsConfig}
          whitelistEntities={whitelistEntities}
        ></EngagementSegments>
      )}
    </div>
  );
};

export default EngagementReport;

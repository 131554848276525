import React from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../../enums/enums";
import { inputNumber } from "../../../../helpers/helpers";
import GlobalValidator from "../../../../validators/GlobalValidator";
import { LoyaltyRewardsEntities } from "../FeaturesLoyalty/LoyaltyRewards/LoyaltyRewards";

type LoyaltyTierInputProps = {
  uid: string;
  label: string;
  value: string | number;
  scenario: LoyaltyRewardsEntities;
  onChangeTiers?: (
    scenario: "Tiers",
    uid: string,
    value: string,
    field: string
  ) => void;
  onChangeBadges?: (value: string) => void;
};

const LoyaltyInput: React.FC<LoyaltyTierInputProps> = ({
  uid,
  label,
  value,
  scenario,
  onChangeTiers,
  onChangeBadges,
}) => {
  const onKeyDown = (event: any) => {
    if (
      label !== "ID" &&
      label !== "Label" &&
      label !== "Main image URL" &&
      label !== "Description"
    ) {
      inputNumber(event, false);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // Prevent duplicating toast
    const toastId = "custom-toast-id";
    const globalValidator = new GlobalValidator();

    if (label === "ID") {
      if (!globalValidator.isIdValueValid(event.target.value)) {
        toast.error(ERROR_MESSAGES.FEATURES_ID_VALUES_VALIDATION, {
          toastId,
        });
        return;
      }
    }

    if (label === "Main image URL") {
      if (!globalValidator.isValidURL(event.target.value)) {
        toast.error(ERROR_MESSAGES.FEATURES_BADGE_ASSETS_INVALID_URL, {
          toastId,
        });
        return;
      }
    }

    scenario === "Tiers"
      ? onChangeTiers(
          scenario,
          uid,
          event.target.value,
          label.toLocaleLowerCase()
        )
      : onChangeBadges(event.target.value);
  };

  return (
    <div className="flex flex-col mb-2">
      <label className="font-bold mb-2">{label}</label>
      <input
        className="p-3"
        defaultValue={value}
        onChange={onChangeInput}
        type={
          label === "ID" ||
          label === "Label" ||
          label === "Description" ||
          label === "Main image URL"
            ? "text"
            : "number"
        }
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default LoyaltyInput;

import DiscussionsBadgesExtended from "../Badges/Discussions/DiscussionsBadgesExtended";
import FootballBadgesExtended from "../Badges/Football/FootballBadgesExtended";
import GameBadgesExtended from "../Badges/Game/GameBadgesExtended";
import GamesBadgesExtended from "../Badges/Games/GamesBadgesExtended";
import MiniGamesBadgesExtended from "../Badges/MiniGames/MiniGamesBadgesExtended";
import PredictorBadgesExtended from "../Badges/Predictor/PredictorBadgesExtended";
import VotingBadgesExtended from "../Badges/Voting/VotingBadgesExtended";

export interface IVotingBadgesModel {
  potm: VotingBadgesExtended[];
  poll: VotingBadgesExtended[];
}

export default class BadgesExtendedModel {
  predictor: PredictorBadgesExtended[] = [];
  topX: GameBadgesExtended[] = [];
  matchQuiz: GameBadgesExtended[] = [];
  games: GamesBadgesExtended[] = [];
  football: FootballBadgesExtended[] = [];
  classicQuizzes: MiniGamesBadgesExtended[] = [];
  eitherOr: MiniGamesBadgesExtended[] = [];
  discussions: DiscussionsBadgesExtended[] = [];
  voting: IVotingBadgesModel = {
    potm: [],
    poll: [],
  };
}

import React, { Dispatch, SetStateAction } from "react";
import { inputNumber } from "../../../../../helpers/helpers";

type SuccessRateNumberFilterProps = {
  placeholder: string;
  setNumberFilter: Dispatch<SetStateAction<number>>;
};

const SuccessRateNumberFilter: React.FC<SuccessRateNumberFilterProps> = ({
  placeholder,
  setNumberFilter,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Add 1 second delay so we avoid making requests for each typed number
    setTimeout(() => setNumberFilter(Number(event.target.value)), 1000);
  };

  return (
    <div className="mb-2 xl:w-6/12 w-full">
      <input
        className="border-blueGray-200 border rounded w-full"
        onChange={onChange}
        type="number"
        onKeyDown={(event) => inputNumber(event, false)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SuccessRateNumberFilter;

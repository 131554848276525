import React, { Dispatch, SetStateAction } from "react";
import FootballEntityFollows from "../../../../../models/Reports/FootballCompetitionsFollows/FootballEntityFollows";
import TopFollowsFootballEntitiesModel from "../../../../../models/Reports/FootballCompetitionsFollows/TopFollowsFootballEntitiesModel";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { Entity } from "../../../../../types/types";
import EntityFollows from "./EntityFollows";
import TopEntities from "./TopEntities";

type FootballEntitiesFollowsProps = {
  topFollowsFootballEntities: TopFollowsFootballEntitiesModel;
  entityFollowsInfo: FootballEntityFollows;
  entity: Entity;
  setEntityIds: Dispatch<SetStateAction<string[]>>;
  setEntities: Dispatch<SetStateAction<SelectOption[]>>;
};

const FootballEntitiesFollows: React.FC<FootballEntitiesFollowsProps> = ({
  topFollowsFootballEntities,
  entityFollowsInfo,
  entity,
  setEntityIds,
  setEntities,
}) => {
  if (entityFollowsInfo) {
    return (
      <EntityFollows enittyFollowsInfo={entityFollowsInfo} entity={entity} />
    );
  }

  return (
    <TopEntities
      topFollowsFootballEntities={topFollowsFootballEntities}
      entity={entity}
      setEntityIds={setEntityIds}
      setEntities={setEntities}
    />
  );
};

export default FootballEntitiesFollows;

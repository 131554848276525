import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import { sortObjectPropertiesByTheirValue } from "../../helpers/helpers";
import PredictionsPerEntityBreakdown from "../../models/Reports/Predictions/PredictionsPerEntityBreakdown";
import { ApiContextValueType } from "../../providers/ApiProvider";
import EntityFollowsBuilder from "./EntityFollowsBuilder";

export default class PredictionsPerEntityBuilder extends EntityFollowsBuilder {
  private predictionsPerEntityBreakdown: any[] = null;

  constructor(api: ApiContextValueType, predictionsPerEntityBreakdown: any) {
    super(api);
    this.predictionsPerEntityBreakdown = predictionsPerEntityBreakdown;
  }

  public build = async () => {
    await this.setModel();
    this.sortPredictionsByTheirCount();

    return this.predictionsPerEntityBreakdown;
  };

  private setModel = async () => {
    const competitionIds: string[] = [];
    const teamIds: string[] = [];
    const playerIds: string[] = [];

    if (
      !this.predictionsPerEntityBreakdown ||
      !this.predictionsPerEntityBreakdown.length
    )
      return;

    this.predictionsPerEntityBreakdown.forEach(
      (breakdown: PredictionsPerEntityBreakdown) => {
        if (breakdown.type === "COMPETITION") {
          competitionIds.push(breakdown.entity);
        } else if (breakdown.type === "TEAM") {
          teamIds.push(breakdown.entity);
        } else if (breakdown.type === "PLAYER") {
          playerIds.push(breakdown.entity);
        }
      }
    );

    const competitionsMap = await this.getEntitiesMap(
      "competitions",
      competitionIds
    );
    const teamsMap = await this.getEntitiesMap("teams", teamIds);
    const playerMap = await this.getEntitiesMap("players", playerIds);

    this.predictionsPerEntityBreakdown.forEach(
      (breakdown: PredictionsPerEntityBreakdown) => {
        if (
          breakdown.type === "COMPETITION" &&
          competitionsMap.get(breakdown.entity)
        ) {
          breakdown.entityModel = competitionsMap.get(
            breakdown.entity
          ) as CompetitionBasicModel;
        } else if (
          breakdown.type === "TEAM" &&
          teamsMap.get(breakdown.entity)
        ) {
          breakdown.entityModel = teamsMap.get(
            breakdown.entity
          ) as TeamBasicModel;
        } else if (
          breakdown.type === "PLAYER" &&
          playerMap.get(breakdown.entity)
        ) {
          breakdown.entityModel = playerMap.get(
            breakdown.entity
          ) as PlayerBasicModel;
        }
      }
    );
  };

  private sortPredictionsByTheirCount = () => {
    this.predictionsPerEntityBreakdown.forEach((breakdownValue: any) => {
      breakdownValue.predictions = sortObjectPropertiesByTheirValue(
        breakdownValue.predictions
      );
    });
  };
}

import React from "react";
import LoyaltyInput from "../../../../Common/LoyaltyInput";
import { IPredictorBadgeRequirements } from "../../../../../../../models/features/loyalty/Badges/Predictor/PredictorBadgesExtended";
import RangeCalendar from "../../../../Common/RangeCalendar";

type PredictorBadgeRequirementsProps = {
  uid: string;
  requirements: IPredictorBadgeRequirements;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
};

const PredictorBadgeRequirements: React.FC<PredictorBadgeRequirementsProps> = ({
  uid,
  requirements,
  onChangeBadges,
}) => {
  return (
    <div className="mb-2">
      <label className="font-bold mb-2 text-center">Requirements:</label>
      <LoyaltyInput
        uid={uid}
        label="Predictions Number"
        value={requirements.predictionsNumber}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "predictionsNumber", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Correct Predictions"
        value={requirements.correctPredictions}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "correctPredictions", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Points"
        value={requirements.points}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "points", value)
        }
        scenario="Badges"
      />
      {/**TODO: When from date and to date is implemented then enable that (FZ-2694) */}
      {/* <RangeCalendar
        defaultDate={[requirements.fromDate, requirements.toDate]}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "dateRange", value)
        }
      /> */}
    </div>
  );
};

export default PredictorBadgeRequirements;

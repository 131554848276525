import Chart, { ChartData } from "chart.js";
import React, { useContext, useState } from "react";
import { useEffect, useRef } from "react";
import UsersAquiredBuilder from "../../../builders/dashboard/UsersAquiredBuilder";
import {
  ApiContextValueType,
  ApiContext,
} from "../../../providers/ApiProvider";
import { getLast28Days } from "../../../helpers/helpers";
import UsersAquired from "../../../models/Reports/UsersAquired";
import ReportingHttps from "../../../services/https/ReportingHttps";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../enums/enums";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";

const UsersAquiredChart: React.FC = () => {
  const [lineChartConfig, setLineChartConfig] = useState<any>(null);

  const api = useContext(ApiContext) as ApiContextValueType;
  const { selectedClient } = useContext(GlobalStatesContext);

  const builder = new UsersAquiredBuilder(api.reportingHttps as ReportingHttps);
  // use a ref to store the chart instance since it it mutable
  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;

    if (chartRef.current) chartRef.current.destroy();

    const ctx = canvas.getContext("2d");

    if (ctx) {
      chartRef.current = new Chart(ctx, lineChartConfig);
    }
  };

  useEffect(() => {
    builder.build().then((usersAquiredMap: Map<string, UsersAquired>) => {
      const usersPast28Days = usersAquiredMap.get("USERS_PAST_28_DAYS");
      const usersPast28DaysBeforeLast28Days = usersAquiredMap.get(
        "USERS_28_DAYS_PAST_28_DAYS"
      );

      if (
        !usersPast28Days?.allUsers.length ||
        !usersPast28Days?.period.length ||
        !usersPast28DaysBeforeLast28Days?.allUsers.length ||
        !usersPast28DaysBeforeLast28Days?.period.length
      ) {
        toast.error(ERROR_MESSAGES.USERS_AQUIRED_REPORT);
        setLineChartConfig(null);
      }

      const finalLineChartConfig = {
        type: "line",
        data: {
          labels: getLast28Days(),
          datasets: [
            {
              labels: usersPast28Days?.period,
              backgroundColor: "#0066cc",
              borderColor: "#0066cc",
              data: usersPast28Days?.allUsers,
              fill: false,
              lineTension: 0,
            },
            {
              labels: usersPast28DaysBeforeLast28Days?.period,
              fill: false,
              backgroundColor: "#ed7e17",
              borderColor: "#ed7e17",
              data: usersPast28DaysBeforeLast28Days?.allUsers,
              lineTension: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Users aquired past days",
            fontColor: "white",
          },
          legend: {
            display: false,
            labels: {
              fontColor: "white",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                //@ts-ignore
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var index = tooltipItem.index;
                return dataset.labels[index] + ": " + dataset.data[index];
              },
              title: () => {},
            },
          },
          hover: {
            mode: "nearest",
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "day",
                  fontColor: "white",
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: 2,
                  color: "rgba(33, 37, 41, 0.3)",
                  zeroLineColor: "rgba(0, 0, 0, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: 2,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                  fontColor: "white",
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: 3,
                  drawBorder: false,
                  color: "rgba(255, 255, 255, 0.15)",
                  zeroLineColor: "rgba(33, 37, 41, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: 2,
                },
              },
            ],
          },
        },
      };
      setLineChartConfig(finalLineChartConfig);

      // must verify that the chart exists
      if (chartRef && chartRef.current) {
        chartRef.current.data = finalLineChartConfig.data as ChartData;
        chartRef.current.update();
      }
    });
  }, [selectedClient]);

  return (
    <div className="p-4 flex-auto">
      <div className="relative h-350-px w-350-px">
        <canvas ref={canvasCallback}></canvas>
      </div>
    </div>
  );
};

export default UsersAquiredChart;

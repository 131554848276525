import React from "react";
import { formatProfileDate } from "../../../../helpers/helpers";
import PredictionsPerEntityBreakdown from "../../../../models/Reports/Predictions/PredictionsPerEntityBreakdown";
import FormattedNumber from "../../../Common/FormattedNumber";
import { getAssetsForEntity } from "../Common/EntityFollows/EntityInformation";
import PredictionsBreakdown from "./PredictionsBreakdown";

type EntityPredictionsBreakdown = {
  breakdown: PredictionsPerEntityBreakdown;
};

const EntityPredictionsBreakdown: React.FC<EntityPredictionsBreakdown> = ({
  breakdown,
}) => {
  return (
    <tr className="px-4 border-b border-blueGray-100">
      <th className="border-t-0 border-l-0 border-r-0 whitespace-nowrap p-3 lg:text-left text-center">
        <div className="flex items-center">
          <div className="w-16 mr-4 hidden lg:block">
            <img
              src={getAssetsForEntity(
                breakdown.type === "COMPETITION" ? true : false,
                breakdown.type === "PLAYER" ? false : true,
                breakdown.entityModel
              )}
              className={
                breakdown.type === "COMPETITION" ? "w-14 h-14" : "w-12 h-12"
              }
            ></img>
          </div>
          <div className="mb-4 w-auto md:w-full">
            <div className="flex items-center lg:justify-start justify-center lg:mb-0 mb-2">
              <img
                src={getAssetsForEntity(
                  breakdown.type === "COMPETITION" ? true : false,
                  breakdown.type === "PLAYER" ? false : true,
                  breakdown.entityModel
                )}
                className={`${
                  breakdown.type === "COMPETITION" ? "h-8" : "h-10"
                } w-10 mr-2 lg:hidden block`}
              ></img>
              <h3 className="font-bold">{breakdown.entityModel?.name}</h3>
            </div>
            <div className="lg:flex lg:flex-row lg:items-center hidden">
              {breakdown.type === "NONE" ? (
                <p className="text-sm">
                  {breakdown.entity === "OWN_GOAL" ? "Own goal" : "Nobody"}
                </p>
              ) : null}
              {breakdown.type === "COMPETITION" ? (
                <>
                  <p className="text-sm">
                    Country:{" "}
                    <span className="font-bold text-blueGray-400 text-sm mr-2">
                      {breakdown.entityModel?.country?.name}
                    </span>
                  </p>
                  {
                    //@ts-ignore
                    breakdown.entityModel.type ? (
                      <p className="text-sm">
                        Type:{" "}
                        <span className="font-bold text-blueGray-400 text-sm mr-2">
                          {
                            //@ts-ignore
                            breakdown.entityModel.type
                          }
                        </span>
                      </p>
                    ) : null
                  }
                </>
              ) : null}
              {breakdown.type === "TEAM" ? (
                <>
                  <p className="text-sm">
                    Country:{" "}
                    <span className="font-bold text-blueGray-400 text-sm mr-2">
                      {breakdown.entityModel?.country?.name}
                    </span>
                  </p>
                  <p className="text-sm">
                    Gender:{" "}
                    <span className="font-bold text-blueGray-400 text-sm mr-2">
                      {
                        //@ts-ignore
                        breakdown.entityModel?.gender
                      }
                    </span>
                  </p>
                </>
              ) : null}
              {breakdown.type === "PLAYER" ? (
                <>
                  <p className="text-sm">
                    Country:{" "}
                    <span className="font-bold text-blueGray-400 text-sm mr-2">
                      {breakdown.entityModel?.country?.name}
                    </span>
                  </p>
                  <p className="text-sm">
                    Birthdate:{" "}
                    <span className="font-bold text-blueGray-400 text-sm mr-2">
                      {formatProfileDate(
                        //@ts-ignore
                        breakdown.entityModel?.birthDate
                      )}
                    </span>
                  </p>
                </>
              ) : null}
            </div>
            <PredictionsBreakdown
              entityId={breakdown.entity}
              predictions={breakdown.predictions}
            />
          </div>
        </div>
      </th>
      <td className="border-t-0 border-l-0 border-r-0 text-sm whitespace-nowrap p-3 xl:table-cell hidden text-right text-blueGray-500">
        <FormattedNumber numberValue={breakdown.predictions.all} />
      </td>
    </tr>
  );
};

export default EntityPredictionsBreakdown;

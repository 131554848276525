import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import ProfileCountryModel from "fansunited-sdk-esm/Core/Namespaces/Profile/Models/ProfileCountryModel";
import FootballEntityFollows from "../../models/Reports/FootballCompetitionsFollows/FootballEntityFollows";
import { ApiContextValueType } from "../../providers/ApiProvider";
import { Entity } from "../../types/types";
import EntityFollowsBuilder from "./EntityFollowsBuilder";

export default class FootballEntityFollowsBuilder extends EntityFollowsBuilder {
  private entityId: string = null;
  public footballEntitiesFollows = new FootballEntityFollows();
  private successRatesEntitiesBreakdown: {
    [key: string]: { [key: string]: number };
  };
  private entity: Entity = null;

  constructor(
    api: ApiContextValueType,
    competitionId: string,
    successRatesEntitiesBreakdown: any | null,
    competitionFollowsInfo: any,
    entity: Entity
  ) {
    super(api);
    this.entityId = competitionId;
    this.successRatesEntitiesBreakdown = successRatesEntitiesBreakdown;
    this.footballEntitiesFollows.reportInformation = competitionFollowsInfo;
    this.entity = entity;
  }

  public build = async () => {
    this.setSuccessRate();
    await this.setProfileCountries();
    await this.setModel();

    return this.footballEntitiesFollows;
  };

  private setModel = async () => {
    let model: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel = null;
    const limit = 1;

    try {
      if (this.entity === "competitions") {
        const competitions = await this.getCompetitions([this.entityId]);
        model = competitions[0];
      } else if (this.entity === "teams") {
        const teams = await this.getTeams([this.entityId], limit);
        model = teams[0];
      } else if (this.entity === "players") {
        const players = await this.getPlayers([this.entityId], limit);
        model = players[0];
      }
    } catch (error) {}

    this.footballEntitiesFollows.model = model;
  };

  private setProfileCountries = async () => {
    const profileCountriesMap: Map<string, ProfileCountryModel> = new Map();
    // There is a chance that the country id from report breakdown is nullable value so we filter it out.
    const usersCountryIds =
      this.footballEntitiesFollows.reportInformation.breakdown
        .map((value: { [key: string]: any }) => value.id)
        .filter(
          (value: string | null) => typeof value === "string"
        ) as string[];

    if (!usersCountryIds.length) return;

    try {
      const countries = await this.getProfileCountries();

      countries.forEach((country: ProfileCountryModel) => {
        if (usersCountryIds.includes(country.id)) {
          profileCountriesMap.set(country.id, country);
        }
      });
    } catch (error) {}

    this.footballEntitiesFollows.profileCountries = profileCountriesMap;
  };

  private setSuccessRate = () => {
    // When we use this method for entity players, we don't have any success rates.
    if (!this.successRatesEntitiesBreakdown) {
      delete this.footballEntitiesFollows.successRate;
      return;
    }

    const successRatesEntityIds = Object.keys(
      this.successRatesEntitiesBreakdown
    ).filter(
      (id: string) =>
        id.includes(this.getEntityRootId(this.entity)) && this.entityId === id
    );

    if (successRatesEntityIds.length) {
      for (const key of Object.keys(this.successRatesEntitiesBreakdown)) {
        if (
          key.includes(this.getEntityRootId(this.entity)) &&
          successRatesEntityIds.includes(key)
        ) {
          this.footballEntitiesFollows.successRate = `${this.successRatesEntitiesBreakdown[key].success_rate_percent}%`;
          return;
        } else {
          this.footballEntitiesFollows.successRate = "N/A";
        }
      }
    } else {
      this.footballEntitiesFollows.successRate = "N/A";
    }
  };
}

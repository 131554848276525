import React, { useState } from "react";
import { usePrivateLeaguesBreakdown } from "../../../../../hooks/reports/privateLeagues/usePrivateLeaguesBreakdown";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import DatePeriod from "../../Common/DatePeriod";
import GroupByPeriod from "../../Common/GroupByPeriod";
import Spinner from "../../../../Spinner/Spinner";
import "../../Reports.css";
import TotalPrivateLeagues from "./TotalPrivateLeagues";
import LineChart from "../../Common/LineChart";
import { constructLabelsChart } from "../../../../../helpers/helpers";
import PrivateLeaguesBreakdownBuilder from "../../../../../builders/reports/PrivateLeaguesBreakdownBuilder";

const PrivateLeagueBreakdown: React.FC = () => {
  const [privateLeaguesBreakdown, setPrivateLeaguesBreakdown] = useState<
    string[]
  >(["all"]);

  const onChangeCheckbox = (privateLeagueBreakdown: string) => {
    const currentBreakdown = [...privateLeaguesBreakdown];
    if (
      currentBreakdown.includes(privateLeagueBreakdown) &&
      currentBreakdown.length !== 1
    ) {
      const newUsersBreakdown = currentBreakdown.filter(
        (value: string) => value !== privateLeagueBreakdown
      );
      setPrivateLeaguesBreakdown(newUsersBreakdown);
    } else if (!currentBreakdown.includes(privateLeagueBreakdown)) {
      currentBreakdown.push(privateLeagueBreakdown);
      setPrivateLeaguesBreakdown(currentBreakdown);
    }
  };

  const privateLeagues = usePrivateLeaguesBreakdown();
  const datasets = new PrivateLeaguesBreakdownBuilder(
    JSON.parse(JSON.stringify(privateLeagues)),
    privateLeaguesBreakdown
  ).build();

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Private Leagues breakdown"}
          description={
            "This is a breakdown of private leagues for a specified period of time. In this report you will also find a breakdown by private league type (private or one vs one)."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod />
        </div>
      </div>
      <GroupByPeriod />
      {privateLeagues && privateLeagues.current ? (
        <>
          <TotalPrivateLeagues
            total={privateLeagues.current.total}
            privateLeaguesBreakdown={privateLeaguesBreakdown}
            onChangeCheckbox={onChangeCheckbox}
          />
          <LineChart
            labels={constructLabelsChart(
              privateLeagues.current.breakdown.private,
              privateLeagues.comparedTo?.breakdown?.private
            )}
            datasets={datasets}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PrivateLeagueBreakdown;

import React from "react";
import { booleanToYesNo } from "./Enabled";
import Select from "react-select";
import { asyncSelectStyles } from "../../../../constants/styles";
import { LoyaltyRewardsEntities } from "../FeaturesLoyalty/LoyaltyRewards/LoyaltyRewards";
import RemoveButton from "../../../Buttons/RemoveButton";

const transformEnabledTierToYesNo = (enableTier: boolean) => {
  return {
    value: booleanToYesNo[enableTier.toString()],
    label: booleanToYesNo[enableTier.toString()],
  };
};

const transformEnabledTierToBoolean = (selectedValue: string): boolean => {
  return selectedValue === "Yes" ? true : false;
};

const enabledOptions = [
  { value: booleanToYesNo["true"], label: booleanToYesNo["true"] },
  { value: booleanToYesNo["false"], label: booleanToYesNo["false"] },
];

type EnabledSelectProps = {
  uid: string;
  enabled: boolean;
  scenario: LoyaltyRewardsEntities;
  onChangeTiers?: (
    scenario: "Tiers",
    uid: string,
    value: boolean,
    field: string
  ) => void;
  onChangeBadges?: (
    field: string,
    requirementField: string,
    value: string | boolean
  ) => void;
  removeExistingReward?: () => void;
};

const EnabledSelect: React.FC<EnabledSelectProps> = ({
  uid,
  enabled,
  scenario,
  onChangeTiers,
  onChangeBadges,
  removeExistingReward,
}) => {
  return (
    <div className="mb-2">
      <div className="flex justify-between items-center mb-2">
        <label className="font-bold">Enabled</label>
        <RemoveButton onClick={removeExistingReward} />
      </div>
      <Select
        onChange={(selectedClient) => {
          scenario === "Tiers"
            ? onChangeTiers(
                "Tiers",
                uid,
                transformEnabledTierToBoolean(selectedClient.value),
                "enabled"
              )
            : onChangeBadges(
                "enabled",
                "",
                transformEnabledTierToBoolean(selectedClient.value)
              );
        }}
        defaultValue={transformEnabledTierToYesNo(enabled)}
        options={enabledOptions}
        styles={asyncSelectStyles}
      />
    </div>
  );
};

export default EnabledSelect;

import React from "react";
import { usePostsBreakdown } from "../../../../../hooks/reports/discussions/usePostsBreakdown";
import DiscussionsDataset from "../../../../../models/Reports/Discussions/DiscussionsDataset";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import DatePeriod from "../../Common/DatePeriod";
import GroupByPeriod from "../../Common/GroupByPeriod";
import LineChart from "../../Common/LineChart";
import { constructLabelsChart } from "../../../../../helpers/helpers";
import Spinner from "../../../../Spinner/Spinner";

const PostsBreakdown: React.FC = () => {
  const postsBreakdown = usePostsBreakdown();
  const datasets = new DiscussionsDataset(
    postsBreakdown?.current,
    postsBreakdown?.comparedTo,
    "Posts"
  ).discussionsDatasets;

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Posts breakdown"}
          description={
            "This is a breakdown of posts for a specified period of time."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod />
        </div>
      </div>
      <GroupByPeriod />
      {postsBreakdown && postsBreakdown.current ? (
        <LineChart
          labels={constructLabelsChart(
            postsBreakdown.current,
            postsBreakdown?.comparedTo
          )}
          datasets={datasets}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PostsBreakdown;

import React from "react";
import FormattedNumber from "../../../../Common/FormattedNumber";

const textColor = (period: "selected" | "compared_to") => {
  let color: string = "";

  if (period === "compared_to") {
    color = "text-emerald-500";
  } else {
    color = "text-brandColor";
  }

  return color;
};

type RequestsInfoProps = {
  title: string;
  description: string;
  totalRequests: number;
  period: "selected" | "compared_to";
};

const RequestsInfo: React.FC<RequestsInfoProps> = ({
  title,
  description,
  totalRequests,
  period,
}) => {
  return (
    <div className="p-2 border border-blueGray-200 rounded mb-4">
      <h2 className="font-bold mb-2">{title}</h2>
      <p className={`${textColor(period)} mb-2`}>{description}</p>
      <hr className="mb-2"></hr>
      <p
        className={`${textColor(
          period
        )} text-3xl mb-2 font-bold cursor-default`}
      >
        <FormattedNumber numberValue={totalRequests} />
      </p>
      <p className="text-sm mb-2">Total requests</p>
      <hr className="mb-2"></hr>
    </div>
  );
};

export default RequestsInfo;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { setDefaultCoparedToDateApi } from "../helpers/helpers";
import ConsumptionModel from "../models/Reports/Consumption/ConsumptionModel";
import ReportPeriods from "../models/Reports/ReportPeriods";
import { ApiContext } from "../providers/ApiProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export const useConsumption = () => {
  const [platformConsumption, setPlatformConsumption] = useState<
    ReportPeriods<ConsumptionModel>
  >({ current: null, comparedTo: null });

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);

  const getPlatformConsumption = async () => {
    try {
      const currentPlatformConsumption =
        await api.reportingHttps.getTotalRequests(datePeriod, groupBy);
      let comparedToPlatformConsumption: ConsumptionModel = null;

      if (comparedTo) {
        comparedToPlatformConsumption =
          await api.reportingHttps.getTotalRequests(
            comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
            groupBy
          );
      }

      setPlatformConsumption({
        current: currentPlatformConsumption,
        comparedTo: comparedToPlatformConsumption,
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.CONSUMPTION_REPORT);
      const reportPeriods: ReportPeriods<ConsumptionModel> = {
        current: new ConsumptionModel(),
        comparedTo: new ConsumptionModel(),
      };
      setPlatformConsumption(reportPeriods);
    }
  };

  useEffect(() => {
    getPlatformConsumption();

    return () => setPlatformConsumption({ current: null, comparedTo: null });
  }, [selectedClient, groupBy, datePeriod, comparedToPeriod, comparedTo]);

  return platformConsumption;
};

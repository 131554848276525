import React from "react";
import LoyaltyInput from "../../../../Common/LoyaltyInput";
import { IDiscussionsBadgeRequirements } from "../../../../../../../models/features/loyalty/Badges/Discussions/DiscussionsBadgesExtended";

type DiscussionsBadgeRequirementsProps = {
  uid: string;
  requirements: IDiscussionsBadgeRequirements;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
};

const DiscussionsBadgeRequirements: React.FC<
  DiscussionsBadgeRequirementsProps
> = ({ uid, requirements, onChangeBadges }) => {
  return (
    <div className="mb-2">
      <label className="font-bold mb-2 text-center">Requirements:</label>
      <LoyaltyInput
        uid={uid}
        label="Total discussion points"
        value={requirements.totalDiscussionPoints}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "totalDiscussionPoints", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Posts Count"
        value={requirements.postsCount}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "postsCount", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Post Points"
        value={requirements.postPoints}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "postPoints", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Reactions Count"
        value={requirements.reactionsCount}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "reactionsCount", value)
        }
        scenario="Badges"
      />
    </div>
  );
};

export default DiscussionsBadgeRequirements;

import React from "react";
import "../../Reports.css";
import { usePrivateLeaguesUsers } from "../../../../../hooks/reports/privateLeagues/usePrivateLeaguesUsers";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import DatePeriod from "../../Common/DatePeriod";
import Spinner from "../../../../Spinner/Spinner";
import PrivateLeagueUsersStats from "./PrivateLeagueUsersStats";

const PrivateLeagueUsers: React.FC = () => {
  const users = usePrivateLeaguesUsers();

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Leagues user statistics"}
          description={
            "This is a report for user statistics for Private and One vs One leagues for a specified period of time. By default the reports shows data for the last 30 days"
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod defaultDatePeriod={true} allTimeDefault={true} />
        </div>
      </div>
      {users ? (
        <>
          <PrivateLeagueUsersStats
            type={"private"}
            data={users.breakdown.private}
          />
          <PrivateLeagueUsersStats
            type={"one_vs_one"}
            data={users.breakdown.oneVsOne}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default PrivateLeagueUsers;

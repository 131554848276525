import React from "react";
import { marketMap } from "../../../../../constants/constants";
import MarketTooltip from "./MarketTooltip";

type ListGroupMarketsProps = {
  title: string;
  paragraph: string;
  markets: string[];
};

const ListGroupMarkets: React.FC<ListGroupMarketsProps> = ({
  title,
  paragraph,
  markets,
}) => {
  return (
    <div className="pr-2">
      <h2 className="mb-2 font-semibold">{title}</h2>
      <p className="mb-2 test-xs italic">{paragraph}</p>
      <div>
        {markets.map((market: string, index: number) => (
          <div
            className="flex items-center border-b last:border-none bg-blueGray-200 p-2 w-full rounded-lg mb-2"
            key={`${market}-${index}`}
          >
            <span className="md:text-sm text-xs font-semibold">{`${marketMap.get(
              market
            )} (${market})`}</span>
            <MarketTooltip market={market} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListGroupMarkets;

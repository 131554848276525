import React, { useState } from "react";
import {
  constructLabelsChart,
  removePropertyFromObject,
  upperCase,
} from "../../../../helpers/helpers";
import { useUsersAcquired } from "../../../../hooks/useUsersAcquired";
import DatasetChart from "../../../../models/Reports/DatasetChart";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import DatePeriod from "../Common/DatePeriod";
import GroupByPeriod from "../Common/GroupByPeriod";
import ReportInfo from "../Common/ReportInfo";
import TotalUsers from "../Common/TotalUsers/TotalUsers";
import LineChart from "../Common/LineChart";
import "../Reports.css";
import {
  previousPeriodDatasetLabel,
  selectedPeriodDatasetLabel,
} from "../../../../constants/constants";

const usersBreakdownDefault = ["all"];

const selectedPeriodColorDataset = (userBreakdown: string) => {
  switch (userBreakdown) {
    case "all":
      return "rgba(0, 102, 204)";
    case "male":
      return "rgba(4, 133, 81)";
    case "female":
      return "rgba(194, 4, 4)";
    case "unspecified":
      return "rgba(163, 166, 3)";
  }
};

const previousPeriodColorDataset = (userBreakdown: string) => {
  switch (userBreakdown) {
    case "all":
      return "#ed7e17";
    case "male":
      return "#b7ffab";
    case "female":
      return "#fa7575";
    case "unspecified":
      return "#e6e872";
  }
};

const usersAcquiredDatasetsChart = (
  usersAcquired: any,
  usersBreakdown: string[]
) => {
  let newDatasetsChart: DatasetChart[] = [];
  let newComparedToUsersBreakdown: any = null;

  // Create new user breakdown depending on the checkboxes the client has selected.
  const newCurrentUsersBreakdown = usersAcquired?.current?.breakdown.map(
    (value: { date: string; users: any }) => {
      const keysToBeRemoved = Object.keys(value.users).filter(
        (userKey: string) => !usersBreakdown.includes(userKey)
      );

      keysToBeRemoved.forEach((key: string) =>
        removePropertyFromObject(value.users, key)
      );

      return value.users;
    }
  );

  // Create dataset list for the chart. It length will depend on the checkboxes the client has checked.
  usersBreakdown.forEach((userBreakdown: string) => {
    const newDatasetChart = new DatasetChart();

    newDatasetChart.id = `${userBreakdown}_selected_period`;
    newDatasetChart.label = `${upperCase(
      userBreakdown
    )}${selectedPeriodDatasetLabel}`;
    newDatasetChart.backgroundColor = selectedPeriodColorDataset(userBreakdown);
    newDatasetChart.borderColor = selectedPeriodColorDataset(userBreakdown);

    newDatasetsChart.push(newDatasetChart);
  });

  if (usersAcquired && usersAcquired.comparedTo) {
    // Create new user breakdown depending on the checkboxes the client has selected.
    newComparedToUsersBreakdown = usersAcquired.comparedTo.breakdown.map(
      (value: { date: string; users: any }) => {
        const keysToBeRemoved = Object.keys(value.users).filter(
          (userKey: string) => !usersBreakdown.includes(userKey)
        );

        keysToBeRemoved.forEach((key: string) =>
          removePropertyFromObject(value.users, key)
        );

        return value.users;
      }
    );

    usersBreakdown.forEach((userBreakdown: string) => {
      const newDatasetChart = new DatasetChart();

      newDatasetChart.id = `${userBreakdown}_previous_period`;
      newDatasetChart.label = `${upperCase(
        userBreakdown
      )}${previousPeriodDatasetLabel}`;
      newDatasetChart.backgroundColor =
        previousPeriodColorDataset(userBreakdown);
      newDatasetChart.borderColor = previousPeriodColorDataset(userBreakdown);

      newDatasetsChart.push(newDatasetChart);
    });
  }

  if (newCurrentUsersBreakdown) {
    Object.values(newCurrentUsersBreakdown).forEach(
      (value: { [key: string]: number }) => {
        newDatasetsChart.forEach((datasetChart: DatasetChart) => {
          if (datasetChart.id === `all_selected_period`)
            datasetChart.data.push(value.all);
          else if (datasetChart.id === `male_selected_period`)
            datasetChart.data.push(value.male);
          else if (datasetChart.id === `female_selected_period`)
            datasetChart.data.push(value.female);
          else if (datasetChart.id === `unspecified_selected_period`)
            datasetChart.data.push(value.unspecified);
        });
      }
    );
  }

  if (newComparedToUsersBreakdown) {
    Object.values(newComparedToUsersBreakdown).forEach(
      (value: { [key: string]: number }) => {
        newDatasetsChart.forEach((datasetChart: DatasetChart) => {
          if (datasetChart.id === `all_previous_period`)
            datasetChart.data.push(value.all);
          else if (datasetChart.id === `male_previous_period`)
            datasetChart.data.push(value.male);
          else if (datasetChart.id === `female_previous_period`)
            datasetChart.data.push(value.female);
          else if (datasetChart.id === `unspecified_previous_period`)
            datasetChart.data.push(value.unspecified);
        });
      }
    );
  }

  return newDatasetsChart;
};

const UsersAcquiredReport: React.FC = () => {
  const [usersBreakdown, setUsersBreakdown] = useState<string[]>(
    usersBreakdownDefault
  );

  const onChangeCheckbox = (userBreakdown: string) => {
    const currentUsersBreakdown = [...usersBreakdown];

    if (
      currentUsersBreakdown.includes(userBreakdown) &&
      currentUsersBreakdown.length !== 1
    ) {
      const newUsersBreakdown = currentUsersBreakdown.filter(
        (value: string) => value !== userBreakdown
      );
      setUsersBreakdown(newUsersBreakdown);
    } else if (!currentUsersBreakdown.includes(userBreakdown)) {
      currentUsersBreakdown.push(userBreakdown);
      setUsersBreakdown(currentUsersBreakdown);
    }
  };

  const usersAcquired = useUsersAcquired();

  const datasetsChart = usersAcquiredDatasetsChart(
    JSON.parse(JSON.stringify(usersAcquired)),
    usersBreakdown
  );

  return (
    <div className="w-full px-4">
      <CardReport>
        <div className="headingContainer mb-6">
          <ReportInfo
            title={"Users report"}
            description={
              "This is a breakdown of your users acquisition for a specified period of time. In this report you will also find a breakdown by gender."
            }
          />
          <div className="xl:flex xl:flex-row xl:items-center">
            <DatePeriod />
          </div>
        </div>
        <GroupByPeriod />
        {usersAcquired.current ? (
          <>
            <TotalUsers
              total={usersAcquired.current.total}
              reportType={"users_acquired"}
              usersBreakdown={usersBreakdown}
              onChangeCheckbox={onChangeCheckbox}
            />
            <LineChart
              labels={constructLabelsChart(
                usersAcquired.current.breakdown,
                usersAcquired?.comparedTo?.breakdown
              )}
              datasets={datasetsChart}
            />
          </>
        ) : (
          <Spinner />
        )}
      </CardReport>
    </div>
  );
};

export default UsersAcquiredReport;

import FootballPaginationModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Pagination/FootballPaginationModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import debounce from "lodash/debounce";
import React, { useContext, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { reactSelectSingleValueStyle } from "../../../../../../constants/styles";
import { ERROR_MESSAGES } from "../../../../../../enums/enums";
import { formatOptions } from "../../../../../../helpers/helpers";
import SelectOption from "../../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../../providers/ApiProvider";
import { placeholders } from "../../../../../../assets/placeholders/placeholders";
import MatchesForTeams from "./MatchesForTeams";

const formatOptionLabel = (option: SelectOption) => {
  return (
    <>
      <div className="xl:flex xl:items-center">
        <img
          src={option.logo ? option.logo : placeholders.team}
          alt={""}
          className="h-5 w-5 mr-1"
        />
        <span className="mr-2 text-xs xl:text-base">{option.label}</span>
      </div>
      <div className="xl:flex xl:items-center">
        <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
        <span className="mr-1">{option.countryName}</span>
      </div>
    </>
  );
};

type BadgeMatchSelectProps = {
  uid: string;
  loadedMatch: MatchBasicModel;
  onChangeEntity: (entity: MatchBasicModel) => void;
};

const BadgeMatchSelect: React.FC<BadgeMatchSelectProps> = ({
  uid,
  loadedMatch,
  onChangeEntity,
}) => {
  const [entityOption, setEntityOption] = useState<SelectOption>(null);

  const api = useContext(ApiContext);

  const minInputLength = 3;

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (inputValue.length < minInputLength) return callback([]);

    const teamFilters: TeamFilters = {
      name: inputValue,
    };
    api.sdk.football
      .getTeams(teamFilters)
      .then((response: FootballPaginationModel) => {
        //Sometimes API will return teams with name like: "Chelsea/Manchester City"
        const actualTeams = response.data.filter(
          (team: TeamBasicModel) => !team.name.includes("/")
        );
        const options = actualTeams.map((team: TeamBasicModel) =>
          formatOptions(team)
        );
        callback(options);
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
        callback([]);
      });
  };

  const handleOnChange = (selectedOption: SelectOption) => {
    setEntityOption(selectedOption);
  };

  const loadOptions = debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? `No team found`
      : "Type at least 3 characters";

  return (
    <>
      <AsyncSelect
        className="mb-2"
        components={{ DropdownIndicator: null }}
        getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
        //@ts-ignore
        styles={reactSelectSingleValueStyle}
        onChange={handleOnChange}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={() => "Loading..."}
        value={entityOption}
        loadOptions={loadOptions}
        isClearable={true}
        placeholder={`Type team name...`}
        formatOptionLabel={formatOptionLabel}
      />
      {entityOption || loadedMatch ? (
        <MatchesForTeams
          uid={uid}
          entityOption={entityOption}
          loadedMatch={loadedMatch}
          onClick={onChangeEntity}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default BadgeMatchSelect;

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { initSDKConfig } from "../sdks/fansUnited/config";
import ReportingHttps from "../services/https/ReportingHttps";
import { FansUnitedSDK } from "fansunited-sdk-esm";
import { GlobalStatesContext } from "./GlobalStatesProvider";
import ClientHttps from "../services/https/ClientHttps";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export type ApiContextValueType = {
  sdk: FansUnitedSDKModel | null;
  clientHttps: ClientHttps | null;
  reportingHttps: ReportingHttps | null;
};

const ApiContext = createContext<ApiContextValueType | null>({
  sdk: null,
  clientHttps: null,
  reportingHttps: null,
});

const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { selectedClient } = useContext(GlobalStatesContext);

  const sdk: FansUnitedSDKModel = useMemo(() => {
    if (selectedClient.id) {
      const config = initSDKConfig(
        selectedClient.id,
        selectedClient.infrastructure
      );

      return FansUnitedSDK(config);
    }

    return null;
  }, [selectedClient]);
  const clientHttps: ClientHttps = useMemo(() => {
    if (selectedClient.id) {
      return new ClientHttps(selectedClient.id, selectedClient.infrastructure);
    }

    return null;
  }, [selectedClient]);
  const reportingHttps: ReportingHttps = useMemo(() => {
    if (selectedClient.id) {
      return new ReportingHttps(
        selectedClient.id,
        selectedClient.infrastructure
      );
    }

    return null;
  }, [selectedClient]);

  return (
    <ApiContext.Provider value={{ sdk, clientHttps, reportingHttps }}>
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };

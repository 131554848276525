import React from "react";
import FormattedNumber from "../../../../Common/FormattedNumber";

type SuccessRateStatsProps = {
  statsName: string;
  value: any;
  label: string;
};

const SuccessRateStats: React.FC<SuccessRateStatsProps> = ({
  statsName,
  value,
  label,
}) => {
  const isOverallSuccessRate =
    statsName === "Overall success rate" ? true : false;

  return (
    <div
      className={`col-span-1 flex flex-col p-4 min-w-0 break-words ${
        isOverallSuccessRate ? "bg-brandColor" : "bg-blueGray-50"
      } rounded mb-6 shadow-lg`}
    >
      <div className="flex mb-4">
        <div className="relative lg:w-8/12 w-full pr-2 max-w-full flex-grow flex-1">
          <h5
            className={`${
              isOverallSuccessRate ? "text-white" : "text-blueGray-400"
            } uppercase font-bold md:text-xs text-xxs`}
          >
            {statsName}
          </h5>
          <span
            className={`font-semibold text-2xl ${
              isOverallSuccessRate ? "text-white" : "text-blueGray-700"
            } cursor-default`}
          >
            <FormattedNumber numberValue={value} />
          </span>
        </div>
        <div className="relative w-auto flex-initial">
          <div className="text-white p-3 text-center inline-flex items-center justify-center lg:w-8 lg:h-8 w-12 h-12 shadow-lg rounded-full bg-emerald-500">
            <i className="fas fa-star"></i>
          </div>
        </div>
      </div>
      <p
        className={`text-sm ${
          isOverallSuccessRate ? "text-white" : "text-blueGray-400"
        } font-bold`}
      >
        {label}
      </p>
    </div>
  );
};

export default SuccessRateStats;

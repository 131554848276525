import {
  consumptionPreviousPeriodDatasetColor,
  consumptionSelectedPeriodDatasetColor,
  previousPeriodDatasetLabel,
  selectedPeriodDatasetLabel,
} from "../../constants/constants";
import BreakdownConsumption from "./Consumption/BreakdownConsumption";
import DatasetChart from "./DatasetChart";

export default class ConsumptionDataset {
  public datasetCharts: DatasetChart[] = [];

  constructor(
    selectedBreakdown: BreakdownConsumption[],
    comparedToBreakdown: BreakdownConsumption[]
  ) {
    this.constructDatasetCharts(selectedBreakdown, false);
    this.constructDatasetCharts(comparedToBreakdown, true);
  }

  /**
   * @param breakdown Metrics breakdown from API.
   */

  private constructDatasetCharts = (
    breakdown: BreakdownConsumption[],
    comparedTo: boolean
  ) => {
    if (breakdown) {
      this.createDatasetsChartRequests(breakdown, comparedTo);
    }
  };

  /**
   * Creates two datasets (total origin and CDN requests) and push them in the current instance variable.
   * @param breakdown Metrics breakdown from API.
   */

  private createDatasetsChartRequests = (
    breakdown: BreakdownConsumption[],
    comparedTo: boolean
  ) => {
    const datasetRequestsChart = new DatasetChart();
    let requestsColor: string = "";

    datasetRequestsChart.id = comparedTo
      ? "requests_previous_period"
      : "requests_selected_period";
    datasetRequestsChart.label = comparedTo
      ? `Requests${previousPeriodDatasetLabel}`
      : `Requests${selectedPeriodDatasetLabel}`;
    datasetRequestsChart.data = this.calculateSum(breakdown);

    if (comparedTo) {
      requestsColor = consumptionPreviousPeriodDatasetColor.get(
        datasetRequestsChart.id
      )
        ? consumptionPreviousPeriodDatasetColor.get(datasetRequestsChart.id)
        : "#10b981";
    } else {
      requestsColor = consumptionSelectedPeriodDatasetColor.get(
        datasetRequestsChart.id
      )
        ? consumptionSelectedPeriodDatasetColor.get(datasetRequestsChart.id)
        : "#0066cc";
    }

    datasetRequestsChart.backgroundColor = requestsColor;
    datasetRequestsChart.borderColor = requestsColor;

    this.datasetCharts.push(datasetRequestsChart);
  };

  private calculateSum = (breakdown: BreakdownConsumption[]) => {
    return breakdown.map((value: BreakdownConsumption) => {
      return value.metrics.reduce(
        (accumulator, currentValue) => accumulator + currentValue.count,
        0
      );
    });
  };
}

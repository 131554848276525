import React, { useRef } from "react";
import { BrowserRouter, Route, useRouteMatch } from "react-router-dom";
import { useFeatures } from "../../../../hooks/features/useFeatures";
import PredictorSuccessRateScopesModel from "../../../../models/features/predictor/PredictorSuccessRateScopesModel";
import Spinner from "../../../Spinner/Spinner";
import FeaturesPredictor from "./FeaturesPredictor";
import EditSuccessRateScopes from "./SuccessRateScopes/EditSuccessRateScopes/EditSuccessRateScopes";
import EditSuccessRateScopesLayout from "./SuccessRateScopes/EditSuccessRateScopes/EditSuccessRateScopesLayout";

const FeaturesPredictorPage: React.FC = () => {
  // Use to trigger useFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const features = useFeatures(isFeaturesEdited);

  const { path } = useRouteMatch();

  return (
    <BrowserRouter>
      <Route path={`${path}/edit`}>
        <EditSuccessRateScopesLayout>
          {features ? (
            <EditSuccessRateScopes
              successRateScopes={
                features.predictor
                  .successRateScopes as PredictorSuccessRateScopesModel
              }
              allMarkets={features.predictor.markets.all}
              isSuccessRateEdited={isFeaturesEdited}
            />
          ) : (
            <Spinner />
          )}
        </EditSuccessRateScopesLayout>
      </Route>
      <Route path={path} exact>
        <FeaturesPredictor features={features} />
      </Route>
    </BrowserRouter>
  );
};

export default FeaturesPredictorPage;

import FeaturesBuilder from "../FeaturesBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";
import FootballFantasyFeatureModel from "../../../models/features/fantasy/FootballFantasyFeature";

export default class FootballFantasyFeaturesBuilder extends FeaturesBuilder {
  private footballFantasyConfigFeatures: any;
  private footballFantasyFeaturesModel: FootballFantasyFeatureModel =
    new FootballFantasyFeatureModel();

  constructor(sdk: FansUnitedSDKModel, footballFantasyConfigFeatures: any) {
    super(sdk);
    this.footballFantasyConfigFeatures = footballFantasyConfigFeatures;
  }

  public build = async (): Promise<FootballFantasyFeatureModel> => {
    this.footballFantasyFeaturesModel.enabled =
      this.footballFantasyConfigFeatures.enabled;
    this.footballFantasyFeaturesModel.addPointsToProfileTotal =
      this.footballFantasyConfigFeatures.addPointsToProfileTotal;
    this.footballFantasyFeaturesModel.competitionsWhitelist =
      await this.getCompetitions(
        this.footballFantasyConfigFeatures.competitionsWhitelist
      );
    this.footballFantasyFeaturesModel.coefficients =
      this.footballFantasyConfigFeatures.coefficients;
    this.footballFantasyFeaturesModel.players =
      this.footballFantasyConfigFeatures.players;

    return this.footballFantasyFeaturesModel;
  };
}

import React, { useState } from "react";
import RewardsModel from "../../../../../models/features/loyalty/RewardsModel/RewardsModel";
import LoyaltyPoints from "../LoyaltyPoints/LoyaltyPoints";
import LoyaltyTiers from "../LoyaltyTiers/LoyaltyTiers";

export type LoyaltyRewardsEntities = "Points" | "Tiers" | "Badges";

type LoyaltyRewardsProps = {
  label: LoyaltyRewardsEntities;
  loyaltyRewards: RewardsModel;
  onChange: (
    scenario: LoyaltyRewardsEntities,
    uid: string,
    value: string | boolean,
    scenarioNode?: string,
    openTab?: string,
    correctScoreAdvancedAlternativeKey?: string
  ) => void;
  addNewReward?: () => void;
  removeExistingReward?: (rewardUid: string) => void;
  isLoyaltyFeaturesEdited?: any;
};

const pointsTabs = [
  "general",
  "predictor",
  "activity",
  "top x",
  "match quiz",
  "discussion post",
  "voting",
];
const startTab = "general";

const LoyaltyRewards: React.FC<LoyaltyRewardsProps> = ({
  label,
  loyaltyRewards,
  onChange,
  addNewReward,
  removeExistingReward,
}) => {
  const [pointsOpenTab, setPointsOpenTab] = useState<string>(startTab);

  const renderRewardsEntity = () => {
    switch (label) {
      case "Points":
        return (
          <>
            <p>
              Every user will receive points for every action he tooks. The
              points are separated in 7 groups: general, predictor, activity,
              Top X, Match Quiz, Discussion post and Voting
            </p>
            <ul
              className="flex mb-0 list-none flex-wrap py-3 flex-row"
              role="tablist"
            >
              {pointsTabs.map((tab: string, index: number) => (
                <li
                  className="mb-2 mr-2 flex-auto text-center"
                  key={`${label}-${tab}-${index}`}
                >
                  <a
                    className={
                      "text-xs font-bold uppercase p-4 rounded block leading-normal " +
                      (pointsOpenTab === tab
                        ? "text-lightBlue-600 bg-white"
                        : "text-white bg-gray-400")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setPointsOpenTab(tab);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
            <LoyaltyPoints
              loyaltyPoints={loyaltyRewards.points}
              openTab={pointsOpenTab}
              onChange={onChange}
            />
          </>
        );
      case "Tiers":
        return (
          <>
            <p>
              User earns a specific tier depending on the total points he has
              earned.
            </p>
            <LoyaltyTiers
              loyaltyTiers={loyaltyRewards.tiers}
              onChange={onChange}
              addNewReward={addNewReward}
              removeExistingReward={removeExistingReward}
            />
          </>
        );
    }
  };

  return (
    <div
      className={`${
        label === "Badges" ? "mb-6" : "mb-2"
      } border border-gray-200 rounded-lg p-2 bg-blueGray-50`}
    >
      <label className="font-bold mb-2">{label}</label>
      {renderRewardsEntity()}
    </div>
  );
};

export default LoyaltyRewards;

import React from "react";
import {
  averageFollowsColorMap,
  averageFollowsDescription,
  averageFollowsIconsMap,
  averageFollowsTitles,
} from "../../../../constants/constants";
import { useAverageFollows } from "../../../../hooks/useAverageFollows";
import CardReport from "../../../Cards/CardReport";
import Spinner from "../../../Spinner/Spinner";
import Stats from "../../Dashboard/Stats/Stats";
import ReportInfo from "../Common/ReportInfo";
import AverageFollowEntityLayout from "./AverageFollowEntity/AverageFollowEntityLayout";
import AverageFollows from "./AverageFollows/AverageFollows";
import AverageFollowsContainer from "./AverageFollows/AverageFollowsContainer";

const Averages: React.FC = () => {
  const averageFollows = useAverageFollows();

  const footballAverageEntities = ["competition", "team", "player"];
  const otherAverageEntities = ["sport", "profile"];

  return (
    <div className="w-full px-4">
      <CardReport>
        <ReportInfo
          title={"Averages"}
          description={
            "The following numbers represent the average activity of your user. Higher numbers indicate better engagement."
          }
        />
        {averageFollows ? (
          <>
            <AverageFollowEntityLayout>
              <AverageFollowsContainer>
                <Stats
                  statsName={averageFollowsTitles.get("average")}
                  label={averageFollowsDescription.get("average")}
                  value={averageFollows?.get("average")}
                  icon={averageFollowsIconsMap.get("average")}
                  iconColor={averageFollowsColorMap.get("average")}
                />
              </AverageFollowsContainer>
            </AverageFollowEntityLayout>
            <AverageFollows
              title={"Football averages"}
              entities={footballAverageEntities}
              averageFollows={averageFollows}
            />
            <AverageFollows
              title={"Other averages"}
              entities={otherAverageEntities}
              averageFollows={averageFollows}
            />
          </>
        ) : (
          <Spinner />
        )}
      </CardReport>
    </div>
  );
};

export default Averages;

import React from "react";
import StaffMembers from "./StaffMembers/StaffMembers";

const Staff: React.FC = () => {
  return (
    <div className="w-full mb-24 px-4">
      <StaffMembers />
    </div>
  );
};

export default Staff;

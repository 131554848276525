import React, { useContext } from "react";
import { MainBreakdown } from "../../../../../models/Reports/Engagement/EngagementSegmentsModel";
import { GlobalStatesContext } from "../../../../../providers/GlobalStatesProvider";
import UserSegments from "../../../../../models/Clients/EngagementCoefficientsConfiguration/UserSegments";
import CriteriaList, {
  BoundRangeInterface,
} from "../../../../../models/Clients/EngagementCoefficientsConfiguration/CriteriaList";

export const segmentsGroups = [0, 1, 2, 3, 4];

export const keyObjectSegmentIdMap = new Map<string, string>()
  .set("recency", "recencyRange")
  .set("frequency", "frequencyRange")
  .set("engagement", "engagementRange");

export const isCheckboxChecked = (
  segmentType: "recency" | "frequency" | "engagement",
  criteriaList: CriteriaList[],
  checkboxIndex: number
): boolean => {
  const objectKey = keyObjectSegmentIdMap.get(segmentType);
  const newCriteriaList = criteriaList.map((value: CriteriaList) => {
    return value[objectKey];
  });

  const sortedLowerBoundCriteriaList = [...newCriteriaList].sort(
    (a: BoundRangeInterface, b: BoundRangeInterface) => {
      return a.lowerBound - b.lowerBound;
    }
  );
  const sortedUpperBoundCriteriaList = [...newCriteriaList].sort(
    (a: BoundRangeInterface, b: BoundRangeInterface) => {
      return b.upperBound - a.upperBound;
    }
  );
  const lowerBound = sortedLowerBoundCriteriaList[0]?.lowerBound;
  const upperBound = sortedUpperBoundCriteriaList[0]?.upperBound;

  return checkboxIndex >= lowerBound && checkboxIndex <= upperBound;
};

type EngagementSegmentsInfoProps = {
  userSegment: UserSegments;
  title: string;
  value: MainBreakdown;
};

const EngagementSegmentsInfo: React.FC<EngagementSegmentsInfoProps> = ({
  userSegment,
  title,
  value,
}) => {
  const { setShowEngagementBreakdownModal, setEngagementSegmentsBreakdown } =
    useContext(GlobalStatesContext);

  const onClickInfo = () => {
    setEngagementSegmentsBreakdown(value);
    setShowEngagementBreakdownModal(true);
  };

  return (
    <div className="border border-blueGray-200 rounded p-2">
      <h3 className="font-bold text-xl text-center mb-2">{title}</h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
        <div className="flex flex-col mt-8">
          <span className="whitespace-nowrap">Recency</span>
          <span>Frequency</span>
          <span>Engagement</span>
        </div>
        <table className="mx-auto mb-2">
          <thead>
            <tr>
              {/* <th></th> */}
              {segmentsGroups.map((groupNumber: number) => (
                <th key={`segment-group-number-${groupNumber}`}>
                  {groupNumber + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <th>
              <span className="mr-2 font-normal">Recency</span>
            </th> */}
              {segmentsGroups.map((groupNumber: number, index: number) => (
                <th key={`recency-group-number-${groupNumber}`}>
                  <input
                    type="checkbox"
                    readOnly
                    disabled
                    checked={isCheckboxChecked(
                      "recency",
                      userSegment.criteriaList,
                      index + 1
                    )}
                  ></input>
                </th>
              ))}
            </tr>
            <tr>
              {/* <th>
              <span className="mr-2 font-normal">Frequency</span>
            </th> */}
              {segmentsGroups.map((groupNumber: number, index: number) => (
                <th key={`frequency-group-number-${groupNumber}`}>
                  <input
                    type="checkbox"
                    readOnly
                    disabled
                    checked={isCheckboxChecked(
                      "frequency",
                      userSegment.criteriaList,
                      index + 1
                    )}
                  ></input>
                </th>
              ))}
            </tr>
            <tr>
              {/* <th>
              <span className="mr-2 font-normal">Engagement</span>
            </th> */}
              {segmentsGroups.map((groupNumber: number, index: number) => (
                <th key={`engagement-group-number-${groupNumber}`}>
                  <input
                    type="checkbox"
                    readOnly
                    disabled
                    checked={isCheckboxChecked(
                      "engagement",
                      userSegment.criteriaList,
                      index + 1
                    )}
                  ></input>
                </th>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <p className="flex items-center mb-2"></p>
      <div className="flex items-center mb-2"></div>
      <div className="flex justify-center items-center mb-2">
        <p className="text-xl mr-2">{value ? value.users : 0}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 cursor-pointer"
          onClick={onClickInfo}
        >
          <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
        </svg>
      </div>
    </div>
  );
};

export default EngagementSegmentsInfo;

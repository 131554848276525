import React from "react";

const headerCellStyle =
  "border border-solid lg:text-xs text-xxs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold bg-blueGray-50 text-blueGray-500 border-blueGray-100";

const TableHeaderSuccessRateUsers: React.FC = () => {
  return (
    <thead>
      <tr>
        <th
          className={`${headerCellStyle} p-3 lg:text-left lg:table-cell hidden text-center w-24`}
        >
          Position
        </th>
        <th className={`${headerCellStyle} p-3 text-left`}>Name</th>
        <th className={`${headerCellStyle} p-3 lg:table-cell hidden text-right`}>
          Predictions
        </th>
        <th className={`${headerCellStyle} p-3 lg:table-cell hidden text-right`}>
          Points
        </th>
        <th className={`${headerCellStyle} md:p-3 sm:text-right text-left lg:text-xs text-xxs`}>
          Success rate
        </th>
      </tr>
    </thead>
  );
};

export default TableHeaderSuccessRateUsers;

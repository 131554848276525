import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import React, { useState } from "react";
import { placeholders } from "../../../../../../assets/placeholders/placeholders";
import ExpandButton from "../../../../../Buttons/ExpandButton";

type TeamsSuccessRateScopeProps = {
  teams: TeamBasicModel[];
};

const TeamsSuccessRateScope: React.FC<TeamsSuccessRateScopeProps> = ({
  teams,
}) => {
  const [teamsSuccessRate, setTeamsSuccessRate] =
    useState<TeamBasicModel[]>(teams);

  return (
    <div className="mb-2">
      <h2 className="font-semibold mb-2 text-center">Teams</h2>
      <div className="lg:grid grid-cols-4 gap-2 justify-between">
        {teamsSuccessRate.map((team: TeamBasicModel) => (
          <div
            key={`${team.id}-success-rate-team`}
            className="bg-blueGray-200 mb-2 rounded-lg border-b border-blueGray-200 last:border-none h-24 flex flex-col justify-center px-2"
          >
            <div className="flex flex-row items-center p-1">
              <img
                src={
                  //@ts-ignore
                  team.assets && team.assets.logo
                    ? //@ts-ignore
                      team.assets.logo
                    : placeholders.team
                }
                className="w-5 h-5 mr-2"
              ></img>
              <span>{team.name}</span>
            </div>
            <div className="flex flex-row items-center p-1">
              <img
                src={
                  team.country.assets &&
                  //@ts-ignore
                  team.country.assets.flag
                    ? //@ts-ignore
                      team.country.assets.flag
                    : placeholders.country
                }
                className="w-4 h-3 rounded mr-2"
              ></img>
              <span className="text-xs">{team.country.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamsSuccessRateScope;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../enums/enums";
import ProfileValidator from "../../../validators/ProfileValidator";
import UpdateButton from "../../Buttons/UpdateButton";
import CardEditProfile from "../../Cards/CardEditProfile";
import EditProfileRow from "./EditProfileRow";
import EditProfileHeader from "./EditProfileHeader";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import StaffRoles from "../Staff/StaffRoles/StaffRoles";
import FullProfileInfo from "../../../models/Clients/FullProfileInfo";
import Spinner from "../../Spinner/Spinner";
import { ApiContext } from "../../../providers/ApiProvider";

const EditProfile: React.FC = () => {
  const { clientHttps } = useContext(ApiContext);
  const { selectedClient, loggedInUser, isLoading, setIsLoading } =
    useContext(GlobalStatesContext);

  const [staffMember, setStaffMember] = useState<FullProfileInfo | null>(null);
  const [name, setName] = useState<string>("");
  const [roles, setRoles] = useState<string[]>([]);

  const isUserHasPermissionRoles = useMemo(() => {
    const clientAdmin = "client_admin";

    if (
      (loggedInUser.roles.global && loggedInUser.roles.global.length) ||
      loggedInUser.roles[selectedClient.id].includes(clientAdmin)
    )
      return true;

    return false;
  }, []);

  const updateProfileValidator = new ProfileValidator();

  useEffect(() => {
    clientHttps
      ?.getStaffMemberById(loggedInUser.user_id)
      .then((profile: FullProfileInfo) => {
        setStaffMember(profile);
        setName(profile.name);
        setRoles(profile.roles);
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.STAFF_MEMBER_INFO);
        setStaffMember(new FullProfileInfo());
      });
  }, []);

  const handleOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  const handleOnChangeRoles = (selectedRole: string) => {
    if (!roles.includes(selectedRole)) {
      setRoles((prevState) => [...prevState, selectedRole]);
    } else {
      const newRoles = roles.filter((role: string) => role !== selectedRole);

      setRoles(newRoles);
    }
  };

  const updateInformation = async () => {
    setIsLoading(true);
    const validator = updateProfileValidator.validateUpdateName(name);

    if (!validator.isValid) {
      console.warn(validator.message);
      toast.warn(validator.message);
      setIsLoading(false);

      return;
    }

    try {
      await clientHttps?.updateStaffInfo(loggedInUser.user_id, {
        name,
        roles,
      });
      toast.success(SUCCESS_MESSAGES.CHANGE_PROFILE_INFO);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.CHANGE_PROFILE_INFO);
    }

    setIsLoading(false);
  };

  return (
    <CardEditProfile>
      <EditProfileHeader label={"Edit profile information"} />
      {staffMember ? (
        <>
          <EditProfileRow
            label={"Current profile e-mail"}
            userEmail={staffMember?.email}
          />
          <EditProfileRow
            label={"Enter new profile name"}
            onChange={handleOnChangeName}
            currentName={staffMember?.name}
          />
          {isUserHasPermissionRoles ? (
            <StaffRoles onChange={handleOnChangeRoles} loadedRoles={roles} />
          ) : null}
          <UpdateButton
            label={"Update information"}
            onClick={updateInformation}
            isLoading={isLoading}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardEditProfile>
  );
};

export default EditProfile;

import React, { useState } from "react";
import MauVsRegistrations from "./MauVsRegistrations";
import ReportTwoTabs from "../Common/ReportTwoTabs";
import MonthlyActiveUsers from "./MonthlyActiveUsers";

const MonthlyActiveUsersReport: React.FC = () => {
  const [mauReport, setMauReport] = useState<string>("monthly_active_users");

  return (
    <div className="w-full px-4">
      <ReportTwoTabs
        tabValue={mauReport}
        setTabValue={setMauReport}
        reportType={"mau"}
      />
      {mauReport === "monthly_active_users" ? (
        <MonthlyActiveUsers />
      ) : (
        <MauVsRegistrations />
      )}
    </div>
  );
};

export default MonthlyActiveUsersReport;

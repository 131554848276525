import {
  previousPeriodDatasetLabel,
  selectedPeriodDatasetLabel,
} from "../../../constants/constants";
import DatasetChart from "../DatasetChart";
import DiscussionsBreakdownModel from "./DiscussionsBreakdownModel";

export default class DiscussionsDataset {
  public discussionsDatasets: DatasetChart[] = [];

  constructor(
    selectedPredictionsBreakdown: DiscussionsBreakdownModel[] | null,
    comparedToPredictionsBreakdown: DiscussionsBreakdownModel[] | null,
    type: "Discussions" | "Posts"
  ) {
    this.discussionsDatasetsChart(selectedPredictionsBreakdown, false, type);
    this.discussionsDatasetsChart(comparedToPredictionsBreakdown, true, type);
  }

  private discussionsDatasetsChart = (
    discussionsBreakdown: DiscussionsBreakdownModel[] | null,
    comparedTo: boolean,
    type: "Discussions" | "Posts"
  ) => {
    if (!discussionsBreakdown || !discussionsBreakdown.length) return;

    const newDatasetChart = new DatasetChart();
    let label: string = type;
    let color: string = comparedTo ? "rgba(237, 126, 23)" : "rgba(0, 102, 204)";
    newDatasetChart.id = comparedTo
      ? `${type.toLocaleLowerCase()}_previous_period`
      : `${type.toLocaleLowerCase()}_selected_period`;
    newDatasetChart.label = comparedTo
      ? label + previousPeriodDatasetLabel
      : label + selectedPeriodDatasetLabel;
    newDatasetChart.backgroundColor = color;
    newDatasetChart.borderColor = color;
    newDatasetChart.data = discussionsBreakdown.length
      ? discussionsBreakdown.map((breakdown) => breakdown.value)
      : [0];
    this.discussionsDatasets.push(newDatasetChart);
  };
}

import Chart from "chart.js";
import { useRef } from "react";
import LineChartConfigBuilder from "../../../../builders/reports/LineChartConfigBuilder";
import DatasetChart from "../../../../models/Reports/DatasetChart";

type LineChartProps = {
  labels: string[];
  datasets: DatasetChart[];
  isMau?: boolean; // For mau we will have origin implementation of line chart config
};

const LineChart: React.FC<LineChartProps> = ({ labels, datasets, isMau }) => {
  // use a ref to store the chart instance since it it mutable
  const chartRef = useRef<Chart | null>(null);

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;

    if (chartRef.current) chartRef.current.destroy();

    const ctx = canvas.getContext("2d");

    if (ctx && !isMau) {
      //@ts-ignore
      chartRef.current = new Chart(
        ctx,
        new LineChartConfigBuilder().build(ctx, labels, datasets)
      );
      //@ts-ignore
      document.getElementById("chart-legend").innerHTML =
        chartRef.current.generateLegend();
    } else if (ctx && isMau) {
      //@ts-ignore
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels,
          datasets,
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true, // Instruct chart js to respond nicely.
          maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
          legend: {
            display: false,
          },
          hover: {
            mode: "nearest",
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                //@ts-ignore
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var index = tooltipItem.index;
                return dataset.label + ": " + dataset.data[index];
              },
              //@ts-ignore
              title: () => {},
            },
          },
        },
      });
    }
  };

  return (
    <>
      <div id="chart-legend" className="mb-6"></div>
      <div className="h-350-px w-full">
        <canvas ref={canvasCallback}></canvas>
      </div>
    </>
  );
};

export default LineChart;

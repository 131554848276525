import MatchQuizConfigModel from "fansunited-sdk-esm/Core/Namespaces/MatchQuiz/Models/Config/MatchQuizConfigModel";
import MatchQuizFeaturesModel from "../../../models/features/matchQuiz/MatchQuizFeaturesModel";
import FeaturesBuilder from "../FeaturesBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class MatchQuizFeaturesBuilder extends FeaturesBuilder {
  private matchQuizConfigFeatures: MatchQuizConfigModel;
  private matchQuizFeaturesModel: MatchQuizFeaturesModel =
    new MatchQuizFeaturesModel();
  private allMarkets: string[] = [];

  constructor(
    sdk: FansUnitedSDKModel,
    matchQuizConfigFeatures: MatchQuizConfigModel,
    predictorAllMarkets: string[]
  ) {
    super(sdk);
    this.matchQuizConfigFeatures = matchQuizConfigFeatures;
    this.allMarkets = predictorAllMarkets;
  }

  public build = async () => {
    this.matchQuizFeaturesModel.enabled = this.matchQuizConfigFeatures.enabled;
    this.matchQuizFeaturesModel.defaultMarkets =
      this.matchQuizConfigFeatures.defaultMarkets;
    this.matchQuizFeaturesModel.allMarkets = this.allMarkets;
    this.matchQuizFeaturesModel.competitionsWhitelist =
      await this.getCompetitions(
        this.matchQuizConfigFeatures.competitionsWhitelist
      );

    return this.matchQuizFeaturesModel;
  };
}

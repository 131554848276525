import React from "react";
import Stats from "../../../Dashboard/Stats/Stats";
import { IBasicPrivateLeagueUsers } from "../../../../../models/Reports/PrivateLeagues/PrivateLeaguesUsersModel";

const basicUsersInLeagueTitleMap = new Map()
  .set("usersInAtLeastOne", "Users in at least one league")
  .set("usersInAtLeastTwo", "Users in at least two leagues")
  .set("usersInAtLeastThree", "Users in at least three leagues")
  .set("usersInAtLeastFive", "Users in at least five leagues")
  .set("usersInAtLeastTen", "Users in at least ten leagues");

const fullUsersInLeagueTitleMap = new Map(basicUsersInLeagueTitleMap).set(
  "averageUsersInLeague",
  "Average users in Private leagues"
);

const basicUsersInLeagueDescriptionMap = new Map()
  .set("usersInAtLeastOne", "How many users are in at least one league")
  .set("usersInAtLeastTwo", "How many users are in at least two leagues")
  .set("usersInAtLeastThree", "How many users are in at least three leagues")
  .set("usersInAtLeastFive", "How many users are in at least five leagues")
  .set("usersInAtLeastTen", "How many users are in at least ten leagues");

const fullUsersInLeagueDescriptionMap = new Map(
  basicUsersInLeagueDescriptionMap
).set("averageUsersInLeague", "How many average users are in Private leagues");

type PrivateLeagueUsersStatsProps = {
  type: "private" | "one_vs_one";
  data: IBasicPrivateLeagueUsers | null;
};

const PrivateLeagueUsersStats: React.FC<PrivateLeagueUsersStatsProps> = ({
  type,
  data,
}) => {
  const title = type === "private" ? "Private leagues" : "One vs One leagues";
  const keys =
    type === "private"
      ? Object.keys({
          averageUsersInLeague: 0,
          usersInAtLeastOne: 0,
          usersInAtLeastTwo: 0,
          usersInAtLeastThree: 0,
          usersInAtLeastFive: 0,
          usersInAtLeastTen: 0,
        })
      : Object.keys({
          usersInAtLeastOne: 0,
          usersInAtLeastTwo: 0,
          usersInAtLeastThree: 0,
          usersInAtLeastFive: 0,
          usersInAtLeastTen: 0,
        });
  const statsTitleMap =
    type === "private" ? fullUsersInLeagueTitleMap : basicUsersInLeagueTitleMap;
  const statsDescriptionMap =
    type === "private"
      ? fullUsersInLeagueDescriptionMap
      : basicUsersInLeagueDescriptionMap;

  return (
    <div className="mb-6">
      <div className="mb-4">
        <h2 className="font-bold mb-2">{title}</h2>
        <hr className="mb-2"></hr>
      </div>
      <div className="lg:grid lg:grid-cols-3 lg:gap-2 mb-4">
        {keys.map((key: string, idx: number) => (
          <div
            key={`private-leagues-users-statistics-${key}-${idx}`}
            className="w-full p-4 min-w-0 break-words bg-blueGray-50 rounded shadow-lg mb-4"
          >
            <Stats
              statsName={statsTitleMap.get(key)}
              label={statsDescriptionMap.get(key)}
              value={data ? data[key] : 0}
              icon={""}
              iconColor={""}
              percentage={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivateLeagueUsersStats;

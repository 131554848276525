import React, { useContext, useState } from "react";
import { useTopFollowedFootballEntities } from "../../../../../hooks/useTopFollowedFootballEntities";
import CardReport from "../../../../Cards/CardReport";
import DatePeriod from "../../Common/DatePeriod";
import ReportInfo from "../../Common/ReportInfo";
import EntitySelect from "../../Common/EntityFollows/EntitySelect";
import { useFollowedFootballEntities } from "../../../../../hooks/useFollowedFootballEntities";
import Spinner from "../../../../Spinner/Spinner";
import FootballEntitiesFollows from "../../Common/EntityFollows/FootballEntitiesFollows";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import "../../Reports.css";

const FootballTeamsReport: React.FC = () => {
  const [teamIds, setTeamIds] = useState<string[]>([]);
  const [teamOptions, setTeamOptions] = useState<SelectOption[]>([]);

  const { datePeriod } = useContext(ReportParamsContext);

  const topFollowsFootballTeams = useTopFollowedFootballEntities(
    datePeriod,
    teamIds,
    "teams"
  );
  const teamFollowsInfo = useFollowedFootballEntities(
    teamIds,
    datePeriod,
    "teams"
  );

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Football teams report"}
          description={
            "This report shows by default the top 100 most followed teams specified by period. Also you can search for a specific team and see how many users are following it specified by gender and country."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} allTimeDefault={true} />
        </div>
      </div>
      <div className="mb-3">
        <EntitySelect
          setEntityIds={setTeamIds}
          entity="teams"
          entities={teamOptions}
          setEntities={setTeamOptions}
        />
      </div>
      {topFollowsFootballTeams || teamFollowsInfo ? (
        <FootballEntitiesFollows
          topFollowsFootballEntities={topFollowsFootballTeams}
          entityFollowsInfo={teamFollowsInfo}
          entity={"teams"}
          setEntityIds={setTeamIds}
          setEntities={setTeamOptions}
        />
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default FootballTeamsReport;

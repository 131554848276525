import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../../enums/enums";
import StaffMemberRequestBody from "../../../../models/Clients/StaffMemberRequestBody";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import ProfileValidator from "../../../../validators/ProfileValidator";
import UpdateButton from "../../../Buttons/UpdateButton";
import CardEditProfile from "../../../Cards/CardEditProfile";
import PasswordRow from "../../ChangePassword/PasswordRow";
import EditProfileHeader from "../../EditProfile/EditProfileHeader";
import EditProfileRow from "../../EditProfile/EditProfileRow";
import StaffRoles from "../StaffRoles/StaffRoles";
import { ApiContext } from "../../../../providers/ApiProvider";

const CreateStaffMember: React.FC = () => {
  const [newStaffMember, setNewStaffMember] = useState<StaffMemberRequestBody>(
    new StaffMemberRequestBody()
  );
  const history = useHistory();
  const { clientHttps } = useContext(ApiContext);
  const { selectedClient, isLoading, setIsLoading } =
    useContext(GlobalStatesContext);

  const validator = new ProfileValidator();

  useEffect(() => {}, [selectedClient]);

  const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewStaffMember((prevState) => {
      return { ...prevState, email: event.target.value };
    });
  };

  const handleOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewStaffMember((prevState) => {
      return { ...prevState, name: event.target.value };
    });
  };

  const handleOnChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewStaffMember((prevState) => {
      return { ...prevState, pass: event.target.value };
    });
  };

  const handleOnChangeRepeatNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewStaffMember((prevState) => {
      return { ...prevState, repeatPass: event.target.value };
    });
  };

  const handleOnChangeRoles = (selectedRole: string) => {
    if (!newStaffMember.roles.includes(selectedRole)) {
      setNewStaffMember((prevState) => {
        return { ...prevState, roles: [...prevState.roles, selectedRole] };
      });
    } else {
      const newRoles = newStaffMember.roles.filter(
        (role: string) => role !== selectedRole
      );

      setNewStaffMember((prevState) => {
        return { ...prevState, roles: newRoles };
      });
    }
  };

  const createStaffMember = async () => {
    setIsLoading(true);
    validator.isEmailValid(newStaffMember.email);
    validator.validateRepeatPassword(
      newStaffMember.pass,
      newStaffMember.repeatPass
    );
    validator.validatePasswordLength(newStaffMember.pass);
    validator.validateUpdateName(newStaffMember.name);

    if (!validator.isValid) {
      toast.warn(validator.message);
      setIsLoading(false);
      return;
    }

    try {
      await clientHttps?.createStaffMember(newStaffMember);
      history.goBack();
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.CREATE_STAFF_MEMBER);
    }
    setIsLoading(false);
  };

  return (
    <CardEditProfile>
      <EditProfileHeader label={"Create staff member"} />
      <EditProfileRow label={"Name"} onChange={handleOnChangeName} />
      <PasswordRow label={"Password"} onChange={handleOnChangeNewPassword} />
      <PasswordRow
        label={"Repeat password"}
        onChange={handleOnChangeRepeatNewPassword}
      />
      <EditProfileRow label={"E-mail"} onChange={handleOnChangeEmail} />
      <StaffRoles
        onChange={handleOnChangeRoles}
        loadedRoles={newStaffMember.roles}
      />
      <UpdateButton
        label={"Create"}
        onClick={createStaffMember}
        isLoading={isLoading}
      />
    </CardEditProfile>
  );
};

export default CreateStaffMember;

import FootballPaginationModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Pagination/FootballPaginationModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { placeholders } from "../../../../../../../assets/placeholders/placeholders";
import { asyncSelectStyles } from "../../../../../../../constants/styles";
import { ERROR_MESSAGES } from "../../../../../../../enums/enums";
import { formatOptions } from "../../../../../../../helpers/helpers";
import SelectOption from "../../../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../../../providers/ApiProvider";

type TeamsSelectProps = {
  loadedTeams: TeamBasicModel[];
  setSuccessRateScopeTeamIds: Dispatch<SetStateAction<string[]>>;
};

const TeamsSelect: React.FC<TeamsSelectProps> = ({
  loadedTeams,
  setSuccessRateScopeTeamIds,
}) => {
  const loadedCompetitionOptions = loadedTeams?.map(
    (competition: TeamBasicModel) => formatOptions(competition)
  );
  const [competitions, setCompetitions] = useState<SelectOption[]>(
    loadedCompetitionOptions
  );

  const api = useContext(ApiContext);

  const minInputLength = 3;

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (inputValue.length < minInputLength) return callback([]);

    let teamFilters: TeamFilters = null;
    let options: SelectOption[] = null;

    if (inputValue) {
      teamFilters = {
        name: inputValue,
      } as TeamFilters;
    }

    api?.sdk.football
      .getTeams(teamFilters)
      .then((response: FootballPaginationModel) => {
        //Sometimes API will return teams with name like: "Chelsea/Manchester City"
        const actualTeams = response.data.filter(
          (team: TeamBasicModel) => !team.name.includes("/")
        );
        options = actualTeams.map((competition: TeamBasicModel) =>
          formatOptions(competition)
        );
        callback(options);
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
        callback([]);
      });
  };

  const handleOnChange = (selectedOption: SelectOption[]) => {
    const teamIds = selectedOption.map((option: SelectOption) => option.id);
    setSuccessRateScopeTeamIds(teamIds);
    setCompetitions(selectedOption);
  };

  const loadOptions = _.debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? "No team found"
      : "Type at least 3 characters";

  return (
    <AsyncSelect
      components={{ DropdownIndicator: null }}
      styles={asyncSelectStyles}
      isMulti
      onChange={handleOnChange}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Loading..."}
      defaultOptions
      value={competitions}
      loadOptions={loadOptions}
      placeholder={""}
      formatOptionLabel={(option) => (
        <div className="flex flex-justify-start">
          <img
            src={option.logo ? option.logo : placeholders.competition}
            alt={""}
            className="h-6 w-6"
          />
          <span>{option.label}</span>
        </div>
      )}
      classNamePrefix="select2-selection"
    />
  );
};

export default TeamsSelect;

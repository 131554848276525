import React, { LegacyRef, useContext } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import ProfileAvatar from "./ProfileAvatar";

type ClientIconProps = {
  btnDropdownRef: null | LegacyRef<HTMLAnchorElement>;
  dropdownPopoverShow: boolean;
  closeDropdownPopover: () => void;
  openDropdownPopover: () => void;
};

const ClientIcon: React.FC<ClientIconProps> = ({
  btnDropdownRef,
  dropdownPopoverShow,
  closeDropdownPopover,
  openDropdownPopover,
}) => {
  const user = useContext(AuthContext);

  return (
    <a
      className="text-blueGray-500 block"
      href="#pablo"
      ref={btnDropdownRef}
      onClick={(e) => {
        e.preventDefault();
        dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
      }}
    >
      <ProfileAvatar userAvatar={user?.photoURL} />
    </a>
  );
};

export default ClientIcon;

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { localstorageKey } from "../constants/constants";
import { ERROR_MESSAGES } from "../enums/enums";
import RolesPermissions from "../models/Clients/RolesPermissions";
import LoggedInUserService from "../services/LoggedInUserService";
import { GlobalStatesContext } from "./GlobalStatesProvider";
import { ApiContext } from "./ApiProvider";

type UserAccessContextType = {
  pages: string[];
};

const UserAccessPagesContext = createContext<UserAccessContextType>({
  pages: [],
});

const UserAccessPagesProvider: React.FC<PropsWithChildren> = (props) => {
  const [pages, setPages] = useState<string[]>([]);

  const { selectedClient, loggedInUser } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  useEffect(() => {
    if (localStorage.getItem(localstorageKey)) {
      const deserializeAccessPages = JSON.parse(
        localStorage.getItem(localstorageKey)
      );
      setPages(deserializeAccessPages.pages);

      return;
    }

    clientHttps
      ?.getRoles()
      .then((roles: RolesPermissions) => {
        if (selectedClient && selectedClient.id && loggedInUser) {
          const userService = new LoggedInUserService(
            selectedClient.id,
            roles,
            loggedInUser
          );
          const userAccessPages = userService.pagesUserHasAccessTo();
          const accessPagesSerializeble = JSON.stringify({
            pages: userAccessPages,
          });
          localStorage.setItem(localstorageKey, accessPagesSerializeble);

          setPages(userAccessPages);
        } else {
          setPages([]);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.ROLES_PERMISSIONS);
      });
  }, [selectedClient, loggedInUser]);

  return (
    <UserAccessPagesContext.Provider value={{ pages }}>
      {props.children}
    </UserAccessPagesContext.Provider>
  );
};

export { UserAccessPagesContext, UserAccessPagesProvider };

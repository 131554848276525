export interface IPrivateLeaguesBreakdownTotal {
  all: number;
  private: number;
  oneVsOne: number;
}

export interface ILeagueBreakdownData {
  date: string;
  value: number;
}

export interface IPrivateLeaguesBreakdown {
  private: ILeagueBreakdownData[];
  oneVsOne: ILeagueBreakdownData[];
}

export default class PrivateLeaguesBreakdownModel {
  breakdown: IPrivateLeaguesBreakdown = {
    private: [],
    oneVsOne: [],
  };
  total: IPrivateLeaguesBreakdownTotal = {
    all: 0,
    private: 0,
    oneVsOne: 0,
  };
}

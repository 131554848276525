import React, { useContext, useEffect, useState } from "react";
import CardEditProfile from "../../Cards/CardEditProfile";
import EditProfileHeader from "../EditProfile/EditProfileHeader";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import ClientFullInfo from "../../../models/Clients/ClientFullInfo/ClientFullInfo";
import { toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner";
import { ERROR_MESSAGES } from "../../../enums/enums";
import { boxStyle } from "../EditProfile/EditProfileRow";
import { ApiContext } from "../../../providers/ApiProvider";

const APIKeys: React.FC = () => {
  const { clientHttps } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const [clientInfo, setClientInfo] = useState<ClientFullInfo | null>(null);

  useEffect(() => {
    clientHttps
      ?.getClientInfo()
      .then((clientInfo: ClientFullInfo) => setClientInfo(clientInfo))
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.CLIENT_INFO);
        setClientInfo(new ClientFullInfo());
      });

    return () => setClientInfo(null);
  }, [selectedClient]);

  return (
    <CardEditProfile>
      <EditProfileHeader label={"API Keys"} />
      {clientInfo && clientInfo.googleEndpointsKeys.length ? (
        clientInfo.googleEndpointsKeys.map((apiKeyInfo, index) => (
          <div
            className="w-full lg:w-6/12"
            key={`${apiKeyInfo.label}-${index}`}
          >
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor={apiKeyInfo.label}
              >
                {apiKeyInfo.label}
              </label>
              <input
                type="text"
                className={boxStyle}
                value={apiKeyInfo.value}
                readOnly
              />
            </div>
          </div>
        ))
      ) : (
        <Spinner></Spinner>
      )}
    </CardEditProfile>
  );
};

export default APIKeys;

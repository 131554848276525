import React from "react";

type ShowAllButtonProps = {
  onClick: () => void;
  label: string;
};

const ShowAllButton: React.FC<ShowAllButtonProps> = ({ onClick, label }) => {
  return (
    <button
      onClick={onClick}
      className="rounded bg-brandColor text-white hover:bg-lightBlue-800 text-xs font-bold p-2"
    >
      {label}
    </button>
  );
};

export default ShowAllButton;

import PrivateLeagueBreakdown from "./Breakdown/PrivateLeagueBreakdown";
import { PRIVATE_LEAGUES_REPORTS_TABS } from "./PrivateLeaguesReport";
import PrivateLeagueTopTemplates from "./TopTemplates/PrivateLeagueTopTemplates";
import PrivateLeagueUsers from "./Users/PrivateLeagueUsers";

type PrivateLeagueReportUIProps = {
  openTab: string;
};

const PrivateLeagueReportUI: React.FC<PrivateLeagueReportUIProps> = ({
  openTab,
}) => {
  if (openTab === PRIVATE_LEAGUES_REPORTS_TABS.BREAKDOWN) {
    return <PrivateLeagueBreakdown />;
  }

  if (openTab === PRIVATE_LEAGUES_REPORTS_TABS.TOP_PRIVATE_LEAGUES_TEMPLATES) {
    return <PrivateLeagueTopTemplates />;
  }

  return <PrivateLeagueUsers />;
};

export default PrivateLeagueReportUI;

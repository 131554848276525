import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import React, { useEffect, useState } from "react";
import { useMatchDataTeam } from "../../../../../hooks/useMatchDataTeam";
import { useMatchSummary } from "../../../../../hooks/useMatchSummary";
import ShowAllButton from "../../../../Buttons/ShowAllButton";
import Match from "../../../../Common/Match";
import Spinner from "../../../../Spinner/Spinner";
import MatchSummary from "./MatchSummary";

/**
 * Used to pass the returned value from function as a prop to MatchSummary component.
 * The match model will be used to construct the heading of the component.
 */

const matchModel = (
  nextMatch: MatchBasicModel | null,
  matches: MatchBasicModel[] | []
) => {
  if (nextMatch) return nextMatch;

  if (matches.length) return matches[0];

  return null;
};

type FootballTeamsMatchDataProps = {
  teamId: string;
};

const FootballTeamsMatchData: React.FC<FootballTeamsMatchDataProps> = ({
  teamId,
}) => {
  const limitMatches = 5;

  const [prevMatchId, setPrevMatchId] = useState<string>("");
  const [nextMatchId, setNextMatchId] = useState<string>("");
  const [prevMatchesLimit, setPrevMatchesLimit] =
    useState<number>(limitMatches);
  const [nextMatchesLimit, setNextMatchesLimit] =
    useState<number>(limitMatches);
  const [prevMatch, setPrevMatch] = useState<MatchBasicModel>(null);
  const [nextMatch, setNextMatch] = useState<MatchBasicModel>(null);

  const matchData = useMatchDataTeam(teamId);
  const { pastMatchSummary, upcomingMatchSummary } = useMatchSummary(
    prevMatchId,
    nextMatchId
  );

  useEffect(() => {
    setPrevMatchId(matchData?.prevMatches[0].id);
    setNextMatchId(matchData?.nextMatches[0].id);
  }, [matchData]);

  if (!matchData) return <Spinner />;

  const loadMorePrevMatches = () =>
    setPrevMatchesLimit((prevState: number) => prevState + limitMatches);

  const loadMoreNextMatches = () =>
    setNextMatchesLimit((prevState: number) => prevState + limitMatches);

  return (
    <div className="grid xl:grid-cols-2 xl:gap-2 grid-cols-1 mb-6">
      <div>
        <div className="overflow-visible shadow-l border border-blueGray-200 rounded bg-blueGray-100 mb-6">
          <h2 className="text-center font-bold mb-4 bg-blueGray-200 py-2">
            Past matches
          </h2>
          <div className="p-2">
            {matchData.prevMatches.length ? (
              matchData.prevMatches
                .slice(0, prevMatchesLimit)
                .map((match: MatchBasicModel, index: number) => (
                  <Match
                    key={`prev-${match.id}-${index}`}
                    match={match}
                    matchId={prevMatchId}
                    setMatchId={setPrevMatchId}
                    setMatchModel={setPrevMatch}
                  />
                ))
            ) : (
              <p className="text-center font-bold">
                No previous matches are avalaible
              </p>
            )}
            {matchData.prevMatches.length > limitMatches ? (
              <div className="text-center">
                <ShowAllButton
                  onClick={loadMorePrevMatches}
                  label={"Load more"}
                />
              </div>
            ) : null}
          </div>
        </div>
        <MatchSummary
          summary={pastMatchSummary}
          model={matchModel(prevMatch, matchData.prevMatches)}
        />
      </div>
      <div>
        <div className="overflow-visible shadow-l border border-blueGray-200 rounded bg-blueGray-100 mb-6">
          <h2 className="text-center font-bold mb-4 bg-blueGray-200 py-2">
            Upcoming matches
          </h2>
          <div className="p-2">
            {matchData.nextMatches.length ? (
              matchData.nextMatches
                .slice(0, nextMatchesLimit)
                .map((match: MatchBasicModel, index: number) => (
                  <Match
                    key={`next-${match.id}-${index}`}
                    match={match}
                    matchId={nextMatchId}
                    setMatchId={setNextMatchId}
                    setMatchModel={setNextMatch}
                  />
                ))
            ) : (
              <p className="text-center font-bold">
                No upcoming matches are avalaible
              </p>
            )}
            {matchData.nextMatches.length > limitMatches ? (
              <div className="text-center">
                <ShowAllButton
                  onClick={loadMoreNextMatches}
                  label={"Load more"}
                />
              </div>
            ) : null}
          </div>
        </div>
        <MatchSummary
          summary={upcomingMatchSummary}
          model={matchModel(nextMatch, matchData.nextMatches)}
        />
      </div>
    </div>
  );
};

export default FootballTeamsMatchData;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import PredictionsPerEntityBuilder from "../builders/reports/PredictionsPerEntityBuilder";
import { ERROR_MESSAGES } from "../enums/enums";
import PredictionsPerEntityBreakdown from "../models/Reports/Predictions/PredictionsPerEntityBreakdown";
import PredictionsReport from "../models/Reports/Predictions/PredictionsReport";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { ReportParamsContext } from "../providers/ReportParamsProvider";

export const usePredictionsPerEntity = (entities: string[]) => {
  const [predictionsPerEntity, setPredictionsPerEntity] =
    useState<PredictionsReport<PredictionsPerEntityBreakdown>>(null);

  const { datePeriod } = useContext(ReportParamsContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const api = useContext(ApiContext);

  const getPredictionsPerEntitiy = async () => {
    try {
      const predictionsPerEntity =
        await api.reportingHttps.getPredictionsPerEntity(datePeriod, entities);

      await new PredictionsPerEntityBuilder(
        api,
        predictionsPerEntity.breakdown
      ).build();
      setPredictionsPerEntity(predictionsPerEntity);
    } catch (error) {
      toast.error(ERROR_MESSAGES.PREDICTIONS_PER_ENTITY_REPORT);
      setPredictionsPerEntity(
        new PredictionsReport<PredictionsPerEntityBreakdown>()
      );
    }
  };

  useEffect(() => {
    getPredictionsPerEntitiy();

    return () => setPredictionsPerEntity(null);
  }, [selectedClient, datePeriod, entities]);

  return predictionsPerEntity;
};

import React, { useRef } from "react";
import FeaturesLayout from "../FeaturesLayout";
import Spinner from "../../../Spinner/Spinner";
import { useFootballSettingsConfig } from "../../../../hooks/client/useFootballSettingsConfig";
import FootballSettingsInfo from "./FootballSettingsInfo";

const FootballSettings: React.FC = () => {
  const isFootballSettingsEdited = useRef<boolean>(false);

  const footballSettingsConfig = useFootballSettingsConfig(
    isFootballSettingsEdited
  );

  return (
    <FeaturesLayout
      title={"Configuration for Football settings"}
      description={"This is football settings configuration for client: "}
      additionalParagraph={"football_settings"}
    >
      {footballSettingsConfig ? (
        <FootballSettingsInfo
          config={footballSettingsConfig}
          isFootballSettingsEdited={isFootballSettingsEdited}
        ></FootballSettingsInfo>
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FootballSettings;

import ActionValueModel from "./ActionValue/ActionValueModel";
import RewardsModel from "./RewardsModel/RewardsModel";

export default class LoyaltyFeaturesModel {
  enabled: boolean = false;
  conditions: {
    list: string[];
  };
  actions: { value: ActionValueModel[] } = { value: [new ActionValueModel()] };
  rewards: RewardsModel = new RewardsModel();
}

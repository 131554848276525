import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export interface MauVsRegistrationBreakdown {
  date: string;
  activeUsers: number;
  registrations: number;
}

export const useMauVsRegistrations = () => {
  const [mauVsRegistrations, setMauVsRegistrations] =
    useState<MauVsRegistrationBreakdown[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getMauVsRegistrations = async () => {
    try {
      const breakdownMauVsRegistrations =
        await api.reportingHttps.getMauVsRegistrations();

      setMauVsRegistrations(breakdownMauVsRegistrations);
    } catch (error) {
      toast.error(ERROR_MESSAGES.MAU_VS_REGISTRATIONS_REPORT);
      setMauVsRegistrations([{ date: "", activeUsers: 0, registrations: 0 }]);
    }
  };

  useEffect(() => {
    getMauVsRegistrations();

    return () => setMauVsRegistrations(null);
  }, [selectedClient]);

  return mauVsRegistrations;
};

import React from "react";
import EnabledSelect from "../../../Common/EnabledSelect";
import BadgesExtended from "../../../../../../models/features/loyalty/Badges/BadgesExtended";
import LoyaltyInput from "../../../Common/LoyaltyInput";
import FlagsSelect from "../../../Common/FlagsSelect";

type BadgeProps = {
  badge: BadgesExtended;
  onChangeBadges: (
    field: string,
    requirementsField: string,
    value: any
  ) => void;
  removeExistingBadge: (uid: string) => void;
};

const Badge: React.FC<BadgeProps> = ({
  badge,
  onChangeBadges,
  removeExistingBadge,
}) => {
  return (
    <>
      <EnabledSelect
        uid={badge.uid}
        enabled={badge.enabled}
        scenario="Badges"
        onChangeBadges={onChangeBadges}
        removeExistingReward={() => removeExistingBadge(badge.uid)}
      />
      <LoyaltyInput
        uid={badge.uid}
        label="ID"
        value={badge.id}
        onChangeBadges={(value) => onChangeBadges("id", "", value)}
        scenario="Badges"
      />
      <LoyaltyInput
        uid={badge.uid}
        label="Label"
        value={badge.label}
        onChangeBadges={(value) => onChangeBadges("label", "", value)}
        scenario="Badges"
      />
      <LoyaltyInput
        uid={badge.uid}
        label="Description"
        value={badge.description}
        onChangeBadges={(value) => onChangeBadges("description", "", value)}
        scenario="Badges"
      />
      <FlagsSelect
        defaultValue={badge.flags}
        onChangeBadges={(value) => onChangeBadges("flags", "", value)}
      />
      <div className="mb-2">
        <label className="font-bold mb-2 text-center">Assets:</label>
        <LoyaltyInput
          uid={badge.uid}
          label="Main image URL"
          value={badge.assets?.mainImageUrl}
          onChangeBadges={(value) => onChangeBadges("assets", "", value)}
          scenario="Badges"
        />
      </div>
    </>
  );
};

export default Badge;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import TopFollowsFootballEntitiesBuilder from "../builders/reports/TopFollowsFootballEntitiesBuilder";
import { ERROR_MESSAGES } from "../enums/enums";
import TopFollowsFootballEntitiesModel from "../models/Reports/FootballCompetitionsFollows/TopFollowsFootballEntitiesModel";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { Entity } from "../types/types";

export const useTopFollowedFootballEntities = (
  period: { fromDate: string; toDate: string },
  entityIds: string[],
  entity: Entity
) => {
  const [topFollowsFootballEntities, setTopFollowsFootballEntities] =
    useState<TopFollowsFootballEntitiesModel>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getTopFollowsFootballEntities = async () => {
    if (!entityIds.length) {
      try {
        let successRatesEntities = null;
        if (entity !== "players") {
          successRatesEntities =
            await api.reportingHttps.getSuccessRatesEntities(period);
        }
        const topFollowedEntities =
          await api.reportingHttps.getTopFollowsEntity(entity, period);
        const builder = new TopFollowsFootballEntitiesBuilder(
          api,
          successRatesEntities
            ? successRatesEntities.breakdown
            : successRatesEntities,
          topFollowedEntities,
          entity
        );
        const footballEntitiesFollows = await builder.build();
        setTopFollowsFootballEntities(footballEntitiesFollows);
      } catch (error) {
        toast.error(ERROR_MESSAGES.TOP_FOLLOWED_FOOTBALL_ENTITIES_REPORT);
        setTopFollowsFootballEntities(new TopFollowsFootballEntitiesModel());
      }
    } else {
      setTopFollowsFootballEntities(null);
    }
  };

  useEffect(() => {
    getTopFollowsFootballEntities();

    return () => setTopFollowsFootballEntities(null);
  }, [selectedClient, entityIds, period]);

  return topFollowsFootballEntities;
};

export default class DatasetChart {
  id: string = "";
  label: string = "";
  data: number[] = [];
  fill: boolean = false;
  lineTension: number = 0;
  borderColor: string = "";
  backgroundColor: string = "";
  borderWidth: number = 1;
}

import Points from "../Points/Points";
import PointsValue from "../PointsValue/PointsValue";

export interface IVotingPointsModel {
  potm: Points[];
  poll: Points[];
}

export default class PointsModel {
  general: Points[] = [];
  discussionPost: PointsValue[] = [];
  predictor: PointsValue[] = [];
  activity: PointsValue[] = [];
  topX: PointsValue[] = [];
  matchQuiz: PointsValue[] = [];
  voting: IVotingPointsModel = {
    potm: [],
    poll: [],
  };
}

import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";
import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";

export default class FeaturesBuilder {
  protected sdk: FansUnitedSDKModel;

  constructor(sdk: FansUnitedSDKModel) {
    this.sdk = sdk;
  }

  protected getCompetitions = async (
    competitionIds: string[]
  ): Promise<CompetitionBasicModel[]> => {
    if (competitionIds.length) {
      const competitionsFilters = { competitionIds } as CompetitionFilters;

      try {
        return await this.sdk.football.getCompetitions(
          competitionsFilters as CompetitionFilters
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    return [];
  };

  protected getTeams = async (teamIds: string[]): Promise<TeamBasicModel[]> => {
    if (teamIds.length) {
      const teamsFilters = { teamIds } as TeamFilters;

      try {
        const paginatedTeams = await this.sdk.football.getTeams(teamsFilters);

        return paginatedTeams.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    return [];
  };
}

import React, { PropsWithChildren } from "react";

const AverageFollowsContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="w-full p-4 min-w-0 break-words bg-blueGray-50 rounded shadow-lg mb-4">
      {children}
    </div>
  );
};

export default AverageFollowsContainer;

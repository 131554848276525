import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import PrivateLeaguesTopTemplatesModel from "../../../models/Reports/PrivateLeagues/PrivateLeaguesTopTemplatesModel";

export const usePrivateLeaguesTopTemplates = () => {
  const [privateLeaguesTopTemplates, setPrivateLeaguesTopTemplates] =
    useState<PrivateLeaguesTopTemplatesModel>(null);

  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod } = useContext(ReportParamsContext);
  const api = useContext(ApiContext);

  const getPrivateLeaguesTopTemplates = async () => {
    try {
      const topTemplates =
        await api.reportingHttps.getPrivateLeagueTopTemplates(datePeriod);
      const templates = await api.sdk.loyalty.getTemplates(null, true);
      topTemplates.breakdown.private.forEach((topTemplate) => {
        topTemplate.model =
          templates.data.find((template) => template.id === topTemplate.id) ||
          null;
      });
      topTemplates.breakdown.oneVsOne.forEach((topTemplate) => {
        topTemplate.model =
          templates.data.find((template) => template.id === topTemplate.id) ||
          null;
      });
      topTemplates.breakdown.private.sort((a, b) => b.position - a.position);
      topTemplates.breakdown.oneVsOne.sort((a, b) => b.position - a.position);
      setPrivateLeaguesTopTemplates(topTemplates.breakdown);
    } catch (error) {
      toast.error(ERROR_MESSAGES.PRIVATE_LEAGUES_TOP_TEMPLATES_REPORT);
      setPrivateLeaguesTopTemplates(new PrivateLeaguesTopTemplatesModel());
    }
  };

  useEffect(() => {
    getPrivateLeaguesTopTemplates();

    return () => setPrivateLeaguesTopTemplates(null);
  }, [selectedClient, datePeriod]);

  return privateLeaguesTopTemplates;
};

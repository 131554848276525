import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../providers/AuthProvider";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../enums/enums";
import { firebaseAuth } from "../../../sdks/firebase/firebase";
import { parseJwt } from "../../../helpers/helpers";
import ProfileValidator from "../../../validators/ProfileValidator";
import UpdateButton from "../../Buttons/UpdateButton";
import CardEditProfile from "../../Cards/CardEditProfile";
import PasswordRow from "./PasswordRow";
import EditProfileHeader from "../EditProfile/EditProfileHeader";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";

const ChangePassword: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { clientHttps } = useContext(ApiContext);
  const user = useContext(AuthContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const updateProfileValidator = new ProfileValidator();

  const getUserInfo = () => {
    return user?.getIdToken().then((idToken: string) => {
      return parseJwt(idToken);
    });
  };

  useEffect(() => {
    getUserInfo()?.then((user: any) => setEmail(user.email));
  }, [user]);

  const handleOnChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentPassword(event.target.value);
  };

  const handleOnChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleOnChangeRepeatNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatNewPassword(event.target.value);
  };

  const updateInformation = async () => {
    setIsLoading(true);
    const validator = updateProfileValidator.validateChangePassword(
      currentPassword,
      newPassword,
      repeatNewPassword
    );

    if (!validator.isValid) {
      console.error(validator.message);
      toast.error(validator.message);
      setIsLoading(false);

      return;
    }

    try {
      await firebaseAuth().signInWithEmailAndPassword(email, currentPassword);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.CURRENT_PASSWORD_INVALID);
      setIsLoading(false);

      return;
    }

    try {
      await clientHttps?.updateStaffInfo(user?.uid as string, {
        pass: newPassword,
      });
      toast.success(SUCCESS_MESSAGES.CHANGE_PASSWORD);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.CHANGE_PASSWORD);
    }

    setIsLoading(false);
  };

  return (
    <CardEditProfile>
      <EditProfileHeader label={"Change password"} />
      <PasswordRow
        label={"Current password"}
        onChange={handleOnChangeCurrentPassword}
      />
      <PasswordRow
        label={"New password"}
        onChange={handleOnChangeNewPassword}
      />
      <PasswordRow
        label={"Repeat new password"}
        onChange={handleOnChangeRepeatNewPassword}
      />
      <UpdateButton
        label={"Change password"}
        onClick={updateInformation}
        isLoading={isLoading}
      />
    </CardEditProfile>
  );
};

export default ChangePassword;

import React, { Dispatch, SetStateAction } from "react";

const filters = ["All", "Competitions", "Teams", "Players"];

type FilterEntityProps = {
  filterEntity: string;
  setFilterEntity: Dispatch<SetStateAction<string>>;
};

const FilterEntity: React.FC<FilterEntityProps> = ({
  filterEntity,
  setFilterEntity,
}) => {
  return (
    <div className="flex items-center mb-4">
      {filters.map((filter: string) => (
        <div
          key={`predictions-per-entity-filter-${filter}`}
          className="flex items-center cursor-pointer"
          onClick={() => setFilterEntity(filter)}
        >
          <input
            type="radio"
            className="mr-1 cursor-pointer"
            checked={filterEntity === filter}
            readOnly
          />
          <span className="mr-1">{filter}</span>
        </div>
      ))}
    </div>
  );
};

export default FilterEntity;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import FootballPaginationModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Pagination/FootballPaginationModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { reactSelectSingleValueStyle } from "../../../../../../constants/styles";
import { ERROR_MESSAGES } from "../../../../../../enums/enums";
import { formatOptions } from "../../../../../../helpers/helpers";
import SelectOption from "../../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../../providers/ApiProvider";
import { placeholders } from "../../../../../../assets/placeholders/placeholders";

const imageLogoEntity = (entity: string, option: SelectOption) => {
  let placeholder: string = "";

  if (entity === "competition") {
    placeholder = placeholders.competition;
  } else if (entity === "team") {
    placeholder = placeholders.team;
  } else if (entity === "player") {
    placeholder = placeholders.avatar;
  }

  return (
    <img
      src={option.logo ? option.logo : placeholder}
      alt={""}
      className="h-5 w-5 mr-1"
    />
  );
};

type BadgeEntitySelectProps = {
  entityType: string;
  loadedEntityOption: SelectOption;
  onChangeEntity: (entityId: string) => void;
};

const BadgeEntitySelect: React.FC<BadgeEntitySelectProps> = ({
  entityType,
  loadedEntityOption,
  onChangeEntity,
}) => {
  const [entityOption, setEntityOption] =
    useState<SelectOption>(loadedEntityOption);

  const api = useContext(ApiContext);

  useEffect(() => {
    return () => {
      setEntityOption(null);
    };
  }, [entityType]);

  const minInputLength = 3;

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (inputValue.length < minInputLength) return callback([]);

    let competitionFilters: CompetitionFilters = null;
    let teamFilters: TeamFilters = null;
    let playerFilters: PlayerFilters = null;
    let options: SelectOption[] = null;

    if (inputValue && entityType === "competition") {
      competitionFilters = {
        name: inputValue,
      } as CompetitionFilters;
    } else if (inputValue && entityType === "team") {
      teamFilters = {
        name: inputValue,
      } as TeamFilters;
    } else if (inputValue && entityType === "player") {
      playerFilters = {
        name: inputValue,
      } as PlayerFilters;
    }

    if (entityType === "competition") {
      api?.sdk.football
        .getCompetitions(competitionFilters)
        .then((response: CompetitionBasicModel[]) => {
          options = response.map((competition: CompetitionBasicModel) =>
            formatOptions(competition)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    } else if (entityType === "team") {
      api?.sdk.football
        .getTeams(teamFilters)
        .then((response: FootballPaginationModel) => {
          //Sometimes API will return teams with name like: "Chelsea/Manchester City"
          const actualTeams = response.data.filter(
            (team: TeamBasicModel) => !team.name.includes("/")
          );
          options = actualTeams.map((team: TeamBasicModel) =>
            formatOptions(team)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    } else if (entityType === "player") {
      api?.sdk.football
        .getPlayers(playerFilters)
        .then((response: FootballPaginationModel) => {
          options = response.data.map((player: PlayerBasicModel) =>
            formatOptions(player)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    }
  };

  const handleOnChange = (selectedOption: SelectOption) => {
    onChangeEntity(selectedOption ? selectedOption.id : null);
    setEntityOption(selectedOption);
  };

  const loadOptions = _.debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? `No ${entityType} found`
      : "Type at least 3 characters";

  return (
    <AsyncSelect
      components={{ DropdownIndicator: null }}
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      //@ts-ignore
      styles={reactSelectSingleValueStyle}
      onChange={handleOnChange}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Loading..."}
      value={entityOption}
      loadOptions={loadOptions}
      isClearable={true}
      placeholder={`Type ${entityType} name...`}
      formatOptionLabel={(option) => (
        <div>
          <div className="xl:flex xl:items-center">
            {imageLogoEntity(entityType, option)}
            <span className="mr-2 text-xs xl:text-base">{option.label}</span>
          </div>
          <div className="xl:flex xl:items-center">
            <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
            <span className="mr-1">{option.countryName}</span>
          </div>
        </div>
      )}
    />
  );
};

export default BadgeEntitySelect;

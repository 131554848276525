import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import FootballEntityFollowsBuilder from "../builders/reports/FootballEntityFollowsBuilder";
import { ERROR_MESSAGES } from "../enums/enums";
import FootballEntityFollows from "../models/Reports/FootballCompetitionsFollows/FootballEntityFollows";
import { ApiContext } from "../providers/ApiProvider";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";
import { Entity } from "../types/types";

export const useFollowedFootballEntities = (
  entityIds: string[],
  period: { fromDate: string; toDate: string },
  entity: Entity
) => {
  const [footballEntityFollows, setFootballEntityFollows] =
    useState<FootballEntityFollows>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getFootballEntityFollows = async () => {
    if (entityIds.length) {
      try {
        let successRatesEntities = null;
        //TODO: Try to optimize this request - to be called only once on component render
        if (entity !== "players") {
          successRatesEntities =
            await api.reportingHttps.getSuccessRatesEntities(period);
        }
        const entitiyFollowsInfo =
          await api.reportingHttps.getEntityFollowsInformation(
            entity,
            entityIds[0],
            period
          );
        const builder = new FootballEntityFollowsBuilder(
          api,
          entityIds[0],
          successRatesEntities
            ? successRatesEntities.breakdown
            : successRatesEntities,
          entitiyFollowsInfo,
          entity
        );
        const footballEntityFollows = await builder.build();
        setFootballEntityFollows(footballEntityFollows);
      } catch (error) {
        toast.error(ERROR_MESSAGES.FOOTBALL_ENTITY_FOLLOWS_REPORT);
        setFootballEntityFollows(new FootballEntityFollows());
      }
    } else {
      setFootballEntityFollows(null);
    }
  };

  useEffect(() => {
    getFootballEntityFollows();

    return () => setFootballEntityFollows(null);
  }, [selectedClient, entityIds, period]);

  return footballEntityFollows;
};

import React from "react";
import { IFootballFantasyCoefficients } from "../../../../models/features/fantasy/FootballFantasyFeature";
import { inputNumber } from "../../../../helpers/helpers";

const goalsCoefficients = [
  "goalsForwards",
  "penaltyGoals",
  "goalsMidfielder",
  "goalsDefender",
  "goalsGoalkeeper",
  "ownGoals",
  "concededGoals",
];

const cleanSheetsCoefficients = [
  "cleanSheetsGoalkeeper",
  "cleanSheetsDefender",
  "cleanSheetsMidfielder",
  "cleanSheetsForwards",
];

const cardsCoefficients = ["yellowCards", "redCards"];

const miscCoefficients = [
  "assists",
  "shots",
  "offsides",
  "tackles",
  "saves",
  "shotsOn",
  "minutesEqualOrOver60",
  "minutesUnder60",
  "foulsCommitted",
  "penaltyCommitted",
  "penaltyWon",
  "penaltyMissed",
  "caughtBall",
];

const keyToLabel = new Map<string, string>()
  .set("assists", "Assists")
  .set("shots", "Shots")
  .set("offsides", "Offsides")
  .set("tackles", "Tackles")
  .set("saves", "Saves")
  .set("minutesEqualOrOver60", "Minutes played equal or over 60")
  .set("minutesUnder60", "Minutes played under 60")
  .set("yellowCards", "Yellow cards")
  .set("redCards", "Red cards")
  .set("goalsGoalkeeper", "Goalkeeper's goals")
  .set("goalsDefender", "Defender's goals")
  .set("goalsMidfielder", "Midfielder's goals")
  .set("goalsForwards", "Forward's goals")
  .set("penaltyGoals", "Penalty goals")
  .set("ownGoals", "Own goals")
  .set("cleanSheetsGoalkeeper", "Goalkeeper's clean sheet")
  .set("cleanSheetsDefender", "Defender's clean sheet")
  .set("cleanSheetsMidfielder", "Midfielder's clean sheet")
  .set("cleanSheetsForwards", "Forward's clean sheet")
  .set("shotsOn", "Shots on")
  .set("foulsCommitted", "Fouls committed")
  .set("penaltyCommitted", "Penalty committed")
  .set("penaltyWon", "Penalty won")
  .set("penaltyMissed", "Penalty missed")
  .set("concededGoals", "Conceded goals")
  .set("caughtBall", "Caught ball");

type FantasyCoefficientsProps = {
  coefficients: IFootballFantasyCoefficients;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FantasyCoefficients: React.FC<FantasyCoefficientsProps> = (props) => {
  return (
    <div className="border border-blueGray-200 rounded-lg p-2 bg-blueGray-50">
      <h3 className="font-bold mb-2">Coefficients</h3>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Goals</h4>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
          {goalsCoefficients.map((key) => (
            <div
              key={`football-fantasy-coefficient-goals-${key}`}
              className="flex flex-col md:mb-0 mb-2 justify-between col-span-1 min-w-0 break-words"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.coefficients[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Clean sheets</h4>
        <div className="grid md:grid-cols-4 grid-cols-1 gap-2">
          {cleanSheetsCoefficients.map((key) => (
            <div
              key={`football-fantasy-coefficients-clean-sheets${key}`}
              className="flex flex-col justify-between col-span-1 min-w-0 break-words"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.coefficients[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Cards</h4>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
          {cardsCoefficients.map((key) => (
            <div
              key={`football-fantasy-coefficient-${key}`}
              className="flex flex-col justify-between col-span-1 min-w-0 break-words"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3"
                defaultValue={props.coefficients[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="border border-blueGray-300 rounded-lg p-2 mb-2">
        <h4 className="font-bold mb-2">Misc</h4>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
          {miscCoefficients.map((key) => (
            <div
              key={`football-fantasy-coefficient-${key}`}
              className="flex flex-col justify-between col-span-1 min-w-0 break-words"
            >
              <label className="mb-2">{keyToLabel.get(key)}</label>
              <input
                name={key}
                className="p-3 mb-0"
                defaultValue={props.coefficients[key]}
                onChange={props.onChange}
                type="text"
                onKeyDown={(event) => inputNumber(event, true)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FantasyCoefficients;

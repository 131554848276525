import React from "react";
import { upperCase } from "../../../../../helpers/helpers";
import UsersBreakdown from "../../../../../models/Reports/UsersBreakdown";
import Users from "./Users";

type TotalUsersProps = {
  total: UsersBreakdown;
  reportType: "users_acquired" | "follows_entity";
  usersBreakdown?: string[];
  onChangeCheckbox?: (userBreakdown: string) => void;
};

const TotalUsers: React.FC<TotalUsersProps> = ({
  total,
  reportType,
  usersBreakdown,
  onChangeCheckbox,
}) => {
  return (
    <div className="xl:grid xl:grid-cols-4 xl:gap-2 grid-cols-1 mb-6">
      {Object.keys(total).map((user: string, idx: number) => (
        <Users
          key={`${user}-${idx}`}
          user={user}
          usersBreakdown={usersBreakdown}
          count={total[user]}
          all={total?.all}
          checkboxLabel={`Show ${upperCase(user)}`}
          onChange={onChangeCheckbox}
          reportType={reportType}
        />
      ))}
    </div>
  );
};

export default TotalUsers;

import React, { useContext } from "react";
import CardReport from "../../../../Cards/CardReport";
import "../../Reports.css";
import ReportInfo from "../../Common/ReportInfo";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import DatePeriod from "../../Common/DatePeriod";
import { useDiscussionsTop100Contributors } from "../../../../../hooks/reports/discussions/useDiscussionsTop100Contributors";
import Spinner from "../../../../Spinner/Spinner";
import DiscussionTableView from "../DiscussionTableView";

const Top100Contributors: React.FC = () => {
  const { datePeriod } = useContext(ReportParamsContext);

  const top100Contributors = useDiscussionsTop100Contributors(datePeriod);

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Discussions top 100 contributors"}
          description={
            "This report shows the top 100 contributors (users with at least 1 post) specified by period."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
          <DatePeriod defaultDatePeriod={true} />
        </div>
      </div>
      {top100Contributors ? (
        <div className="mb-6 border border-blueGray-200 rounded p-2">
          <h2 className="text-center mb-4 font-bold text-xl">{`Top ${top100Contributors.length} discussions contributors`}</h2>
          <div className="block w-full overflow-x-auto">
            <DiscussionTableView
              type={"top_contributors"}
              data={top100Contributors}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default Top100Contributors;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import DiscussionsTop100ContributorsModel from "../../../models/Reports/Discussions/DiscussionsTop100ContributorsModel";
import ProfileModel from "fansunited-sdk-esm/Core/Namespaces/Profile/Models/ProfileModel";

export const useDiscussionsTop100Contributors = (period) => {
  const [top100Contributors, setTop100Contributors] =
    useState<DiscussionsTop100ContributorsModel[]>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getTop100Contributors = async () => {
    try {
      const top100Contributors =
        await api.reportingHttps.getTop100DiscussionsContributors(period);
      const profileIds = top100Contributors.map(
        (contributor: DiscussionsTop100ContributorsModel) => contributor.id
      );
      const profiles = await api.sdk.profile.getByIds(profileIds);
      top100Contributors.forEach(
        (contributor: DiscussionsTop100ContributorsModel) => {
          contributor.profileModel =
            profiles.find(
              (profile: ProfileModel) => profile.id === contributor.id
            ) || null;
        }
      );
      setTop100Contributors(top100Contributors);
    } catch (error) {
      toast.error(ERROR_MESSAGES.DISCUSSIONS_TOP_100_CONTRIBUTORS_REPORT);
      setTop100Contributors([]);
    }
  };

  useEffect(() => {
    getTop100Contributors();

    return () => setTop100Contributors(null);
  }, [selectedClient]);

  return top100Contributors;
};

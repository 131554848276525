import React, { useContext, useState } from "react";
import { ReportParamsContext } from "../../../../providers/ReportParamsProvider";
import CardReport from "../../../Cards/CardReport";
import DatePeriod from "../Common/DatePeriod";
import ReportInfo from "../Common/ReportInfo";
import SuccessRateMarkets from "./Markets/SuccessRateMarkets";
import SuccessRateTabsReport from "./SuccessRateTabsReport";
import SuccessRateUsers from "./Users/SuccessRateUsers";
import "../Reports.css";

export enum SUCCESS_RATE_TABS {
  MARKETS = "MARKETS",
  USERS = "USERS",
}

const SuccessRateReport: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>(SUCCESS_RATE_TABS.MARKETS);

  const { comparedTo } = useContext(ReportParamsContext);

  return (
    <div className="w-full px-4">
      <CardReport>
        <div className="headingContainer mb-6">
          <ReportInfo
            title={"Success rate report"}
            description={
              "This report is separated in two tabs. In the first one 'Markets' it shows success rates for all markets for the past 30 days. For the second one 'Users' shows the top 100 users with highest success rate with at least 100 placed predictions across all markets."
            }
          />
          <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
            {openTab === SUCCESS_RATE_TABS.MARKETS ? (
              <DatePeriod />
            ) : (
              <DatePeriod defaultDatePeriod={true} />
            )}
          </div>
        </div>
        <SuccessRateTabsReport openTab={openTab} setOpenTab={setOpenTab} />
        {openTab === SUCCESS_RATE_TABS.MARKETS ? (
          <>
            <SuccessRateMarkets period="current" />
            {comparedTo ? <SuccessRateMarkets period="compared_to" /> : null}
          </>
        ) : (
          <SuccessRateUsers />
        )}
      </CardReport>
    </div>
  );
};

export default SuccessRateReport;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { pageIcons } from "../../../constants/constants";
import { MainPagesEnum } from "../../../enums/enums";
import { linkStyle } from "../../../helpers/helpers";
import { UserAccessPagesContext } from "../../../providers/UserAccessPagesProvider";
import NavigationButton from "./NavigationButton";

type NavigationProps = {
  onClickNavigationUrlHideMobileMenu: () => void;
};

const Navigation: React.FC<NavigationProps> = ({
  onClickNavigationUrlHideMobileMenu,
}) => {
  const { pages } = useContext(UserAccessPagesContext);

  // Reports and Features are used as buttons to display sub pages
  const excludedNavigationPages = [
    MainPagesEnum.API_KEYS,
    MainPagesEnum.EDIT_PROFILE,
    MainPagesEnum.CHANGE_PASSWORD,
    MainPagesEnum.FEATURES,
    MainPagesEnum.REPORTS,
  ] as string[];
  const originalMainPages = Object.values(MainPagesEnum).filter(
    (page: string) => !excludedNavigationPages.includes(page)
  ) as string[];
  const mainPages = pages.filter((page: string) =>
    originalMainPages.includes(page)
  );

  return (
    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
      {mainPages.map((page: string, index: number) => (
        <li key={index} className="flex items-center">
          <Link
            className={linkStyle(page)}
            to={`/${page}`}
            onClick={onClickNavigationUrlHideMobileMenu}
          >
            <i
              className={`${
                pageIcons[page as keyof typeof pageIcons]
              } mr-2 text-sm w-5
                  ${
                    window.location.href.indexOf(`/${page}`) !== -1
                      ? "opacity-75"
                      : "text-blueGray-300"
                  }`}
            ></i>{" "}
            <span>{page}</span>
          </Link>
        </li>
      ))}
      {pages.includes("features") ? (
        <NavigationButton
          navigation={"features"}
          onClickNavigationUrlHideMobileMenu={
            onClickNavigationUrlHideMobileMenu
          }
        />
      ) : (
        <></>
      )}
      {pages.includes("reports") ? (
        <NavigationButton
          navigation={"reports"}
          onClickNavigationUrlHideMobileMenu={
            onClickNavigationUrlHideMobileMenu
          }
        />
      ) : (
        <></>
      )}
    </ul>
  );
};

export default Navigation;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { useState } from "react";
import { placeholders } from "../../../../../assets/placeholders/placeholders";

type FullCoverageCompetitionsPredictorProps = {
  competitions: CompetitionBasicModel[];
};

const FullCoverageCompetitionsPredictor: React.FC<
  FullCoverageCompetitionsPredictorProps
> = ({ competitions }) => {
  const [fullCoverageCompetitions, setFullCoverageCompetitions] =
    useState<CompetitionBasicModel[]>(competitions);

  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <div className="mb-2">
        <label className="font-bold mb-2">Full coverage competitions</label>
        <p className="italic">
          For the following competitions, all prediction markets are allowed.
        </p>
      </div>
      <div>
        <div className="lg:grid grid-cols-4 gap-2 justify-between">
          {fullCoverageCompetitions &&
            fullCoverageCompetitions.length &&
            fullCoverageCompetitions.map(
              (competition: CompetitionBasicModel) => (
                <div
                  key={`${competition.id}-full-coverage-competition`}
                  className="bg-blueGray-200 mb-2 rounded-lg last:border-none p-2"
                >
                  <div className="flex flex-row items-center p-1">
                    <img
                      src={
                        //@ts-ignore
                        competition.assets && competition.assets.logo
                          ? //@ts-ignore
                            competition.assets.logo
                          : placeholders.competition
                      }
                      className="w-5 h-5 mr-2"
                    ></img>
                    <span>{competition.name}</span>
                  </div>
                  <div className="flex flex-row items-center p-1">
                    <img
                      src={
                        competition.country.assets &&
                        //@ts-ignore
                        competition.country.assets.flag
                          ? //@ts-ignore
                            competition.country.assets.flag
                          : placeholders.country
                      }
                      className="w-4 h-3 mr-2 rounded"
                    ></img>
                    <span className="text-xs">{competition.country.name}</span>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default FullCoverageCompetitionsPredictor;

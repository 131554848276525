import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import FootballPaginationModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Pagination/FootballPaginationModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { webhookTypeSelectStyles } from "../../../../../constants/styles";
import { ERROR_MESSAGES } from "../../../../../enums/enums";
import { formatOptions } from "../../../../../helpers/helpers";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../providers/ApiProvider";
import { Entity } from "../../../../../types/types";
import { imageLogoEntity } from "../../../Reports/Common/EntityFollows/EntitySelect";
import { CustomAssetsInterface } from "../../../../../models/Clients/FootballSettingsConfigModel";

type EntitySelectProps = {
  entity: Entity;
  selectedOption: SelectOption;
  entityId: string;
  setCustomAssets: Dispatch<SetStateAction<CustomAssetsInterface>>;
  setSelectedOption: Dispatch<SetStateAction<Map<string, SelectOption>>>;
};

const AssetsSelect: React.FC<EntitySelectProps> = ({
  entity,
  selectedOption,
  entityId,
  setCustomAssets,
  setSelectedOption,
}) => {
  const api = useContext(ApiContext);

  const minInputLength = 3;
  const entitySingular = entity.replace("s", "");

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (!inputValue) {
      return callback([]);
    }

    const filters = { name: inputValue };
    let options: SelectOption[] = null;

    if (entity === "competitions") {
      api?.sdk.football
        .getCompetitions(filters as CompetitionFilters)
        .then((response: CompetitionBasicModel[]) => {
          options = response.map((competition: CompetitionBasicModel) =>
            formatOptions(competition)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    } else if (entity === "teams") {
      api?.sdk.football
        .getTeams(filters as TeamFilters)
        .then((response: FootballPaginationModel) => {
          //Sometimes API will return teams with name like: "Chelsea/Manchester City"
          const actualTeams = response.data.filter(
            (team: TeamBasicModel) => !team.name.includes("/")
          );
          options = actualTeams.map((team: TeamBasicModel) =>
            formatOptions(team)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    } else if (entity === "players") {
      api?.sdk.football
        .getPlayers(filters as PlayerFilters)
        .then((response: FootballPaginationModel) => {
          options = response.data.map((player: PlayerBasicModel) =>
            formatOptions(player)
          );
          callback(options);
        })
        .catch((error) => {
          console.error(error);
          toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
          callback([]);
        });
    }
  };

  const handleOnChange = (option: SelectOption) => {
    switch (entity) {
      case "competitions":
        setCustomAssets((prevState) => {
          const copyPrevState = { ...prevState };

          if (option) {
            copyPrevState.competitionLogos[option.id] =
              copyPrevState.competitionLogos[entityId];
            delete copyPrevState.competitionLogos[entityId];

            return copyPrevState;
          }

          delete copyPrevState.competitionLogos[entityId];

          return copyPrevState;
        });
        break;
      case "teams":
        setCustomAssets((prevState) => {
          const copyPrevState = { ...prevState };

          if (option) {
            copyPrevState.teamLogos[option.id] =
              copyPrevState.teamLogos[entityId];
            delete copyPrevState.teamLogos[entityId];

            return copyPrevState;
          }

          delete copyPrevState.teamLogos[entityId];

          return copyPrevState;
        });
        break;
      case "players":
        setCustomAssets((prevState) => {
          const copyPrevState = { ...prevState };

          if (option) {
            copyPrevState.playerHeadshots[option.id] =
              copyPrevState.playerHeadshots[entityId];
            delete copyPrevState.playerHeadshots[entityId];

            return copyPrevState;
          }

          delete copyPrevState.playerHeadshots[entityId];

          return copyPrevState;
        });
        break;
    }
    setSelectedOption((prevState) => {
      const newMap = new Map(prevState);

      if (option) {
        newMap.set(option.id, option);

        return newMap;
      }

      newMap.delete(entityId);

      return newMap;
    });
  };

  const loadOptions = _.debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? `No ${entitySingular} found`
      : "Type at least 3 characters";

  return (
    <AsyncSelect
      components={{ DropdownIndicator: null }}
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      styles={webhookTypeSelectStyles}
      onChange={handleOnChange}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Loading..."}
      defaultOptions={true}
      value={selectedOption}
      loadOptions={loadOptions}
      isClearable={true}
      placeholder={`Type ${entitySingular} name...`}
      formatOptionLabel={(option) => (
        <div className="flex items-center">
          {imageLogoEntity(entity, option)}
          <span className="mr-2">{option.label}</span>
          <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
          <span className="mr-1">{option.countryName}</span>
        </div>
      )}
    />
  );
};

export default AssetsSelect;

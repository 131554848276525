import { createPopper } from "@popperjs/core";
import React, { useRef, useState } from "react";

type InfoTooltipProps = {
  title: string;
};

const infoTooltipDescription = (rfmGroup: string) => {
  switch (rfmGroup) {
    case "Recency":
      return (
        <>
          <p className="mb-2">
            In the Recency segment, users are divided into five groups based on
            their activity on the website, with Group 5 being the most recent
            and Group 1 representing users who haven’t visited recently. You can
            customize the time thresholds for each group in the configuration
            settings. The default values are:
          </p>
          <ul className="list-disc">
            <li className="ml-6 mb-1">Group 5: Last 48 hours (2 days)</li>
            <li className="ml-6 mb-1">Group 4: Last 120 hours (5 days)</li>
            <li className="ml-6 mb-1">Group 3: Last 336 hours (14 days)</li>
            <li className="ml-6 mb-1">Group 2: Last 720 hours (30 days)</li>
            <li className="ml-6 mb-2">
              Group 1: More than 720 hours (over 1 month)
            </li>
          </ul>
          <p>Feel free to adjust these values to match your specific needs.</p>
        </>
      );
    case "Frequency":
      return (
        <>
          <p className="mb-2">
            Frequency is a crucial metric that evaluates how often users have
            visited the website within a specified time frame, which can be
            easily configured to suit your needs. Unlike Engagement, Frequency
            focuses on the frequency of visits rather than the weight of
            specific actions. Just like with Engagement, we categorize users
            into five groups to provide a clear understanding of their
            visitation habits. Here’s how we determine these groups:
          </p>
          <p className="mb-2">
            We identify the user who visited the least (e.g., 4 times) and the
            one who visited the most frequently (e.g., 762 times) within the
            defined time frame, such as the past year. We evenly divide the
            range of visitation frequencies by 5. For instance, in the range
            (762-4), this division results in intervals of 152. This division
            then leads to the following groupings:
          </p>
          <ul className="list-disc">
            <li className="ml-6 mb-1">
              Group 5: Users who visited most frequently (e.g., 609 to 762
              times)
            </li>
            <li className="ml-6 mb-1">
              Group 4: Users with high visitation rates (e.g., 457 to 608 times)
            </li>
            <li className="ml-6 mb-1">
              Group 3: Users with infrequent visits (e.g., 153 to 304 times)
            </li>
            <li className="ml-6 mb-1">
              Group 2: Users who visited most frequently (e.g., 609 to 762
              times)
            </li>
            <li className="ml-6 mb-2">
              Group 1: Users who visited the least (e.g., 4 to 152 times)
            </li>
          </ul>
          <p>
            These groups allow you to discern different visitation patterns
            among users, aiding you in crafting engagement strategies tailored
            to their frequency of interaction with your website.
          </p>
        </>
      );
    case "Engagement":
      return (
        <>
          <p className="mb-2">
            Engagement is calculated by assigning different weights to user
            actions, allowing for more significant engagements to have a higher
            impact on the calculation. This weighting system is fully
            customizable within the settings. Users are categorized into five
            distinct groups, with Group 5 representing the most engaged and
            Group 1 the least. Here’s how we determine these groups:
          </p>
          <p className="mb-2">
            We identify the user with the lowest engagement value (e.g., 6) and
            the user with the highest (e.g., 971). Next, we evenly divide the
            engagement range by 5. For example, in the range (971-6), this
            results in a division of 193. This division then leads to the
            following group breakdown:
          </p>
          <ul className="list-disc">
            <li className="ml-6 mb-1">
              Group 5: Engagement values from 778 to 971
            </li>
            <li className="ml-6 mb-1">
              Group 4: Engagement values from 584 to 777
            </li>
            <li className="ml-6 mb-1">
              Group 3: Engagement values from 390 to 583
            </li>
            <li className="ml-6 mb-1">
              Group 2: Engagement values from 196 to 389
            </li>
            <li className="ml-6 mb-2">
              Group 1: Engagement values from 4 to 195
            </li>
          </ul>
          <p>
            These groups offer a clear perspective on user engagement levels,
            making it easier to tailor your strategies and interactions
            accordingly.
          </p>
        </>
      );
  }
};

const InfoTooltip: React.FC<InfoTooltipProps> = ({ title }) => {
  const [popoverShow, setPopoverShow] = useState<boolean>(false);

  const iconRef = useRef<SVGSVGElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const openTooltip = () => {
    createPopper(
      iconRef.current as SVGSVGElement,
      popoverRef.current as HTMLDivElement,
      {
        placement: "right",
      }
    );
    setPopoverShow(true);
  };

  const closeTooltip = () => {
    setPopoverShow(false);
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
          ref={iconRef}
        >
          <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
        </svg>
        <div
          className={`${popoverShow ? "" : "hidden"}
              bg-blueGray-600 border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg`}
          ref={popoverRef}
        >
          <div>
            <div className="bg-blueGray-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg">
              {title}
            </div>
            <div className="text-white p-3">
              {infoTooltipDescription(title)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTooltip;

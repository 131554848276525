import LoyaltyConfigModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/ClientFeatures/LoyaltyConfigModel";
import PointsValueModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Points/PointsValueModel";
import TiersModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Tiers/TiersModel";
import ActionValueModel from "../../../models/features/loyalty/ActionValue/ActionValueModel";
import LoyaltyFeaturesModel from "../../../models/features/loyalty/LoyaltyFeaturesModel";
import Points from "../../../models/features/loyalty/Points/Points";
import PointsValue from "../../../models/features/loyalty/PointsValue/PointsValue";
import TiersModelExtended from "../../../models/features/loyalty/TiersModel/TiersModel";
import FeaturesBuilder from "../FeaturesBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class LoyaltyFeaturesBuilder extends FeaturesBuilder {
  private loyaltyConfigFeatures: LoyaltyConfigModel;
  private loyaltyFeaturesModel: LoyaltyFeaturesModel =
    new LoyaltyFeaturesModel();

  constructor(
    sdk: FansUnitedSDKModel,
    loyaltyConfigFeatures: LoyaltyConfigModel
  ) {
    super(sdk);

    this.loyaltyConfigFeatures = loyaltyConfigFeatures;
  }

  /**
   * Adding additional field uid in every editable entity for loyalty features.
   * @returns Our custom LoyaltyFeaturesModel.
   */
  public build = () => {
    this.loyaltyFeaturesModel.enabled = this.loyaltyConfigFeatures.enabled;
    this.loyaltyFeaturesModel.conditions =
      this.loyaltyConfigFeatures.conditions;
    this.buildActionsLoyaltyFeatures();
    this.buildRewardsPointsLoyaltyFeatures();
    this.buildRewardsTiersLoyaltyFeatures();

    return this.loyaltyFeaturesModel;
  };

  private buildActionsLoyaltyFeatures = () => {
    const actions: ActionValueModel[] = [];
    this.loyaltyConfigFeatures.actions.value.forEach(
      (actionValue: any, idx: number) => {
        const action: ActionValueModel = new ActionValueModel();
        action.id = actionValue.id;
        action.conditions = actionValue.conditions;
        action.weight = actionValue.weight;
        action.multiplier = actionValue.multiplier;
        action.uid = `action-${actionValue.id}-${idx}`;

        actions.push(action);
      }
    );

    this.loyaltyFeaturesModel.actions.value = actions;
  };

  private buildRewardsPointsLoyaltyFeatures = () => {
    this.loyaltyFeaturesModel.rewards.points.general = this.addUidFieldInPoints(
      this.loyaltyConfigFeatures.rewards.points.general,
      "general"
    );
    this.loyaltyFeaturesModel.rewards.points.activity =
      this.addUidFieldInPointsMultiplierLoyaltyRewards(
        this.loyaltyConfigFeatures.rewards.points.activity,
        "activity"
      );
    this.loyaltyFeaturesModel.rewards.points.predictor =
      this.addUidFieldInPointsMultiplierLoyaltyRewards(
        this.loyaltyConfigFeatures.rewards.points.predictor,
        "predictor"
      );
    this.loyaltyFeaturesModel.rewards.points.topX =
      this.addUidFieldInPointsMultiplierLoyaltyRewards(
        this.loyaltyConfigFeatures.rewards.points.topX,
        "top_x"
      );
    this.loyaltyFeaturesModel.rewards.points.matchQuiz =
      this.addUidFieldInPointsMultiplierLoyaltyRewards(
        this.loyaltyConfigFeatures.rewards.points.matchQuiz,
        "match_quiz"
      );
    this.loyaltyFeaturesModel.rewards.points.discussionPost =
      this.addUidFieldInPointsMultiplierLoyaltyRewards(
        this.loyaltyConfigFeatures.rewards.points.discussionPost,
        "discussion_post"
      );
    this.loyaltyFeaturesModel.rewards.points.voting.poll =
      this.addUidFieldInPoints(
        //@ts-ignore
        this.loyaltyConfigFeatures.rewards.points.voting.poll,
        "poll"
      );
    this.loyaltyFeaturesModel.rewards.points.voting.potm =
      this.addUidFieldInPoints(
        //@ts-ignore
        this.loyaltyConfigFeatures.rewards.points.voting.potm,
        "potm"
      );
  };

  private addUidFieldInPoints = (points: any[], type: string) => {
    const newPoints: Points[] = [];

    points.forEach((points: Points, idx: number) => {
      const pointsModel = new Points();
      pointsModel.id = points.id;
      pointsModel.points = points.points;
      pointsModel.uid = `reward-points-${type}-${points.id}-${idx}`;

      newPoints.push(pointsModel);
    });

    return newPoints;
  };

  private buildRewardsTiersLoyaltyFeatures = () => {
    const tiers: TiersModelExtended[] = [];
    this.loyaltyConfigFeatures.rewards.tiers.forEach(
      (tier: TiersModel, idx: number) => {
        const model = new TiersModelExtended();

        model.enabled = tier.enabled;
        model.id = tier.id;
        model.points = tier.points;
        model.label = tier.label;
        model.uid = `reward-points-tier-${tier.id}-${idx}`;

        tiers.push(model);
      }
    );

    this.loyaltyFeaturesModel.rewards.tiers = tiers;
  };

  private addUidFieldInPointsMultiplierLoyaltyRewards = (
    points: PointsValueModel[],
    pointEntity: string
  ) => {
    const pointsValue: PointsValue[] = [];

    points.forEach((activityPoints: PointsValueModel, idx: number) => {
      const pointValue = new PointsValue();

      pointValue.id = activityPoints.id;
      pointValue.points = activityPoints.points;
      pointValue.multiplier = activityPoints.multiplier;
      pointValue.conditions = activityPoints.conditions;
      pointValue.alternative = activityPoints.alternative;
      pointValue.uid = `reward-points-${pointEntity}-${activityPoints.id}-${idx}`;

      pointsValue.push(pointValue);
    });

    return pointsValue;
  };
}

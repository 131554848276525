import React from "react";
import PubSubWebhookCredentials from "../../../../../models/features/webhook/credentials/PubSubWebhookCredentials";
import { AllCredentialsFields } from "./WebhookCredentials";
import WebhookTooltip from "../WebhookTooltip";

type PubSubWebhookCredentialsComponentProps = {
  value: PubSubWebhookCredentials;
  onChange: (value: string, field: AllCredentialsFields) => void;
};

const PubSubWebhookCredentialsComponent: React.FC<
  PubSubWebhookCredentialsComponentProps
> = ({ value, onChange }) => {
  return (
    <>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Project ID</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.projectId}
            onChange={(event) => onChange(event.target.value, "projectId")}
            type="text"
          />
          <WebhookTooltip tooltipType={"pub_sub"} property={"projectId"} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Topic Name</label>
        <div className="flex items-center">
          <input
            className="p-3 w-full mr-2"
            defaultValue={value.topicName}
            onChange={(event) => onChange(event.target.value, "topicName")}
            type="text"
          />
          <WebhookTooltip tooltipType={"pub_sub"} property={"topicName"} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label className="font-bold mb-2">Credentials JSON</label>
        <div className="flex items-center">
          <textarea
            className="p-3 w-full mr-2"
            defaultValue={value.json}
            rows={5}
            onChange={(event) => onChange(event.target.value, "json")}
          ></textarea>
          <WebhookTooltip tooltipType={"pub_sub"} property={"json"} />
        </div>
      </div>
    </>
  );
};

export default PubSubWebhookCredentialsComponent;

import TemplateModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Template/TemplateModel";

export interface ITopTemplates {
  id: string;
  model: TemplateModel;
  position: number;
  count: number;
}

export default class PrivateLeaguesTopTemplatesModel {
  private: ITopTemplates[] = [];
  oneVsOne: ITopTemplates[] = [];
}

import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../providers/ApiProvider";
import SelectOption from "../models/SelectOption/SelectOption";
import { formatOptions } from "../helpers/helpers";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";

export const useFootballEntityOptions = (ids: any) => {
  const [competitions, setCompetitions] = useState<SelectOption[]>([]);
  const [teams, setTeams] = useState<SelectOption[]>([]);
  const [players, setPlayers] = useState<SelectOption[]>([]);

  const { sdk } = useContext(ApiContext);

  const getEntityOptions = async () => {
    if (ids.competitionIds.length > 0) {
      const competitions = await sdk.football.getCompetitions({
        competitionIds: ids.competitionIds,
      } as CompetitionFilters);
      setCompetitions(competitions.map((c) => formatOptions(c)));
    }

    if (ids.teamIds.length > 0) {
      const teams = await sdk.football.getTeams({
        teamIds: ids.teamIds,
        limit: ids.teamIds.length,
      });
      setTeams(teams.data.map((t) => formatOptions(t)));
    }

    if (ids.playerIds.length > 0) {
      const players = await sdk.football.getPlayers({
        playerIds: ids.playerIds,
        limit: ids.playerIds.length,
      });
      setPlayers(players.data.map((p) => formatOptions(p)));
    }
  };

  useEffect(() => {
    getEntityOptions();
  }, []);

  return { competitions, teams, players };
};

import GamePredictionsEngagement from "./GamePredictionsEngagement";
import SinglePredictionsEngagement from "./SinglePredictionsEngagement";

export default class EngagementThroughPredictionsModel {
  breakdown: {
    predictions: SinglePredictionsEngagement | GamePredictionsEngagement;
  } = { predictions: new SinglePredictionsEngagement() };
  total: { allUsers: number } = { allUsers: 0 };
  typePredictionsEngagement: "SINGLES" | "GAMES" = "SINGLES";
}

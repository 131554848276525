import React, { useState, useContext } from "react";
import HashService from "../../services/HashService/HashService";
import { ApiContext } from "../../providers/ApiProvider";
import { toast } from "react-toastify";
import { placeholders } from "../../assets/placeholders/placeholders";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../enums/enums";

const hashService = new HashService();

type FileUploaderProps = {
  type:
    | "default_competition_logo"
    | "default_team_logo"
    | "default_player_headshot"
    | "custom_competition_logo"
    | "custom_team_logo"
    | "custom_player_headshot";
  id: string;
  onChangeImages: (value: string) => void;
};

const FileUploader: React.FC<FileUploaderProps> = ({
  type,
  id,
  onChangeImages,
}) => {
  const [loading, setLoading] = useState(false);

  const api = useContext(ApiContext);

  const uniqueInputId = `file-upload-${type}-${id}`;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);

      try {
        const imageType = selectedFile.type.split("/")[1];
        const base64 = await hashService.readFileAndHash(selectedFile);
        const fileName =
          (await hashService.generateHashFileName(selectedFile)) +
          "." +
          imageType;
        const requestBody = {
          image_type: imageType,
          image_file_name: fileName,
          image_size: selectedFile.size,
          base64_sum: base64,
        };
        const response = await api.clientHttps.generateSignedInUrl(requestBody);
        const googleHeaders = {
          "Content-Type": selectedFile.type,
          "Content-MD5": base64,
        };

        try {
          const file = new File([selectedFile], fileName, {
            type: selectedFile.type,
          });
          const url = await api.clientHttps.storeInBucket(
            response.data.data.signed_url,
            file,
            googleHeaders
          );
          onChangeImages(url);
          toast.success(SUCCESS_MESSAGES.UPLOAD_IMAGE);
        } catch (e) {
          console.error("There was a problem with uploading the file: ", e);
          toast.error(ERROR_MESSAGES.UPLOAD_IMAGE);
        }
      } catch (e) {
        console.error("There was a problem with generating signed in URL:", e);
        toast.error(ERROR_MESSAGES.SIGNED_IN_URL);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <button
        className={`rounded p-2 flex items-center mr-1 p-2 ${
          loading ? "bg-blueGray-200" : "bg-white"
        } border border-blueGray-200`}
        onClick={() => document.getElementById(uniqueInputId).click()}
        disabled={loading}
      >
        <img src={placeholders.upload} alt="Upload" className="w-6 h-6" />
      </button>
      <input
        id={uniqueInputId}
        type="file"
        className="hidden"
        accept="image/*"
        onChange={handleFileChange}
      />
    </>
  );
};

export default FileUploader;

import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../providers/ApiProvider";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../enums/enums";
import { GlobalStatesContext } from "../providers/GlobalStatesProvider";

export const useGames = () => {
  const [games, setGames] = useState<any[]>(null);

  const { sdk } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getGames = async () => {
    try {
      const topX = await sdk.topX.getGames({ limit: 50 });
      const matchQuiz = await sdk.matchQuiz.getGames({ limit: 50 });
      setGames([...topX.data, ...matchQuiz.data]);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.GAMES);
      setGames([]);
    }
  };

  useEffect(() => {
    getGames();

    return () => setGames(null);
  }, [selectedClient]);

  return games;
};

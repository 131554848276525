import React from "react";
import { IPrivateLeaguesBreakdownTotal } from "../../../../../models/Reports/PrivateLeagues/PrivateLeaguesBreakdownModel";
import FormattedNumber from "../../../../Common/FormattedNumber";

const title = (record: string) => {
  switch (record) {
    case "all":
      return "All leagues";
    case "private":
      return "Private Leagues";
    case "oneVsOne":
      return "One vs One leagues";
    default:
      break;
  }
};

const checkboxLabel = (record: string) => {
  switch (record) {
    case "all":
      return "Show all leagues";
    case "private":
      return "Show Private Leagues";
    case "oneVsOne":
      return "Show One vs One leagues";
    default:
      break;
  }
};

const recordToLabel = (record: string) => {
  switch (record) {
    case "private":
      return "Private";
    case "oneVsOne":
      return "One vs One";
    default:
      break;
  }
};

const percentage = (count: number, all: number) => {
  let percentage = (count / all) * 100;
  percentage = percentage || 0;

  return percentage.toFixed(2);
};

type TotalPrivateLeaguesProps = {
  total: IPrivateLeaguesBreakdownTotal;
  privateLeaguesBreakdown: string[];
  onChangeCheckbox: (breakdown: string) => void;
};

const TotalPrivateLeagues: React.FC<TotalPrivateLeaguesProps> = ({
  total,
  privateLeaguesBreakdown,
  onChangeCheckbox,
}) => {
  const isChecked = (record: string) =>
    privateLeaguesBreakdown.includes(record);

  return (
    <div className="xl:grid xl:grid-cols-3 xl:gap-2 grid-cols-1 mb-6">
      {Object.keys(total).map((record: string) => (
        <div key={record} className="w-full">
          <div className="md:text-center cursor-default">
            <h2>{title(record)}</h2>
            <p className="font-bold mb-2">
              <FormattedNumber numberValue={total[record]} />
              {record !== "all"
                ? ` ${recordToLabel(record)} leagues (${percentage(
                    total[record],
                    total.all
                  )}%)`
                : ` leagues`}
            </p>
          </div>
          <div
            className="flex flex-row justify-center items-center cursor-pointer mb-2"
            onClick={() => onChangeCheckbox(record)}
          >
            <input
              className="mr-1"
              type="checkbox"
              checked={isChecked(record)}
              readOnly
            />
            <span className="text-xs">{checkboxLabel(record)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalPrivateLeagues;

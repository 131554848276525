import React, { useState } from "react";
import { pageIcons, pageTitles } from "../../../constants/constants";
import SubNavigation from "./SubNavigation";

export type NavigationButtonProps = {
  navigation: string;
  onClickNavigationUrlHideMobileMenu: () => void;
};

const NavigationButton: React.FC<NavigationButtonProps> = ({
  navigation,
  onClickNavigationUrlHideMobileMenu,
}) => {
  const [showSubLinks, setShowSubLinks] = useState<boolean>(false);

  return (
    <>
      <button
        className="flex items-center text-xs focus:outline-none py-2 font-bold blocktext-blueGray-700"
        onClick={() => setShowSubLinks(!showSubLinks)}
      >
        <i
          className={`${pageIcons[navigation]} mr-2 w-5 text-sm text-blueGray-300 flex-none`}
        ></i>{" "}
        <span className="ml-1 mr-2 uppercase">
          {pageTitles[navigation.toLocaleUpperCase()]}
        </span>
        <i
          className={`fas ${
            !showSubLinks ? "fa-arrow-down" : "fa-arrow-up"
          } text-blueGray-300 text-base text-sm flex-1 text-right`}
        />
      </button>
      {showSubLinks ? (
        <SubNavigation
          navigation={navigation}
          onClickNavigationUrlHideMobileMenu={
            onClickNavigationUrlHideMobileMenu
          }
        />
      ) : null}
    </>
  );
};

export default NavigationButton;

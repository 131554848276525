import React from "react";
import LoyaltyInput from "../../../../Common/LoyaltyInput";
import { IVotingBadgeRequirements } from "../../../../../../../models/features/loyalty/Badges/Voting/VotingBadgesExtended";
import Entity from "../Entity";

type VotingBadgeRequirementsProps = {
  uid: string;
  requirements: IVotingBadgeRequirements;
  onChangeBadges: (field: string, requirement: string, value: any) => void;
};

const VotingBadgeRequirements: React.FC<VotingBadgeRequirementsProps> = ({
  uid,
  requirements,
  onChangeBadges,
}) => {
  const onChangeEntityIdRequirement = (entityId: string) => {
    onChangeBadges("requirements", "entityId", entityId);
  };

  const onChangeEntityTypeRequirement = (entityType: string) => {
    onChangeBadges("requirements", "entityType", entityType);
  };

  return (
    <div className="mb-2">
      <label className="font-bold mb-2 text-center">Requirements:</label>
      <LoyaltyInput
        uid={uid}
        label="Points"
        value={requirements.points}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "points", value)
        }
        scenario="Badges"
      />
      <LoyaltyInput
        uid={uid}
        label="Vote Count"
        value={requirements.voteCount}
        onChangeBadges={(value) =>
          onChangeBadges("requirements", "voteCount", value)
        }
        scenario="Badges"
      />
      <Entity
        entityId={requirements.entityId}
        entityType={requirements.entityType}
        entityModel={requirements.entityModel}
        uid={uid}
        onChangeEntityIdRequirement={onChangeEntityIdRequirement}
        onChangeEntityTypeRequirement={onChangeEntityTypeRequirement}
      />
    </div>
  );
};

export default VotingBadgeRequirements;

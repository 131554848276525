import { lowerCase } from "lodash";
import React, { useContext } from "react";
import {
  formatDateLabelChart,
  upperCase,
} from "../../../../../helpers/helpers";
import TotalPlatformConsumption from "../../../../../models/Reports/TotalPlatformConsumption";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import ReportInfo from "../../Common/ReportInfo";
import MetricField from "./MetricField";
import BreakdownConsumption from "../../../../../models/Reports/Consumption/BreakdownConsumption";

type BreakdownsProps = {
  breakdown: BreakdownConsumption[];
};

const Breakdowns: React.FC<BreakdownsProps> = ({ breakdown }) => {
  const { groupBy } = useContext(ReportParamsContext);

  if (!breakdown.length) return null;

  const copyBreakdown = JSON.parse(JSON.stringify(breakdown));

  return (
    <>
      <ReportInfo
        title={`Breakdown by ${groupBy}`}
        description={`${upperCase(
          groupBy
        )} by ${groupBy} examination of your usage.`}
      />
      <hr className="mb-4"></hr>
      {copyBreakdown.map((value: any, idx: number) => (
        <div
          key={`breakdown-${value.date}-${idx}`}
          className="p-2 border border-blueGray-200 rounded mb-6"
        >
          <h2 className="font-bold mb-2">{formatDateLabelChart(value.date)}</h2>
          <hr className="mb-2"></hr>
          {value.metrics.map(
            (metric: TotalPlatformConsumption, index: number) => (
              <div key={`metric-${metric.service}-${index}`}>
                {Object.keys(metric).map((key: string) => (
                  <MetricField
                    key={`metric-field-${value.date}-${metric.service}-${key}`}
                    paragraph={upperCase(lowerCase(key))}
                    value={metric[key]}
                  />
                ))}
                <hr className="mb-2"></hr>
              </div>
            )
          )}
        </div>
      ))}
    </>
  );
};

export default Breakdowns;

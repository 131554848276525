import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";

export interface IFootballFantasyCoefficients {
  assists: number;
  shots: number;
  offsides: number;
  tackles: number;
  saves: number;
  minutesEqualOrOver60: number;
  minutesUnder60: number;
  yellowCards: number;
  redCards: number;
  goalsGoalkeeper: number;
  goalsDefender: number;
  goalsMidfielder: number;
  goalsForwards: number;
  penaltyGoals: number;
  ownGoals: number;
  cleanSheetsGoalkeeper: number;
  cleanSheetsDefender: number;
  cleanSheetsMidfielder: number;
  cleanSheetsForwards: number;
  shotsOn: number;
  foulsCommitted: number;
  penaltyCommitted: number;
  penaltyWon: number;
  penaltyMissed: number;
  concededGoals: number;
  caughtBall: number;
}

export interface IFootballFantasyPlayers {
  total: number;
  minGoalkeepers: number;
  maxGoalkeepers: number;
  minDefenders: number;
  maxDefenders: number;
  minMidfielders: number;
  maxMidfielders: number;
  minStrikers: number;
  maxStrikers: number;
}

export default class FootballFantasyFeatureModel {
  enabled: boolean = false;
  addPointsToProfileTotal: boolean = false;
  competitionsWhitelist: CompetitionBasicModel[] = [];
  coefficients: IFootballFantasyCoefficients = null;
  players: IFootballFantasyPlayers = null;
}

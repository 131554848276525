import dayjs from "dayjs";
import { formatDateLabelChart } from "../../helpers/helpers";
import Breakdown from "../../models/Reports/Breakdown";
import UsersAquired from "../../models/Reports/UsersAquired";
import ReportingHttps from "../../services/https/ReportingHttps";
import { PeriodType } from "../../types/types";
import ReportingBuilder from "../reports/ReportingBuilder";

export default class UsersAquiredBuilder extends ReportingBuilder {
  private usersAquiredMap: Map<string, UsersAquired> = new Map<
    string,
    UsersAquired
  >();

  constructor(https: ReportingHttps) {
    super(https);
  }

  public build = () => {
    return this.getAquiredUsersPast28Days()
      .then((response: UsersAquired) => {
        this.usersAquiredMap.set("USERS_PAST_28_DAYS", response);

        return this.getAquiredUsers28DaysPast28Days()
          .then((response: UsersAquired) => {
            this.usersAquiredMap.set("USERS_28_DAYS_PAST_28_DAYS", response);

            return this.usersAquiredMap;
          })
          .catch((error: any) => {
            console.error(error);
            return this.usersAquiredMap.set(
              "USERS_28_DAYS_PAST_28_DAYS",
              new UsersAquired()
            );
          });
      })
      .catch((error: any) => {
        console.error(error);
        return this.usersAquiredMap.set(
          "USERS_PAST_28_DAYS",
          new UsersAquired()
        );
      });
  };

  private getAquiredUsersPast28Days = async () => {
    const period = this.constructDefaultPeriod();
    const { breakdown } = await this.https.getAcquiredUsers(period);

    return this.constructUsersAquiredModel(breakdown as Breakdown[]);
  };

  private getAquiredUsers28DaysPast28Days = async (): Promise<UsersAquired> => {
    const period = this.constructPeriod();
    const { breakdown } = await this.https.getAcquiredUsers(period);

    return this.constructUsersAquiredModel(breakdown as Breakdown[]);
  };

  private constructDefaultPeriod = (): PeriodType => {
    const fromDate = dayjs().subtract(28, "day").format("YYYY-MM-DD");
    const toDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");

    return { fromDate, toDate };
  };

  private constructPeriod = (): PeriodType => {
    const fromDate = dayjs().subtract(56, "day").format("YYYY-MM-DD");
    const toDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");

    return { fromDate, toDate };
  };

  private constructUsersAquiredModel = (
    breakdown: Breakdown[]
  ): UsersAquired => {
    const model = new UsersAquired();
    model.period = breakdown.map((breakdown: Breakdown) =>
      formatDateLabelChart(breakdown.date)
    );
    model.allUsers = breakdown.map(
      (breakdown: Breakdown) => breakdown.users.all
    );

    return model;
  };
}

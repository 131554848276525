import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import ProfileCountryModel from "fansunited-sdk-esm/Core/Namespaces/Profile/Models/ProfileCountryModel";
import UsersAcquired from "../UsersAcquired/UsersAcquired";

export default class FootballEntityFollows {
  successRate: string = null;
  profileCountries: Map<string, ProfileCountryModel> = new Map();
  reportInformation: UsersAcquired = new UsersAcquired();
  model: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel = null;
}

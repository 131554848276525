import React, { useContext } from "react";
import {
  constructLabelsChart,
  formatProvidedPeriodReport,
} from "../../../../../helpers/helpers";
import ConsumptionDataset from "../../../../../models/Reports/ConsumptionDataset";
import ReportInfo from "../../Common/ReportInfo";
import LineChart from "../../Common/LineChart";
import ReportPeriods from "../../../../../models/Reports/ReportPeriods";
import ConsumptionModel from "../../../../../models/Reports/Consumption/ConsumptionModel";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";

type TrendsProps = {
  platformConsumption: ReportPeriods<ConsumptionModel>;
};

const Trends: React.FC<TrendsProps> = ({ platformConsumption }) => {
  const { datePeriod, comparedToPeriod, comparedTo } =
    useContext(ReportParamsContext);

  const [selectedTrendsFromDate, selectedTrendsToDate] =
    formatProvidedPeriodReport(
      datePeriod?.fromDate,
      datePeriod?.toDate,
      "selected"
    );
  const [comparedToTrendsFromDate, comparedToTrendsToDate] =
    formatProvidedPeriodReport(
      comparedToPeriod?.fromDate,
      comparedToPeriod?.toDate,
      "compared_to"
    );

  const datasetsChart = new ConsumptionDataset(
    platformConsumption.current.breakdown,
    platformConsumption.comparedTo?.breakdown
  ).datasetCharts;

  return (
    <div className="mb-6">
      <ReportInfo
        title={
          comparedTo
            ? `Selected trends from ${selectedTrendsFromDate} to ${selectedTrendsToDate} compared from ${comparedToTrendsFromDate} to ${comparedToTrendsToDate}`
            : `Selected trends from ${selectedTrendsFromDate} to ${selectedTrendsToDate}`
        }
        description={
          "A visual representation of your usage over the specified period."
        }
      />
      <hr className="mb-4"></hr>
      <LineChart
        labels={constructLabelsChart(
          platformConsumption.current.breakdown,
          platformConsumption.comparedTo?.breakdown
        )}
        datasets={datasetsChart}
      />
    </div>
  );
};

export default Trends;

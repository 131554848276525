import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import { placeholders } from "../../assets/placeholders/placeholders";
import { ApiContextValueType } from "../../providers/ApiProvider";
import FollowedEntitiesModel, {
  TopFollowedEntitiy,
  TopFollowedSports,
} from "../../models/Reports/FollowedEntitiesModel";
import ReportingHttps from "../../services/https/ReportingHttps";
import { Entity } from "../../types/types";
import ReportingBuilder from "../reports/ReportingBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class FollowedEntitiesBuilder extends ReportingBuilder {
  private followedEntities: FollowedEntitiesModel = new FollowedEntitiesModel();
  private sdk: FansUnitedSDKModel;
  constructor(api: ApiContextValueType) {
    super(api.reportingHttps as ReportingHttps);
    this.sdk = api.sdk as FansUnitedSDKModel;
  }

  public build = () => {
    return Promise.all([
      this.getTopFollowedCompetitions(),
      this.getTopFollowedTeams(),
      this.getTopFollowedPlayers(),
      this.getTopFollowedSports(),
    ]).then(() => {
      return this.followedEntities;
    });
  };

  private getTopFollowedCompetitions = async () => {
    let { breakdown } = await this.https.getTopFollowsEntity("competitions");
    const { ids, followers, topFollowedBreakdown } =
      this.filterTopFiveBreakdown(breakdown);
    const competitions = await this.fetchEntityModels(ids, "competitions");

    topFollowedBreakdown.forEach((value: any, index: number) => {
      const competition = competitions.filter(
        (competition: CompetitionBasicModel) => value.id === competition.id
      )[0] as CompetitionBasicModel;
      const topFollowedEntity: TopFollowedEntitiy = {
        countryName: competition.country.name,
        //@ts-ignore
        countryLogo: competition.country.assets?.flag
          ? //@ts-ignore
            competition.country.assets?.flag
          : placeholders.country,
        name: competition.name,
        //@ts-ignore
        logo: competition.assets?.logo
          ? //@ts-ignore
            competition.assets?.logo
          : placeholders.competition,
        followers: followers[index],
      };

      this.followedEntities.topFollowedCompetitions.push(topFollowedEntity);
    });
  };

  private getTopFollowedTeams = async () => {
    let { breakdown } = await this.https.getTopFollowsEntity("teams");
    const { ids, followers, topFollowedBreakdown } =
      this.filterTopFiveBreakdown(breakdown);
    const teams = await this.fetchEntityModels(ids, "teams");

    topFollowedBreakdown.forEach((value: any, index: number) => {
      const team = teams.filter(
        (team: TeamBasicModel) => value.id === team.id
      )[0] as TeamBasicModel;
      const topFollowedEntity: TopFollowedEntitiy = {
        countryName: team.country.name,
        //@ts-ignore
        countryLogo: team.country.assets?.flag
          ? //@ts-ignore
            team.country.assets?.flag
          : placeholders.country,
        name: team.name,
        //@ts-ignore
        logo: team.assets?.logo
          ? //@ts-ignore
            team.assets?.logo
          : placeholders.team,
        followers: followers[index],
      };

      this.followedEntities.topFollowedTeams.push(topFollowedEntity);
    });
  };

  private getTopFollowedPlayers = async () => {
    let { breakdown } = await this.https.getTopFollowsEntity("players");
    const { ids, followers, topFollowedBreakdown } =
      this.filterTopFiveBreakdown(breakdown);
    const players = await this.fetchEntityModels(ids, "players");

    topFollowedBreakdown.forEach((value: any, index: number) => {
      const player = players.filter(
        (player: PlayerBasicModel) => value.id === player.id
      )[0] as TeamBasicModel;
      const topFollowedEntity: TopFollowedEntitiy = {
        countryName: player.country.name,
        //@ts-ignore
        countryLogo: player.country.assets?.flag
          ? //@ts-ignore
            player.country.assets?.flag
          : placeholders.country,
        name: player.name,
        //@ts-ignore
        logo: player.assets?.headshot
          ? //@ts-ignore
            player.assets?.headshot
          : placeholders.avatar,
        followers: followers[index],
      };

      this.followedEntities.topFollowedPlayers.push(topFollowedEntity);
    });
  };

  private getTopFollowedSports = async () => {
    const top5 = 5;
    let { breakdown } = await this.https.getTopFollowsEntity("sports");
    let topFollowedBreakdown = JSON.parse(JSON.stringify(breakdown));

    if (breakdown.length > top5) {
      topFollowedBreakdown = breakdown.filter(
        (breakdown: any, index: number) => {
          if (index <= top5 - 1) {
            return breakdown;
          }
        }
      );
    }

    topFollowedBreakdown.forEach((breakdown: any) => {
      const topFollowedEntity: TopFollowedSports = {
        name: breakdown.id,
        logo: placeholders[breakdown.id]
          ? placeholders[breakdown.id]
          : placeholders.sport,
        followers: breakdown.follows.all,
      };

      this.followedEntities.topFollowedSports.push(topFollowedEntity);
    });
  };

  private fetchEntityModels = async (ids: string[], entity: Entity) => {
    let models: any[] = [];

    switch (entity) {
      case "competitions":
        models = await this.sdk.football.getCompetitions({
          competitionIds: ids,
        } as CompetitionFilters);
        break;
      case "teams":
        const teamsPaginatedResponse = await this.sdk.football.getTeams({
          teamIds: ids,
        } as TeamFilters);
        models = teamsPaginatedResponse.data;
        break;
      case "players":
        const playersPaginatedResponse = await this.sdk.football.getPlayers({
          playerIds: ids,
        } as PlayerFilters);
        models = playersPaginatedResponse.data;
        break;
      default:
        break;
    }

    return models;
  };

  private filterTopFiveBreakdown = (breakdown: any[]) => {
    const top5 = 5;
    let followers: number[] = [];
    let ids: string[] = [];
    let topFollowedBreakdown = JSON.parse(JSON.stringify(breakdown));

    if (breakdown.length > top5) {
      topFollowedBreakdown = breakdown.filter(
        (breakdown: any, index: number) => {
          if (index <= top5 - 1) {
            return breakdown;
          }
        }
      );

      ids = topFollowedBreakdown.map((value: any) => value.id);
      followers = topFollowedBreakdown.map((value: any) => value.follows.all);
    }

    if (!ids.length && !followers.length) {
      ids = topFollowedBreakdown.map((value: any) => value.id);
      followers = topFollowedBreakdown.map((value: any) => value.follows.all);
    }

    return { followers, ids, topFollowedBreakdown };
  };
}

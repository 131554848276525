import PredictionsPerMatchBreakdown from "../../models/Reports/Predictions/PredictionsPerMatchBreakdown";
import { ApiContextValueType } from "../../providers/ApiProvider";
import EntityFollowsBuilder from "./EntityFollowsBuilder";

export default class PredictionsPerMatchBuilder extends EntityFollowsBuilder {
  private predictionsPerMatchBreakdown: any[] = null;

  constructor(api: ApiContextValueType, predictionsPerMatchBreakdown: any) {
    super(api);
    this.predictionsPerMatchBreakdown = predictionsPerMatchBreakdown;
  }

  public build = async () => {
    await this.setModel();

    return this.predictionsPerMatchBreakdown;
  };

  private setModel = async () => {
    const matchIds: string[] = [];

    if (
      !this.predictionsPerMatchBreakdown ||
      !this.predictionsPerMatchBreakdown.length
    )
      return;

    this.predictionsPerMatchBreakdown.forEach(
      (breakdown: PredictionsPerMatchBreakdown) => {
        matchIds.push(breakdown.match);
      }
    );

    const matchesMap = await this.getMatchesMap(matchIds);

    this.predictionsPerMatchBreakdown.forEach(
      (breakdown: PredictionsPerMatchBreakdown) => {
        breakdown.matchModel = matchesMap.get(breakdown.match);
      }
    );
  };
}

import React from "react";
import { RecencyGroupInterface } from "../../../../../models/Clients/EngagementCoefficientsConfiguration/EngagementCoefficientsConfiguration";
import { inputNumber } from "../../../../../helpers/helpers";

type ECRecencyConfigProps = {
  recencyGroups: RecencyGroupInterface[];
  onChange: (id: number, value: number) => void;
};

const ECRecencyConfig: React.FC<ECRecencyConfigProps> = ({
  recencyGroups,
  onChange,
}) => {
  return (
    <div className="mb-2 border border-blueGray-300 rounded-lg p-2 bg-blueGray-100">
      <h3 className="font-bold text-xl mb-2">Recency configuration</h3>
      {recencyGroups.map((recency) => (
        <div
          key={`recency-config-${recency.id}`}
          className="flex items-center mb-2"
        >
          <span className="mr-2 ">R{recency.id}</span>
          <input
            className="w-20 mr-2"
            type="number"
            defaultValue={recency.period}
            onKeyDown={(event) => inputNumber(event, false)}
            onChange={(event) =>
              onChange(recency.id, Number(event.target.value))
            }
          />
          <span>hours</span>
        </div>
      ))}
    </div>
  );
};

export default ECRecencyConfig;

import DiscussionsBadgesExtended from "../../../models/features/loyalty/Badges/Discussions/DiscussionsBadgesExtended";
import FootballBadgesExtended from "../../../models/features/loyalty/Badges/Football/FootballBadgesExtended";
import GameBadgesExtended from "../../../models/features/loyalty/Badges/Game/GameBadgesExtended";
import GamesBadgesExtended from "../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";
import MiniGamesBadgesExtended from "../../../models/features/loyalty/Badges/MiniGames/MiniGamesBadgesExtended";
import PredictorBadgesExtended from "../../../models/features/loyalty/Badges/Predictor/PredictorBadgesExtended";
import VotingBadgesExtended from "../../../models/features/loyalty/Badges/Voting/VotingBadgesExtended";
import BadgesExtendedModel from "../../../models/features/loyalty/BadgesExtendedModel/BadgesExtendedModel";
import BadgesModelV2 from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/BadgesModelV2";
import PredictorBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/Predictor/PredictorBadgesValue";
import GameBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/Game/GameBadgesValue";
import GamesBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/Games/GamesBadgesValue";
import FootballBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/Football/FootballBadgesValue";
import MiniGamesBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/MiniGames/MiniGamesBadgesValue";
import DiscussionsBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/Discussions/DiscussionsBadgesValue";
import PotmBadgesValue from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Badges/v2/Voting/PotmBadgesValue";

export default class BadgesBuilder {
  private badgesFromApi: BadgesModelV2;
  private newBadge: BadgesExtendedModel = new BadgesExtendedModel();

  constructor(badgesFromApi: BadgesModelV2) {
    this.badgesFromApi = badgesFromApi;
  }

  /**
   * Adding additional field uid in every badge.
   * @returns Our custom BadgesModel.
   */
  public build = () => {
    this.buildRewardsBadgesLoyaltyFeatures();

    return this.newBadge;
  };

  private buildRewardsBadgesLoyaltyFeatures = () => {
    this.newBadge.predictor = this.addUidFieldInPredictorBadgesLoyaltyRewards(
      this.badgesFromApi.predictor
    );
    this.newBadge.topX = this.addUidFieldInGameBadgesLoyaltyRewards(
      this.badgesFromApi.topX
    );
    this.newBadge.matchQuiz = this.addUidFieldInGameBadgesLoyaltyRewards(
      this.badgesFromApi.matchQuiz
    );
    this.newBadge.games = this.addUidFieldInGamesBadgesLoyaltyRewards(
      this.badgesFromApi.games
    );
    this.newBadge.football = this.addUidFieldInFootballBadgesLoyaltyRewards(
      this.badgesFromApi.football
    );
    this.newBadge.classicQuizzes =
      this.addUidFieldInMiniGamesBadgesLoyaltyRewards(
        this.badgesFromApi.classicQuizzes
      );
    this.newBadge.eitherOr = this.addUidFieldInMiniGamesBadgesLoyaltyRewards(
      this.badgesFromApi.eitherOr
    );
    this.newBadge.discussions =
      this.addUidFieldInDiscussionsBadgesLoyaltyRewards(
        this.badgesFromApi.discussions
      );

    if (this.newBadge.voting) {
      this.newBadge.voting.poll = this.addUidFieldInVotingBadgesLoyaltyRewards(
        this.badgesFromApi.voting.poll,
        "poll"
      );
      this.newBadge.voting.potm = this.addUidFieldInVotingBadgesLoyaltyRewards(
        this.badgesFromApi.voting.potm,
        "potm"
      );
    }
  };

  private addUidFieldInPredictorBadgesLoyaltyRewards = (
    badgesApi: PredictorBadgesValue[]
  ) => {
    const badges: PredictorBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new PredictorBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-predictor-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.correctPredictions =
          badgeValue.requirements.correctPredictions;
        newBadge.requirements.predictionsNumber =
          badgeValue.requirements.predictionsNumber;
        newBadge.requirements.points = badgeValue.requirements.points;
        newBadge.requirements.fromDate = badgeValue.requirements.fromDate;
        newBadge.requirements.toDate = badgeValue.requirements.toDate;

        badges.push(newBadge);
      });
    }

    return badges;
  };

  private addUidFieldInGameBadgesLoyaltyRewards = (
    badgesApi: GameBadgesValue[]
  ) => {
    const badges: GameBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new GameBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-game-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.participationCount =
          badgeValue.requirements.participationCount;
        newBadge.requirements.points = badgeValue.requirements.points;
        newBadge.requirements.fromDate = badgeValue.requirements.fromDate;
        newBadge.requirements.toDate = badgeValue.requirements.toDate;

        badges.push(newBadge);
      });
    }

    return badges;
  };

  private addUidFieldInGamesBadgesLoyaltyRewards = (
    badgesApi: GamesBadgesValue[]
  ) => {
    const badges: GamesBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new GamesBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-games-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.participationCount =
          badgeValue.requirements.participationCount;
        newBadge.requirements.points = badgeValue.requirements.points;
        newBadge.requirements.fromDate = badgeValue.requirements.fromDate;
        newBadge.requirements.toDate = badgeValue.requirements.toDate;

        if (
          badgeValue.requirements.specificGames &&
          badgeValue.requirements.specificGames.length > 0
        ) {
          newBadge.requirements.specificGames =
            badgeValue.requirements.specificGames;
        }

        badges.push(newBadge);
      });
    }

    return badges;
  };

  private addUidFieldInFootballBadgesLoyaltyRewards = (
    badgesApi: FootballBadgesValue[]
  ) => {
    const badges: FootballBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new FootballBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-football-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.predictionsNumber =
          badgeValue.requirements.predictionsNumber;
        newBadge.requirements.correctPredictions =
          badgeValue.requirements.correctPredictions;
        newBadge.requirements.points = badgeValue.requirements.points;
        newBadge.requirements.entityId = badgeValue.requirements.entityId;
        newBadge.requirements.entityType = badgeValue.requirements.entityType;
        newBadge.requirements.entityModel = badgeValue.requirements.entityModel;

        badges.push(newBadge);
      });
    }

    return badges;
  };

  private addUidFieldInMiniGamesBadgesLoyaltyRewards = (
    badgesApi: MiniGamesBadgesValue[]
  ) => {
    const badges: MiniGamesBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new MiniGamesBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-mini-games-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.participationCount =
          badgeValue.requirements.participationCount;
        newBadge.requirements.fromDate = badgeValue.requirements.fromDate;
        newBadge.requirements.toDate = badgeValue.requirements.toDate;

        badges.push(newBadge);
      });
    }

    return badges;
  };

  private addUidFieldInDiscussionsBadgesLoyaltyRewards = (
    badgesApi: DiscussionsBadgesValue[]
  ) => {
    const badges: DiscussionsBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new DiscussionsBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-discussions-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.totalDiscussionPoints =
          badgeValue.requirements.totalDiscussionPoints;
        newBadge.requirements.postsCount = badgeValue.requirements.postsCount;
        newBadge.requirements.postPoints = badgeValue.requirements.postPoints;
        newBadge.requirements.reactionsCount =
          badgeValue.requirements.reactionsCount;

        badges.push(newBadge);
      });
    }

    return badges;
  };

  private addUidFieldInVotingBadgesLoyaltyRewards = (
    badgesApi: PotmBadgesValue[],
    type: string
  ) => {
    const badges: VotingBadgesExtended[] = [];

    if (badgesApi && badgesApi.length > 0) {
      badgesApi.forEach((badgeValue: any, idx: number) => {
        const newBadge = new VotingBadgesExtended();

        newBadge.id = badgeValue.id;
        newBadge.uid = `reward-badges-voting-${type}-${badgeValue.id}-${idx}`;
        newBadge.enabled = badgeValue.enabled;
        newBadge.label = badgeValue.label;
        newBadge.description = badgeValue.description;
        newBadge.flags = badgeValue.flags ? badgeValue.flags : [];
        newBadge.assets = badgeValue.assets;
        newBadge.requirements.points = badgeValue.requirements.points;
        newBadge.requirements.voteCount = badgeValue.requirements.voteCount;
        newBadge.requirements.entityId = badgeValue.requirements.entityId;
        newBadge.requirements.entityType = badgeValue.requirements.entityType;
        newBadge.requirements.entityModel = badgeValue.requirements.entityModel;

        badges.push(newBadge);
      });
    }

    return badges;
  };
}

import BadgesExtended from "../BadgesExtended";

export interface ISpecificGamesBadgeRequirement {
  gameId: string;
  gameType: string;
}
export interface IGamesBadgeRequirements {
  participationCount: number;
  points: number;
  specificGames: ISpecificGamesBadgeRequirement[];
  fromDate: string;
  toDate: string;
}

export default class GamesBadgesExtended extends BadgesExtended {
  public requirements: IGamesBadgeRequirements = {
    participationCount: 0,
    points: 0,
    specificGames: [],
    fromDate: null,
    toDate: null,
  };
}

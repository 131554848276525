import { camelCase } from "lodash";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import {
  remapKeysToSpecificCase,
  removePropertyFromObject,
} from "../../../../helpers/helpers";
import LoyaltyFeaturesModel from "../../../../models/features/loyalty/LoyaltyFeaturesModel";
import PointsValue from "../../../../models/features/loyalty/PointsValue/PointsValue";
import TiersModelExtended from "../../../../models/features/loyalty/TiersModel/TiersModel";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import FeaturesLoyaltyService from "../../../../services/FeaturesLoyalty/FeaturesLoyaltyService";
import GlobalValidator from "../../../../validators/GlobalValidator";
import UpdateButton from "../../../Buttons/UpdateButton";
import Enabled from "../Common/Enabled";
import LoyaltyActions from "./LoyaltyActions/LoyaltyActions";
import LoyaltyRewards, {
  LoyaltyRewardsEntities,
} from "./LoyaltyRewards/LoyaltyRewards";
import { ApiContext } from "../../../../providers/ApiProvider";
import Points from "../../../../models/features/loyalty/Points/Points";

const validator = new GlobalValidator();

/**
  Update tiers and points.
 */
const updateCurrentRewardsEntityNode = (
  currentLoyaltyFeatures: LoyaltyFeaturesModel,
  scenario: string,
  uid: string,
  value: string | boolean,
  scenarioNode: string,
  alternativeKey?: string
): void => {
  const currentScenarioRewardsFeatures =
    currentLoyaltyFeatures.rewards[scenario];

  // For Tiers we don't have any nodes, so scenarioNode variable is the name of the field to be updated.
  if (Array.isArray(currentScenarioRewardsFeatures)) {
    currentScenarioRewardsFeatures.forEach((node: TiersModelExtended) => {
      if (node.uid === uid) {
        if (typeof value === "boolean" && scenarioNode === "enabled")
          node.enabled = value;
        else if (typeof value === "string" && scenarioNode === "id") {
          node.id = value;
        } else if (typeof value === "string" && scenarioNode === "label") {
          node.label = value;
        } else if (typeof value === "string" && scenarioNode === "points")
          node.points = Number(value);
      }
    });
  } else {
    const transformKeyToCamelCase = camelCase(scenarioNode);
    const rewardScenarioNodeToUpdate =
      currentScenarioRewardsFeatures[transformKeyToCamelCase];

    if (Array.isArray(rewardScenarioNodeToUpdate)) {
      rewardScenarioNodeToUpdate.forEach((node: PointsValue) => {
        if (node.uid === uid && !uid.includes("CORRECT_SCORE_ADVANCED")) {
          node.points = Number(value);
        } else if (node.uid === uid && uid.includes("CORRECT_SCORE_ADVANCED")) {
          node.alternative[alternativeKey] = Number(value);
        }
      });
    } else {
      // For Voting Points we have a object with poll and potm.
      rewardScenarioNodeToUpdate[alternativeKey].forEach((node: Points) => {
        if (node.uid === uid) {
          node.points = Number(value);
        }
      });
    }
  }
};

type FeaturesLoyaltyProps = {
  loyaltyFeatures: LoyaltyFeaturesModel;
  isLoyaltyFeaturesEdited: any;
};

const FeaturesLoyalty: React.FC<FeaturesLoyaltyProps> = ({
  loyaltyFeatures,
  isLoyaltyFeaturesEdited,
}) => {
  // Make copy to default loyalty features. That way we will handle the UI update.
  const copyLoyaltyFeatures = JSON.parse(JSON.stringify(loyaltyFeatures));

  // The prop 'loyaltyFeatures' will be updated on each button click, so the UI input values will be saved.
  const [newLoyaltyFeatures, setNewLoyaltyFeatures] =
    useState<LoyaltyFeaturesModel>(loyaltyFeatures);
  const [updatedLoyaltyFeatures, setUpdatedLoyaltyFeatures] =
    useState<LoyaltyFeaturesModel>(copyLoyaltyFeatures);
  const [newTierIndex, setNewTierIndex] = useState<number>(0);

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const addNewReward = () => {
    const currentLoyaltyFeatures: LoyaltyFeaturesModel = {
      ...newLoyaltyFeatures,
    };
    setNewTierIndex((prevState) => prevState + 1);
    const newTier = new TiersModelExtended();
    newTier.uid = `new-tier-${newTierIndex}`;
    currentLoyaltyFeatures.rewards.tiers.push(newTier);

    /* Create a new references to the updated loyalty features. Used to build the final request body and prevent
    re-rendering the component tree. */

    const copyCurrentLoyaltyFeatures = JSON.parse(
      JSON.stringify(currentLoyaltyFeatures)
    );
    setUpdatedLoyaltyFeatures((prevState) => {
      prevState.rewards.tiers.push(newTier);
      return prevState;
    });
    setNewLoyaltyFeatures(copyCurrentLoyaltyFeatures);
  };

  const removeExistingReward = (rewardUid: string) => {
    // Make shallow copy to default loyalty features. That way we will handle the UI update.
    const currentLoyaltyFeatures: LoyaltyFeaturesModel = {
      ...newLoyaltyFeatures,
    };
    const newTiers = currentLoyaltyFeatures.rewards.tiers.filter(
      (tier: TiersModelExtended) => tier.uid !== rewardUid
    );
    currentLoyaltyFeatures.rewards.tiers = newTiers;

    /* Create a new references to the updated loyalty features. Used to build the final request body and prevent
    re-rendering the component tree. */

    const copyCurrentLoyaltyFeatures = JSON.parse(
      JSON.stringify(currentLoyaltyFeatures)
    );
    setUpdatedLoyaltyFeatures((prevState) => {
      prevState.rewards.tiers = newTiers;
      return prevState;
    });
    setNewLoyaltyFeatures(copyCurrentLoyaltyFeatures);
  };

  const handleLoyaltyFeatures = (
    scenario: LoyaltyRewardsEntities,
    uid: string,
    value: string | boolean,
    scenarioNode: string,
    correctScoreAdvancedAlternativeKey?: string
  ) => {
    const scenarioToLowerCase = scenario.toLocaleLowerCase();
    const currentLoyaltyFeatures: LoyaltyFeaturesModel = {
      ...updatedLoyaltyFeatures,
    };
    updateCurrentRewardsEntityNode(
      currentLoyaltyFeatures,
      scenarioToLowerCase,
      uid,
      value,
      scenarioNode,
      correctScoreAdvancedAlternativeKey
    );
    setUpdatedLoyaltyFeatures(currentLoyaltyFeatures);
  };

  const updateLoyaltyFeatures = async () => {
    const id = "id";
    if (validator.isPropertyWithInvalidValue(updatedLoyaltyFeatures, id)) {
      toast.error(ERROR_MESSAGES.FEATURES_ID_VALUES_EMPTY);
      return;
    }

    setIsLoading(true);
    const uid = "uid";
    const entityModel = "entityModel";
    const originalRequestBody = removePropertyFromObject(
      JSON.parse(JSON.stringify(updatedLoyaltyFeatures)),
      uid
    );
    removePropertyFromObject(originalRequestBody, entityModel);
    //@ts-ignore
    setNewLoyaltyFeatures(originalRequestBody);
    const reqBodySnakeCase = remapKeysToSpecificCase(
      originalRequestBody,
      "snake"
    );
    // Alternative keys for CORRECT_SCORE_ADVANCED are not remapped properly.
    new FeaturesLoyaltyService().remapAlternativeKeysToSnakeCase(
      reqBodySnakeCase.rewards.points
    );

    try {
      await clientHttps.updateFeaturesForClient(
        reqBodySnakeCase,
        FansUnitedFeatures.LOYALTY
      );
      isLoyaltyFeaturesEdited.current = !isLoyaltyFeaturesEdited.current;
      toast.success(SUCCESS_MESSAGES.FEATURES_LOYALTY_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FEATURES_LOYALTY_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Enabled value={newLoyaltyFeatures?.enabled} />
      <LoyaltyActions actions={newLoyaltyFeatures?.actions.value} />
      <LoyaltyRewards
        label={"Points"}
        loyaltyRewards={newLoyaltyFeatures.rewards}
        onChange={handleLoyaltyFeatures}
      />
      <LoyaltyRewards
        label={"Tiers"}
        loyaltyRewards={newLoyaltyFeatures.rewards}
        onChange={handleLoyaltyFeatures}
        addNewReward={addNewReward}
        removeExistingReward={removeExistingReward}
      />
      <UpdateButton
        label={"Update loyalty features"}
        onClick={updateLoyaltyFeatures}
        isLoading={isLoading}
      />
    </>
  );
};

export default FeaturesLoyalty;

import React from "react";
import FormattedNumber from "../../../../Common/FormattedNumber";

type RFMInfoProps = {
  keyGroup: string;
  value: number;
};

const RFMInfo: React.FC<RFMInfoProps> = ({ keyGroup, value }) => {
  const numberOfGroup = keyGroup ? keyGroup.slice(1) : "";

  return (
    <div className="flex items-center justify-between mb-2 border-b border-blueGray-200">
      <p className="mr-2">Group {numberOfGroup}:</p>
      <div className="font-bold">
        <FormattedNumber numberValue={value} />
      </div>
    </div>
  );
};

export default RFMInfo;

import React, { useRef } from "react";
import { useFeatures } from "../../../../hooks/features/useFeatures";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import ExternalPointsComponent from "./ExternalPointsComponent";

const FeaturesExternalPoints: React.FC = () => {
  const isExternalPointsFeaturesEdited = useRef<boolean>(false);

  const features = useFeatures(isExternalPointsFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for External points features"}
      description={"This is External points configuration for client: "}
    >
      {features ? (
        <ExternalPointsComponent
          externalPoints={features.externalPoints}
          isExternalPointsFeaturesEdited={isExternalPointsFeaturesEdited}
        ></ExternalPointsComponent>
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesExternalPoints;

import { ERROR_MESSAGES } from "../enums/enums";
import { ExternalPoints } from "../models/features/externalPoints/ExternalPointsModel";

export default class ExternalPointsConfigValidator {
  isValid: boolean = true;
  message: string = "";

  public validate = (externalPointsConfigurations: ExternalPoints[]) => {
    externalPointsConfigurations.forEach((configuration: ExternalPoints) => {
      this.validateIdField(configuration.id);
      this.validatePointsOrMaxCountField(configuration.points, false);
      this.validatePointsOrMaxCountField(configuration.maxCount, true);
    });
  };

  private validateIdField = (idValue: string) => {
    // Id's length has an API validation for characters between 1 and 100 (inclusive)
    if (idValue.length < 1 || idValue.length > 100) {
      this.isValid = false;
      this.message = ERROR_MESSAGES.EXTERNAL_POINTS_ID_VALUE_INVALID_LENGTH;
    }
    // Allow only digits, characters and underscores
    const regex = /^[a-zA-Z0-9_]*$/;

    if (!regex.test(idValue)) {
      this.isValid = false;
      this.message = ERROR_MESSAGES.EXTERNAL_POINTS_ID_VALUE_INVALID_VALUE;
    }
  };

  private validatePointsOrMaxCountField = (value: number, isMaxCount: boolean) => {
    // Only maximum count need to be positive integer
    if (value <= 0 && isMaxCount) {
      this.isValid = false;
      this.message = ERROR_MESSAGES.INVALID_POSITIVE_INTEGER_VALUE;
    }

    const maxIntValue = 2_147_483_647;
    if (value > maxIntValue) {
      this.isValid = false;
      this.message = ERROR_MESSAGES.INVALID_INTEGER_VALUE;
    }
  };
}

import React, { useContext } from "react";
import CardReport from "../../../../Cards/CardReport";
import "../../Reports.css";
import ReportInfo from "../../Common/ReportInfo";
import { ReportParamsContext } from "../../../../../providers/ReportParamsProvider";
import DatePeriod from "../../Common/DatePeriod";
import Spinner from "../../../../Spinner/Spinner";
import DiscussionTableView from "../DiscussionTableView";
import { useTop100Discussions } from "../../../../../hooks/reports/discussions/useTop100Discussions";

const Top100Discussions: React.FC = () => {
  const { datePeriod } = useContext(ReportParamsContext);

  const top100Discussions = useTop100Discussions(datePeriod);

  return (
    <div>
      <CardReport>
        <div className="headingContainer mb-6">
          <ReportInfo
            title={"Top 100 discussions"}
            description={
              "This report shows the top 100 discussions specified by period."
            }
          />
          <div className="xl:flex xl:flex-row xl:items-center xl:justify-end">
            <DatePeriod defaultDatePeriod={true} />
          </div>
        </div>
        {top100Discussions ? (
          <div className="mb-6 border border-blueGray-200 rounded p-2">
            <h2 className="text-center mb-4 font-bold text-xl">{`Top ${top100Discussions.length} discussions`}</h2>
            <div className="block w-full overflow-x-auto">
              <DiscussionTableView
                type={"top_discussions"}
                data={top100Discussions}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </CardReport>
    </div>
  );
};

export default Top100Discussions;

import FootballPaginationModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Pagination/FootballPaginationModel";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import { formatOptions } from "../../../../../helpers/helpers";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../providers/ApiProvider";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";
import { ERROR_MESSAGES } from "../../../../../enums/enums";
import { asyncSelectStyles } from "../../../../../constants/styles";
import { placeholders } from "../../../../../assets/placeholders/placeholders";

type PlayersSelectProps = {
  loadedPlayers: PlayerBasicModel[];
  setPlayerIds: Dispatch<SetStateAction<string[]>>;
};

const PlayersSelect: React.FC<PlayersSelectProps> = ({
  loadedPlayers,
  setPlayerIds,
}) => {
  const loadedPlayerOptions = loadedPlayers.map((player: PlayerBasicModel) =>
    formatOptions(player)
  );
  const [players, setPlayers] = useState<SelectOption[]>(loadedPlayerOptions);

  const api = useContext(ApiContext);

  const minInputLength = 3;

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (inputValue.length < minInputLength) return callback([]);

    let playerFilters: PlayerFilters = null;
    let options: SelectOption[] = null;

    if (inputValue) {
      playerFilters = {
        name: inputValue,
      } as PlayerFilters;
    }

    api?.sdk.football
      .getPlayers(playerFilters)
      .then((response: FootballPaginationModel) => {
        options = response.data.map((player: PlayerBasicModel) =>
          formatOptions(player)
        );
        callback(options);
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
        callback([]);
      });
  };

  const handleOnChange = (selectedOption: SelectOption[]) => {
    const teamIds = selectedOption.map((option: SelectOption) => option.id);
    setPlayerIds(teamIds);
    setPlayers(selectedOption);
  };

  const loadOptions = _.debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? "No player found"
      : "Type at least 3 characters";

  return (
    <AsyncSelect
      components={{ DropdownIndicator: null }}
      styles={asyncSelectStyles}
      isMulti
      onChange={handleOnChange}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Loading..."}
      defaultOptions
      value={players}
      loadOptions={loadOptions}
      placeholder={""}
      formatOptionLabel={(option) => (
        <div className="flex flex-justify-start">
          <img
            src={option.logo ? option.logo : placeholders.player}
            alt={""}
            className="h-6 w-6"
          />
          <span>{option.label}</span>
        </div>
      )}
      classNamePrefix="select2-selection"
    />
  );
};

export default PlayersSelect;

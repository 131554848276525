import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import MatchBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchBasicModel";
import MatchFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Match/MatchFilters";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import PlayerFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerFilters";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamFilters";
import { ApiContextValueType } from "../../providers/ApiProvider";
import { Entity } from "../../types/types";
import ReportingBuilder from "./ReportingBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class EntityFollowsBuilder extends ReportingBuilder {
  protected sdk: FansUnitedSDKModel;

  constructor(api: ApiContextValueType) {
    super(api.reportingHttps);
    this.sdk = api.sdk;
  }

  protected getProfileCountries = async () => {
    try {
      return await this.sdk.profile.getCountries();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  protected getMatchesMap = async (matchIds: string[]) => {
    const matchesMap = new Map<string, MatchBasicModel>();

    if (!matchIds.length) return matchesMap;

    const matches = await this.getMatches(matchIds, matchIds.length);

    matches.forEach((match: MatchBasicModel) => {
      matchesMap.set(match.id, match);
    });

    return matchesMap;
  };

  protected getEntitiesMap = async (entity: Entity, entityIds: string[]) => {
    const entitiesMap = new Map<
      string,
      CompetitionBasicModel | TeamBasicModel | PlayerBasicModel
    >();
    let entities:
      | CompetitionBasicModel[]
      | TeamBasicModel[]
      | PlayerBasicModel[] = [];

    if (!entityIds.length) return entitiesMap;

    if (entity === "competitions") {
      entities = await this.getCompetitions(entityIds);
    } else if (entity === "teams") {
      entities = await this.getTeams(entityIds, entityIds.length);
    } else if (entity === "players") {
      entities = await this.getPlayers(entityIds, entityIds.length);
    }

    entities.forEach(
      (entity: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel) =>
        entitiesMap.set(entity.id, entity)
    );

    return entitiesMap;
  };

  protected getCompetitions = async (competitionIds: string[]) => {
    try {
      return await this.sdk.football.getCompetitions({
        competitionIds,
      } as CompetitionFilters);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  protected getTeams = async (teamIds: string[], limit: number) => {
    try {
      const paginationResponse = await this.sdk.football.getTeams({
        teamIds,
        limit,
      } as TeamFilters);

      // When teamIds collection is empty API returns an empty list.
      return Object.keys(paginationResponse).length
        ? paginationResponse.data
        : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  protected getPlayers = async (playerIds: string[], limit: number) => {
    try {
      const paginationResponse = await this.sdk.football.getPlayers({
        playerIds,
        limit,
      } as PlayerFilters);

      // When playerIds collection is empty API returns an empty list.
      return Object.keys(paginationResponse).length
        ? paginationResponse.data
        : [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  protected getMatches = async (matchIds: string[], limit: number) => {
    try {
      const paginationResponse = await this.sdk.football.getMatches({
        matchIds,
        limit,
      } as MatchFilters);

      return paginationResponse.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  protected getEntityRootId = (entity: Entity): string => {
    if (entity === "competitions") return "fb:c";
    else if (entity === "teams") return "fb:t";
    else if (entity === "players") return "fb:p";
  };
}

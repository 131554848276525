import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../providers/ApiProvider";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { ERROR_MESSAGES, FansUnitedFeatures } from "../../enums/enums";
import { toast } from "react-toastify";
import EitherOrFeatureModel from "../../models/features/eitherOr/EitherOrFeatureModel";

export const useEitherOrFeatures = (isFeaturesEdited: any) => {
  const [eitherOrFeature, setEitherOrFeature] =
    useState<EitherOrFeatureModel>(null);

  const { clientHttps } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getEitherOrClientFeature = async () => {
    try {
      const feature = await clientHttps.getClientSpecificFeature(
        FansUnitedFeatures.EITHER_OR
      );
      setEitherOrFeature(feature);
    } catch (e) {
      toast.error(ERROR_MESSAGES.EITHER_OR_FEATURE);
      setEitherOrFeature(new EitherOrFeatureModel());
    }
  };

  useEffect(() => {
    getEitherOrClientFeature();

    return () => setEitherOrFeature(null);
  }, [selectedClient, isFeaturesEdited.current]);

  return eitherOrFeature;
};

import React from "react";
import { headerCellStyle } from "../Common/EntityFollows/TableHeaderTopFollows";
import DiscussionRecord from "./DiscussionRecord";

export type TopDiscussionReportType = "top_discussions" | "top_contributors";

type DiscussionTableViewProps = {
  type: TopDiscussionReportType;
  data: any[];
};

const DiscussionTableView: React.FC<DiscussionTableViewProps> = ({
  type,
  data,
}) => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th className={`${headerCellStyle} text-left`}>Name</th>
          <th className={`${headerCellStyle} text-right`}>Posts</th>
        </tr>
      </thead>
      <tbody>
        {data.map((record) => (
          <DiscussionRecord key={record.id} type={type} record={record} />
        ))}
      </tbody>
    </table>
  );
};

export default DiscussionTableView;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../enums/enums";
import CacheConfigModel from "../../models/Clients/CacheConfigModel";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { ApiContext } from "../../providers/ApiProvider";

export const useCacheConfigEndpoint = (apiId: string, endpoint: string) => {
  const [cacheConfig, setCacheConfig] = useState<CacheConfigModel>(null);

  const { selectedClient } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const getCacheConfig = async () => {
    try {
      const cacheConfig = await clientHttps.getClientCacheTtlConfig(
        apiId,
        endpoint
      );
      setCacheConfig(cacheConfig);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.CACHE_CONFIG);
      setCacheConfig(new CacheConfigModel());
    }
  };

  useEffect(() => {
    getCacheConfig();

    return () => setCacheConfig(null);
  }, [selectedClient]);

  return cacheConfig;
};

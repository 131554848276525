import React, { useContext, useState } from "react";
import FootballSettingsConfigModel, {
  CustomAssetsInterface,
} from "../../../../models/Clients/FootballSettingsConfigModel";
import DefaultAssets from "./Assets/DefaultAssets";
import { Entity } from "../../../../types/types";
import CustomAssets from "./Assets/CustomAssets";
import UpdateButton from "../../../Buttons/UpdateButton";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../../enums/enums";
import FootballSettingsValidator from "../../../../validators/FootballSettingsValidator";
import { ApiContext } from "../../../../providers/ApiProvider";

type FootballSettingsInfoProps = {
  config: FootballSettingsConfigModel;
  isFootballSettingsEdited: { current: boolean };
};

const FootballSettingsInfo: React.FC<FootballSettingsInfoProps> = ({
  config,
  isFootballSettingsEdited,
}) => {
  const [newFootballSettings, setNewFootballSettings] =
    useState<FootballSettingsConfigModel>(config);
  const [newCustomAssets, setNewCustomAssets] = useState<CustomAssetsInterface>(
    config.customAssets
  );

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const onChangeEnabled = () =>
    setNewFootballSettings((prevState) => {
      return {
        ...prevState,
        disableAvailableAssets: !prevState.disableAvailableAssets,
      };
    });

  const onChangeDefaultAssets = (entity: Entity, value: string) => {
    let defaultAssetsProperty: string = "";

    switch (entity) {
      case "competitions":
        defaultAssetsProperty = "competitionLogo";
        break;
      case "teams":
        defaultAssetsProperty = "teamLogo";
        break;
      case "players":
        defaultAssetsProperty = "playerHeadshot";
        break;
    }

    const currentDefaultAssets = { ...newFootballSettings.defaultAssets };
    currentDefaultAssets[defaultAssetsProperty] = value;

    setNewFootballSettings((prevState) => {
      return {
        ...prevState,
        defaultAssets: currentDefaultAssets,
      };
    });
  };

  const updateFootballSettings = async () => {
    const validator = new FootballSettingsValidator();
    validator.validateCustomAssets(newCustomAssets);

    if (!validator.isValid) {
      toast.error(validator.message);

      return;
    }

    newFootballSettings.customAssets = newCustomAssets;
    setIsLoading(true);
    const requestBody = {
      disable_available_assets: newFootballSettings.disableAvailableAssets,
      default_assets: {
        competition_logo: newFootballSettings.defaultAssets.competitionLogo
          ? newFootballSettings.defaultAssets.competitionLogo
          : null,
        team_logo: newFootballSettings.defaultAssets.teamLogo
          ? newFootballSettings.defaultAssets.teamLogo
          : null,
        player_headshot: newFootballSettings.defaultAssets.playerHeadshot
          ? newFootballSettings.defaultAssets.playerHeadshot
          : null,
      },
      custom_assets: {
        competition_logos:
          newFootballSettings.customAssets.competitionLogos &&
          Object.keys(newFootballSettings.customAssets.competitionLogos).length
            ? newFootballSettings.customAssets.competitionLogos
            : null,
        team_logos:
          newFootballSettings.customAssets.teamLogos &&
          Object.keys(newFootballSettings.customAssets.teamLogos).length
            ? newFootballSettings.customAssets.teamLogos
            : null,
        player_headshots:
          newFootballSettings.customAssets.playerHeadshots &&
          Object.keys(newFootballSettings.customAssets.playerHeadshots).length
            ? newFootballSettings.customAssets.playerHeadshots
            : null,
      },
    };

    try {
      clientHttps.updateFootballSettingsConfig(requestBody);
      toast.success(SUCCESS_MESSAGES.FOOTBALL_SETTINGS_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FOOTBALL_SETTINGS_UPDATE);
    }

    setIsLoading(false);
    isFootballSettingsEdited.current = !isFootballSettingsEdited.current;
  };

  return (
    <div className="mb-2 border border-blueGray-200 rounded-lg p-2 bg-blueGray-50">
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          defaultChecked={config.disableAvailableAssets}
          onChange={onChangeEnabled}
          className="mr-2"
        ></input>
        <span>Enable Overriding Football Assets</span>
      </div>
      <DefaultAssets
        defaultValue={newFootballSettings.defaultAssets}
        onChange={onChangeDefaultAssets}
      />
      <CustomAssets
        loadedCustomAssets={config.customAssets}
        customAssets={newCustomAssets}
        setCustomAssets={setNewCustomAssets}
      />
      <UpdateButton
        label={"Update"}
        isLoading={isLoading}
        onClick={updateFootballSettings}
      />
    </div>
  );
};

export default FootballSettingsInfo;

import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionFilters";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { asyncSelectStyles } from "../../../../../../../constants/styles";
import SelectOption from "../../../../../../../models/SelectOption/SelectOption";
import { ApiContext } from "../../../../../../../providers/ApiProvider";
import AsyncSelect from "react-select/async";
import { placeholders } from "../../../../../../../assets/placeholders/placeholders";
import _ from "lodash";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../../../../../enums/enums";
import { formatOptions } from "../../../../../../../helpers/helpers";

type SuccessRateCompetitionSelectProps = {
  loadedCompetitions: CompetitionBasicModel[];
  maximumCompetitions: number;
  setCompetitionIds: Dispatch<SetStateAction<string[]>>;
};

const SuccessRateCompetitionSelect: React.FC<
  SuccessRateCompetitionSelectProps
> = ({ loadedCompetitions, maximumCompetitions, setCompetitionIds }) => {
  const loadedCompetitionOptions = loadedCompetitions?.map(
    (competition: CompetitionBasicModel) => formatOptions(competition)
  );
  const [competitions, setCompetitions] = useState<SelectOption[]>(
    loadedCompetitionOptions
  );

  const api = useContext(ApiContext);

  const minInputLength = 3;

  const search = (inputValue: string, callback: (options: any[]) => void) => {
    if (inputValue.length < minInputLength) return callback([]);

    let competitionFilters: CompetitionFilters = null;
    let options: SelectOption[] = null;

    if (inputValue) {
      competitionFilters = {
        name: inputValue,
      } as CompetitionFilters;
    }

    api?.sdk.football
      .getCompetitions(competitionFilters)
      .then((response: CompetitionBasicModel[]) => {
        options = response.map((competition: CompetitionBasicModel) =>
          formatOptions(competition)
        );
        callback(options);
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.FOOTBALL_DATA);
        callback([]);
      });
  };

  const handleOnChange = (selectedOption: SelectOption[]) => {
    const competitionIds = selectedOption.map(
      (selectedOption: SelectOption) => selectedOption.id
    );
    setCompetitionIds(competitionIds);
    setCompetitions(selectedOption);
  };

  const loadOptions = _.debounce(search, 300);

  const noOptionsMessage = (input) =>
    input.inputValue.length >= minInputLength
      ? "No competition found"
      : "Type at least 3 characters";

  return (
    <AsyncSelect
      components={{ DropdownIndicator: null }}
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      styles={asyncSelectStyles}
      isMulti
      onChange={handleOnChange}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Loading..."}
      isOptionDisabled={() => competitions.length === maximumCompetitions}
      defaultOptions
      value={competitions}
      loadOptions={loadOptions}
      placeholder=""
      formatOptionLabel={(option) => (
        <div>
          <div className="flex flex-justify-start mb-2">
            <img
              src={option.logo ? option.logo : placeholders.competition}
              alt={""}
              className="h-5 w-5"
            />
            <span>{option.label}</span>
          </div>
          <div className="flex flex-justify-start">
            <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
            <span>{option.countryName}</span>
          </div>
        </div>
      )}
    />
  );
};

export default SuccessRateCompetitionSelect;

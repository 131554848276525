import React from "react";
import ButtonSpinner from "../Spinner/ButtonSpinner";

type LoginButtonType = "login" | "edit_profile" | "delete_staff_member";

type LoadingButtonProps = {
  type: LoginButtonType;
  isLoading: boolean;
};

const loadingButtonStyle = (type: LoginButtonType): string => {
  let style = "";

  switch (type) {
    case "login":
      style =
        "flex items-center justify-center cursor-default bg-blueGray-800 text-white text-sm font-bold uppercase px-6 py-3 rounded w-full mt-6";
      break;
    case "edit_profile":
      style =
        "flex items-center bg-blueGray-800 text-white font-bold uppercase text-xs px-3 py-2 rounded shadow outline-none mr-1";
      break;
    case "delete_staff_member":
      style =
        "flex items-center bg-blueGray-800 text-white font-bold uppercase text-sm px-6 py-3 rounded outline-none mr-1 mb-1 cursor-default";
      break;
  }

  return style;
};

const LoadingButton: React.FC<LoadingButtonProps> = ({ type, isLoading }) => {
  return (
    <>
      {isLoading && (
        <button className={loadingButtonStyle(type)} disabled>
          <ButtonSpinner />
          Loading...
        </button>
      )}
    </>
  );
};

export default LoadingButton;

import ActionValueModel from "fansunited-sdk-esm/Core/Namespaces/Loyalty/Models/Actions/ActionValueModel";
import React from "react";
import Action from "./Action";

type LoyaltyActionsProps = {
  actions: ActionValueModel[];
};

const LoyaltyActions: React.FC<LoyaltyActionsProps> = ({ actions }) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <label className="font-bold mb-2">Actions</label>
      <p>All user actions related to interacting with Fans United platform.</p>
      <div>
        <ul className="md:grid md:gap-2 md:grid-cols-3">
          {actions &&
            actions.length &&
            actions.map((action: ActionValueModel) => (
              <Action key={`${action.id}-user-action`} action={action} />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default LoyaltyActions;

import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import BadgesExtendedModel from "../../../../../models/features/loyalty/BadgesExtendedModel/BadgesExtendedModel";
import PredictorBadgesExtended from "../../../../../models/features/loyalty/Badges/Predictor/PredictorBadgesExtended";
import BadgeEntity from "./BadgeEntity/BadgeEntity";
import snakeCase from "lodash/snakeCase";
import GameBadgesExtended from "../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";
import FootballBadgesExtended from "../../../../../models/features/loyalty/Badges/Football/FootballBadgesExtended";
import MiniGamesBadgesExtended from "../../../../../models/features/loyalty/Badges/MiniGames/MiniGamesBadgesExtended";
import DiscussionsBadgesExtended from "../../../../../models/features/loyalty/Badges/Discussions/DiscussionsBadgesExtended";
import VotingBadgesExtended from "../../../../../models/features/loyalty/Badges/Voting/VotingBadgesExtended";
import GlobalValidator from "../../../../../validators/GlobalValidator";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../../../../enums/enums";
import {
  remapKeysToSpecificCase,
  removePropertyFromObject,
} from "../../../../../helpers/helpers";
import { ApiContext } from "../../../../../providers/ApiProvider";
import UpdateButton from "../../../../Buttons/UpdateButton";
import GamesBadgesExtended from "../../../../../models/features/loyalty/Badges/Games/GamesBadgesExtended";

const validator = new GlobalValidator();

const badgesTabs = [
  "predictor",
  "top x",
  "match quiz",
  "football",
  "games",
  "classic quizzes",
  "either or",
  "discussions",
  "voting poll",
  "voting potm",
];

const setNewValueForBadge = (
  badges: any[],
  uid: string,
  field: string,
  requirementField: string,
  value: any
) => {
  return badges.map((badge) => {
    if (badge.uid === uid) {
      if (field === "assets") {
        if (!badge.assets) {
          badge.assets = {
            mainImageUrl: "",
          };
        }

        badge.assets.mainImageUrl = value;

        return badge;
      }

      if (requirementField) {
        if (requirementField === "dateRange") {
          // For date range fields we have both from and to dates
          if (value) {
            const [fromDate, toDate] = value;
            badge.requirements.fromDate = fromDate;
            badge.requirements.toDate = toDate;

            return badge;
          } else {
            badge.requirements.fromDate = null;
            badge.requirements.toDate = null;

            return badge;
          }
        }

        if (
          requirementField !== "entityId" &&
          requirementField !== "entityType" &&
          requirementField !== "specificGames"
        ) {
          badge.requirements[requirementField] = Number(value);
        } else {
          badge.requirements[requirementField] = value;
        }
      } else {
        badge[field] = value;
      }
    }

    return badge;
  });
};

type LoyaltyBadgesProps = {
  originalBadges: BadgesExtendedModel;
};

const LoyaltyBadges: React.FC<LoyaltyBadgesProps> = ({ originalBadges }) => {
  const [badgeTab, setBadgeTab] = useState<string>(badgesTabs[0]);
  const [requestBody, setRequestBody] = useState<BadgesExtendedModel>(
    JSON.parse(JSON.stringify(originalBadges))
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clientHttps } = useContext(ApiContext);

  const badges = originalBadges;

  const onChangeBadges = (
    badgeType: string,
    uid: string,
    field: string,
    requirementField: string,
    value: any
  ) => {
    switch (badgeType) {
      case "predictor":
        if (field === "flags") {
          //Update the UI variable
          badges.predictor.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            predictor: setNewValueForBadge(
              prevState.predictor,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "top_x":
        if (field === "flags") {
          //Update the UI variable
          badges.topX.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            topX: setNewValueForBadge(
              prevState.topX,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "match_quiz":
        if (field === "flags") {
          //Update the UI variable
          badges.matchQuiz.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            matchQuiz: setNewValueForBadge(
              prevState.matchQuiz,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "football":
        //Update the UI variable
        if (field === "flags") {
          badges.football.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        if (requirementField === "entity") {
          badges.football.find(
            (badge) => badge.uid === uid
          ).requirements.entityId = value.id;
          badges.football.find(
            (badge) => badge.uid === uid
          ).requirements.entityModel = value;

          setRequestBody((prevState) => {
            return {
              ...prevState,
              football: setNewValueForBadge(
                prevState.football,
                uid,
                field,
                "entityId",
                value.id
              ),
            };
          });
          // We break, because we have updated the request body.
          break;
        }

        if (requirementField === "entityType") {
          badges.football.find(
            (badge) => badge.uid === uid
          ).requirements.entityType = value;
          badges.football.find(
            (badge) => badge.uid === uid
          ).requirements.entityModel = null;
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            football: setNewValueForBadge(
              prevState.football,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "games":
        if (field === "flags") {
          //Update the UI variable
          badges.games.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            games: setNewValueForBadge(
              prevState.games,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "classic_quizzes":
        if (field === "flags") {
          //Update the UI variable
          badges.classicQuizzes.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            classicQuizzes: setNewValueForBadge(
              prevState.classicQuizzes,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "either_or":
        if (field === "flags") {
          //Update the UI variable
          badges.eitherOr.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            eitherOr: setNewValueForBadge(
              prevState.eitherOr,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "discussions":
        if (field === "flags") {
          //Update the UI variable
          badges.discussions.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        setRequestBody((prevState) => {
          return {
            ...prevState,
            discussions: setNewValueForBadge(
              prevState.discussions,
              uid,
              field,
              requirementField,
              value
            ),
          };
        });
        break;
      case "voting_poll":
        if (field === "flags") {
          //Update the UI variable
          badges.voting.poll.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        if (requirementField === "entity") {
          badges.voting.poll.find(
            (badge) => badge.uid === uid
          ).requirements.entityId = value.id;
          badges.voting.poll.find(
            (badge) => badge.uid === uid
          ).requirements.entityModel = value;

          setRequestBody((prevState) => {
            const votingBadges = { ...prevState.voting };
            votingBadges.poll = setNewValueForBadge(
              votingBadges.poll,
              uid,
              field,
              "entityId",
              value.id
            );
            return { ...prevState, voting: votingBadges };
          });
          // We break, because we have updated the request body.
          break;
        }

        if (requirementField === "entityType") {
          badges.voting.poll.find(
            (badge) => badge.uid === uid
          ).requirements.entityType = value;
          badges.voting.poll.find(
            (badge) => badge.uid === uid
          ).requirements.entityModel = null;
        }

        setRequestBody((prevState) => {
          const votingBadges = { ...prevState.voting };
          votingBadges.poll = setNewValueForBadge(
            votingBadges.poll,
            uid,
            field,
            requirementField,
            value
          );

          return { ...prevState, voting: votingBadges };
        });
        break;
      case "voting_potm":
        if (field === "flags") {
          //Update the UI variable
          badges.voting.potm.find((badge) => badge.uid === uid).flags =
            Array.from(value);
        }

        if (requirementField === "entity") {
          badges.voting.potm.find(
            (badge) => badge.uid === uid
          ).requirements.entityId = value.id;
          badges.voting.potm.find(
            (badge) => badge.uid === uid
          ).requirements.entityModel = value;

          setRequestBody((prevState) => {
            const votingBadges = { ...prevState.voting };
            votingBadges.potm = setNewValueForBadge(
              votingBadges.potm,
              uid,
              field,
              "entityId",
              value.id
            );
            return { ...prevState, voting: votingBadges };
          });
          // We break, because we have updated the request body.
          break;
        }

        if (requirementField === "entityType") {
          badges.voting.potm.find(
            (badge) => badge.uid === uid
          ).requirements.entityType = value;
          badges.voting.potm.find(
            (badge) => badge.uid === uid
          ).requirements.entityModel = null;
        }

        setRequestBody((prevState) => {
          const votingBadges = { ...prevState.voting };
          votingBadges.potm = setNewValueForBadge(
            votingBadges.potm,
            uid,
            field,
            requirementField,
            value
          );

          return { ...prevState, voting: votingBadges };
        });
        break;
    }
  };

  const removeExistingBadge = (badgeType: string, rewardUid: string) => {
    switch (badgeType) {
      case "predictor":
        setRequestBody((prevState) => {
          let copyPredictorBadges = Array.from(prevState.predictor);
          copyPredictorBadges = copyPredictorBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.predictor = Array.from(copyPredictorBadges);

          return {
            ...prevState,
            predictor: copyPredictorBadges,
          };
        });
        break;
      case "top_x":
        setRequestBody((prevState) => {
          let copyTopXBadges = Array.from(prevState.topX);
          copyTopXBadges = copyTopXBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.topX = Array.from(copyTopXBadges);

          return {
            ...prevState,
            topX: copyTopXBadges,
          };
        });
        break;
      case "match_quiz":
        setRequestBody((prevState) => {
          let copyMachQuizBadges = Array.from(prevState.matchQuiz);
          copyMachQuizBadges = copyMachQuizBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.matchQuiz = Array.from(copyMachQuizBadges);

          return {
            ...prevState,
            matchQuiz: copyMachQuizBadges,
          };
        });
        break;
      case "football":
        setRequestBody((prevState) => {
          let copyFootballBadges = Array.from(prevState.football);
          copyFootballBadges = copyFootballBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.football = Array.from(copyFootballBadges);

          return {
            ...prevState,
            football: copyFootballBadges,
          };
        });
        break;
      case "games":
        setRequestBody((prevState) => {
          let copyGamesBadges = Array.from(prevState.games);
          copyGamesBadges = copyGamesBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.games = Array.from(copyGamesBadges);

          return {
            ...prevState,
            games: copyGamesBadges,
          };
        });
        break;
      case "classic_quizzes":
        setRequestBody((prevState) => {
          let copyClassicQuizzesBadges = Array.from(prevState.classicQuizzes);
          copyClassicQuizzesBadges = copyClassicQuizzesBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.classicQuizzes = Array.from(copyClassicQuizzesBadges);

          return {
            ...prevState,
            classicQuizzes: copyClassicQuizzesBadges,
          };
        });
        break;
      case "either_or":
        setRequestBody((prevState) => {
          let copyEitherOrBadges = Array.from(prevState.eitherOr);
          copyEitherOrBadges = copyEitherOrBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.eitherOr = Array.from(copyEitherOrBadges);

          return {
            ...prevState,
            eitherOr: copyEitherOrBadges,
          };
        });
        break;
      case "discussions":
        setRequestBody((prevState) => {
          let copyDiscussionsBadges = Array.from(prevState.discussions);
          copyDiscussionsBadges = copyDiscussionsBadges.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.discussions = Array.from(copyDiscussionsBadges);

          return {
            ...prevState,
            discussions: copyDiscussionsBadges,
          };
        });
        break;
      case "voting_poll":
        setRequestBody((prevState) => {
          let copyVotingBadges = { ...prevState.voting };
          copyVotingBadges.poll = copyVotingBadges.poll.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.voting.poll = Array.from(copyVotingBadges.poll);

          return { ...prevState, voting: copyVotingBadges };
        });
        break;
      case "voting_potm":
        setRequestBody((prevState) => {
          let copyVotingBadges = { ...prevState.voting };
          copyVotingBadges.potm = copyVotingBadges.potm.filter(
            (badge) => badge.uid !== rewardUid
          );
          badges.voting.potm = Array.from(copyVotingBadges.potm);

          return { ...prevState, voting: copyVotingBadges };
        });
        break;
    }
  };

  const addNewBadge = (badgeType: string, idx: number) => {
    switch (badgeType) {
      case "predictor":
        const predictorBadge = new PredictorBadgesExtended();
        predictorBadge.uid = `new-badge-predictor-${idx}`;
        setRequestBody((prevState) => {
          const copyPredictorBadges = Array.from(prevState.predictor);
          copyPredictorBadges.push(predictorBadge);
          badges.predictor = Array.from(copyPredictorBadges);

          return {
            ...prevState,
            predictor: copyPredictorBadges,
          };
        });
        break;
      case "top_x":
        const topXBadge = new GameBadgesExtended();
        topXBadge.uid = `new-badge-top-x-${idx}`;
        setRequestBody((prevState) => {
          const copyTopXBadges = Array.from(prevState.topX);
          copyTopXBadges.push(topXBadge);
          badges.topX = Array.from(copyTopXBadges);

          return {
            ...prevState,
            topX: copyTopXBadges,
          };
        });
        break;
      case "match_quiz":
        const matchQuizBadge = new GameBadgesExtended();
        matchQuizBadge.uid = `new-badge-match-quiz-${idx}`;
        setRequestBody((prevState) => {
          const copyMatchQuizBadges = Array.from(prevState.matchQuiz);
          copyMatchQuizBadges.push(matchQuizBadge);
          badges.matchQuiz = Array.from(copyMatchQuizBadges);

          return {
            ...prevState,
            matchQuiz: copyMatchQuizBadges,
          };
        });
        break;
      case "football":
        const footballBadge = new FootballBadgesExtended();
        footballBadge.uid = `new-badge-football-${idx}`;
        setRequestBody((prevState) => {
          const copyFootballBadges = Array.from(prevState.football);
          copyFootballBadges.push(footballBadge);
          badges.football = Array.from(copyFootballBadges);

          return {
            ...prevState,
            football: copyFootballBadges,
          };
        });
        break;
      case "games":
        const gameBadge = new GamesBadgesExtended();
        gameBadge.uid = `new-badge-game-${idx}`;
        setRequestBody((prevState) => {
          const copyGameBadges = Array.from(prevState.games);
          copyGameBadges.push(gameBadge);
          badges.games = Array.from(copyGameBadges);

          return {
            ...prevState,
            games: copyGameBadges,
          };
        });
        break;
      case "classic_quizzes":
        const classicQuizBadge = new MiniGamesBadgesExtended();
        classicQuizBadge.uid = `new-badge-classic-quiz-${idx}`;
        setRequestBody((prevState) => {
          const copyClassicQuizBadges = Array.from(prevState.classicQuizzes);
          copyClassicQuizBadges.push(classicQuizBadge);
          badges.classicQuizzes = Array.from(copyClassicQuizBadges);

          return {
            ...prevState,
            classicQuizzes: copyClassicQuizBadges,
          };
        });
        break;
      case "either_or":
        const eitherOrBadge = new MiniGamesBadgesExtended();
        eitherOrBadge.uid = `new-badge-either-or-${idx}`;
        setRequestBody((prevState) => {
          const copyEitherOrBadges = Array.from(prevState.eitherOr);
          copyEitherOrBadges.push(eitherOrBadge);
          badges.eitherOr = Array.from(copyEitherOrBadges);

          return {
            ...prevState,
            eitherOr: copyEitherOrBadges,
          };
        });
        break;
      case "discussions":
        const discussionsBadge = new DiscussionsBadgesExtended();
        discussionsBadge.uid = `new-badge-discussions-${idx}`;
        setRequestBody((prevState) => {
          const copyDiscussionsBadges = Array.from(prevState.discussions);
          copyDiscussionsBadges.push(discussionsBadge);
          badges.discussions = Array.from(copyDiscussionsBadges);

          return {
            ...prevState,
            discussions: copyDiscussionsBadges,
          };
        });
        break;
      case "voting_poll":
        const pollBadge = new VotingBadgesExtended();
        pollBadge.uid = `new-badge-poll-${idx}`;
        badges.voting.poll.push(pollBadge);
        setRequestBody((prevState) => {
          const copyVotingBadges = { ...prevState.voting };
          copyVotingBadges.poll.push(pollBadge);

          return {
            ...prevState,
            voting: copyVotingBadges,
          };
        });
        break;
      case "voting_potm":
        const potmBadge = new VotingBadgesExtended();
        potmBadge.uid = `new-badge-potm-${idx}`;
        badges.voting.potm.push(potmBadge);
        setRequestBody((prevState) => {
          const copyVotingBadges = { ...prevState.voting };
          copyVotingBadges.potm.push(potmBadge);

          return {
            ...prevState,
            voting: copyVotingBadges,
          };
        });
        break;
    }
  };

  const updateBadges = async () => {
    const id = "id";

    if (validator.isPropertyWithInvalidValue(requestBody, id)) {
      toast.error(ERROR_MESSAGES.FEATURES_ID_VALUES_EMPTY);
      return;
    }

    setIsLoading(true);
    const uid = "uid";
    const entityModel = "entityModel";
    const requestBodyWithNoUid = removePropertyFromObject(
      JSON.parse(JSON.stringify(requestBody)),
      uid
    );
    const originalRequestBody = removePropertyFromObject(
      JSON.parse(JSON.stringify(requestBodyWithNoUid)),
      entityModel
    );
    const reqBodySnakeCase = remapKeysToSpecificCase(
      originalRequestBody,
      "snake"
    );

    try {
      await clientHttps.updateBadgesForClient(reqBodySnakeCase);
      toast.success(SUCCESS_MESSAGES.BADGES_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.BADGES_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      <p className="mb-2">
        Every user will receive different badges when he covers specific
        requirements. The badges are separated in {badgesTabs.length} groups:
        Predictor, Top X, Match Quiz, Football, Games, Classic Quizzes, Either
        Or, Discussions and Voting.
      </p>
      <ul
        className="flex mb-0 list-none flex-wrap py-3 flex-row"
        role="tablist"
      >
        {badgesTabs.map((tab: string, index: number) => (
          <li
            className="mb-2 mr-2 flex-auto text-center"
            key={`${tab}-${index}`}
          >
            <a
              className={
                "text-xs font-bold uppercase p-4 rounded block leading-normal " +
                (badgeTab === snakeCase(tab)
                  ? "text-lightBlue-600 bg-white"
                  : "text-white bg-gray-400")
              }
              onClick={(e) => {
                e.preventDefault();
                setBadgeTab(snakeCase(tab));
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              {tab}
            </a>
          </li>
        ))}
      </ul>
      <div className="mb-2">
        <div
          className={badgeTab === "predictor" ? "block" : "hidden"}
          id="Edit loyalty predictor badges "
        >
          <BadgeEntity
            badges={badges.predictor}
            type={"predictor"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "top_x" ? "block" : "hidden"}
          id="Edit loyalty top x badges "
        >
          <BadgeEntity
            badges={badges.topX}
            type={"top_x"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "match_quiz" ? "block" : "hidden"}
          id="Edit loyalty match quiz badges "
        >
          <BadgeEntity
            badges={badges.matchQuiz}
            type={"match_quiz"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "football" ? "block" : "hidden"}
          id="Edit loyalty match quiz badges "
        >
          <BadgeEntity
            badges={badges.football}
            type={"football"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "games" ? "block" : "hidden"}
          id="Edit loyalty match quiz badges "
        >
          <BadgeEntity
            badges={badges.games}
            type={"games"}
            requestBodyGameBadges={requestBody.games}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "classic_quizzes" ? "block" : "hidden"}
          id="Edit loyalty classic quizzes badges "
        >
          <BadgeEntity
            badges={badges.classicQuizzes}
            type={"classic_quizzes"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "either_or" ? "block" : "hidden"}
          id="Edit loyalty classic quizzes badges "
        >
          <BadgeEntity
            badges={badges.eitherOr}
            type={"either_or"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "discussions" ? "block" : "hidden"}
          id="Edit loyalty discussions badges "
        >
          <BadgeEntity
            badges={badges.discussions}
            type={"discussions"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "voting_poll" ? "block" : "hidden"}
          id="Edit loyalty poll badges "
        >
          <BadgeEntity
            badges={badges.voting.poll}
            type={"voting_poll"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
        <div
          className={badgeTab === "voting_potm" ? "block" : "hidden"}
          id="Edit loyalty potm badges "
        >
          <BadgeEntity
            badges={badges.voting.potm}
            type={"voting_potm"}
            onChangeBadges={(uid, field, requirementField, value) =>
              onChangeBadges(badgeTab, uid, field, requirementField, value)
            }
            addNewBadge={(idx) => addNewBadge(badgeTab, idx)}
            removeExistingBadge={(uid) => removeExistingBadge(badgeTab, uid)}
          />
        </div>
      </div>
      <UpdateButton
        label={"Update Badges"}
        onClick={updateBadges}
        isLoading={isLoading}
      />
    </>
  );
};

export default LoyaltyBadges;

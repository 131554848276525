import React, { Dispatch, SetStateAction } from "react";
import ReactSelectBaseOption from "../../../../../models/SelectOption/ReactSelectBaseOption";
import { PredictionsEngagementTypes } from "./EngagementThroughPredictions";
import Select from "react-select";
import { marketSelectStyles } from "../../../../../constants/styles";

const engagementTypes = [
  { id: "all", value: "ALL", label: "All" },
  { id: "single", value: "SINGLE", label: "Single" },
  { id: "game", value: "GAME", label: "Games" },
  { id: "top_x", value: "TOP_X", label: "Top X" },
  { id: "match_quiz", value: "MATCH_QUIZ", label: "Match Quiz" },
];

type EngagementSelectTypeProps = {
  setType: Dispatch<SetStateAction<PredictionsEngagementTypes>>;
};

const EngagementSelectType: React.FC<EngagementSelectTypeProps> = ({
  setType,
}) => {
  const marketsHandler = (selectOption: ReactSelectBaseOption) => {
    const newType = selectOption
      ? (selectOption.value as PredictionsEngagementTypes)
      : "ALL";
    setType(newType);
  };

  return (
    <div className="w-full mb-3">
      <Select
        getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
        styles={marketSelectStyles}
        isClearable
        onChange={marketsHandler}
        options={engagementTypes}
        placeholder={"Select type..."}
        defaultValue={{ id: "all", value: "ALL", label: "All" }}
      />
    </div>
  );
};

export default EngagementSelectType;

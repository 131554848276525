import BadgesExtended from "../BadgesExtended";

export interface IGameBadgeRequirements {
  participationCount: number;
  points: number;
  fromDate: string;
  toDate: string;
}

export default class GameBadgesExtended extends BadgesExtended {
  public requirements: IGameBadgeRequirements = {
    participationCount: 0,
    points: 0,
    fromDate: null,
    toDate: null,
  };
}

interface GoogleEndpointsKeysInterface {
    label: string;
    value: string;
}

interface SecretsInterface {
    firebaseCredentials: string;
}

export default class ClientFullInfo {
    id: string = "";
    name: string = "";
    googleEndpointsKeys: GoogleEndpointsKeysInterface[] = [];
    secrets: SecretsInterface = null;
    audience: string = "";
    audiences: string = "";
    createdAt: string = "";
    updatedAt: string = "";
}
import React from "react";
import { upperCase } from "../../../../helpers/helpers";
import {
  TopFollowedEntitiy,
  TopFollowedSports,
} from "../../../../models/Reports/FollowedEntitiesModel";
import FormattedNumber from "../../../Common/FormattedNumber";

const cellStyle =
  "align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-blueGray-500 border-blueGray-100";
const flexedContainer = "flex items-center";
export const imageStyle = "h-12 w-12 bg-white rounded-full border";
const textStyle = "font-bold text-blueGray-600";

type TopFollowedEntityRowProps = {
  value: TopFollowedEntitiy | TopFollowedSports;
  entity: string;
};

const TopFollowedEntityRow: React.FC<TopFollowedEntityRowProps> = ({
  value,
  entity,
}) => {
  if (entity === "topFollowedSports") {
    return (
      <tr>
        <th className={`${cellStyle} text-left px-6`}>
          <div className={flexedContainer}>
            <img src={value.logo} className={imageStyle} alt={value.name} />{" "}
            <span className={`${textStyle} ml-3`}>{upperCase(value.name)}</span>
          </div>
        </th>
        <td
          style={{ paddingRight: "4rem" }}
          className={`${cellStyle} text-right cursor-default`}
        >
          <FormattedNumber numberValue={value.followers} />
        </td>
      </tr>
    );
  }

  const newValue = value as TopFollowedEntitiy;

  return (
    <tr>
      <th
        className={`${cellStyle} text-left ${
          entity !== "topFollowedCompetitions" ? "px-6" : "px-3"
        }`}
      >
        <div className={flexedContainer}>
          <img
            src={newValue.logo}
            alt={newValue.name}
            className={
              entity !== "topFollowedCompetitions" ? imageStyle : "h-12 w-14"
            }
          ></img>
          <span
            className={`${textStyle} ${
              entity !== "topFollowedCompetitions" ? "ml-3" : "ml-1"
            }`}
          >
            {newValue.name}
          </span>
        </div>
      </th>
      <td className={`${cellStyle} text-left px-6`}>
        <div className={flexedContainer}>
          <img
            src={newValue.countryLogo}
            className={imageStyle}
            alt={newValue.countryName}
          ></img>{" "}
          <span className={`${textStyle} ml-3`}>{newValue.countryName}</span>
        </div>
      </td>
      <td className={`${cellStyle} text-center cursor-default`}>
        <FormattedNumber numberValue={newValue.followers} />
      </td>
    </tr>
  );
};

export default TopFollowedEntityRow;

import React from "react";
import { useHistory } from "react-router-dom";
import { MainPagesEnum } from "../../../../enums/enums";

type TableHeaderProps = {
  title: string;
  entityKey?: string;
};

const TableHeader: React.FC<TableHeaderProps> = ({ title, entityKey }) => {
  const history = useHistory();

  return (
    <div className="rounded-t mb-0 px-2 py-3 border-0">
      <div className="flex flex-wrap items-center">
        <div className="w-full flex items-center justify-between">
          <h3 className="font-semibold text-lg text-blueGray-700">{title}</h3>
          {entityKey && entityKey !== "topFollowedSports" ? (
            <a
              className="text-brandColor font-semibold text-sm cursor-pointer hover:text-lightBlue-800 pr-3"
              onClick={() => history.push(`/${MainPagesEnum.REPORTS}/football`)}
            >
              Learn more
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TableHeader;

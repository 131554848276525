import React from "react";
import LoadingButton from "./LoadingButton";

type UpdateButtonProps = {
  label: string;
  isLoading: boolean;
  disableButton?: boolean;
  onClick: () => void;
};

const UpdateButton: React.FC<UpdateButtonProps> = ({
  label,
  isLoading,
  disableButton,
  onClick,
}) => {
  if (isLoading) {
    return <LoadingButton type={"edit_profile"} isLoading={isLoading} />;
  }

  return (
    <button
      className="bg-brandColor text-white hover:bg-lightBlue-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button"
      onClick={onClick}
      disabled={disableButton}
    >
      {label}
    </button>
  );
};

export default UpdateButton;

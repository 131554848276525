import React, { useState } from "react";
import PrivateLeaguesReportTabs from "./PrivateLeaguesReportTabs";
import PrivateLeagueReportUI from "./PrivateLeagueReportUI";

export enum PRIVATE_LEAGUES_REPORTS_TABS {
  BREAKDOWN = "breakdown",
  TOP_PRIVATE_LEAGUES_TEMPLATES = "top_private_leagues_templates",
  USERS = "users",
}

const PrivateLeaguesReport: React.FC = () => {
  const [openTab, setOpenTab] = useState<string>(
    PRIVATE_LEAGUES_REPORTS_TABS.BREAKDOWN
  );

  return (
    <div className="w-full px-4">
      <PrivateLeaguesReportTabs tabValue={openTab} setTabValue={setOpenTab} />
      <PrivateLeagueReportUI openTab={openTab} />
    </div>
  );
};

export default PrivateLeaguesReport;

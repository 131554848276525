import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES } from "../../../enums/enums";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { setDefaultCoparedToDateApi } from "../../../helpers/helpers";
import ReportPeriods from "../../../models/Reports/ReportPeriods";
import MiniGamesParticipations from "../../../models/Reports/MiniGames/MiniGamesParticipations";

export const useClassicQuizBreakdown = () => {
  const [classicQuizBreakdown, setClassicQuizBreakdown] =
    useState<ReportPeriods<MiniGamesParticipations>>(null);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);

  const getClassicQuizBreakdown = async () => {
    try {
      const classicQuizParticipation =
        await api.reportingHttps.getClassicQuizBreakdown(datePeriod, groupBy);
      let comparedToBreakdown: any = null;

      if (comparedTo) {
        comparedToBreakdown = await api.reportingHttps.getClassicQuizBreakdown(
          comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
          groupBy
        );
      }

      setClassicQuizBreakdown({
        current: classicQuizParticipation,
        comparedTo: comparedToBreakdown,
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.MINI_GAMES_BREAKDOWN_REPORT);
      setClassicQuizBreakdown({
        current: new MiniGamesParticipations(),
        comparedTo: new MiniGamesParticipations(),
      });
    }
  };

  useEffect(() => {
    getClassicQuizBreakdown();

    return () => setClassicQuizBreakdown(null);
  }, [selectedClient, comparedToPeriod, groupBy, datePeriod, comparedTo]);

  return classicQuizBreakdown;
};

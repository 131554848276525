import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth, connectAuthEmulator } from "firebase/auth";

let isFirebaseInitialized = false;

export const firebaseAuth = () => {
  if (isFirebaseInitialized) return firebase.auth();

  if (process.env.REACT_APP_MANAGEMENT_PORTAL_ENVIRONMENT === "dev") {
    const apiKey = "apiKey";
    firebase.initializeApp({
      apiKey,
    });
    const auth = getAuth();
    connectAuthEmulator(
      auth,
      process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST as string
    );
  } else {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    firebase.initializeApp(firebaseConfig);
  }

  isFirebaseInitialized = true;

  return firebase.auth();
};
